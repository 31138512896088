import React from 'react';
import { Lock } from 'lucide-react';

export const PersonalDetails = ({ formData = {}, onChange, errors = {} }) => {
  return (
    <div className="w-full space-y-6">
      {/* First Name */}
      <div className="relative">
        <label className="absolute -top-2.5 left-4 px-2 bg-[#fef6ef] text-gray-500 text-sm">
          First Name
        </label>
        <input
          type="text"
          name="firstName"
          value={formData.firstName || ''}
          onChange={onChange}
          placeholder="John"
          className={`w-full px-4 py-3 bg-transparent border ${errors.firstName ? 'border-red-500' : 'border-gray-300'} rounded-full 
            focus:outline-none focus:border-gray-400
            placeholder-gray-400 text-gray-800`}
        />
        {errors.firstName && (
          <p className="text-red-500 text-xs mt-1 ml-4">{errors.firstName}</p>
        )}
      </div>

      {/* Last Name */}
      <div className="relative">
        <label className="absolute -top-2.5 left-4 px-2 bg-[#fef6ef] text-gray-500 text-sm">
          Last Name
        </label>
        <input
          type="text"
          name="lastName"
          value={formData.lastName || ''}
          onChange={onChange}
          placeholder="Doe"
          className={`w-full px-4 py-3 bg-transparent border ${errors.lastName ? 'border-red-500' : 'border-gray-300'} rounded-full 
            focus:outline-none focus:border-gray-400
            placeholder-gray-400 text-gray-800`}
        />
        {errors.lastName && (
          <p className="text-red-500 text-xs mt-1 ml-4">{errors.lastName}</p>
        )}
      </div>

      {/* Password */}
      <div className="relative">
        <label className="absolute -top-2.5 left-4 px-2 bg-[#fef6ef] text-gray-500 text-sm">
          Password
        </label>
        <span className="absolute left-4 top-3.5 text-gray-400">
          <Lock size={20} />
        </span>
        <input
          type="password"
          name="password"
          value={formData.password || ''}
          onChange={onChange}
          placeholder="Let's make this secure"
          className={`w-full pl-12 pr-4 py-3 bg-transparent border ${errors.password ? 'border-red-500' : 'border-gray-300'} rounded-full 
            focus:outline-none focus:border-gray-400
            placeholder-gray-400 text-gray-800`}
        />
        {errors.password && (
          <p className="text-red-500 text-xs mt-1 ml-4">{errors.password}</p>
        )}
      </div>

      {/* Confirm Password */}
      <div className="relative">
        <label className="absolute -top-2.5 left-4 px-2 bg-[#fef6ef] text-gray-500 text-sm">
          Confirm Password
        </label>
        <span className="absolute left-4 top-3.5 text-gray-400">
          <Lock size={20} />
        </span>
        <input
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword || ''}
          onChange={onChange}
          placeholder="One more time"
          className={`w-full pl-12 pr-4 py-3 bg-transparent border ${errors.confirmPassword ? 'border-red-500' : 'border-gray-300'} rounded-full 
            focus:outline-none focus:border-gray-400
            placeholder-gray-400 text-gray-800`}
        />
        {errors.confirmPassword && (
          <p className="text-red-500 text-xs mt-1 ml-4">{errors.confirmPassword}</p>
        )}
      </div>
    </div>
  );
};

export default PersonalDetails;