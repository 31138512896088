// src/components/referrals/ReferralDocumentList.js
import React, { useState, useEffect } from 'react';
import { FileText, Download, Upload, Loader, AlertCircle, X } from 'lucide-react';
import { documentReferralService } from '../../backend/referralServices/DocumentReferralService';
import { useS3Upload } from '../../backend/uploadHook';

const ReferralDocumentList = ({ 
  referralId, 
  isOutgoing = false,
  onRefresh 
}) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Use the hook at the component level
  const { 
    uploadFile, 
    deleteFile, 
    uploading, 
    error: uploadError, 
    progress,
    resetState 
  } = useS3Upload({
    maxSizeMB: 10,
    allowedTypes: ['application/pdf', 'image/jpeg', 'image/png', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    folder: 'referral-documents'
  });

  useEffect(() => {
    fetchDocuments();
  }, [referralId]);

  const fetchDocuments = async () => {
    try {
      const { success, data, error } = await documentReferralService.getReferralDocuments(referralId);
      if (!success) throw new Error(error);
      setDocuments(data || []);
    } catch (err) {
      console.error('Error fetching documents:', err);
      setError('Failed to load documents');
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      // First upload to S3
      const fileData = await uploadFile(file);
      
      // Then create document record
      const { success, error } = await documentReferralService.createDocumentRecord(
        file,
        referralId,
        fileData.key
      );

      if (!success) throw new Error(error);
      
      await fetchDocuments();
      if (onRefresh) await onRefresh();
      resetState();
    } catch (err) {
      console.error('Error uploading document:', err);
      setError(err.message);
    }
  };

  const handleDeleteDocument = async (documentId) => {
    try {
      // First get the document data to get the S3 key
      const { success: getSuccess, data: docData, error: getError } = 
        await documentReferralService.getDocumentData(documentId);
      
      if (!getSuccess) throw new Error(getError);

      // Delete from S3
      await deleteFile(docData.url_id);
      
      // Delete from database
      const { success, error } = await documentReferralService.deleteReferralDocument(documentId);
      if (!success) throw new Error(error);

      await fetchDocuments();
    } catch (err) {
      console.error('Error deleting document:', err);
      setError(err.message);
    }
  };

  const handleDownload = async (documentId) => {
    try {
      const { success, data, error } = await documentReferralService.getDocumentData(documentId);
      if (!success) throw new Error(error);

      // Construct download URL using the bucket URL from environment
      const bucketUrl = process.env.REACT_APP_S3_BUCKET_URL;
      window.open(`${bucketUrl}/${data.url_id}`, '_blank');
    } catch (err) {
      console.error('Error downloading document:', err);
      setError(err.message);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center py-8">
        <Loader className="w-6 h-6 animate-spin text-[#3d3731]" />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Error Message */}
      {error && (
        <div className="p-4 bg-red-50 border border-red-200 rounded-lg flex items-center text-red-700">
          <AlertCircle className="w-5 h-5 mr-2 flex-shrink-0" />
          <p className="text-sm flex-1">{error}</p>
          <button onClick={() => setError(null)} className="ml-2">
            <X className="w-4 h-4" />
          </button>
        </div>
      )}

      {/* Upload Section */}
      {isOutgoing && (
        <div>
          <label className="block p-4 border-2 border-dashed border-[#d8d1c7] rounded-lg hover:border-[#a39b8f] transition-colors cursor-pointer text-center">
            <input
              type="file"
              onChange={handleFileUpload}
              className="hidden"
              accept=".pdf,.doc,.docx,.txt,.jpg,.jpeg,.png"
              disabled={uploading}
            />
            <Upload className={`w-8 h-8 mx-auto mb-2 ${uploading ? 'animate-bounce' : ''}`} />
            <p className="text-[#3d3731]">
              {uploading ? `Uploading... ${progress}%` : 'Click to upload or drag and drop'}
            </p>
            <p className="text-sm text-[#a39b8f] mt-1">
              PDF, Word, Text, or Images up to 10MB
            </p>
          </label>
        </div>
      )}

      {/* Documents List */}
      {documents.length > 0 ? (
        <div className="space-y-2">
          {documents.map((doc) => (
            <div key={doc.id} className="flex items-center justify-between p-3 bg-white border border-[#d8d1c7] rounded-lg">
              <div className="flex items-center">
                <FileText className="w-5 h-5 text-[#3d3731] mr-3" />
                <div>
                  <p className="font-medium text-black">{doc.file_name}</p>
                  <p className="text-sm text-[#3d3731]">
                    {(doc.file_size / 1024 / 1024).toFixed(2)} MB • 
                    {new Date(doc.created_at).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => handleDownload(doc.id)}
                  className="text-[#3d3731] hover:text-black"
                >
                  <Download className="w-4 h-4" />
                </button>
                {isOutgoing && (
                  <button
                    onClick={() => handleDeleteDocument(doc.id)}
                    className="text-[#3d3731] hover:text-black"
                  >
                    <X className="w-4 h-4" />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-8 text-[#3d3731]">
          No documents attached
        </div>
      )}
    </div>
  );
};

export default ReferralDocumentList;