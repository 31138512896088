import React, { useState, useRef, useEffect } from 'react';
import { MoreVertical, Edit, Trash2 } from 'lucide-react';
import StatusBadge from './StatusBadge';
import { useNavigate } from 'react-router-dom';

const LienCard = ({ lien, onDelete }) => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  // Handle clicking outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClick = () => {
    navigate(`/dashboard/liens/${lien.id}`);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    setShowDropdown(false);
    navigate(`/dashboard/liens/${lien.id}/edit`);
  };

  const handleDelete = async (e) => {
    e.stopPropagation();
    setShowDropdown(false);
    
    if (window.confirm('Are you sure you want to delete this lien?')) {
      onDelete(lien.id);
    }
  };

  // Add default values to handle potential undefined properties
  const {
    id,
    lienholder_name = 'Unknown Lienholder',
    amount = 0,
    filing_date,
    expiry_date,
    type = 'Unknown',
    debtor_name = 'Unknown Debtor',
    property_address = 'No address provided',
    status = 'pending'
  } = lien || {};

  const formatDate = (dateString) => {
    if (!dateString) return 'Not set';
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <div 
      className="bg-[#e9e4dc] rounded-lg shadow-sm border border-[#a39b8f] p-6 cursor-pointer hover:shadow-md transition-shadow duration-200"
      onClick={handleClick}
    >
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="text-lg font-semibold text-black">{lienholder_name}</h3>
          <p className="text-sm text-[#3d3731]">Case #{id}</p>
        </div>
        <div className="flex items-center space-x-2">
          <StatusBadge status={status} />
          <div className="relative" ref={dropdownRef}>
            <button 
              onClick={(e) => {
                e.stopPropagation();
                setShowDropdown(!showDropdown);
              }}
              className="text-[#3d3731] hover:text-black transition-colors duration-150 p-1"
            >
              <MoreVertical className="w-5 h-5" />
            </button>
            
            {showDropdown && (
              <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white border border-[#a39b8f] z-10">
                <div className="py-1">
                  <button
                    onClick={handleEdit}
                    className="flex items-center w-full px-4 py-2 text-sm text-[#3d3731] hover:bg-[#f5f1eb]"
                  >
                    <Edit className="w-4 h-4 mr-2" />
                    Edit Lien
                  </button>
                  <button
                    onClick={handleDelete}
                    className="flex items-center w-full px-4 py-2 text-sm text-red-600 hover:bg-[#f5f1eb]"
                  >
                    <Trash2 className="w-4 h-4 mr-2" />
                    Delete Lien
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      <div className="space-y-3">
        <div className="flex justify-between">
          <span className="text-[#3d3731]">Amount:</span>
          <span className="font-semibold text-black">${amount.toLocaleString()}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-[#3d3731]">Filing Date:</span>
          <span className="text-black">{formatDate(filing_date)}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-[#3d3731]">Expiry Date:</span>
          <span className="text-black">{formatDate(expiry_date)}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-[#3d3731]">Type:</span>
          <span className="text-black">{type}</span>
        </div>
        <div className="flex justify-between">
          <span className="text-[#3d3731]">Debtor:</span>
          <span className="text-black">{debtor_name}</span>
        </div>
      </div>
    
      <div className="mt-4 pt-4 border-t border-[#a39b8f]">
        <p className="text-sm text-[#3d3731] mb-2">Property Address:</p>
        <p className="text-sm text-black">{property_address}</p>
      </div>
    </div>
  );
};

export default LienCard;