import React, { useState, useEffect } from 'react';
import { X, FileText, Briefcase, UserPlus, Activity } from 'lucide-react';
import { publicReferralService } from '../../backend/publicReferralsService';

const ExploreFormModal = ({ isOpen, onClose, onPostCreated, editingPost }) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    practiceArea: '',
    clientRelation: '',
    status: 'active'
  });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (editingPost) {
      setFormData({
        title: editingPost.title || '',
        description: editingPost.description || '',
        practiceArea: editingPost.practice_area || '',
        clientRelation: editingPost.client_relation || '',
        status: editingPost.status || 'active'
      });
    } else {
      setFormData({
        title: '',
        description: '',
        practiceArea: '',
        clientRelation: '',
        status: 'active'
      });
    }
  }, [editingPost]);

  const practiceAreas = [
    'Personal Injury',
    'Medical Malpractice',
    'Family Law',
    'Criminal Defense',
    'Immigration',
    'Real Estate',
    'Corporate',
    'Patent',
    'Trademark',
    'Other'
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      let result;
      if (editingPost) {
        result = await publicReferralService.updatePublicReferral(editingPost.id, {
          title: formData.title,
          description: formData.description,
          practice_area: formData.practiceArea,
          client_relation: formData.clientRelation,
          status: formData.status
        });
      } else {
        result = await publicReferralService.createPublicReferral(formData);
      }
      
      if (!result.success) throw new Error(result.error);

      onClose();
      onPostCreated();
      
      const alert = document.createElement('div');
      alert.className = 'fixed bottom-4 right-4 p-4 bg-green-500 text-white rounded-lg shadow-lg z-50';
      alert.textContent = `Post ${editingPost ? 'updated' : 'created'} successfully`;
      document.body.appendChild(alert);
      
      setTimeout(() => {
        alert.remove();
      }, 3000);
      
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-[#e9e4dc] rounded-2xl w-full max-w-2xl max-h-[90vh] overflow-y-auto shadow-xl">
        {/* Header */}
        <div className="flex items-center justify-between p-6 border-b border-[#d8d1c7]">
          <div className="flex items-center gap-3">
            <div className="bg-black p-2 rounded-lg">
              <FileText className="w-5 h-5 text-white" />
            </div>
            <h2 className="text-xl font-semibold">
              {editingPost ? 'Edit Public Referral' : 'Create Public Referral'}
            </h2>
          </div>
          <button 
            onClick={onClose}
            className="p-2 hover:bg-[#d8d1c7] rounded-full transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Form Content */}
        <div className="p-6">
          {error && (
            <div className="mb-6 p-4 bg-red-50 border border-red-100 text-red-600 rounded-xl">
              {error}
            </div>
          )}
          
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Title Input */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-[#3d3731]">Title</label>
              <div className="relative">
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => setFormData({...formData, title: e.target.value})}
                  className="w-full p-3 bg-[#f0ece4] border border-[#d8d1c7] rounded-xl focus:outline-none focus:ring-2 focus:ring-black focus:bg-white transition-all pl-11"
                  placeholder="Enter referral title"
                  required
                />
                <FileText className="w-5 h-5 text-gray-400 absolute left-4 top-3.5" />
              </div>
            </div>

            {/* Practice Area Select */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Practice Area</label>
              <div className="relative">
                <select
                  value={formData.practiceArea}
                  onChange={(e) => setFormData({...formData, practiceArea: e.target.value})}
                  className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-black focus:bg-white transition-all appearance-none pl-11"
                  required
                >
                  <option value="">Select Practice Area</option>
                  {practiceAreas.map(area => (
                    <option key={area} value={area}>{area}</option>
                  ))}
                </select>
                <Briefcase className="w-5 h-5 text-gray-400 absolute left-4 top-3.5" />
                <div className="absolute inset-y-0 right-4 flex items-center pointer-events-none">
                  <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>
            </div>

            {/* Description Textarea */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">Description</label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData({...formData, description: e.target.value})}
                className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-black focus:bg-white transition-all h-32 resize-none"
                placeholder="Describe the case details and requirements..."
                required
              />
            </div>

            {/* Client Relation & Status Grid */}
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Client Relation</label>
                <div className="relative">
                  <select
                    value={formData.clientRelation}
                    onChange={(e) => setFormData({...formData, clientRelation: e.target.value})}
                    className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-black focus:bg-white transition-all appearance-none pl-11"
                    required
                  >
                    <option value="">Select Relation</option>
                    <option value="direct">Direct Client</option>
                    <option value="referred">Referred Client</option>
                    <option value="potential">Potential Client</option>
                  </select>
                  <UserPlus className="w-5 h-5 text-gray-400 absolute left-4 top-3.5" />
                  <div className="absolute inset-y-0 right-4 flex items-center pointer-events-none">
                    <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Status</label>
                <div className="relative">
                  <select
                    value={formData.status}
                    onChange={(e) => setFormData({...formData, status: e.target.value})}
                    className="w-full p-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-black focus:bg-white transition-all appearance-none pl-11"
                    required
                  >
                    <option value="active">Active</option>
                    <option value="referred">Referred</option>
                  </select>
                  <Activity className="w-5 h-5 text-gray-400 absolute left-4 top-3.5" />
                  <div className="absolute inset-y-0 right-4 flex items-center pointer-events-none">
                    <svg className="w-4 h-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          
            {/* Form Actions */}
            <div className="flex justify-end gap-3 pt-6 border-t border-gray-100">
              <button
                type="button"
                onClick={onClose}
                className="px-6 py-2.5 text-gray-700 hover:bg-gray-100 rounded-xl transition-colors"
                disabled={loading}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-6 py-2.5 bg-black text-white rounded-xl hover:bg-gray-800 disabled:opacity-50 transition-colors flex items-center gap-2"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    {editingPost ? 'Updating...' : 'Creating...'}
                  </>
                ) : (
                  editingPost ? 'Update Referral' : 'Create Referral'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ExploreFormModal;