import React, { useState, useEffect, useRef } from 'react';
import { MoreVertical, Edit2, Trash2 } from 'lucide-react';
import { publicReferralService } from '../../backend/publicReferralsService';
import { supabase } from '../../backend/supabase';
import BookmarkButton from './BookmarkButton';

// Simple Delete Confirmation Modal
const DeleteConfirmModal = ({ onConfirm, onCancel }) => (
  <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
    <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
      <h3 className="text-lg font-semibold mb-4">Delete Post</h3>
      <p className="text-gray-600 mb-6">Are you sure you want to delete this post? This action cannot be undone.</p>
      <div className="flex justify-end gap-3">
        <button
          onClick={onCancel}
          className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-md"
        >
          Cancel
        </button>
        <button
          onClick={onConfirm}
          className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
);

const PostActionsMenu = ({ post, onEdit, onPostDeleted }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const checkCurrentUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setIsCurrentUser(user?.id === post.user_id);
    };
    checkCurrentUser();
  }, [post.user_id]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleDelete = async () => {
    try {
      const result = await publicReferralService.deletePublicReferral(post.id);
      if (!result.success) throw new Error(result.error);
      onPostDeleted(post.id);
      setShowDeleteModal(false);

      const alert = document.createElement('div');
      alert.className = 'fixed bottom-4 right-4 p-4 bg-green-500 text-white rounded-lg shadow-lg z-50';
      alert.textContent = 'Post deleted successfully';
      document.body.appendChild(alert);

      setTimeout(() => {
        alert.remove();
      }, 3000);
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  return (
    <>
      <div className="z-10" onClick={e => e.stopPropagation()}>
        {!isCurrentUser && (
          <BookmarkButton post={post} />
        )}
        
        {isCurrentUser && (
          <div className="relative" ref={menuRef}>
            <button 
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowMenu(!showMenu);
              }}
              className="text-gray-400 hover:text-black transition-colors"
            >
              <MoreVertical className="w-5 h-5" />
            </button>

            {showMenu && (
              <div 
                className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50 border border-gray-200"
                onClick={e => e.stopPropagation()}
              >
                <div className="py-1">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowMenu(false);
                      onEdit(post);
                    }}
                    className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
                  >
                    <Edit2 className="w-4 h-4 mr-2" />
                    Edit Post
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowMenu(false);
                      setShowDeleteModal(true);
                    }}
                    className="flex items-center px-4 py-2 text-sm text-red-600 hover:bg-red-50 w-full"
                  >
                    <Trash2 className="w-4 h-4 mr-2" />
                    Delete Post
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {showDeleteModal && (
        <DeleteConfirmModal
          onConfirm={handleDelete}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}
    </>
  );
};

export default PostActionsMenu;