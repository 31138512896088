import React, { useState, useEffect } from 'react';
import { 
  ArrowLeft, 
  Upload, 
  Download, 
  FileText, 
  Clock, 
  CheckCircle, 
  AlertCircle,
  X,
  Plus,
  Loader,
  Trash2,
  Eye
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useS3Upload } from '../../backend/uploadHook';
import { supabase } from '../../backend/supabase';
import { updateLienStatus } from '../../backend/lienService';
import { documentService } from '../../backend/documentService';
import WorkflowSection from './LienWorkflowSection';  


const LienDetailPage = ({ lien, lienId, onBack = () => {}, refreshData = () => {} }) => {
  const navigate = useNavigate();
  
  // States for document management
  const [activeTab, setActiveTab] = useState('overview');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentStatus, setCurrentStatus] = useState(lien?.status || 'active');
  const [error, setError] = useState(null);

  const { 
    uploadFile: uploadToS3, 
    deleteFile, 
    uploading, 
    error: uploadError, 
    progress,
    resetState 
  } = useS3Upload({
    maxSizeMB: 10,
    allowedTypes: ['application/pdf', 'image/jpeg', 'image/png', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    folder: 'documents'
  });

  // Load documents on component mount
  useEffect(() => {
    fetchDocuments();
  }, []);

  const getStepIcon = (status) => {
    switch (status) {
      case 'completed':
        return <CheckCircle className="w-6 h-6 text-black" />;
      case 'in-progress':
        return <Clock className="w-6 h-6 text-[#3d3731]" />;
      default:
        return <AlertCircle className="w-6 h-6 text-[#a39b8f]" />;
    }
  };

const handleStatusChange = async () => {
  try {
    setLoading(true);
    setError(null);
    const newStatus = currentStatus === 'active' ? 'resolved' : 'active';

    await updateLienStatus(lien.id, newStatus);
    await refreshData(); // Refresh data before navigating
    navigate('/dashboard/liens');
  } catch (error) {
    console.error('Error updating lien status:', error);
    setError('Failed to update lien status. Please try again.');
    setLoading(false);
  }
};

// ... continuing from part 1

const fetchDocuments = async () => {
  try {
    const { data, error } = await documentService.getLienDocuments(lienId);
    if (error) throw error;
    setDocuments(data || []);
  } catch (error) {
    console.error('Error fetching documents:', error);
  } finally {
    setLoading(false);
  }
};

const handleFileSelect = (event) => {
  const file = event.target.files[0];
  if (file) {
    setUploadFile(file);
    setShowUploadModal(true);
  }
};

const handleUploadConfirm = async () => {
  if (!uploadFile) return;

  try {
    // Upload to S3
    const fileData = await uploadToS3(uploadFile);

    // Create document record
    const { error } = await documentService.uploadLienDocument(uploadFile, lien.id, fileData);
    if (error) {
      // If database insert fails, delete from S3
      await deleteFile(fileData.key);
      throw error;
    }

    // Refresh documents list
    await fetchDocuments();
    
    setShowUploadModal(false);
    setUploadFile(null);
    resetState();
  } catch (error) {
    console.error('Error uploading document:', error);
    alert('Failed to upload document: ' + error.message);
  }
};

const handleDeleteDocument = async (document) => {
  try {
    // Delete from S3
    await deleteFile(document.url_id);

    // Delete from database
    const { error } = await documentService.deleteDocument(document.id);
    if (error) throw error;

    // Refresh documents list
    await fetchDocuments();
  } catch (error) {
    console.error('Error deleting document:', error);
    alert('Failed to delete document: ' + error.message);
  }
};

const getFileUrl = (key) => {
  return `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${key}`;
};

const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

// Helper functions for UI components
const handleAddDocument = () => {
  navigate('/dashboard/liens/document/new');
};

const handleBack = () => {
  navigate('/dashboard/liens');
};

// ... continuing from part 2

const renderDocuments = () => {
  if (loading) {
    return (
      <div className="flex justify-center items-center py-8">
        <Loader className="w-6 h-6 animate-spin text-[#3d3731]" />
      </div>
    );
  }

  if (documents.length === 0) {
    return (
      <div className="text-center py-8 text-[#3d3731]">
        No documents found
      </div>
    );
  }

  return documents.map((doc) => (
    <div key={doc.id} className="py-4 flex items-center justify-between">
      <div className="flex items-center">
        <FileText className="w-5 h-5 mr-3 text-[#3d3731]" />
        <div>
          <p className="font-medium text-black">{doc.file_name}</p>
          <p className="text-sm text-[#3d3731]">
            {formatFileSize(doc.file_size)} • {new Date(doc.created_at).toLocaleDateString()}
          </p>
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <button 
          onClick={() => {
            setSelectedDocument(doc);
            setShowPreview(true);
          }}
          className="text-[#3d3731] hover:text-black"
        >
          <Eye className="w-4 h-4" />
        </button>
        <a 
          href={getFileUrl(doc.url_id)}
          download
          className="text-[#3d3731] hover:text-black"
        >
          <Download className="w-4 h-4" />
        </a>
        <button 
          onClick={() => handleDeleteDocument(doc)}
          className="text-[#3d3731] hover:text-black"
        >
          <Trash2 className="w-4 h-4" />
        </button>
      </div>
    </div>
  ));
};

const renderPreviewModal = () => {
  if (!showPreview || !selectedDocument) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#e9e4dc] p-6 rounded-lg w-full max-w-4xl max-h-[90vh] flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-black">
            {selectedDocument.file_name || 'Document Preview'}
          </h3>
          <button 
            onClick={() => {
              setShowPreview(false);
              setSelectedDocument(null);
            }}
            className="text-[#3d3731] hover:text-black"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
        <div className="flex-1 overflow-auto min-h-0 bg-white rounded">
          {selectedDocument.file_type?.includes('pdf') ? (
            <iframe
              src={getFileUrl(selectedDocument.url_id)}
              title={selectedDocument.file_name}
              className="w-full h-full min-h-[600px]"
            />
          ) : selectedDocument.file_type?.includes('image/') ? (
            <img
              src={getFileUrl(selectedDocument.url_id)}
              alt={selectedDocument.file_name}
              className="max-w-full h-auto"
            />
          ) : (
            <div className="flex items-center justify-center h-full p-8 text-center text-[#3d3731]">
              <div>
                <FileText className="w-16 h-16 mx-auto mb-4" />
                <p>Preview not available for this file type.</p>
                <a
                  href={getFileUrl(selectedDocument.url_id)}
                  download
                  className="mt-4 inline-flex items-center px-4 py-2 bg-black text-white rounded-md hover:bg-[#3d3731] transition-colors duration-150"
                >
                  <Download className="w-4 h-4 mr-2" />
                  Download to view
                </a>
              </div>
            </div>
          )}
        </div>
        <div className="mt-4 flex justify-end">
          <button
            onClick={() => {
              setShowPreview(false);
              setSelectedDocument(null);
            }}
            className="px-4 py-2 text-[#3d3731] bg-[#d8d1c7] border border-[#a39b8f] rounded-md hover:bg-[#c5beb2]"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const renderUploadModal = () => {
  if (!showUploadModal) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-[#e9e4dc] p-6 rounded-lg max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-black">Upload Document</h3>
          <button 
            onClick={() => {
              setShowUploadModal(false);
              resetState();
            }}
            className="text-[#3d3731] hover:text-black"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
        <div className="mb-4">
          <p className="text-[#3d3731]">Selected file: {uploadFile?.name}</p>
          {uploadError && (
            <p className="text-red-500 text-sm mt-2">{uploadError}</p>
          )}
          {uploading && (
            <div className="mt-4">
              <div className="w-full bg-[#d8d1c7] rounded-full h-2">
                <div 
                  className="bg-black h-2 rounded-full transition-all duration-300"
                  style={{ width: `${progress}%` }}
                />
              </div>
              <p className="text-sm text-[#3d3731] mt-2 text-center">{progress}%</p>
            </div>
          )}
        </div>
        <div className="flex justify-end space-x-3">
          <button
            onClick={() => {
              setShowUploadModal(false);
              resetState();
            }}
            className="px-4 py-2 text-[#3d3731] bg-[#d8d1c7] border border-[#a39b8f] rounded-md hover:bg-[#c5beb2]"
            disabled={uploading}
          >
            Cancel
          </button>
          <button
            onClick={handleUploadConfirm}
            disabled={uploading || !uploadFile}
            className={`px-4 py-2 rounded-md flex items-center ${
              uploading || !uploadFile
                ? 'bg-[#a39b8f] text-[#3d3731] cursor-not-allowed'
                : 'bg-black text-white hover:bg-[#3d3731]'
            }`}
          >
            {uploading ? (
              <>
                <Loader className="w-4 h-4 mr-2 animate-spin" />
                Uploading...
              </>
            ) : (
              'Upload'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

// ... continuing from part 3

return (
  <div className="min-h-screen bg-[#d8d1c7] flex flex-col">
    {/* Header */}
    <div className="bg-[#e9e4dc] border-b border-[#a39b8f] p-6">
      <button 
        onClick={onBack}
        className="flex items-center text-[#3d3731] hover:text-black mb-4"
      >
        <ArrowLeft className="w-4 h-4 mr-2" />
        Back to Liens
      </button>
      <div className="flex justify-between items-start">
        <div>
          <h1 className="text-2xl font-bold text-black mb-2">{lien?.lienholder_name}</h1>
          <p className="text-[#3d3731]">Case #{lien?.id}</p>
          <p></p>
        </div>
        <button
          onClick={handleStatusChange}
          disabled={loading}
          className={`px-4 py-2 rounded-md transition-colors duration-150 flex items-center ${
            loading 
              ? 'bg-[#a39b8f] text-[#3d3731] cursor-not-allowed'
              : currentStatus === 'active'
              ? 'bg-black text-white hover:bg-[#3d3731]'
              : 'bg-[#d8d1c7] text-[#3d3731] hover:bg-[#c5beb2] border border-[#a39b8f]'
          }`}
        >
          {loading ? (
            <>
              <Loader className="w-4 h-4 mr-2 animate-spin" />
              Updating...
            </>
          ) : currentStatus === 'active' ? (
            'Mark as Resolved'
          ) : (
            'Set to Active'
          )}
        </button>
      </div>
      <p className="text-[#3d3731]">{lien?.description}</p>
    </div>

    {/* Tabs */}
    <div className="bg-[#e9e4dc] border-b border-[#a39b8f]">
      <div className="px-6">
        <div className="flex space-x-6">
          {['overview', 'documents', 'workflow'].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`py-4 px-2 -mb-px text-sm font-medium ${
                activeTab === tab
                  ? 'border-b-2 border-black text-black'
                  : 'text-[#3d3731] hover:text-black'
              }`}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>
      </div>
    </div>

    {/* Content */}
    <div className="flex-1 overflow-auto p-6">
      {activeTab === 'overview' && (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="bg-[#e9e4dc] p-6 rounded-lg border border-[#a39b8f]">
            <h2 className="text-lg font-semibold text-black mb-4">Lien Details</h2>
            <div className="space-y-4">
              <div className="flex justify-between">
                <span className="text-[#3d3731]">Amount:</span>
                <span className="font-semibold text-black">${lien?.amount?.toLocaleString()}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-[#3d3731]">Filing Date:</span>
                <span className="text-black">{new Date(lien?.filing_date).toLocaleDateString()}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-[#3d3731]">Expiry Date:</span>
                <span className="text-black">{new Date(lien?.expiry_date).toLocaleDateString()}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-[#3d3731]">Type:</span>
                <span className="text-black">{lien?.type}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-[#3d3731]">Debtor:</span>
                <span className="text-black">{lien?.debtor_name}</span>
              </div>
            </div>
          </div>

          <div className="bg-[#e9e4dc] p-6 rounded-lg border border-[#a39b8f]">
            <h2 className="text-lg font-semibold text-black mb-4">Property Information</h2>
            <div className="space-y-4">
              <p className="text-[#3d3731]">{lien?.property_address}</p>
              <div className="mt-4">
                <img 
                  src="/api/placeholder/400/200" 
                  alt="Property" 
                  className="w-full h-48 object-cover rounded-lg"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {activeTab === 'documents' && (
        <div className="bg-[#e9e4dc] p-6 rounded-lg border border-[#a39b8f]">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-lg font-semibold text-black">Documents</h2>
            <div className="flex space-x-3">
              <label className="px-4 py-2 text-[#3d3731] bg-[#d8d1c7] border border-[#a39b8f] rounded-md hover:bg-[#c5beb2] transition-colors duration-150 cursor-pointer">
                <input 
                  type="file" 
                  className="hidden" 
                  onChange={handleFileSelect}
                  accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                />
                <Upload className="w-4 h-4 inline-block mr-2" />
                Upload
              </label>
              <button 
                onClick={handleAddDocument}
                className="px-4 py-2 bg-black text-white rounded-md hover:bg-[#3d3731] transition-colors duration-150"
              >
                <Plus className="w-4 h-4 inline-block mr-2" />
                Add Document
              </button>
            </div>
          </div>
          <div className="divide-y divide-[#a39b8f]">
            {renderDocuments()}
          </div>
        </div>
      )}

      {activeTab === 'workflow' && (
        <div className="bg-[#e9e4dc] p-6 rounded-lg border border-[#a39b8f]">
          <h2 className="text-lg font-semibold text-black mb-6">Workflow Progress</h2>
          <WorkflowSection lien={lien} onUpdate={refreshData} />
        </div>
      )}
    </div>

    {/* Modals */}
    {renderPreviewModal()}
    {renderUploadModal()}
  </div>
);
};

export default LienDetailPage;