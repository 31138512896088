import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronDown, Search, Plus, Archive, Trash2, RefreshCw } from 'lucide-react';
import { supabase } from './backend/supabase';
import { userToUserReferralService } from './backend/referralServices/userToUserReferral';
import { outgoingReferralService } from './backend/referralServices/outgoingReferrals';
import StatCard from './components/dashboard(home)/StatCard';
import { FilterPill } from './components/dashboard(home)/FilterPill';
import { CustomerTable } from './components/dashboard(home)/CustomerTable';
import { CustomerListItem } from './components/dashboard(home)/CustomerListItem';
import { PlaceholderView } from './components/dashboard(home)/PlaceholderView';
import MetricDropdown from './components/dashboard(home)/MetricDropdown';

const Home = () => {
  const navigate = useNavigate();
  
  // State management
  const [currentView, setCurrentView] = useState('overview');
  const [outgoingReferrals, setOutgoingReferrals] = useState([]);
  const [incomingReferrals, setIncomingReferrals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [selectedTimeframe, setSelectedTimeframe] = useState('ALL');

  // Initialize metrics with timeframe-specific data structure
  const [metrics, setMetrics] = useState({
    outgoing: {
      ALL: { count: null, successRate: null },
      '1W': { count: null, successRate: null },
      '1M': { count: null, successRate: null },
      '6M': { count: null, successRate: null },
      '1Y': { count: null, successRate: null }
    },
    incoming: {
      ALL: { count: null, successRate: null },
      '1W': { count: null, successRate: null },
      '1M': { count: null, successRate: null },
      '6M': { count: null, successRate: null },
      '1Y': { count: null, successRate: null }
    },
    earnings: {
      ALL: { amount: null, growth: null },
      '1W': { amount: null, growth: null },
      '1M': { amount: null, growth: null },
      '6M': { amount: null, growth: null },
      '1Y': { amount: null, growth: null }
    }
  });

  useEffect(() => {
    loadReferrals();
  }, [refreshTrigger]);

  const loadReferrals = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      // Load both outgoing and incoming referrals
      const [outgoingResult, incomingResult] = await Promise.all([
        outgoingReferralService.fetchAllOutgoingReferrals(user.id),
        userToUserReferralService.fetchIncomingReferrals(user.id)
      ]);

      if (!outgoingResult.success) throw new Error(outgoingResult.error);
      if (!incomingResult.success) throw new Error(incomingResult.error);

      console.log('Outgoing referrals loaded:', outgoingResult.data);
      console.log('Incoming referrals loaded:', incomingResult.data);

      // Set referrals state
      setOutgoingReferrals(outgoingResult.data);
      setIncomingReferrals(incomingResult.data);

      // Calculate metrics for all timeframes
      calculateMetricsForAllTimeframes(outgoingResult.data, incomingResult.data);

    } catch (error) {
      console.error('Error loading referrals:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const calculateMetricsForAllTimeframes = (outgoing, incoming) => {
    const timeframes = ['1W', '1M', '6M', '1Y', 'ALL'];
    const now = new Date();
    const newMetrics = { outgoing: {}, incoming: {}, earnings: {} };

    timeframes.forEach(timeframe => {
      let daysToSubtract = 0;
      switch(timeframe) {
        case '1W': daysToSubtract = 7; break;
        case '1M': daysToSubtract = 30; break;
        case '6M': daysToSubtract = 180; break;
        case '1Y': daysToSubtract = 365; break;
        default: daysToSubtract = null;
      }

      // Filter referrals by timeframe
      const filterByTimeframe = (referrals) => {
        if (!daysToSubtract) return referrals;
        const cutoffDate = new Date(now.getTime() - (daysToSubtract * 24 * 60 * 60 * 1000));
        return referrals.filter(ref => new Date(ref.created_at) >= cutoffDate);
      };

      // Calculate outgoing metrics
      const timeframeOutgoing = filterByTimeframe(outgoing);
      const outgoingSuccessCount = timeframeOutgoing.filter(ref => ref.status === 'completed').length;
      newMetrics.outgoing[timeframe] = {
        count: timeframeOutgoing.length,
        successRate: timeframeOutgoing.length > 0 ? 
          Math.round((outgoingSuccessCount / timeframeOutgoing.length) * 100) : 0
      };

      // Calculate incoming metrics
      const timeframeIncoming = filterByTimeframe(incoming);
      const incomingSuccessCount = timeframeIncoming.filter(ref => ref.status === 'completed').length;
      newMetrics.incoming[timeframe] = {
        count: timeframeIncoming.length,
        successRate: timeframeIncoming.length > 0 ? 
          Math.round((incomingSuccessCount / timeframeIncoming.length) * 100) : 0
      };

      // Calculate earnings
      const timeframeEarnings = timeframeOutgoing.reduce((total, ref) => {
        if (ref.status === 'completed' && ref.earnings) {
          return total + ref.earnings.reduce((sum, e) => sum + (e.amount || 0), 0);
        }
        return total;
      }, 0);
      newMetrics.earnings[timeframe] = {
        amount: timeframeEarnings,
        growth: 0 // Calculate growth rate if needed
      };
    });

    setMetrics(newMetrics);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRefresh = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  const handleTimeframeChange = (type, timeframe, newMetrics) => {
    setSelectedTimeframe(timeframe);
    setMetrics(prev => ({
      ...prev,
      [type]: {
        ...prev[type],
        [timeframe]: newMetrics
      }
    }));
  };

  const filterReferrals = (referrals) => {
    if (!searchQuery) return referrals;

    const searchLower = searchQuery.toLowerCase();
    return referrals.filter(referral => 
      referral.name?.toLowerCase().includes(searchLower) ||
      referral.contact?.toLowerCase().includes(searchLower) ||
      referral.email?.toLowerCase().includes(searchLower) ||
      referral.status?.toLowerCase().includes(searchLower) ||
      referral.phone?.toLowerCase().includes(searchLower)
    );
  };

  const renderView = () => {
    if (loading) {
      return (
        <div className="flex justify-center items-center h-64">
          <div className="text-[#3d3731]">Loading referrals...</div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="flex justify-center items-center h-64">
          <div className="text-red-500">Error: {error}</div>
        </div>
      );
    }

    const referrals = currentView === 'overview' ? outgoingReferrals : incomingReferrals;
    const filteredReferrals = filterReferrals(referrals);

    if (referrals.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center h-64">
          <div className="text-[#3d3731] mb-4">
            {currentView === 'overview' 
              ? 'No outgoing referrals found'
              : 'No incoming referrals found'
            }
          </div>
          {currentView === 'overview' && (
            <button
              onClick={() => navigate('/submit-referral')}
              className="px-4 py-2 bg-black text-white rounded-md hover:bg-[#3d3731]"
            >
              Create Your First Referral
            </button>
          )}
        </div>
      );
    }

    switch (currentView) {
      case 'overview':
        return (
          <CustomerTable 
            customers={filteredReferrals} 
            type="outgoing" 
            onRefresh={handleRefresh}
            searchQuery={searchQuery}
          />
        );
      case 'table':
        return (
          <CustomerTable 
            customers={filteredReferrals} 
            type="incoming" 
            onRefresh={handleRefresh}
            searchQuery={searchQuery}
          />
        );
      case 'list':
        return (
          <div className="select-none outline-none focus:outline-none" tabIndex="-1">
            {filteredReferrals.map((referral, index) => (
              <CustomerListItem 
                key={referral.id || index} 
                {...referral} 
              />
            ))}
          </div>
        );
      case 'segment':
      case 'custom':
        return <PlaceholderView type={currentView.charAt(0).toUpperCase() + currentView.slice(1)} />;
      default:
        return null;
    }
  };

  return (
    <div className="flex-1 overflow-auto bg-[#f5f1eb]">
      <div className="py-8 px-10">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-semibold text-[#3d3731]">Referrals</h1>
          <div className="flex space-x-3">
            <button
              onClick={handleRefresh}
              className="p-2 text-[#3d3731] hover:text-black transition-colors"
            >
              <RefreshCw className="w-5 h-5" />
            </button>
            <button className="px-4 py-2 border border-[#a39b8f] rounded-md text-[#3d3731] bg-[#d8d1c7] hover:bg-[#c5beb2]">
              <span className="mr-2">↓</span> Import
            </button>
            <button 
              onClick={() => navigate('/submit-referral')}
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-[#e9e4dc] bg-black hover:bg-[#3d3731]"
            >
              + Add Referral
            </button>
          </div>
        </div>
        
        <div className="grid grid-cols-3 gap-6 mb-8">
          <StatCard 
            title="Outgoing Referrals" 
            value={metrics.outgoing[selectedTimeframe]?.count?.toString() || '0'}
            change={`${metrics.outgoing[selectedTimeframe]?.successRate || 0}% success rate`}
            showAvatars
            metricDropdown={
              <MetricDropdown 
                referrals={outgoingReferrals}
                onTimeframeChange={(timeframe, newMetrics) => 
                  handleTimeframeChange('outgoing', timeframe, newMetrics)
                }
              />
            }
          />
          <StatCard 
            title="Incoming Referrals" 
            value={metrics.incoming[selectedTimeframe]?.count?.toString() || '0'}
            change={`${metrics.incoming[selectedTimeframe]?.successRate || 0}% success rate`}
            showAvatars
            type="incoming"
            metricDropdown={
              <MetricDropdown 
                referrals={incomingReferrals}
                onTimeframeChange={(timeframe, newMetrics) => 
                  handleTimeframeChange('incoming', timeframe, newMetrics)
                }
              />
            }
          />
          <StatCard 
            title="Referral Earnings" 
            value={`$${(metrics.earnings[selectedTimeframe]?.amount || 0).toLocaleString()}`}
            change={`${metrics.earnings[selectedTimeframe]?.growth || 0}% growth`}
          />
        </div>

        <div className="bg-[#f5f1eb] shadow rounded-lg">
          <div className="px-6 py-4 border-b border-[#d8d1c7] flex justify-between items-center">
            <div className="flex space-x-4">
              {['Overview', 'Table', 'List view', 'Segment', 'Custom'].map((view) => (
                <button
                  key={view}
                  onClick={() => setCurrentView(view.toLowerCase().replace(' ', ''))}
                  className={`px-3 py-2 text-sm font-medium ${
                    currentView === view.toLowerCase().replace(' ', '')
                      ? 'border-b-2 border-black text-[#3d3731]'
                      : 'text-[#a39b8f] hover:text-[#3d3731]'
                  }`}
                >
                  {view === 'Overview' ? 'Outgoing Referrals' : 
                   view === 'Table' ? 'Incoming Referrals' : 
                   view}
                </button>
              ))}
            </div>
          </div>

          <div className="px-6 py-4 flex justify-between items-center">
            <div className="flex space-x-2">
              <FilterPill>All time</FilterPill>
              <FilterPill>US, AU, +4</FilterPill>
              <button className="inline-flex items-center px-3 py-0.5 rounded-md text-sm font-medium bg-[#f5f1eb] border border-[#a39b8f] text-[#3d3731] hover:bg-[#d8d1c7]">
                More filters
                <ChevronDown size={16} className="ml-1" />
              </button>
            </div>
            <div className="relative">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                placeholder="Search"
                className="focus:ring-[#a39b8f] focus:border-[#a39b8f] block w-full pl-10 pr-3 py-2 border border-[#a39b8f] rounded-md leading-5 bg-[#f5f1eb] placeholder-[#a39b8f] focus:outline-none focus:placeholder-[#c5beb2] sm:text-sm"
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search size={20} className="text-[#a39b8f]" />
              </div>
            </div>
          </div>

          {renderView()}
        </div>
      </div>
    </div>
  );
};

export default Home;