// src/components/dashboard/DashboardUI.js
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Home, 
  Layers, 
  Files, 
  CheckSquare, 
  PieChart, 
  Users, 
  Bell, 
  HelpCircle, 
  Settings, 
  Bot,
  ChevronDown,
  LogOut
} from 'lucide-react';
import UserAvatar from '../ui/UserAvatar';
import { supabase } from '../../backend/supabase';

// SidebarItem Component
const SidebarItem = ({ icon: Icon, text, count, active, onClick }) => (
  <button
    onClick={onClick}
    className={`w-full flex items-center px-6 py-3 ${
      active ? 'bg-[#d8d1c7] text-black' : 'text-[#3d3731] hover:bg-[#e9e4dc]'
    } transition-colors duration-150`}
  >
    <Icon className="w-5 h-5 mr-3" />
    <span className="text-sm font-medium">{text}</span>
    {count !== undefined && (
      <span className="ml-auto bg-[#a39b8f] text-black rounded-full px-2 py-1 text-xs">{count}</span>
    )}
  </button>
);

// UserMenu Component
export const UserMenu = ({ user }) => {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const userMenuRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setShowUserMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="p-4 border-t border-[#a39b8f] relative" ref={userMenuRef}>
      <button
        onClick={() => setShowUserMenu(!showUserMenu)}
        className="w-full flex items-center justify-between hover:bg-[#f0ece4] p-2 rounded-md transition-colors duration-150"
      >
        <div className="flex items-center">
          <UserAvatar 
            src={user?.user_metadata?.picture} 
            alt={`${user?.user_metadata?.full_name || 'User'} avatar`}
            size={40}
          />
          <div className="ml-3">
            <p className="font-medium text-black">
              {user?.user_metadata?.full_name || 'User'}
            </p>
            <p className="text-sm text-[#3d3731]">{user?.email}</p>
          </div>
        </div>
        <ChevronDown className="w-4 h-4 text-[#3d3731]" />
      </button>

      {showUserMenu && (
        <div className="absolute bottom-full left-0 right-0 mb-1 bg-[#e9e4dc] border border-[#a39b8f] rounded-md shadow-lg">
          <div className="p-2">
            <button
              onClick={handleSignOut}
              className="w-full flex items-center px-4 py-2 text-[#3d3731] hover:bg-[#f0ece4] rounded-md transition-colors duration-150"
            >
              <LogOut className="w-4 h-4 mr-2" />
              Sign Out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

// Sidebar Component
export const Sidebar = ({ activeItem, setActiveItem, user, navigate }) => (
  <div className="w-64 bg-[#e9e4dc] h-screen shadow-sm flex flex-col">
    <div className="p-6 flex items-center">
      <div className="w-8 h-8 bg-black rounded mr-3 flex items-center justify-center" onClick={() => navigate('/home2')}>
        <span className="text-[#e9e4dc] font-bold">L</span>
      </div>
      <h2 className="text-xl font-semibold text-black">Lawdie</h2>
    </div>
    
    <nav className="flex-grow mt-2">
      <SidebarItem icon={Home} text="Home" active={activeItem === 'Home'} onClick={() => setActiveItem('Home')} />
      <SidebarItem icon={Layers} text="Referrals" active={activeItem === 'Projects'} onClick={() => setActiveItem('Projects')} />
      <SidebarItem icon={CheckSquare} text="Tasks" count={10} active={activeItem === 'Tasks'} onClick={() => setActiveItem('Tasks')} />
      <SidebarItem icon={PieChart} text="Liens" active={activeItem === 'Liens'} onClick={() => setActiveItem('Liens')} />
      <SidebarItem icon={Bot} text="Kiwi AI" active={activeItem === 'Kiwi AI'} onClick={() => window.open('/kiwi-ai', '_blank')} />
    </nav>
    
    <div className="mt-auto border-t border-[#a39b8f]">
      <SidebarItem icon={Bell} text="Notifications" count={10} active={activeItem === 'Notifications'} onClick={() => setActiveItem('Notifications')} />
      <SidebarItem icon={HelpCircle} text="Support" active={activeItem === 'Support'} onClick={() => window.open('/support', '_blank')} />
      <SidebarItem icon={Settings} text="Settings" active={activeItem === 'Settings'} onClick={() => setActiveItem('Settings')} />
    </div>

    <UserMenu user={user} />
  </div>
);

// TopBar Component
export const TopBar = ({ navigate }) => (
  <div className="flex justify-between items-center py-4 px-8 border-b border-[#a39b8f] bg-[#e9e4dc]">
    <h1 className="text-2xl font-semibold text-black">Referral Hub</h1>
    <div className="flex space-x-2">
      <button className="px-4 py-2 text-[#3d3731] bg-[#d8d1c7] border border-[#a39b8f] rounded-md hover:bg-[#c5beb2] transition-colors duration-150">
        Import
      </button>
      <button 
        onClick={() => navigate('/submit-referral')}
        className="px-4 py-2 bg-black text-[#e9e4dc] rounded-md hover:bg-[#3d3731] transition-colors duration-150"
      >
        + Add Referral
      </button>
    </div>
  </div>
);

// TabBar Component
export const TabBar = ({ activeTab, setActiveTab }) => {
    const navigate = useNavigate();  // Add this line
  
    return (
        <div className="flex border-b border-[#a39b8f] bg-[#e9e4dc] px-8">
        {['Public', 'Private', 'Favorites'].map((tab) => (
            <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`px-4 py-4 ${
                activeTab === tab
                ? 'border-b-2 border-black text-black'
                : 'text-[#3d3731] hover:text-black'
            } transition-colors duration-150 text-sm font-medium`}
            >
            {tab}
            </button>
        ))}
        <button
            onClick={() => navigate('/explore')}
            className="px-4 py-4 text-[#3d3731] hover:text-black transition-colors duration-150 text-sm font-medium"
        >
            Explore
        </button>
        </div>
    );
}

export default {
  Sidebar,
  UserMenu,
  TopBar,
  TabBar
};