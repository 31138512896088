import React from 'react';
import { Search } from 'lucide-react';
import _ from 'lodash';

const SearchComponent = ({ searchTerm, setSearchTerm, className }) => {
  // Debounced search function to avoid too many re-renders
  const handleSearchChange = _.debounce((value) => {
    setSearchTerm(value);
  }, 300);

  return (
    <div className={`relative ${className}`}>
      <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
      <input
        type="text"
        defaultValue={searchTerm}
        onChange={(e) => handleSearchChange(e.target.value)}
        placeholder="Search referrals..."
        className="w-full pl-10 pr-4 py-2 bg-[#e9e4dc] rounded-lg border border-[#d8d1c7] focus:outline-none focus:ring-2 focus:ring-black text-black placeholder-gray-500"
      />
    </div>
  );
};

export default SearchComponent;