import { supabase } from './supabase';

export const publicReferralService = {
  createPublicReferral: async (referralData) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      // Get user profile info
      const { data: userProfile, error: profileError } = await supabase
        .from('users')
        .select('first_name, last_name')
        .eq('id', user.id)
        .single();

      if (profileError) throw profileError;

      // Create public referral
      const { data, error } = await supabase
        .from('public_referrals')
        .insert([{
          id: crypto.randomUUID(),
          user_id: user.id,
          title: referralData.title,
          description: referralData.description,
          practice_area: referralData.practiceArea,
          client_relation: referralData.clientRelation,
          status: referralData.status,
          username: `${userProfile.first_name} ${userProfile.last_name}`,
          profile_pic: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjUuYcnZ-xqlGZiDZvuUy_iLx3Nj6LSaZSzQ&s",
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }])
        .select()
        .single();

      if (error) throw error;
      return { success: true, data };

    } catch (error) {
      console.error('Error creating public referral:', error);
      return { 
        success: false, 
        error: error.message || 'Failed to create public referral'
      };
    }
  },

  fetchPublicReferrals: async () => {
    try {
      const { data, error } = await supabase
        .from('public_referrals')
        .select('*, users(*)')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return { success: true, data };

    } catch (error) {
      console.error('Error fetching public referrals:', error);
      return { 
        success: false, 
        error: error.message || 'Failed to fetch public referrals'
      };
    }
  },

  fetchPublicReferral: async (id) => {
    try {
      const { data, error } = await supabase
        .from('public_referrals')
        .select('*, users(*)')
        .eq('id', id)
        .single();
  
      if (error) throw error;
      return { success: true, data };
  
    } catch (error) {
      console.error('Error fetching public referral:', error);
      return { 
        success: false, 
        error: error.message || 'Failed to fetch public referral'
      };
    }
  },

  fetchLatestPosts: async () => {
    try {
      const { data, error } = await supabase
      .from('public_referrals')
      .select('*, users(*)')
      .order('created_at', { ascending: false })
      .limit(3);

      if (error) throw error;
      return { success: true, data };

    } catch (error) {
      console.error('Error fetching latest posts:', error);
      return { 
        success: false, 
        error: error.message || 'Failed to fetch latest posts'
      };
    }
  },

  fetchUserListings: async () => {
    try {
      const { data: authUser } = await supabase.auth.getUser();
    
      if (!authUser?.user?.id) {
        throw new Error('User not authenticated');
      }


      const { data, error } = await supabase
      .from('public_referrals')
      .select('*')
      .eq('user_id', authUser.user.id)
      .order('created_at', { ascending: false })

      if (error) throw error;
      return { success: true, data };

    } catch (error) {
      console.error('Error fetching user listings:', error);
      return { 
        success: false, 
        error: error.message || 'Failed to fetch user listings'
      };
    }
  },

  updatePublicReferral: async (id, updateData) => {
    try {
      const { data, error } = await supabase
        .from('public_referrals')
        .update({
          ...updateData,
          updated_at: new Date().toISOString()
        })
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;
      return { success: true, data };

    } catch (error) {
      console.error('Error updating public referral:', error);
      return { 
        success: false, 
        error: error.message || 'Failed to update public referral'
      };
    }
  },

  deletePublicReferral: async (id) => {
    try {
      const { error } = await supabase
        .from('public_referrals')
        .delete()
        .eq('id', id);

      if (error) throw error;
      return { success: true };

    } catch (error) {
      console.error('Error deleting public referral:', error);
      return { 
        success: false, 
        error: error.message || 'Failed to delete public referral'
      };
    }
  }
};