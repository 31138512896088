// src/components/FileDownloadHandler.js
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import OpenAI from 'openai';

const FileDownloadHandler = () => {
  const [searchParams] = useSearchParams();
  const threadId = searchParams.get('threadId');
  const messageId = searchParams.get('messageId');
  const fileName = searchParams.get('fileName');

  React.useEffect(() => {
    const handleDownload = async () => {
      try {
        const openai = new OpenAI({
          apiKey: process.env.REACT_APP_OPENAI_API_KEY,
          dangerouslyAllowBrowser: true
        });

        console.log('Attempting to get message:', { threadId, messageId });

        // Get the message that contains the file
        const messages = await openai.beta.threads.messages.list(threadId);
        const message = messages.data.find(m => m.id === messageId);

        if (!message) {
          throw new Error('Message not found');
        }

        console.log('Message content:', message);

        // Get the file content
        if (message.file_ids && message.file_ids.length > 0) {
          const fileId = message.file_ids[0];
          const fileContent = await openai.files.content(fileId);
          console.log('Got file content');

          // Create blob and trigger download
          const blob = new Blob([fileContent], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName || 'download.pdf';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        } else {
          throw new Error('No file found in message');
        }

      } catch (error) {
        console.error('Error downloading file:', error);
      }
    };

    if (threadId && messageId) {
      handleDownload();
    }
  }, [threadId, messageId, fileName]);

  return null;
};

export default FileDownloadHandler;