// src/pages/Dashboard.js
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './backend/supabase';
import { Search, Filter } from 'lucide-react';
import { Sidebar, TopBar, TabBar } from './components/dashboard(main)/DashboardUI';
import HomeComponent from './Home';
import LienManagement from './components/lien/LienManagement';
import DocumentDashboard from './components/documentUI/DocumentDashboard';
import SettingsPage from './components/other/Settings';
import NotificationsPage from './components/other/Notifications';
import { privateNetworkService } from './backend/referralServices/privateNetworkService';
import { PrivateNetworkTable, AddContactForm } from './components/dashboard(main)/PrivateNetwork';
import PublicNetwork from './components/dashboard(main)/PublicNetwork';
import ExploreTab from './components/dashboard(main)/Explore';
import FavoritesPage from './components/dashboard(main)/FavoritesPage';
import { favoritesService } from './backend/favoritesService';

const Dashboard = () => {
  const navigate = useNavigate();
  const mainContainerRef = useRef(null);
  
  // State management
  const [activeItem, setActiveItem] = useState('Home');
  const [activeTab, setActiveTab] = useState('Public');
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAddContact, setShowAddContact] = useState(false);
  const [privateContacts, setPrivateContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch user data on mount
  useEffect(() => {
    getUser();
  }, []);

  // Fetch private contacts when private tab is active
  useEffect(() => {
    if (activeTab === 'Private') {
      fetchPrivateContacts();
    }
  }, [activeTab]);

  const getUser = async () => {
    try {
      const { data: { user: authUser }, error: authError } = await supabase.auth.getUser();
      if (authError) throw authError;

      if (authUser) {
        const { data: profileData, error: profileError } = await supabase
          .from('users')
          .select('*')
          .eq('email', authUser.email)
          .single();

        if (profileError && profileError.code !== 'PGRST116') {
          throw profileError;
        }

        setUser({
          ...authUser,
          ...profileData
        });
      }
    } catch (error) {
      console.error('Error fetching user:', error);
    } finally {
      setLoading(false);
    }
  };

  // Private network handlers
  const handlePrivateNetworkFavorite = async (contact) => {
    try {
      const result = await favoritesService.toggleFavorite({
        email: contact.email,
        firm_name: contact.firm,
        first_name: contact.contact.split(' ')[0],
        last_name: contact.contact.split(' ')[1],
        phone: contact.phone,
        is_favorite: contact.is_favorite
      });
  
      if (result.success) {
        setPrivateContacts(prev => prev.map(c => 
          c.email === contact.email 
            ? { ...c, is_favorite: !c.is_favorite }
            : c
        ));
      }
    } catch (err) {
      console.error('Error toggling favorite:', err);
    }
  };
  
  // Also update your fetchPrivateContacts function to include favorite status
  const fetchPrivateContacts = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Fetch private contacts
      const result = await privateNetworkService.fetchContacts();
      if (!result.success) throw new Error(result.error);
      
      // Get favorites to check status
      const favoritesResult = await favoritesService.fetchFavorites();
      
      // Create a Set of favorited emails for quick lookup
      const favoritedEmails = new Set(
        favoritesResult.success 
          ? favoritesResult.data.map(f => f.email)
          : []
      );
  
      // Add is_favorite flag to contacts
      const contactsWithFavorites = result.data.map(contact => ({
        ...contact,
        is_favorite: favoritedEmails.has(contact.email)
      }));
  
      setPrivateContacts(contactsWithFavorites);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching contacts:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddContact = async (contactData) => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await privateNetworkService.addContact(contactData);
      if (!result.success) throw new Error(result.error);
      setPrivateContacts(prev => [result.data, ...prev]);
      setShowAddContact(false);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditContact = async (contact) => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await privateNetworkService.updateContact(contact.id, contact);
      if (!result.success) throw new Error(result.error);
      setPrivateContacts(prev => prev.map(c => c.id === contact.id ? result.data : c));
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteContact = async (contact) => {
    if (!window.confirm('Are you sure you want to delete this contact?')) return;
    
    setIsLoading(true);
    setError(null);
    try {
      const result = await privateNetworkService.deleteContact(contact.id);
      if (!result.success) throw new Error(result.error);
      setPrivateContacts(prev => prev.filter(c => c.id !== contact.id));
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const renderReferralHubContent = () => {
    if (activeTab === 'Private') {
      return (
        <>
          <AddContactForm 
            isOpen={showAddContact} 
            onClose={() => setShowAddContact(false)}
            onSubmit={handleAddContact}
          />
          <div className="flex items-center justify-between p-4 border-b border-[#d8d1c7]">
            <div className="flex items-center space-x-4">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#a39b8f] w-4 h-4" />
                <input
                  type="text"
                  placeholder="Search contacts..."
                  className="pl-10 pr-4 py-2 border border-[#d8d1c7] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                />
              </div>
              <div className="flex items-center space-x-2">
                <Filter className="text-[#a39b8f] w-4 h-4" />
                <select className="p-2 border border-[#d8d1c7] rounded-md focus:outline-none focus:ring-1 focus:ring-black">
                  <option value="">All Practice Areas</option>
                </select>
              </div>
            </div>
            <button
              onClick={() => setShowAddContact(true)}
              className="px-4 py-2 bg-black text-[#e9e4dc] rounded-md hover:bg-[#3d3731] transition-colors duration-150"
            >
              + Add Contact
            </button>
          </div>
          {error && (
            <div className="p-4 text-red-700 bg-red-100 border border-red-200 rounded-md mx-4 mt-4">
              {error}
            </div>
          )}
          {isLoading ? (
            <div className="flex justify-center items-center p-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-black"></div>
            </div>
          ) : (
            <PrivateNetworkTable
              data={privateContacts}
              onEdit={handleEditContact}
              onDelete={handleDeleteContact}
              onToggleFavorite={handlePrivateNetworkFavorite}
            />
          )}
        </>
      );
    } else if (activeTab === 'Explore') {
      return <ExploreTab />;
    } else if (activeTab === 'Public') {
      return <PublicNetwork />;
    } else if (activeTab === 'Favorites') {
      return <FavoritesPage />;
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-[#d8d1c7] flex items-center justify-center">
        <span className="text-[#3d3731]">Loading...</span>
      </div>
    );
  }

  const getMainContent = () => {
    switch (activeItem) {
      case 'Home':
        return <HomeComponent />;
      case 'Liens':
        return (
          <div className="flex-1 overflow-hidden bg-[#e9e4dc]">
            <LienManagement />
          </div>
        );
      case 'Documents':
        return (
          <div className="flex-1 overflow-hidden bg-[#e9e4dc]">
            <DocumentDashboard />
          </div>
        );
      case 'Settings':
        return (
          <div className="flex-1 overflow-hidden bg-[#e9e4dc]">
            <SettingsPage />
          </div>
        );
      case 'Notifications':
        return (
          <div className="flex-1 overflow-hidden bg-[#e9e4dc]">
            <NotificationsPage />
          </div>
        );
      case 'Projects': // Referrals section
        return (
          <div className="flex-1 flex flex-col overflow-hidden ml-4">
            <TopBar navigate={navigate} />
            <TabBar activeTab={activeTab} setActiveTab={setActiveTab} />
            <div className="flex-grow flex flex-col bg-[#e9e4dc] rounded-lg shadow-sm mt-4 mx-4 overflow-hidden">
              {renderReferralHubContent()}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-[#d8d1c7] flex items-center justify-center">
        <span className="text-[#3d3731]">Loading...</span>
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-[#e9e4dc]">
      <Sidebar 
        activeItem={activeItem} 
        setActiveItem={setActiveItem}
        user={user}
        navigate={navigate}
      />
      {getMainContent()}
    </div>
  );
};

export default Dashboard;