import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Building, Mail, Phone, User, Calendar, DollarSign, MapPin, FileText } from 'lucide-react';
import { supabase } from '../../backend/supabase';
import { createLien } from '../../backend/lienService';
import StepIndicator from '../shared/StepIndicator';
import FormInput from '../shared/FormInput';

const LienCreationForm = () => {
  const navigate = useNavigate();
  
  // Form state
  const [formData, setFormData] = useState({
    lienholderName: '',
    debtor: '',
    amount: '',
    type: '',
    filingDate: '',
    expiryDate: '',
    propertyAddress: '',
    description: ''
  });
  
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [stepProgress, setStepProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Check authentication
  useEffect(() => {
    const checkAuth = async () => {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error || !user) {
        console.error('Auth check failed:', error);
        navigate('/signup/login');
      }
    };
    checkAuth();
  }, [navigate]);

  // Calculate step progress
  useEffect(() => {
    if (currentStep === 1) {
      const requiredFields = ['lienholderName', 'debtor', 'amount', 'type'];
      const filledFields = requiredFields.filter(field => formData[field].trim()).length;
      setStepProgress(filledFields / requiredFields.length);
    } 
    else if (currentStep === 2) {
      const requiredFields = ['filingDate', 'expiryDate', 'propertyAddress'];
      const filledFields = requiredFields.filter(field => formData[field].trim()).length;
      setStepProgress(filledFields / requiredFields.length);
    }
    else if (currentStep === 3) {
      setStepProgress(files.length > 0 ? 1 : 0.5);
    }
  }, [currentStep, formData, files]);

  const validateField = (name, value) => {
    switch (name) {
      case 'amount':
        return isNaN(value) || value <= 0 ? 'Invalid amount' : '';
      case 'lienholderName':
      case 'debtor':
      case 'type':
      case 'propertyAddress':
        return !value.trim() ? `${name.replace(/([A-Z])/g, ' $1').trim()} is required` : '';
      case 'filingDate':
      case 'expiryDate':
        return !value ? 'Date is required' : '';
      default:
        return '';
    }
  };

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    
    if (errors[field]) {
      setErrors(prev => ({
        ...prev,
        [field]: ''
      }));
    }
  };

  const handleFileSelect = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(prev => [...prev, ...selectedFiles]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError('');

    try {
      const result = await createLien(formData);
      navigate(`/dashboard/liens/${result.id}`);
    } catch (error) {
      console.error('Submission failed:', error);
      setError(error.message || 'Failed to create lien');
    } finally {
      setLoading(false);
    }
  };

  const renderStep1 = () => (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold text-black mb-2">Basic Information</h2>
      <div className="grid grid-cols-2 gap-6">
        <FormInput
          icon={Building}
          label="LIENHOLDER NAME"
          name="lienholderName"
          value={formData.lienholderName}
          onChange={(value) => handleInputChange('lienholderName', value)}
          error={errors.lienholderName}
          required
        />
        <FormInput
          icon={User}
          label="DEBTOR NAME"
          name="debtor"
          value={formData.debtor}
          onChange={(value) => handleInputChange('debtor', value)}
          error={errors.debtor}
          required
        />
        <FormInput
          icon={DollarSign}
          label="AMOUNT"
          name="amount"
          type="number"
          value={formData.amount}
          onChange={(value) => handleInputChange('amount', value)}
          error={errors.amount}
          required
        />
        <div>
          <label className="block text-sm font-medium text-[#3d3731] mb-2">TYPE</label>
          <select
            value={formData.type}
            onChange={(e) => handleInputChange('type', e.target.value)}
            className="w-full px-4 py-2 border border-[#a39b8f] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
          >
            <option value="">Select type</option>
            <option value="medical">Medical</option>
            <option value="tax">Tax</option>
            <option value="hospital">Hospital</option>
            <option value="mechanic">Mechanic</option>
            <option value="other">Other</option>
          </select>
        </div>
      </div>
    </div>
  );

  const renderStep2 = () => (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold text-black mb-2">Property & Dates</h2>
      <div className="grid grid-cols-2 gap-6">
        <FormInput
          icon={Calendar}
          label="FILING DATE"
          name="filingDate"
          type="date"
          value={formData.filingDate}
          onChange={(value) => handleInputChange('filingDate', value)}
          error={errors.filingDate}
          required
        />
        <FormInput
          icon={Calendar}
          label="EXPIRY DATE"
          name="expiryDate"
          type="date"
          value={formData.expiryDate}
          onChange={(value) => handleInputChange('expiryDate', value)}
          error={errors.expiryDate}
          required
        />
        <div className="col-span-2">
          <FormInput
            icon={MapPin}
            label="PROPERTY ADDRESS"
            name="propertyAddress"
            value={formData.propertyAddress}
            onChange={(value) => handleInputChange('propertyAddress', value)}
            error={errors.propertyAddress}
            required
          />
        </div>
        <div className="col-span-2">
          <label className="block text-sm font-medium text-[#3d3731] mb-2">DESCRIPTION</label>
          <textarea
            value={formData.description}
            onChange={(e) => handleInputChange('description', e.target.value)}
            className="w-full px-4 py-2 border border-[#a39b8f] rounded-md focus:outline-none focus:ring-1 focus:ring-black h-32"
            placeholder="Add any additional details about the lien..."
          />
        </div>
      </div>
    </div>
  );

  const renderStep3 = () => (
    <div className="space-y-6">
      <h2 className="text-xl font-semibold text-black mb-2">Document Upload</h2>
      <div
        className="border-2 border-dashed border-[#d8d1c7] rounded-lg p-12 text-center bg-[#f5f1eb] hover:border-[#a39b8f] transition-colors duration-150 cursor-pointer"
        onClick={() => document.getElementById('file-upload').click()}
      >
        <input
          id="file-upload"
          type="file"
          multiple
          onChange={handleFileSelect}
          className="hidden"
        />
        <FileText className="w-12 h-12 mx-auto text-[#a39b8f] mb-4" />
        <p className="text-[#3d3731] mb-2">
          Drag & drop files or <span className="text-black underline">Browse</span>
        </p>
        <p className="text-sm text-[#a39b8f]">
          Upload any relevant documents (deeds, notices, etc.)
        </p>
      </div>
      {files.length > 0 && (
        <div className="mt-6 space-y-2">
          {files.map((file, index) => (
            <div key={index} className="flex items-center justify-between p-3 bg-white border border-[#d8d1c7] rounded-lg">
              <div className="flex items-center gap-2">
                <FileText className="w-4 h-4 text-[#3d3731]" />
                <span className="text-[#3d3731]">{file.name}</span>
              </div>
              <button
                onClick={() => setFiles(files.filter((_, i) => i !== index))}
                className="text-[#a39b8f] hover:text-black"
              >
                ×
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div className="min-h-screen bg-[#e9e4dc] flex flex-col pt-4">
      <div className="max-w-5xl mx-auto w-full px-6">
        <div className="flex items-center justify-between mb-6">
          <button
            onClick={() => currentStep > 1 ? setCurrentStep(prev => prev - 1) : navigate(-1)}
            className="inline-flex items-center text-[#3d3731] hover:text-black transition-colors duration-150"
          >
            <ArrowLeft className="h-5 w-5 mr-2" />
            <span className="text-lg">Back</span>
          </button>
          <h1 className="text-3xl font-bold text-black">Create New Lien</h1>
          <div className="w-[100px]" />
        </div>

        <div className="bg-white rounded-2xl shadow-sm p-8">
          <StepIndicator currentStep={currentStep} totalSteps={3} progress={stepProgress} />

          {currentStep === 1 && renderStep1()}
          {currentStep === 2 && renderStep2()}
          {currentStep === 3 && renderStep3()}

          <div className="flex justify-between mt-8">
            <button
              onClick={() => currentStep > 1 ? setCurrentStep(prev => prev - 1) : navigate(-1)}
              className="px-6 py-2.5 text-[#3d3731] rounded-full transition-colors duration-150 text-sm font-medium border border-[#d8d1c7] hover:border-[#a39b8f]"
            >
              {currentStep > 1 ? 'Previous step' : 'Cancel'}
            </button>
            <button
              onClick={() => currentStep < 3 ? setCurrentStep(prev => prev + 1) : handleSubmit()}
              disabled={loading}
              className="px-6 py-2.5 bg-black text-[#e9e4dc] rounded-full transition-colors duration-150 text-sm font-medium hover:bg-[#3d3731]"
            >
              {currentStep === 3 ? (loading ? 'Creating...' : 'Create Lien') : 'Next step'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LienCreationForm;