import { supabase } from '../supabase';

export const privateNetworkService = {
  async fetchContacts() {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      // Get the users table ID
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('id')
        .eq('email', user.email)
        .single();

      if (userError) throw userError;

      const { data, error } = await supabase
        .from('private_network_contacts')
        .select('*')
        .eq('user_id', userData.id)
        .order('created_at', { ascending: false });

      if (error) throw error;

      return { success: true, data };
    } catch (error) {
      console.error('Error fetching private network contacts:', error);
      return { success: false, error: error.message };
    }
  },

  async addContact(contactData) {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      // Get the users table ID
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('id')
        .eq('email', user.email)
        .single();

      if (userError) throw userError;

      const { data, error } = await supabase
        .from('private_network_contacts')
        .insert([{
          user_id: userData.id,
          firm: contactData.firm,
          contact: contactData.contact,
          phone: contactData.phone,
          email: contactData.email,
          practice_area: contactData.practiceArea,
          status: contactData.status,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }])
        .select()
        .single();

      if (error) throw error;

      return { success: true, data };
    } catch (error) {
      console.error('Error adding private network contact:', error);
      return { success: false, error: error.message };
    }
  },

  async updateContact(contactId, contactData) {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      // Get the users table ID
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('id')
        .eq('email', user.email)
        .single();

      if (userError) throw userError;

      const { data, error } = await supabase
        .from('private_network_contacts')
        .update({
          firm: contactData.firm,
          contact: contactData.contact,
          phone: contactData.phone,
          email: contactData.email,
          practice_area: contactData.practiceArea,
          status: contactData.status,
          updated_at: new Date().toISOString()
        })
        .eq('id', contactId)
        .eq('user_id', userData.id)
        .select()
        .single();

      if (error) throw error;

      return { success: true, data };
    } catch (error) {
      console.error('Error updating private network contact:', error);
      return { success: false, error: error.message };
    }
  },

  async deleteContact(contactId) {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      // Get the users table ID
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('id')
        .eq('email', user.email)
        .single();

      if (userError) throw userError;

      const { error } = await supabase
        .from('private_network_contacts')
        .delete()
        .eq('id', contactId)
        .eq('user_id', userData.id);

      if (error) throw error;

      return { success: true };
    } catch (error) {
      console.error('Error deleting private network contact:', error);
      return { success: false, error: error.message };
    }
  }
};