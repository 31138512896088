import React from 'react';

const SectionDivider = ({ isBlackAndWhite = false }) => {
  const baseStyle = isBlackAndWhite 
    ? 'bg-gray-100 bg-opacity-80' 
    : 'bg-[#2E1A0E] bg-opacity-80';

  return (
    <div className={`relative h-16 ${baseStyle} overflow-hidden`}>
      {!isBlackAndWhite && (
        <div className="absolute inset-0">
          <div className="absolute -top-1/4 -right-1/4 w-2/3 h-2/3 bg-[#964B00] rounded-full filter blur-[100px] opacity-30 origin-center" />
          <div className="absolute -bottom-1/3 -left-1/4 w-3/4 h-3/4 bg-[#FFC499] rounded-full filter blur-[100px] opacity-30 origin-center" />
        </div>
      )}
    </div>
  );
};

export default SectionDivider;