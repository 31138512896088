import { supabase } from './supabase';

export const favoritesService = {
  fetchFavorites: async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { data, error } = await supabase
        .from('favorites_contacts')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;

      return {
        success: true,
        data
      };
    } catch (error) {
      console.error('Error fetching favorites:', error);
      return {
        success: false,
        error: error.message || 'Failed to fetch favorites'
      };
    }
  },

  toggleFavorite: async (contact) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      // Check if already in favorites
      const { data: existingFavorite, error: fetchError } = await supabase
        .from('favorites_contacts')
        .select('id')
        .eq('user_id', user.id)
        .eq('email', contact.email)
        .single();

      if (fetchError && fetchError.code !== 'PGRST116') throw fetchError;

      if (existingFavorite) {
        // Delete existing favorite
        const { error: deleteError } = await supabase
          .from('favorites_contacts')
          .delete()
          .eq('id', existingFavorite.id);

        if (deleteError) throw deleteError;
      } else {
        // Add new favorite
        const { error: insertError } = await supabase
          .from('favorites_contacts')
          .insert({
            id: crypto.randomUUID(),
            user_id: user.id,
            firm: contact.firm_name,
            contact: `${contact.first_name} ${contact.last_name}`,
            phone: contact.phone,
            email: contact.email,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
          });

        if (insertError) throw insertError;
      }

      return {
        success: true,
        data: { ...contact, is_favorite: !contact.is_favorite }
      };

    } catch (error) {
      console.error('Error toggling favorite:', error);
      return {
        success: false,
        error: error.message || 'Failed to toggle favorite'
      };
    }
  },

  checkFavoriteStatus: async (emails) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { data, error } = await supabase
        .from('favorites_contacts')
        .select('email')
        .eq('user_id', user.id)
        .in('email', emails);

      if (error) throw error;

      // Create a Set of favorited emails for efficient lookup
      const favoritedEmails = new Set(data.map(f => f.email));

      return {
        success: true,
        data: favoritedEmails
      };
    } catch (error) {
      console.error('Error checking favorite status:', error);
      return {
        success: false,
        error: error.message || 'Failed to check favorite status'
      };
    }
  }
};