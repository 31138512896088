// src/backend/emailService.js
import { SESClient, SendEmailCommand } from "@aws-sdk/client-ses";

// Verify AWS credentials are present and properly formatted
// Note: Using domain verification (lawdie.co) rather than individual email verification
// referrals@lawdie.co is a WorkMail mailbox that exists on the verified domain
const validateCredentials = () => {
  const missingVars = [];
  const credentials = {
    accessKeyId: process.env.REACT_APP_SES_ACCESS_KEY_ID?.trim(),
    secretAccessKey: process.env.REACT_APP_SES_SECRET_ACCESS_KEY?.trim(),
    region: process.env.REACT_APP_SES_REGION?.trim() || 'us-east-2'
  };

  if (!credentials.accessKeyId) missingVars.push('REACT_APP_SES_ACCESS_KEY_ID');
  if (!credentials.secretAccessKey) missingVars.push('REACT_APP_SES_SECRET_ACCESS_KEY');

  console.log('AWS Configuration:', {
    region: credentials.region,
    hasAccessKey: !!credentials.accessKeyId,
    hasSecretKey: !!credentials.secretAccessKey
  });

  if (missingVars.length > 0) {
    throw new Error(`Missing required AWS credentials: ${missingVars.join(', ')}`);
  }

  return credentials;
};

// Create SES Client with validated credentials
const createSESClient = () => {
  try {
    const credentials = validateCredentials();
    console.log('Creating SES client...');
    
    return new SESClient({ 
      region: credentials.region,
      credentials: {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey
      },
      maxAttempts: 3
    });
  } catch (error) {
    console.error('Error creating SES client:', error);
    throw error;
  }
};

const ses = createSESClient();

const generateEmailContent = (referralData) => {
  const subject = 'New Referral Request from Lawdie';
  
  const text = `
Hello ${referralData.name},

You have received a new referral request from Lawdie.

Case Details:
- Practice Area: ${referralData.selectedSubject}
- Description: ${referralData.caseDetails}
- Compensation: ${referralData.compensationPercentage}% referral fee
- Response Required By: ${new Date(referralData.acceptanceDeadline).toLocaleDateString()}

Please log in to your Lawdie dashboard to review and respond to this referral:
https://lawdie.co/dashboard

Important Notes:
- This referral requires your response by the deadline indicated above
- You can accept or decline this referral through your dashboard
- All communications regarding this referral should be conducted through the Lawdie platform

If you have any questions, please contact us at lawdieco@gmail.com

Best regards,
The Lawdie Team
  `;

  const html = `
<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>New Referral Request</title>
</head>
<body style="font-family: Arial, sans-serif; line-height: 1.6; color: #333; max-width: 600px; margin: 0 auto; padding: 20px;">
  <div style="text-align: center; margin-bottom: 30px;">
    <h1 style="color: #1a1a1a; margin-bottom: 10px;">New Referral Request</h1>
    <p style="color: #666;">Via Lawdie's Referral Network</p>
  </div>

  <div style="background-color: #f9f9f9; border-radius: 8px; padding: 20px; margin-bottom: 30px;">
    <h2 style="color: #1a1a1a; margin-bottom: 20px;">Case Details</h2>
    <table style="width: 100%; border-collapse: collapse;">
      <tr>
        <td style="padding: 10px 0; border-bottom: 1px solid #eee;">
          <strong>Practice Area:</strong>
        </td>
        <td style="padding: 10px 0; border-bottom: 1px solid #eee;">
          ${referralData.selectedSubject}
        </td>
      </tr>
      <tr>
        <td style="padding: 10px 0; border-bottom: 1px solid #eee;">
          <strong>Description:</strong>
        </td>
        <td style="padding: 10px 0; border-bottom: 1px solid #eee;">
          ${referralData.caseDetails}
        </td>
      </tr>
      <tr>
        <td style="padding: 10px 0; border-bottom: 1px solid #eee;">
          <strong>Compensation:</strong>
        </td>
        <td style="padding: 10px 0; border-bottom: 1px solid #eee;">
          ${referralData.compensationPercentage}% referral fee
        </td>
      </tr>
      <tr>
        <td style="padding: 10px 0;">
          <strong>Response Required By:</strong>
        </td>
        <td style="padding: 10px 0;">
          ${new Date(referralData.acceptanceDeadline).toLocaleDateString()}
        </td>
      </tr>
    </table>
  </div>

  <div style="background-color: #fff3e0; border-radius: 8px; padding: 20px; margin-bottom: 30px;">
    <h3 style="color: #1a1a1a; margin-bottom: 10px;">Important Notes:</h3>
    <ul style="margin: 0; padding-left: 20px;">
      <li>This referral requires your response by the deadline indicated above</li>
      <li>You can accept or decline this referral through your dashboard</li>
      <li>All communications regarding this referral should be conducted through the Lawdie platform</li>
    </ul>
  </div>

  <div style="text-align: center; margin-top: 30px;">
    <a href="https://lawdie.co/dashboard" 
       style="display: inline-block; background-color: #1a1a1a; color: white; padding: 12px 24px; text-decoration: none; border-radius: 4px; font-weight: bold;">
      View Referral in Dashboard
    </a>
  </div>

  <div style="margin-top: 40px; text-align: center; color: #666; font-size: 14px;">
    <p>If you have any questions, please contact us at <a href="mailto:lawdieco@gmail.com" style="color: #1a1a1a;">lawdieco@gmail.com</a></p>
    <p>© 2024 Lawdie. All rights reserved.</p>
  </div>
</body>
</html>
  `;

  return {
    subject,
    text,
    html
  };
};

export const sendReferralEmail = async (referralData) => {
  try {
    // Validate input data
    if (!referralData?.email) {
      throw new Error('Email recipient is required');
    }

    // Log attempt
    console.log('Attempting to send email:', {
      to: referralData.email,
      subject: 'New Referral Request from Lawdie',
      hasName: !!referralData.name,
      hasSubject: !!referralData.selectedSubject,
      hasDetails: !!referralData.caseDetails,
      hasCompensation: !!referralData.compensationPercentage,
      hasDeadline: !!referralData.acceptanceDeadline
    });

    const emailContent = generateEmailContent(referralData);
    
    console.log('Email content generated:', {
      hasHtmlContent: !!emailContent.html,
      hasTextContent: !!emailContent.text,
      subjectLength: emailContent.subject.length
    });

    const emailParams = {
      Source: 'referrals@lawdie.co',
      Destination: {
        ToAddresses: [referralData.email.trim()]
      },
      ReplyToAddresses: ['referrals@lawdie.co'],
      Message: {
        Subject: {
          Data: emailContent.subject,
          Charset: 'UTF-8'
        },
        Body: {
          Text: {
            Data: emailContent.text,
            Charset: 'UTF-8'
          },
          Html: {
            Data: emailContent.html,
            Charset: 'UTF-8'
          }
        }
      }
    };

    console.log('Sending email with params:', {
      source: emailParams.Source,
      destination: emailParams.Destination.ToAddresses[0],
      replyTo: emailParams.ReplyToAddresses[0],
      hasSubject: !!emailParams.Message.Subject.Data,
    });

    const command = new SendEmailCommand(emailParams);
    const response = await ses.send(command);

    console.log('Email sent successfully:', {
      messageId: response.MessageId,
      requestId: response.$metadata?.requestId,
      httpStatusCode: response.$metadata?.httpStatusCode
    });

    return {
      success: true,
      messageId: response.MessageId,
      metadata: response.$metadata
    };

  } catch (error) {
    console.error('Detailed error in sendReferralEmail:', {
      name: error?.name || 'Unknown error',
      message: error?.message || 'No error message available',
      code: error?.code,
      requestId: error?.$metadata?.requestId,
      httpStatusCode: error?.$metadata?.httpStatusCode,
      stack: error?.stack,
      awsErrorDetails: error?.awsErrorDetails,
      serviceId: error?.serviceId
    });
    
    throw error;
  }
};

//email service for support ticket
export const sendSupportTicket = async (ticketData) => {
  try {
    // Validate input data
    if (!ticketData?.email) {
      throw new Error('Email sender is required');
    }

    // Log attempt
    console.log('Attempting to send support ticket:', {
      from: ticketData.email,
      subject: `Support Ticket: ${ticketData.subject}`,
      hasName: !!ticketData.name,
      hasDescription: !!ticketData.description,
      category: ticketData.category,
      priority: ticketData.priority
    });

    const emailContent = generateSupportTicketContent(ticketData);
    
    const emailParams = {
      Source: 'support@lawdie.co',
      Destination: {
        ToAddresses: ['babhinav.117@gmail.com']
      },
      ReplyToAddresses: [ticketData.email],
      Message: {
        Subject: {
          Data: `Support Ticket: ${ticketData.subject}`,
          Charset: 'UTF-8'
        },
        Body: {
          Text: {
            Data: emailContent.text,
            Charset: 'UTF-8'
          },
          Html: {
            Data: emailContent.html,
            Charset: 'UTF-8'
          }
        }
      }
    };

    console.log('Sending support ticket email with params:', {
      source: emailParams.Source,
      destination: emailParams.Destination.ToAddresses[0],
      replyTo: emailParams.ReplyToAddresses[0],
      hasSubject: !!emailParams.Message.Subject.Data,
    });

    const command = new SendEmailCommand(emailParams);
    const response = await ses.send(command);

    console.log('Support ticket email sent successfully:', {
      messageId: response.MessageId,
      requestId: response.$metadata?.requestId,
      httpStatusCode: response.$metadata?.httpStatusCode
    });

    return {
      success: true,
      messageId: response.MessageId,
      metadata: response.$metadata
    };

  } catch (error) {
    console.error('Error sending support ticket:', error);
    throw error;
  }
};

const generateSupportTicketContent = (ticketData) => {
  const text = `
Support Ticket Submitted

From: ${ticketData.name} (${ticketData.email})
Category: ${ticketData.category}
Priority: ${ticketData.priority}

Description:
${ticketData.description}
  `;

  const html = `
<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Support Ticket</title>
</head>
<body style="font-family: Arial, sans-serif; line-height: 1.6; color: #333; max-width: 600px; margin: 0 auto; padding: 20px;">
  <div style="text-align: center; margin-bottom: 30px;">
    <h1 style="color: #1a1a1a; margin-bottom: 10px;">New Support Ticket</h1>
    <p style="color: #666;">Via Lawdie Support System</p>
  </div>

  <div style="background-color: #f9f9f9; border-radius: 8px; padding: 20px; margin-bottom: 30px;">
    <h2 style="color: #1a1a1a; margin-bottom: 20px;">Ticket Details</h2>
    <table style="width: 100%; border-collapse: collapse;">
      <tr>
        <td style="padding: 10px 0; border-bottom: 1px solid #eee;">
          <strong>From:</strong>
        </td>
        <td style="padding: 10px 0; border-bottom: 1px solid #eee;">
          ${ticketData.name} (${ticketData.email})
        </td>
      </tr>
      <tr>
        <td style="padding: 10px 0; border-bottom: 1px solid #eee;">
          <strong>Category:</strong>
        </td>
        <td style="padding: 10px 0; border-bottom: 1px solid #eee;">
          ${ticketData.category}
        </td>
      </tr>
      <tr>
        <td style="padding: 10px 0; border-bottom: 1px solid #eee;">
          <strong>Priority:</strong>
        </td>
        <td style="padding: 10px 0; border-bottom: 1px solid #eee;">
          ${ticketData.priority}
        </td>
      </tr>
    </table>

    <div style="margin-top: 20px;">
      <h3 style="color: #1a1a1a; margin-bottom: 10px;">Description:</h3>
      <p style="white-space: pre-line;">${ticketData.description}</p>
    </div>
  </div>

  <div style="margin-top: 40px; text-align: center; color: #666; font-size: 14px;">
    <p>© 2024 Lawdie Support System</p>
  </div>
</body>
</html>
  `;

  return { text, html };
};