import React from 'react';
import { useNavigate } from 'react-router-dom';

const SignupPage = () => {
  const navigate = useNavigate();

  const handleRoleSelect = (role) => {
    console.log('Setting role:', role);
    localStorage.setItem('userRole', role);
    navigate('/signup/login');
  };

  return (
    <div className="min-h-screen bg-[#fef6ef] flex flex-col">
      {/* Header with Back Button and Logo */}
      <div className="flex justify-between items-center p-6">
        <button 
          onClick={() => navigate(-1)}
          className="text-gray-600 hover:text-gray-800 text-sm"
        >
          ← Back
        </button>
        <div className="text-center text-xl font-bold tracking-wider">Lawdie</div>
        <div className="w-[52px]"></div> {/* Spacer for alignment */}
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col items-center justify-center px-4 -mt-8">
        <h1 className="text-3xl font-semibold text-[#333333] mb-16">
          Who Are You
        </h1>

        <div className="flex justify-center items-center gap-20 max-w-4xl w-full mb-16">
          {/* Client Option */}
          <div className="flex-1 flex flex-col items-center">
            <div className="mb-6">
              <img 
                src="/client_img.png" 
                alt="Client"
                className="w-64 h-64 object-contain"
              />
            </div>
            <button
              onClick={() => handleRoleSelect('client')}
              className="w-full max-w-xs bg-[#3a302e] text-white py-3 rounded-lg hover:bg-gray-800 transition-colors"
            >
              Client
            </button>
          </div>

          {/* Divider */}
          <div className="flex flex-col items-center text-gray-500 -mt-16">
            <div className="w-px h-32 bg-gray-300 mb-4"></div>
            <div>OR</div>
            <div className="w-px h-32 bg-gray-300 mt-4"></div>
          </div>


          {/* Attorney Option */}
          <div className="flex-1 flex flex-col items-center">
            <div className="mb-6">
              <img 
                src="/attorney_img.png" 
                alt="Attorney"
                className="w-64 h-64 object-contain"
              />
            </div>
            <button
              onClick={() => handleRoleSelect('attorney')}
              className="w-full max-w-xs bg-[#3a302e] text-white py-3 rounded-lg hover:bg-gray-800 transition-colors"
            >
              Attorney
            </button>
          </div>
        </div>

        {/* Footer */}
        <div className="text-center">
          <div className="text-base font-medium mb-1">Lawdie</div>
          <div className="text-sm text-gray-600">For clients, attorneys, everyone.</div>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;