import React, { useState, useEffect } from 'react';
import { X, Loader } from 'lucide-react';
import ContactDetailsStep from '../referrals/ContactDetailsStep';
import CaseDetailsStep from '../referrals/CaseDetailsStep';
import { FilesStep } from '../referrals/FilesAndSubmitSteps';
import StepIndicator from '../shared/StepIndicator';
import { supabase } from '../../backend/supabase';


import { referralUpdateService } from '../../backend/referralServices/referralUpdateService';

const EditReferralModal = ({ isOpen, onClose, referral, onUpdate }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [stepProgress, setStepProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    firm: '',
    selectedSubject: '',
    caseDetails: '',
    files: [],
    compensationPercentage: 25,
    acceptanceDeadline: null,
    non_user_id: null,
    case_id: null
  });

  useEffect(() => {
    if (referral) {
      console.log('Received referral data for edit:', {
        ...referral,
        case_id: referral.case_id,
        non_user_id: referral.non_user_id,
        contact: referral.contact,
        phone: referral.phone,
        email: referral.email
      });

      // Extract compensation percentage from terms
      const percentageMatch = referral.compensationTerms?.match(/(\d+)%/);
      const percentage = percentageMatch ? parseInt(percentageMatch[1]) : 25;

      const newFormData = {
        name: referral.contact || '',
        email: referral.email || '',
        phone: referral.phone || '',
        firm: referral.name || '',
        selectedSubject: referral.caseDetails?.practiceArea || '',
        caseDetails: referral.caseDetails?.description || '',
        files: referral.files || [],
        compensationPercentage: percentage,
        acceptanceDeadline: referral.acceptanceDeadline,
        non_user_id: referral.non_user_id,
        case_id: referral.case_id
      };

      console.log('Setting form data with IDs:', {
        case_id: newFormData.case_id,
        non_user_id: newFormData.non_user_id,
        phone: newFormData.phone,
        email: newFormData.email
      });

      setFormData(newFormData);
    }
  }, [referral]);

  useEffect(() => {
    // Calculate step progress
    if (currentStep === 1) {
      const filledFields = Object.entries(formData)
        .filter(([key]) => ['name', 'email', 'phone', 'firm'].includes(key))
        .filter(([_, value]) => value?.trim())
        .length;
      setStepProgress(filledFields / 4);
    } else if (currentStep === 2) {
      setStepProgress(
        formData.selectedSubject && formData.caseDetails ? 1 : 0.5
      );
    } else if (currentStep === 3) {
      setStepProgress(formData.acceptanceDeadline ? 1 : 0.5);
    }
  }, [currentStep, formData]);

  if (!isOpen) return null;

  const handleInputChange = (field, value) => {
    console.log('Input change:', field, value);
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const validateStep = (step) => {
    const errors = {};
    
    switch (step) {
      case 1:
        if (!formData.name.trim()) errors.name = 'Name is required';
        if (!formData.email.trim()) errors.email = 'Email is required';
        if (!formData.phone.trim()) errors.phone = 'Phone is required';
        if (!formData.firm.trim()) errors.firm = 'Firm is required';
        break;
        
      case 2:
        if (!formData.selectedSubject) errors.subject = 'Subject is required';
        if (!formData.caseDetails.trim()) errors.details = 'Case details are required';
        break;
        
      case 3:
        if (!formData.acceptanceDeadline) errors.deadline = 'Deadline is required';
        break;
    }
    
    return errors;
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError('');

    try {
      console.log('Submitting update with data:', {
        referralId: referral.id,
        formData
      });

      if (!formData.non_user_id || !formData.case_id) {
        throw new Error('Missing required IDs for update');
      }

      const result = await referralUpdateService.updateReferral(referral.id, formData);

      if (!result.success) {
        throw new Error(result.error || 'Failed to update referral');
      }

      console.log('Update successful:', result.data);

      if (onUpdate) {
        onUpdate(result.data);
      }
      onClose();
    } catch (err) {
      console.error('Error updating referral:', err);
      setError(err.message || 'Failed to update referral');
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    const errors = validateStep(currentStep);
    if (Object.keys(errors).length > 0) {
      setError('Please fill in all required fields');
      return;
    }
    
    setCurrentStep(prev => prev + 1);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#e9e4dc] rounded-lg max-w-4xl w-full m-4 max-h-[90vh] flex flex-col">
        <div className="flex justify-between items-center p-6 border-b border-[#d8d1c7]">
          <h2 className="text-xl font-semibold text-black">Edit Referral</h2>
          <button 
            onClick={onClose}
            className="text-[#3d3731] hover:text-black"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="flex-1 overflow-y-auto p-6">
          <StepIndicator 
            currentStep={currentStep} 
            totalSteps={3} 
            progress={stepProgress} 
          />

          {error && (
            <div className="mb-6 p-4 bg-red-50 border border-red-200 text-red-700 rounded">
              {error}
            </div>
          )}

          {currentStep === 1 && (
            <ContactDetailsStep
              formData={formData}
              errors={{}}
              onInputChange={handleInputChange}
              onInputBlur={() => {}}
            />
          )}

          {currentStep === 2 && (
            <CaseDetailsStep
              phase={1}
              selectedSubject={formData.selectedSubject}
              caseDetails={formData.caseDetails}
              onSubjectSelect={(subject) => handleInputChange('selectedSubject', subject)}
              onDetailsChange={(details) => handleInputChange('caseDetails', details)}
            />
          )}

          {currentStep === 3 && (
            <FilesStep
              phase={1}
              files={formData.files}
              onFileUpload={(files) => handleInputChange('files', files)}
              compensationPercentage={formData.compensationPercentage}
              acceptanceDeadline={formData.acceptanceDeadline}
              onCompensationChange={(value) => handleInputChange('compensationPercentage', value)}
              onDeadlineChange={(date) => handleInputChange('acceptanceDeadline', date)}
            />
          )}
        </div>

        <div className="p-6 border-t border-[#d8d1c7] flex justify-between">
          <button
            onClick={() => currentStep > 1 ? setCurrentStep(prev => prev - 1) : onClose()}
            disabled={loading}
            className="px-4 py-2 text-[#3d3731] bg-[#d8d1c7] border border-[#a39b8f] rounded-md hover:bg-[#c5beb2]"
          >
            {currentStep > 1 ? 'Previous' : 'Cancel'}
          </button>

          <button
            onClick={() => currentStep < 3 ? handleNext() : handleSubmit()}
            disabled={loading}
            className="px-6 py-2 bg-black text-white rounded-md hover:bg-[#3d3731] flex items-center"
          >
            {loading && <Loader className="w-4 h-4 mr-2 animate-spin" />}
            {currentStep === 3 ? 'Save Changes' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditReferralModal;