// src/backend/referralServices/userToUserReferral.js
import { supabase } from '../supabase';

class UserToUserReferralService {
  async fetchIncomingReferrals(userId) {
    try {
      // First get the user's email
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('email')
        .eq('id', userId)
        .single();

      if (userError) throw userError;

      // Get ONLY direct referrals where user is the referred user
      const { data: referrals, error: referralError } = await supabase
        .from('referrals')
        .select(`
          id,
          status,
          compensation_terms,
          acceptance_deadline,
          created_at,
          case_id,
          cases (
            id,
            title,
            description,
            practice_area,
            status
          ),
          referring_user:referring_user_id (
            id,
            first_name,
            last_name,
            email,
            phone,
            lawyer_profiles (
              firm_name
            )
          )
        `)
        .eq('referred_user_id', userId);

      if (referralError) throw referralError;

      // Get ONLY non_user referrals that match the user's email exactly and haven't been claimed
      const { data: nonUserReferrals, error: nonUserError } = await supabase
        .rpc('get_unclaimed_referrals_by_email', { 
          user_email: userData.email.toLowerCase() 
        });

      if (nonUserError) throw nonUserError;

      // Format referrals
      const formattedReferrals = [
        ...(referrals || []),
        ...(nonUserReferrals || [])
      ].map(ref => ({
        id: ref.id,
        logo: "/api/placeholder/40/40",
        name: ref.referring_user?.lawyer_profiles?.[0]?.firm_name || 'Unknown Firm',
        contact: `${ref.referring_user?.first_name || ''} ${ref.referring_user?.last_name || ''}`.trim(),
        email: ref.referring_user?.email || '',
        phone: ref.referring_user?.phone || '',
        status: ref.status,
        progress: this.calculateProgress(ref.status),
        case_id: ref.case_id,
        caseDetails: {
          title: ref.cases?.title || '',
          description: ref.cases?.description || '',
          practiceArea: ref.cases?.practice_area || '',
        },
        compensationTerms: ref.compensation_terms,
        acceptanceDeadline: ref.acceptance_deadline,
        created_at: ref.created_at
      }));

      // If we found any unclaimed referrals, claim them
      if (nonUserReferrals?.length > 0) {
        const referralIds = nonUserReferrals.map(r => r.id);
        
        // Update referrals to link to this user
        await supabase
          .from('referrals')
          .update({
            referred_user_id: userId,
            referred_non_user_id: null,
            updated_at: new Date().toISOString()
          })
          .in('id', referralIds);
        
        // Update user's referral count
        await supabase
          .from('users')
          .update({
            incoming_referrals: formattedReferrals.length,
            updated_at: new Date().toISOString()
          })
          .eq('id', userId);
      }

      return { 
        success: true, 
        data: formattedReferrals 
      };
    } catch (error) {
      console.error('Error fetching incoming referrals:', error);
      return { 
        success: false, 
        error: error.message 
      };
    }
  }

  calculateProgress(status) {
    const statusProgress = {
      pending: 25,
      received: 40,
      viewed: 50,
      renegotiating: 65,
      accepted: 75,
      litigation: 85,
      billing: 95,
      completed: 100,
      rejected: 0,
      expired: 0
    };
    return statusProgress[status?.toLowerCase()] || 0;
  }
}

export const userToUserReferralService = new UserToUserReferralService();