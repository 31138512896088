// src/components/referrals/SubmitStep.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Send, Check, AlertCircle, Loader, FileText } from 'lucide-react';
import { referralSubmissionService } from '../../backend/referralServices/referralSubmissionService';
import { emailApiService } from '../../backend/emailApiService';

export const SubmitStep = ({ 
  formData, 
  files = [], 
  onSuccess, 
  onBack 
}) => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [isComplete, setIsComplete] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    // Reset state when form data changes
    setError(null);
    setIsComplete(false);
    setShowConfirmation(false);
  }, [formData]);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      setError(null);

      // 1. Create referral in database
      console.log('Creating referral with data:', {
        ...formData,
        filesCount: files.length
      });

      const result = await referralSubmissionService.handleReferralSubmit(formData, files);
      
      if (!result.success) {
        throw new Error(result.error || 'Failed to submit referral');
      }

      console.log('Referral created successfully:', result.data);

      // 2. Send email notification through our email API
      try {
        console.log('Sending referral email notification...');
        await emailApiService.sendReferralEmail({
          ...formData,
          referralId: result.data.id
        });
        console.log('Email sent successfully');
      } catch (emailError) {
        console.error('Error sending email:', emailError);
        // Don't fail the whole submission if email fails
        setError('Referral created but email notification failed. Please try resending the email later.');
      }

      setIsComplete(true);
      if (onSuccess) {
        onSuccess(result.data);
      }

      // Navigate to dashboard after a short delay
      setTimeout(() => {
        navigate('/dashboard');
      }, 1500);
    } catch (err) {
      console.error('Submission failed:', err);
      setError(err.message || 'Failed to submit referral');
      setShowConfirmation(false);
    } finally {
      setSubmitting(false);
    }
  };

  const confirmSubmit = () => {
    if (submitting) return;
    setShowConfirmation(true);
  };

  // Format file size for display
  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  return (
    <div className="flex flex-col items-center py-8">
      {/* Status Icon */}
      <div className="relative w-24 h-24 mb-8">
        <div className={`w-24 h-24 rounded-full flex items-center justify-center transition-all duration-500 ${
          error ? 'bg-red-500' : 
          isComplete ? 'bg-black scale-110' : 
          'bg-black'
        }`}>
          <div className="relative">
            {error ? (
              <AlertCircle className="w-12 h-12 text-white" />
            ) : isComplete ? (
              <Check className="w-12 h-12 text-[#e9e4dc]" />
            ) : (
              <Send className="w-12 h-12 text-[#e9e4dc]" />
            )}
          </div>
        </div>
      </div>

      {/* Title & Message */}
      <h2 className="text-2xl font-semibold text-black mb-4">
        {error ? 'Submission Failed' : 
         isComplete ? 'Submitted!' : 
         showConfirmation ? 'Confirm Submission' :
         'Review & Submit'}
      </h2>

      {error ? (
        <div className="text-center mb-8">
          <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-4">
            <p className="text-red-700">{error}</p>
          </div>
          <p className="text-[#3d3731]">
            Please try again or contact support if the problem persists.
          </p>
        </div>
      ) : isComplete ? (
        <div className="text-center mb-8">
          <p className="text-[#3d3731]">
            Your referral has been successfully submitted and an email has been sent to {formData.email}.
          </p>
          <p className="text-[#3d3731] mt-2">
            Redirecting to dashboard...
          </p>
        </div>
      ) : showConfirmation ? (
        <div className="w-full max-w-2xl">
          {/* Confirmation Details */}
          <div className="bg-[#f5f1eb] rounded-lg p-6 mb-6">
            <h3 className="font-medium text-lg mb-4">Please confirm the details:</h3>
            
            <div className="space-y-4">
              <div>
                <h4 className="font-medium text-[#3d3731]">Recipient</h4>
                <p className="text-black">{formData.name}</p>
                <p className="text-[#3d3731]">{formData.email}</p>
                <p className="text-[#3d3731]">{formData.phone}</p>
                <p className="text-[#3d3731]">{formData.firm}</p>
              </div>

              <div>
                <h4 className="font-medium text-[#3d3731]">Case Details</h4>
                <p className="text-black">{formData.selectedSubject}</p>
                <p className="text-[#3d3731]">{formData.caseDetails}</p>
              </div>

              <div>
                <h4 className="font-medium text-[#3d3731]">Terms</h4>
                <p className="text-black">{formData.compensationPercentage}% referral fee</p>
                <p className="text-[#3d3731]">Response required by {formatDate(formData.acceptanceDeadline)}</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {/* Files Section */}
          {files && files.length > 0 && (
            <div className="mb-6 w-full max-w-2xl">
              <h3 className="font-medium text-lg mb-3">Attachments</h3>
              <div className="bg-[#f5f1eb] rounded-lg p-4">
                {files.map((file, index) => (
                  <div key={index} className="flex justify-between items-center py-2">
                    <div className="flex items-center">
                      <FileText className="w-4 h-4 mr-2 text-[#3d3731]" />
                      <span className="text-[#3d3731]">{file.name}</span>
                    </div>
                    <span className="text-[#a39b8f]">
                      {formatFileSize(file.size)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Information Box */}
          <div className="bg-[#f5f1eb] rounded-lg p-4 mb-8 w-full max-w-2xl">
            <p className="text-[#3d3731] text-sm">
              By submitting this referral, an email will be sent to {formData.email} with 
              the referral agreement. The recipient will have until {formatDate(formData.acceptanceDeadline)} 
              to accept the referral.
            </p>
          </div>
        </>
      )}

      {/* Buttons */}
      <div className="flex space-x-4">
        {!isComplete && (
          <>
            <button
              onClick={showConfirmation ? () => setShowConfirmation(false) : onBack}
              disabled={submitting}
              className="px-6 py-3 border border-[#d8d1c7] rounded-full text-[#3d3731] hover:border-[#a39b8f] transition-colors duration-150"
            >
              {showConfirmation ? 'Back to Review' : 'Back'}
            </button>
            <button
              onClick={showConfirmation ? handleSubmit : confirmSubmit}
              disabled={submitting}
              className={`px-8 py-3 rounded-full transition-all duration-300 flex items-center ${
                error 
                  ? 'bg-red-500 hover:bg-red-600 text-white' 
                  : 'bg-black text-[#e9e4dc] hover:bg-[#3d3731]'
              }`}
            >
              {submitting ? (
                <>
                  <Loader className="w-4 h-4 mr-2 animate-spin" />
                  Submitting...
                </>
              ) : error ? (
                'Try Again'
              ) : showConfirmation ? (
                'Confirm and Submit'
              ) : (
                'Review Submission'
              )}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default SubmitStep;