import React from 'react';
import { Link } from 'react-router-dom';

const Footer = ({ 
  isBlackAndWhite = false,
  footerLinks = {
    products: [
      { name: 'Assistant', path: '/assistant' },
      { name: 'Vault', path: '/vault' },
      { name: 'Knowledge', path: '/knowledge' },
      { name: 'Workflows', path: '/workflows' }
    ],
    about: [
      { name: 'Customers', path: '/customers' },
      { name: 'Security', path: '/security' },
      { name: 'Company', path: '/company' },
      { name: 'News', path: '/news' },
      { name: 'Careers', path: '/careers' }
    ],
    resources: [
      { name: 'Legal', path: '/legal' },
      { name: 'Privacy Policy', path: '/privacy' },
      { name: 'Press Kit', path: '/press' }
    ],
    follow: [
      { name: 'LinkedIn', path: 'https://www.linkedin.com/company/lawdie' },
      { name: 'X', path: 'https://x.com' }
    ],
    legal: [
      { name: 'Terms', path: '/terms' },
      { name: 'Privacy', path: '/privacy' },
      { name: 'Cookies', path: '/cookies' }
    ]
  },
  onLogoClick
}) => {
  const baseTextColor = isBlackAndWhite ? 'text-gray-500' : 'text-[#8a8178]';
  const headerColor = isBlackAndWhite ? 'text-gray-900' : 'text-[#d8d1c7]';
  const backgroundColor = isBlackAndWhite ? 'bg-white' : 'bg-[#0f0b08]';
  const borderColor = isBlackAndWhite ? 'border-gray-200' : 'border-[#2a2118]';
  const hoverColor = isBlackAndWhite ? 'hover:text-black' : 'hover:text-[#d8d1c7]';
  
  const footerSections = [
    { title: 'Products', links: footerLinks.products },
    { title: 'About', links: footerLinks.about },
    { title: 'Resources', links: footerLinks.resources },
    { title: 'Follow', links: footerLinks.follow }
  ];

  const renderLink = (link) => {
    // Check if it's an external link (starts with http)
    const isExternal = link.path.startsWith('http');
    
    if (isExternal) {
      return (
        <a 
          href={link.path}
          target="_blank"
          rel="noopener noreferrer"
          className={`text-sm ${baseTextColor} ${hoverColor} transition-colors duration-200`}
        >
          {link.name}
        </a>
      );
    }
    
    return (
      <Link 
        to={link.path}
        className={`text-sm ${baseTextColor} ${hoverColor} transition-colors duration-200`}
      >
        {link.name}
      </Link>
    );
  };

  return (
    <footer className={`${backgroundColor} border-t ${borderColor}`}>
      <div className="container mx-auto px-6 py-16">
        <div className="flex flex-col lg:flex-row lg:items-start lg:justify-between gap-12">
          {/* Logo */}
          <div className="lg:w-1/3">
            <span 
              className={`text-2xl font-semibold ${headerColor} cursor-pointer`}
              onClick={onLogoClick}
            >
              Lawdie
            </span>
          </div>

          {/* Navigation Sections */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 lg:gap-16">
            {footerSections.map((section, idx) => (
              <div key={idx} className="flex flex-col">
                <h3 className={`text-sm font-semibold mb-4 ${headerColor}`}>
                  {section.title}
                </h3>
                <ul className="space-y-3">
                  {section.links.map((link, linkIdx) => (
                    <li key={linkIdx}>
                      {renderLink(link)}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Bottom Section */}
        <div className={`mt-16 pt-8 flex flex-col md:flex-row justify-between items-center gap-4`}>
          <div className={`text-sm ${baseTextColor}`}>
            © 2024 Lawdie AI Corporation. All rights reserved.
          </div>
          <div className="flex items-center gap-6">
            {footerLinks.legal.map((link, idx) => (
              <Link 
                key={idx}
                to={link.path}
                className={`text-sm ${baseTextColor} ${hoverColor} transition-colors duration-200`}
              >
                {link.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;