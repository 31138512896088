// src/backend/referralService.js
import { supabase } from './supabase';
import { sendReferralEmail } from './emailService';

// ---------------------------------------------------
// Helper function to properly format phone numbers
// ---------------------------------------------------
const formatPhoneNumber = (phone) => {
  // Remove all non-digit characters
  const digitsOnly = phone.replace(/\D/g, '');
  
  // Ensure we have a valid number of digits (10 for US numbers)
  if (digitsOnly.length === 10) {
    return `+1${digitsOnly}`;
  } else if (digitsOnly.length === 11 && digitsOnly.startsWith('1')) {
    return `+${digitsOnly}`;
  } else if (digitsOnly.length > 11) {
    // For international numbers
    return `+${digitsOnly}`;
  }
  
  // Default case: pad with zeros if necessary (though this might not be ideal)
  const paddedNumber = digitsOnly.padStart(10, '0');
  return `+1${paddedNumber}`;
};

// ---------------------------------------------------
// Calculate the progress of a referral based on status
// ---------------------------------------------------
export const calculateProgress = (status) => {
  const statusProgress = {
    pending: 25,
    received: 40,
    viewed: 50,
    renegotiating: 65,
    accepted: 75,
    litigation: 85,
    billing: 95,
    completed: 100,
    rejected: 0,
    expired: 0,
  };
  return statusProgress[status.toLowerCase()] || 0;
};

// ---------------------------------------------------
// Find an existing attorney (either user or non_user)
// ---------------------------------------------------
const findExistingAttorney = async (email, phone) => {
  try {
    const userQuery = `
      id,
      email,
      phone,
      first_name,
      last_name,
      lawyer_profiles (
        id,
        firm_name,
        practice_areas
      )
    `;

    // First, try to find a user by email
    if (email) {
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select(userQuery)
        .eq('email', email.toLowerCase())
        .eq('user_type', 'attorney')
        .maybeSingle();

      if (userError && userError.code !== 'PGRST116') throw userError;
      if (userData) return { type: 'user', data: userData };
    }

    // Then, try to find a user by phone
    if (phone) {
      const formattedPhone = formatPhoneNumber(phone);
      const { data: phoneUser, error: phoneError } = await supabase
        .from('users')
        .select(userQuery)
        .eq('phone', formattedPhone)
        .eq('user_type', 'attorney')
        .maybeSingle();

      if (phoneError && phoneError.code !== 'PGRST116') throw phoneError;
      if (phoneUser) return { type: 'user', data: phoneUser };
    }

    // Finally, check non_users for a matching record
    const formattedPhone = phone ? formatPhoneNumber(phone) : null;
    const filterStr = [];

    if (email) filterStr.push(`email.eq.${email.toLowerCase()}`);
    if (formattedPhone) filterStr.push(`phone.eq.${formattedPhone}`);

    if (filterStr.length > 0) {
      const { data: nonUserData, error: nonUserError } = await supabase
        .from('non_users')
        .select('*')
        .or(filterStr.join(','))
        .maybeSingle();

      if (nonUserError && nonUserError.code !== 'PGRST116') throw nonUserError;
      if (nonUserData) return { type: 'non_user', data: nonUserData };
    }

    return null;
  } catch (error) {
    console.error('Error finding existing attorney:', error);
    throw error;
  }
};

// ---------------------------------------------------
// Create a referral without files, updated to format phone
// and handle error cleanup properly
// ---------------------------------------------------
export const createReferralWithoutFiles = async (formData) => {
  try {
    // Format the phone number before creating the non_user
    const formattedPhone = formatPhoneNumber(formData.phone);

    // 1. Create case
    const { data: caseData, error: caseError } = await supabase
      .from('cases')
      .insert([
        {
          title: formData.selectedSubject,
          practice_area: formData.selectedSubject,
          description: formData.caseDetails,
          status: 'pending',
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        },
      ])
      .select()
      .single();

    if (caseError) throw caseError;

    // 2. Create non_user
    const { data: nonUserData, error: nonUserError } = await supabase
      .from('non_users')
      .insert([
        {
          first_name: formData.name.split(' ')[0],
          last_name: formData.name.split(' ').slice(1).join(' '),
          email: formData.email.toLowerCase(),
          phone: formattedPhone,
          company_name: formData.firm,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        },
      ])
      .select()
      .single();

    if (nonUserError) {
      // If non_user creation fails, clean up the case
      await supabase.from('cases').delete().eq('id', caseData.id);
      throw nonUserError;
    }

    // 3. Create referral
    const { data: referralData, error: referralError } = await supabase
      .from('referrals')
      .insert([
        {
          case_id: caseData.id,
          referred_non_user_id: nonUserData.id,
          referring_user_id: (await supabase.auth.getUser()).data.user?.id,
          status: 'pending',
          compensation_terms: `${formData.compensationPercentage}% referral fee`,
          acceptance_deadline: formData.acceptanceDeadline,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          notes: formData.notes || null,
          referral_type: 'standard',
        },
      ])
      .select()
      .single();

    if (referralError) {
      // If referral creation fails, clean up case and non_user
      await supabase.from('cases').delete().eq('id', caseData.id);
      await supabase.from('non_users').delete().eq('id', nonUserData.id);
      throw referralError;
    }

    return { success: true, data: referralData };
  } catch (error) {
    console.error('Error in createReferralWithoutFiles:', error);
    return { 
      success: false, 
      error: error.message || 'Failed to create referral'
    };
  }
};

// ---------------------------------------------------
// Fetch user referrals
// ---------------------------------------------------
export const fetchUserReferrals = async () => {
  try {
    const { data: { user }, error: authError } = await supabase.auth.getUser();
    if (authError) throw authError;
    if (!user) throw new Error('No authenticated user');

    const { data: userMetrics, error: metricsError } = await supabase
      .from('users')
      .select(`
        outgoing_referrals,
        incoming_referrals,
        referral_earnings
      `)
      .eq('id', user.id)
      .single();

    if (metricsError) throw metricsError;

    const { data: outgoingData, error: outgoingError } = await supabase
      .from('referrals')
      .select(`
        id,
        status,
        case_id,
        referred_user_id,
        referred_non_user_id,
        compensation_terms,
        acceptance_deadline,
        created_at,
        cases (
          id,
          title,
          description,
          practice_area,
          status
        ),
        non_users (
          id,
          first_name,
          last_name,
          company_name,
          job_title,
          email,
          phone
        ),
        users (
          id,
          first_name,
          last_name,
          email,
          phone,
          lawyer_profiles (
            firm_name
          )
        )
      `)
      .eq('referring_user_id', user.id)
      .order('created_at', { ascending: false });

    if (outgoingError) throw outgoingError;

    // Format each referral's display info
    const formattedReferrals = outgoingData.map(ref => {
      const isUserReferral = !!ref.referred_user_id;
      const referredPerson = isUserReferral ? ref.users : ref.non_users;

      return {
        id: ref.id,
        logo: "/api/placeholder/40/40",
        name: isUserReferral
          ? ref.users?.lawyer_profiles?.[0]?.firm_name
          : ref.non_users?.company_name || '',
        contact: `${referredPerson?.first_name || ''} ${referredPerson?.last_name || ''}`.trim(),
        email: referredPerson?.email || '',
        phone: referredPerson?.phone || '',
        position: referredPerson?.job_title || 'Attorney',
        status: ref.status,
        progress: calculateProgress(ref.status),
        caseDetails: {
          title: ref.cases?.title || '',
          description: ref.cases?.description || '',
          practiceArea: ref.cases?.practice_area || '',
        },
        case_id: ref.case_id,
        referred_user_id: ref.referred_user_id,
        non_user_id: ref.referred_non_user_id,
        compensationTerms: ref.compensation_terms,
        acceptanceDeadline: ref.acceptance_deadline,
        files: [],
      };
    });

    return {
      success: true,
      data: formattedReferrals,
      metrics: userMetrics || {
        outgoing_referrals: 0,
        incoming_referrals: 0,
        referral_earnings: 0,
      },
    };
  } catch (error) {
    console.error('Error fetching referrals:', error);
    return {
      success: false,
      error: error.message || 'Failed to fetch referrals',
    };
  }
};

// ---------------------------------------------------
// Update the status of a referral
// ---------------------------------------------------
export const updateReferralStatus = async (referralId, newStatus) => {
  try {
    const { data, error } = await supabase
      .from('referrals')
      .update({
        status: newStatus,
        updated_at: new Date().toISOString(),
      })
      .eq('id', referralId)
      .select()
      .single();

    if (error) throw error;

    return { success: true, data };
  } catch (error) {
    console.error('Error updating referral status:', error);
    return { success: false, error };
  }
};

// ---------------------------------------------------
// Delete a referral (and all associated data)
// ---------------------------------------------------
export const deleteReferral = async (referralId) => {
  try {
    const { data: referral, error: referralError } = await supabase
      .from('referrals')
      .select(`
        case_id,
        referring_user_id,
        referred_user_id,
        referred_non_user_id
      `)
      .eq('id', referralId)
      .single();

    if (referralError) throw referralError;

    // Get any documents attached to this referral's case
    const { data: documents, error: docsError } = await supabase
      .from('documents')
      .select('url_id')
      .eq('case_id', referral.case_id);

    if (docsError) throw docsError;

    // Remove files from storage, then delete from 'documents'
    for (const doc of documents || []) {
      await supabase.storage.from('documents').remove([doc.url_id]);
    }
    await supabase.from('documents').delete().eq('case_id', referral.case_id);

    // Delete the referral itself
    const { error: deleteError } = await supabase
      .from('referrals')
      .delete()
      .eq('id', referralId);

    if (deleteError) throw deleteError;

    // Clean up the case associated with this referral
    await supabase.from('cases').delete().eq('id', referral.case_id);

    // Decrement referral counts for the referring and referred user
    if (referral.referring_user_id) {
      const { data: referringUser } = await supabase
        .from('users')
        .select('outgoing_referrals')
        .eq('id', referral.referring_user_id)
        .single();

      if (referringUser?.outgoing_referrals > 0) {
        await supabase
          .from('users')
          .update({
            outgoing_referrals: referringUser.outgoing_referrals - 1,
          })
          .eq('id', referral.referring_user_id);
      }
    }

    if (referral.referred_user_id) {
      const { data: referredUser } = await supabase
        .from('users')
        .select('incoming_referrals')
        .eq('id', referral.referred_user_id)
        .single();

      if (referredUser?.incoming_referrals > 0) {
        await supabase
          .from('users')
          .update({
            incoming_referrals: referredUser.incoming_referrals - 1,
          })
          .eq('id', referral.referred_user_id);
      }
    }

    return { success: true };
  } catch (error) {
    console.error('Error deleting referral:', error);
    return {
      success: false,
      error: error.message || 'Failed to delete referral',
    };
  }
};

// ---------------------------------------------------
// Handle referral submit with optional file uploads
// ---------------------------------------------------
export const handleReferralSubmit = async (formData, files) => {
  try {
    const { data: { user: authUser }, error: authError } = await supabase.auth.getUser();
    if (authError) throw authError;
    if (!authUser) throw new Error('No authenticated user');

    console.log('Creating new referral with data:', formData);

    // 1. Create case
    const { data: caseData, error: caseError } = await supabase
      .from('cases')
      .insert([
        {
          id: crypto.randomUUID(),
          title: `${formData.name} - ${formData.selectedSubject}`,
          description: formData.caseDetails,
          practice_area: formData.selectedSubject,
          status: 'pending',
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        },
      ])
      .select()
      .single();

    if (caseError) throw caseError;

    // 2. Check if referred attorney already exists
    const existingAttorney = await findExistingAttorney(formData.email, formData.phone);
    let referredUserId = null;
    let referredNonUserId = null;

    if (existingAttorney) {
      if (existingAttorney.type === 'user') {
        referredUserId = existingAttorney.data.id;
      } else {
        referredNonUserId = existingAttorney.data.id;
      }
    } else {
      // If not found, create a new non_user record
      const { data: newNonUser, error: createNonUserError } = await supabase
        .from('non_users')
        .insert([
          {
            id: crypto.randomUUID(),
            first_name: formData.name.split(' ')[0],
            last_name: formData.name.split(' ').slice(1).join(' '),
            email: formData.email.toLowerCase(),
            phone: formatPhoneNumber(formData.phone),
            company_name: formData.firm,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
          },
        ])
        .select()
        .single();

      if (createNonUserError) throw createNonUserError;
      referredNonUserId = newNonUser.id;
    }

    // 3. Create the referral record
    const { data: referral, error: referralError } = await supabase
      .from('referrals')
      .insert([
        {
          id: crypto.randomUUID(),
          case_id: caseData.id,
          referring_user_id: authUser.id,
          referred_user_id: referredUserId,
          referred_non_user_id: referredNonUserId,
          status: 'pending',
          email_sent: false,
          reminder_count: 0,
          referral_type: 'standard',
          acceptance_deadline: new Date(formData.acceptanceDeadline).toISOString(),
          compensation_terms: `${formData.compensationPercentage}% referral fee`,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        },
      ])
      .select()
      .single();

    if (referralError) throw referralError;

    // 4. Upload files (if any) and attach them to the case
    if (files?.length > 0) {
      for (const file of files) {
        const fileKey = `referrals/${caseData.id}/${file.name}`;
        const { error: uploadError } = await supabase.storage
          .from('documents')
          .upload(fileKey, file);

        if (uploadError) throw uploadError;

        const { error: docError } = await supabase
          .from('documents')
          .insert([
            {
              id: crypto.randomUUID(),
              url_id: fileKey,
              created_by: authUser.id,
              case_id: caseData.id,
              file_name: file.name,
              file_type: file.type,
              file_size: file.size,
              is_a_lien: false,
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString(),
            },
          ]);

        if (docError) throw docError;
      }
    }

    // 5. Send referral email (non-blocking, wrapped in a try/catch)
    try {
      await sendReferralEmail(formData);
    } catch (emailError) {
      console.error('Error sending email:', emailError);
    }

    // 6. Update referral metrics for the referring user
    const { data: currentUser } = await supabase
      .from('users')
      .select('outgoing_referrals')
      .eq('id', authUser.id)
      .single();

    const { error: updateError } = await supabase
      .from('users')
      .update({
        outgoing_referrals: (currentUser?.outgoing_referrals || 0) + 1,
      })
      .eq('id', authUser.id);

    if (updateError) throw updateError;

    // 7. Update referral metrics for the referred user
    if (referredUserId) {
      const { data: referredUser } = await supabase
        .from('users')
        .select('incoming_referrals')
        .eq('id', referredUserId)
        .single();

      await supabase
        .from('users')
        .update({
          incoming_referrals: (referredUser?.incoming_referrals || 0) + 1,
        })
        .eq('id', referredUserId);
    }

    return { success: true };
  } catch (error) {
    console.error('Error in handleReferralSubmit:', error);
    return {
      success: false,
      error: error.message || 'An error occurred during submission',
    };
  }
};

// ---------------------------------------------------
// Update an existing referral
// ---------------------------------------------------
export const updateReferral = async (referralId, updateData) => {
  try {
    console.log('Updating referral:', { referralId, updateData });

    if (!updateData.non_user_id || !updateData.case_id) {
      throw new Error('Missing required IDs for update');
    }

    // Update the non_user record
    const { error: nonUserError } = await supabase
      .from('non_users')
      .update({
        first_name: updateData.name.split(' ')[0],
        last_name: updateData.name.split(' ').slice(1).join(' '),
        email: updateData.email,
        phone: formatPhoneNumber(updateData.phone),
        company_name: updateData.firm,
        updated_at: new Date().toISOString(),
      })
      .eq('id', updateData.non_user_id);

    if (nonUserError) throw nonUserError;

    // Update the case record
    const { error: caseError } = await supabase
      .from('cases')
      .update({
        title: `${updateData.name} - ${updateData.selectedSubject}`,
        description: updateData.caseDetails,
        practice_area: updateData.selectedSubject,
        updated_at: new Date().toISOString(),
      })
      .eq('id', updateData.case_id);

    if (caseError) throw caseError;

    // Update the referral record
    const { data: referral, error: referralError } = await supabase
      .from('referrals')
      .update({
        compensation_terms: `${updateData.compensationPercentage}% referral fee`,
        acceptance_deadline: updateData.acceptanceDeadline,
        updated_at: new Date().toISOString(),
      })
      .eq('id', referralId)
      .select()
      .single();

    if (referralError) throw referralError;

    return { success: true, data: referral };
  } catch (error) {
    console.error('Error updating referral:', error);
    return {
      success: false,
      error: error.message || 'Failed to update referral',
    };
  }
};

// End of file
