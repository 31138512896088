import React, { useState, useEffect } from 'react';
import { Bookmark } from 'lucide-react';
import { savedReferralsService } from '../../backend/savedReferralsService';

const BookmarkButton = ({ post }) => {
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Check saved status when component mounts and when post changes
  useEffect(() => {
    let isMounted = true;

    const checkSavedStatus = async () => {
      try {
        setIsLoading(true);
        const result = await savedReferralsService.checkIfSaved(post.id);
        if (isMounted && result.success) {
          setIsSaved(result.saved);
        }
      } catch (error) {
        console.error('Error checking saved status:', error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    checkSavedStatus();

    // Cleanup function to prevent setting state on unmounted component
    return () => {
      isMounted = false;
    };
  }, [post.id]);

  const handleBookmark = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isLoading) return;

    setIsLoading(true);

    try {
      let result;
      if (isSaved) {
        result = await savedReferralsService.unsaveReferral(post.id);
      } else {
        result = await savedReferralsService.saveReferral(post);
      }

      if (result.success) {
        setIsSaved(!isSaved);
        
        // Show success message
        const message = isSaved ? 'Removed from bookmarks' : 'Added to bookmarks';
        const alert = document.createElement('div');
        alert.className = 'fixed bottom-4 right-4 p-4 bg-green-500 text-white rounded-lg shadow-lg z-50';
        alert.textContent = message;
        document.body.appendChild(alert);
        
        setTimeout(() => {
          alert.remove();
        }, 3000);
      } else {
        throw new Error(result.error);
      }
    } catch (error) {
      console.error('Error toggling bookmark:', error);
      // Show error message
      const alert = document.createElement('div');
      alert.className = 'fixed bottom-4 right-4 p-4 bg-red-500 text-white rounded-lg shadow-lg z-50';
      alert.textContent = 'Failed to update bookmark';
      document.body.appendChild(alert);
      
      setTimeout(() => {
        alert.remove();
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      onClick={handleBookmark}
      disabled={isLoading}
      className={`text-gray-400 hover:text-black transition-colors ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
      title={isSaved ? 'Remove from bookmarks' : 'Add to bookmarks'}
    >
      <Bookmark 
        className={`w-5 h-5 transition-colors ${isSaved ? 'fill-current text-black' : ''}`} 
      />
    </button>
  );
};

export default BookmarkButton;