// MessageFormater.js
import React, { useState } from 'react';
import { Copy, Bookmark, Download, ExternalLink, Calendar, MessageSquare, Loader2 } from 'lucide-react';
import { documentService } from '../../backend/documentService';

const ChatMessage = ({ message, threadId, isStreaming = false }) => {
  const [downloadStatus, setDownloadStatus] = useState({
    loading: false,
    error: null
  });

  const getFormattedTime = (timestamp) => {
    if (!timestamp) return 'Just now';
    
    const date = new Date(timestamp);
    const now = new Date();
    const diffInHours = (now - date) / (1000 * 60 * 60);
    
    if (diffInHours < 1) {
      const minutes = Math.floor((now - date) / (1000 * 60));
      return minutes <= 1 ? 'Just now' : `${minutes}m ago`;
    } else if (diffInHours < 24) {
      const hours = Math.floor(diffInHours);
      return `${hours}h ago`;
    } else if (diffInHours < 48) {
      return 'Yesterday';
    } else {
      return date.toLocaleDateString();
    }
  };

  const ThinkingAnimation = () => (
    <div className="inline-flex items-center">
      <span>Thinking</span>
      <span className="inline-flex ml-1">
        <span className="animate-bounce mx-0.5 delay-0">.</span>
        <span className="animate-bounce mx-0.5 delay-100">.</span>
        <span className="animate-bounce mx-0.5 delay-200">.</span>
      </span>
    </div>
  );
  
  // Function to handle file downloads
  const handleDownload = async (fileName, path) => {
    setDownloadStatus({ loading: true, error: null });
    try {
      await documentService.downloadFile(path, {
        threadId: threadId,
        messageId: message.id
      });
    } catch (error) {
      console.error('Download error:', error);
      setDownloadStatus({ 
        loading: false, 
        error: 'Failed to download file. Please try again.' 
      });
    } finally {
      setDownloadStatus({ loading: false, error: null });
    }
  };

  // Function to render different types of links
  const renderLink = (linkText, linkPath, match) => {
    if (linkPath.startsWith('sandbox:') || linkPath.startsWith('http') && linkPath.includes('download')) {
      return (
        <button
          key={match.index}
          onClick={() => handleDownload(linkText, linkPath)}
          disabled={downloadStatus.loading}
          className="inline-flex items-center gap-1 px-2 py-1 bg-[#10A37F] text-white rounded-md hover:bg-opacity-90 text-xs disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {downloadStatus.loading ? (
            <Loader2 size={12} className="animate-spin" />
          ) : (
            <Download size={12} />
          )}
          {linkText}
        </button>
      );
    } else if (linkPath.includes('slack')) {
      return (
        <a
          key={match.index}
          href={linkPath}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center gap-1 px-2 py-1 bg-[#481449] text-white rounded-md hover:bg-opacity-90 text-xs"
        >
          <MessageSquare size={12} />
          {linkText}
          <ExternalLink size={10} className="ml-1" />
        </a>
      );
    } else if (linkPath.includes('calendar.google.com')) {
      return (
        <a
          key={match.index}
          href={linkPath}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center gap-1 px-2 py-1 bg-[#1a73e8] text-white rounded-md hover:bg-opacity-90 text-xs"
        >
          <Calendar size={12} />
          {linkText}
          <ExternalLink size={10} className="ml-1" />
        </a>
      );
    } else {
      return (
        <a
          key={match.index}
          href={linkPath}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center gap-1 px-2 py-1 bg-[#202633] text-white rounded-md hover:bg-opacity-90 text-xs"
        >
          <ExternalLink size={12} />
          {linkText}
        </a>
      );
    }
  };

  // Function to process message content and render markdown-style links
  const processContent = (content) => {
    if (content === 'Thinking...') {
      return <ThinkingAnimation />;
    }

    const linkRegex = /\[([^\]]+)\]\(((?:sandbox:|http)[^)]+)\)/g;
    
    if (!content.match(linkRegex)) {
      return <div className="whitespace-pre-wrap">{content}</div>;
    }

    let lastIndex = 0;
    const elements = [];
    let match;

    while ((match = linkRegex.exec(content)) !== null) {
      if (match.index > lastIndex) {
        elements.push(content.slice(lastIndex, match.index));
      }

      const [, linkText, linkPath] = match;
      elements.push(renderLink(linkText, linkPath, match));
      lastIndex = match.index + match[0].length;
    }

    if (lastIndex < content.length) {
      elements.push(content.slice(lastIndex));
    }

    return (
      <div className="whitespace-pre-wrap">
        {elements}
        {downloadStatus.error && (
          <div className="mt-2 p-2 bg-red-500/10 border border-red-500/20 rounded-md">
            <p className="text-red-500 text-xs">
              {downloadStatus.error}
            </p>
          </div>
        )}
      </div>
    );
  };

  const messageClass = message.type === 'assistant' 
    ? 'bg-[#131515]' 
    : message.type === 'system' 
      ? 'bg-[#4A4A4A]' 
      : 'bg-[#252422]';

  return (
    <div className="flex gap-3">
      <img
        src={message.type === 'user' ? '/user_input_photo.png' : '/ai_logo.png'}
        alt={message.type === 'user' ? 'User' : 'Assistant'}
        className="w-6 h-6 rounded-lg flex-shrink-0"
      />
      <div className="flex-1">
        <div className="flex items-center gap-2 mb-1.5">
          <span className="text-white text-xs font-medium">
            {message.type === 'user' ? 'You' : message.type === 'system' ? 'System' : 'Kiwi AI'}
          </span>
          <span className="text-[#666666] text-[10px]">
            {isStreaming ? 'Typing...' : getFormattedTime(message.created_at)}
          </span>
        </div>
        <div className={`rounded-lg p-4 text-xs relative ${messageClass} text-white`}>
          {processContent(message.content)}
          
          {message.type === 'assistant' && message.content !== 'Thinking...' && !isStreaming && (
            <div className="flex items-center justify-end gap-3 mt-4">
              <button 
                onClick={() => navigator.clipboard.writeText(message.content)}
                className="flex items-center gap-1 bg-[#202633] text-[#EEEEEE] px-3 py-1.5 rounded-full text-[10px] hover:bg-opacity-80"
              >
                <Copy size={10} />
                Copy
              </button>
              <button className="flex items-center gap-1 bg-[#202633] text-[#EEEEEE] px-3 py-1.5 rounded-full text-[10px] hover:bg-opacity-80">
                <Bookmark size={10} />
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;