// src/components/dashboard/PublicNetwork.js
import React, { useState, useEffect } from 'react';
import { Star } from 'lucide-react';
import { publicNetworkService } from '../../backend/publicNetworkService';
import { favoritesService } from '../../backend/favoritesService';

const ReferralTable = ({ data, onToggleFavorite }) => (
  <div className="flex-grow">
    <table className="w-full">
      <thead>
        <tr className="text-left text-[#3d3731] bg-[#d8d1c7]">
          <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Firm</th>
          <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Firm Contact</th>
          <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Phone Number</th>
          <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Email</th>
          <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Status</th>
          <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Actions</th>
        </tr>
      </thead>
      <tbody className="bg-[#e9e4dc] divide-y divide-[#d8d1c7]">
        {data.map((row, index) => (
          <tr key={row.id || index}>
            <td className="px-6 py-4">{row.firm_name}</td>
            <td className="px-6 py-4">{`${row.first_name} ${row.last_name}`}</td>
            <td className="px-6 py-4">{row.phone}</td>
            <td className="px-6 py-4">{row.email}</td>
            <td className="px-6 py-4">
              <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                row.is_active
                  ? 'bg-[#c5beb2] text-black'
                  : 'bg-[#a39b8f] text-[#3d3731]'
              }`}>
                {row.is_active ? 'Active' : 'Inactive'}
              </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
              <div className="flex items-center justify-center space-x-2">
                <button 
                  onClick={() => onToggleFavorite(row)}
                  className={`transition-colors ${row.is_favorite ? 'text-yellow-500 hover:text-yellow-600' : 'text-gray-400 hover:text-yellow-500'}`}
                >
                  <Star size={20} fill={row.is_favorite ? "#FFD700" : "none"} />
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Pagination = ({ currentPage, totalItems, itemsPerPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  return (
    <div className="flex justify-between items-center px-6 py-3 bg-[#e9e4dc] border-t border-[#a39b8f]">
      <p className="text-sm text-[#3d3731]">
        Showing <span className="font-medium">{startItem}</span> to{' '}
        <span className="font-medium">{endItem}</span> of{' '}
        <span className="font-medium">{totalItems}</span> entries
      </p>
      <div className="flex space-x-1">
        {[1, 2, 3, 4, '...', totalPages].map((page, index) => (
          <button
            key={index}
            onClick={() => typeof page === 'number' && onPageChange(page)}
            disabled={page === '...'}
            className={`px-3 py-1 ${
              page === currentPage ? 'bg-black text-[#e9e4dc]' : 'bg-[#d8d1c7] text-[#3d3731] hover:bg-[#c5beb2]'
            } border border-[#a39b8f] rounded-md text-sm`}
          >
            {page}
          </button>
        ))}
      </div>
    </div>
  );
};

const PublicNetwork = () => {
  const [attorneys, setAttorneys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;

  const fetchAttorneys = async () => {
    setLoading(true);
    try {
      const result = await publicNetworkService.fetchAttorneys(currentPage, itemsPerPage);
      if (!result.success) throw new Error(result.error);
      
      // Get favorite status for all emails
      const emails = result.data.map(attorney => attorney.email);
      const favoritesResult = await favoritesService.checkFavoriteStatus(emails);
      
      if (favoritesResult.success) {
        const favoritedEmails = favoritesResult.data;
        const dataWithFavorites = result.data.map(attorney => ({
          ...attorney,
          is_favorite: favoritedEmails.has(attorney.email)
        }));
        
        setAttorneys(dataWithFavorites);
        setTotalItems(result.totalCount);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleFavorite = async (attorney) => {
    try {
      const result = await favoritesService.toggleFavorite(attorney);
      if (result.success) {
        setAttorneys(prev => prev.map(a => 
          a.email === attorney.email ? { ...a, is_favorite: !a.is_favorite } : a
        ));
      }
    } catch (err) {
      console.error('Error toggling favorite:', err);
    }
  };

  useEffect(() => {
    fetchAttorneys();
  }, [currentPage]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-black"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-700 bg-red-100 border border-red-200 rounded-md m-4">
        Error loading attorneys: {error}
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      <ReferralTable 
        data={attorneys} 
        onToggleFavorite={handleToggleFavorite}
      />
      <Pagination 
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default PublicNetwork;