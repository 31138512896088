// CompanyPage.js
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Users, Target, Briefcase, Book, ArrowRight, ChevronRight, Globe, Phone, Mail, MapPin } from 'lucide-react';
import Footer from './components/landing/Footer';
import FloatingCalendlyButton from './components/landing/FloatingButton';

// StatCard Component
const StatCard = ({ icon: Icon, value, label, isBlackAndWhite }) => {
  const cardStyle = isBlackAndWhite
    ? 'bg-gray-50 border-gray-200'
    : 'bg-[#1a1410] border-[#2a2118]';

  return (
    <div className={`border ${cardStyle} rounded-xl p-6 text-center hover:scale-105 transition-all duration-300`}>
      <Icon className={`w-8 h-8 mx-auto mb-4 ${
        isBlackAndWhite ? "text-gray-700" : "text-[#FFC499]"
      }`} />
      <h3 className={`text-3xl font-bold mb-2 ${
        isBlackAndWhite ? "text-gray-900" : "text-white"
      }`}>
        {value}
      </h3>
      <p className={isBlackAndWhite ? "text-gray-600" : "text-gray-400"}>
        {label}
      </p>
    </div>
  );
};

// ValueCard Component
const ValueCard = ({ icon: Icon, title, description, isBlackAndWhite }) => {
  const cardStyle = isBlackAndWhite
    ? 'bg-gray-50 border-gray-200'
    : 'bg-[#1a1410] border-[#2a2118]';

  return (
    <div className={`border ${cardStyle} rounded-xl p-8 hover:scale-105 transition-all duration-300`}>
      <Icon className={`w-10 h-10 mb-6 ${
        isBlackAndWhite ? "text-gray-700" : "text-[#FFC499]"
      }`} />
      <h3 className={`text-xl font-bold mb-4 ${
        isBlackAndWhite ? "text-gray-900" : "text-white"
      }`}>
        {title}
      </h3>
      <p className={isBlackAndWhite ? "text-gray-600" : "text-gray-400"}>
        {description}
      </p>
    </div>
  );
};

// TeamMemberCard Component
const TeamMemberCard = ({ name, role, bio, image, isBlackAndWhite }) => {
  const cardStyle = isBlackAndWhite
    ? 'bg-gray-50 border-gray-200'
    : 'bg-[#1a1410] border-[#2a2118]';

  return (
    <div className={`border ${cardStyle} rounded-xl p-8 flex gap-6 hover:scale-105 transition-all duration-300`}>
      <div className="w-24 h-24 rounded-full overflow-hidden flex-shrink-0">
        <img 
          src={image || "/api/placeholder/96/96"}
          alt={name} 
          className="w-full h-full object-cover"
        />
      </div>
      <div>
        <h3 className={`text-xl font-bold mb-2 ${
          isBlackAndWhite ? "text-gray-900" : "text-white"
        }`}>
          {name}
        </h3>
        <p className={`text-sm mb-3 ${
          isBlackAndWhite ? "text-gray-700" : "text-[#FFC499]"
        }`}>
          {role}
        </p>
        <p className={isBlackAndWhite ? "text-gray-600" : "text-gray-400"}>
          {bio}
        </p>
      </div>
    </div>
  );
};

const CompanyPage = () => {
  const navigate = useNavigate();
  const [isBlackAndWhite, setIsBlackAndWhite] = useState(false);
  const canvasRef = useRef(null);
  const darkBrownGradientRef = useRef(null);
  const lightBrownGradientRef = useRef(null);

  // Canvas effect implementation...
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    let mouse = { x: 0, y: 0 };

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      canvas.style.width = '100%';
      canvas.style.height = '100%';
    };

    const createGrid = () => {
      const gridSize = 30;
      const rows = Math.ceil(canvas.height / gridSize);
      const cols = Math.ceil(canvas.width / gridSize);
      const grid = [];

      for (let y = 0; y <= rows; y++) {
        for (let x = 0; x <= cols; x++) {
          grid.push({
            x: x * gridSize,
            y: y * gridSize,
            baseX: x * gridSize,
            baseY: y * gridSize,
          });
        }
      }

      return { grid, rows, cols };
    };

    let { grid, rows, cols } = createGrid();

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.beginPath();
      ctx.strokeStyle = isBlackAndWhite ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.1)';
      ctx.lineWidth = 0.5;

      for (let i = 0; i < grid.length; i++) {
        const point = grid[i];
        const distX = mouse.x - point.baseX;
        const distY = mouse.y - point.baseY;
        const distance = Math.sqrt(distX * distX + distY * distY);
        const maxDistance = 150;

        if (distance < maxDistance) {
          const force = (1 - distance / maxDistance) * 0.2;
          point.x = point.baseX - distX * force;
          point.y = point.baseY - distY * force;
        } else {
          point.x += (point.baseX - point.x) * 0.1;
          point.y += (point.baseY - point.y) * 0.1;
        }

        if (i < grid.length - 1 && (i + 1) % (cols + 1) !== 0) {
          ctx.moveTo(point.x, point.y);
          ctx.lineTo(grid[i + 1].x, grid[i + 1].y);
        }

        if (i + cols + 1 < grid.length) {
          ctx.moveTo(point.x, point.y);
          ctx.lineTo(grid[i + cols + 1].x, grid[i + cols + 1].y);
        }
      }

      ctx.stroke();
      animationFrameId = requestAnimationFrame(animate);
    };

    const handleMouseMove = (event) => {
      mouse.x = event.clientX;
      mouse.y = event.clientY;
    };

    const handleResize = () => {
      resizeCanvas();
      ({ grid, rows, cols } = createGrid());
    };

    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (darkBrownGradientRef.current) {
        darkBrownGradientRef.current.style.transform = `rotate(${scrollY * 0.1}deg)`;
      }
      if (lightBrownGradientRef.current) {
        lightBrownGradientRef.current.style.transform = `rotate(${-scrollY * 0.1}deg)`;
      }
    };

    resizeCanvas();
    window.addEventListener('resize', handleResize);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('scroll', handleScroll);
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('scroll', handleScroll);
      cancelAnimationFrame(animationFrameId);
    };
  }, [isBlackAndWhite]);

  const toggleBlackAndWhite = () => {
    setIsBlackAndWhite(!isBlackAndWhite);
  };

  const stats = [
    { label: "Active Users", value: "40+", icon: Users },
    { label: "Law Firms", value: "20+", icon: Briefcase },
    { label: "Cities", value: "5+", icon: Globe },
    { label: "Success Rate", value: "95%", icon: Target }
  ];

  const values = [
    {
      title: "Innovation",
      description: "We're constantly pushing boundaries to create better solutions for legal professionals.",
      icon: Book
    },
    {
      title: "Trust",
      description: "Building lasting relationships through transparency and reliability.",
      icon: Users
    },
    {
      title: "Excellence",
      description: "Committed to delivering the highest quality service to our clients.",
      icon: Target
    }
  ];

  const team = [
    {
      name: "Sanay Shajith",
      role: "Co-founder",
      bio: "Sanay is passionate about community service, hiking, and golfing, bringing a well-rounded perspective and dedication to making a positive impact.",
      image: "/user_input_photo.png"
    },
    {
      name: "Meeth Naik",
      role: "Co-founder",
      bio: "Meeth, with his love for coding, running, and reading, adds a sharp technical edge to the team.",
      image: "/user_input_photo.png"
    }
  ];

  const baseStyles = "transition-all duration-300";
  const colorStyles = isBlackAndWhite 
    ? "bg-white bg-opacity-90 text-black" 
    : "bg-black text-white";

  return (
    <div className={`min-h-screen flex flex-col relative overflow-hidden font-sans ${baseStyles} ${colorStyles}`}>
      {/* Rest of the JSX implementation remains the same... */}
      <canvas 
        ref={canvasRef} 
        className="fixed inset-0 pointer-events-none" 
        style={{ zIndex: 1 }}
      />

      {!isBlackAndWhite && (
        <>
          <div
            ref={darkBrownGradientRef}
            className="fixed -top-1/4 -right-1/4 w-2/3 h-2/3 bg-[#964B00] rounded-full filter blur-[100px] opacity-30 origin-center"
            style={{ zIndex: 2 }}
          />
          <div
            ref={lightBrownGradientRef}
            className="fixed -bottom-1/3 -left-1/4 w-3/4 h-3/4 bg-[#FFC499] rounded-full filter blur-[100px] opacity-30 origin-center"
            style={{ zIndex: 2 }}
          />
        </>
      )}

      <div className="relative z-10 flex flex-col min-h-screen">
        {/* Header */}
        <header className="p-6 flex justify-between items-center">
          <div className="flex items-center">
            <span 
              className="text-xl font-bold cursor-pointer" 
              onClick={toggleBlackAndWhite}
            >
              Lawdie
            </span>
          </div>

          <nav>
            <ul className="flex space-x-6 items-center">
              <li>
                <Link to="/" className="hover:underline">Home</Link>
              </li>
              <li>
                <Link to="/company" className="hover:underline">Company</Link>
              </li>
              <li>
                <Link to="/coming-soon" className="hover:underline">
                  Lawyer Search{" "}
                  <span className={isBlackAndWhite 
                    ? "bg-gradient-to-r from-gray-600 to-gray-400 text-transparent bg-clip-text" 
                    : "bg-gradient-to-r from-[#FFC499] to-[#FFC499] text-transparent bg-clip-text"
                  }>
                    (BETA)
                  </span>
                </Link>
              </li>
              <li>
                <Link 
                  to="/signup"
                  className={`border px-4 py-1 rounded-full hover:bg-opacity-80 transition duration-300 ${
                    isBlackAndWhite 
                      ? "border-black hover:bg-gray-200" 
                      : "border-white hover:bg-white hover:text-black"
                  }`}
                >
                  Login
                </Link>
              </li>
            </ul>
          </nav>
        </header>

        {/* Main Content */}
        <main className="flex-grow">
          {/* Hero Section */}
          <section className="container mx-auto px-6 py-24">
            <div className="max-w-4xl mx-auto text-center mb-24">
            <h1 className={`text-7xl font-bold mb-8 leading-tight ${
              isBlackAndWhite 
                ? "text-grey-900" 
                : "text-white"
            }`}>
              Revolutionizing
              <br />
              Legal Tech
            </h1>
              <p className={`text-xl mb-12 ${
                isBlackAndWhite ? "text-gray-600" : "text-gray-400"
              }`}>
                Building the future of legal services through innovation, 
                trust, and excellence.
              </p>
              <div className="flex justify-center gap-6">
                <Link to="/signup">
                  <button className={`px-8 py-3 rounded-full flex items-center gap-2 transition ${
                    isBlackAndWhite 
                      ? "bg-black text-white hover:bg-gray-800" 
                      : "bg-[#2a2118] text-[#d8d1c7] hover:bg-[#3d3326]"
                  }`}>
                    Get Started <ArrowRight size={20} />
                  </button>
                </Link>
                <a href="https://calendly.com/sanayshajith/lawdie-intro-call" target="_blank">
                  <button className={`px-8 py-3 rounded-full flex items-center gap-2 transition ${
                    isBlackAndWhite 
                      ? "border border-black hover:bg-gray-100" 
                      : "border border-white hover:bg-white hover:text-black"
                  }`}>
                    Contact Us <Phone size={20} />
                  </button>
                </a>
              </div>
            </div>

            {/* Stats Section */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-24">
              {stats.map((stat, index) => (
                <StatCard 
                key={index}
                {...stat}
                isBlackAndWhite={isBlackAndWhite}
              />
            ))}
          </div>

          {/* About Section */}
          <div className="grid md:grid-cols-2 gap-16 items-center mb-24">
            <div>
              <h2 className={`text-4xl font-bold mb-6 ${
                isBlackAndWhite ? "text-gray-900" : "text-white"
              }`}>
                About Lawdie
              </h2>
              <p className={`text-lg mb-6 ${
                isBlackAndWhite ? "text-gray-600" : "text-gray-400"
              }`}>
                At Lawdie, we're reimagining how legal services are discovered, 
                delivered, and managed. Our platform connects clients with the right 
                legal professionals while providing attorneys with powerful tools 
                to grow their practice.
              </p>
              <p className={`text-lg mb-6 ${
                isBlackAndWhite ? "text-gray-600" : "text-gray-400"
              }`}>
                We are dedicated to solving the challenges both clients and attorneys face 
                in today's legal landscape. Our platform is designed as a one-stop shop, 
                helping clients find legal representation that matches their specific needs, 
                budget, and case details with ease.
              </p>
              <p className={`text-lg mb-8 ${
                isBlackAndWhite ? "text-gray-600" : "text-gray-400"
              }`}>
                For attorneys, our platform simplifies the referral process, increases their 
                online presence, and provides seamless case management tools, allowing them 
                to focus on what matters most—providing quality legal services.
              </p>
              <div className="space-y-4">
                {["Personalized lawyer matching", "Secure data handling", "Efficient case management", "Smart referral system"].map((feature, index) => (
                  <div key={index} className="flex items-center gap-3">
                    <ChevronRight className={
                      isBlackAndWhite ? "text-gray-700" : "text-[#FFC499]"
                    } />
                    <span className={
                      isBlackAndWhite ? "text-gray-700" : "text-gray-300"
                    }>
                      {feature}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="relative">
              <div className={`w-200 h-200 rounded-2xl overflow-hidden ${
                isBlackAndWhite ? "bg-gray-50 border-gray-200" : "bg-[#1a1410] border-[#2a2118]"
              } border`}>
                <img 
                  src="/professional_convo.jpg" 
                  alt="About Lawdie" 
                  className="object-cover w-full h-full"
                />
              </div>
              <div className={`absolute -bottom-6 -left-6 p-6 rounded-xl ${
                isBlackAndWhite ? "bg-gray-50 border-gray-200" : "bg-[#1a1410] border-[#2a2118]"
              } border`}>
                <p className={`text-4xl font-bold ${
                  isBlackAndWhite ? "text-gray-900" : "text-[#FFC499]"
                }`}>
                  2024
                </p>
                <p className={
                  isBlackAndWhite ? "text-gray-600" : "text-gray-400"
                }>
                  Founded
                </p>
              </div>
            </div>
          </div>

          {/* Values Section */}
          <div className="mb-24">
            <h2 className={`text-4xl font-bold text-center mb-16 ${
              isBlackAndWhite ? "text-gray-900" : "text-white"
            }`}>
              Our Values
            </h2>
            <div className="grid md:grid-cols-3 gap-8">
              {values.map((value, index) => (
                <ValueCard 
                  key={index}
                  {...value}
                  isBlackAndWhite={isBlackAndWhite}
                />
              ))}
            </div>
          </div>

          {/* Team Section */}
          <div className="mb-24">
            <h2 className={`text-4xl font-bold text-center mb-16 ${
              isBlackAndWhite ? "text-gray-900" : "text-white"
            }`}>
              Meet Our Team
            </h2>
            <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
              {team.map((member, index) => (
                <TeamMemberCard 
                  key={index}
                  {...member}
                  isBlackAndWhite={isBlackAndWhite}
                />
              ))}
            </div>
          </div>

          {/* Contact Section */}
          <div className="max-w-4xl mx-auto text-center">
            <h2 className={`text-4xl font-bold mb-16 ${
              isBlackAndWhite ? "text-gray-900" : "text-white"
            }`}>
              Get in Touch
            </h2>
            <div className="grid md:grid-cols-3 gap-8 mb-12">
              {[
                { icon: Mail, text: "hello@lawdie.com" },
                { icon: Phone, text: "(404) 555-0123" },
                { icon: MapPin, text: "Atlanta, GA" }
              ].map((contact, index) => (
                <div key={index} className="flex flex-col items-center">
                  <contact.icon className={`w-6 h-6 mb-4 ${
                    isBlackAndWhite ? "text-gray-700" : "text-[#FFC499]"
                  }`} />
                  <p className={
                    isBlackAndWhite ? "text-gray-600" : "text-gray-400"
                  }>
                    {contact.text}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>

      {/* Footer and Floating Button */}
      <Footer isBlackAndWhite={isBlackAndWhite} />
      <FloatingCalendlyButton isBlackAndWhite={isBlackAndWhite} />
    </div>
  </div>
);
};

export default CompanyPage;