// components/lien/StatusBadge.js
import React from 'react';
import { CheckCircle, Clock, XCircle } from 'lucide-react';

const StatusBadge = ({ status = 'pending' }) => {  // Add default value
  const getStatusStyles = () => {
    const statusLower = (status || '').toLowerCase();
    switch (statusLower) {
      case 'active':
        return 'bg-[#c5beb2] text-black';
      case 'pending':
        return 'bg-[#d8d1c7] text-[#3d3731]';
      case 'expired':
        return 'bg-[#a39b8f] text-[#3d3731]';
      default:
        return 'bg-[#a39b8f] text-[#3d3731]';
    }
  };

  const getStatusIcon = () => {
    const statusLower = (status || '').toLowerCase();
    switch (statusLower) {
      case 'active':
        return <CheckCircle className="w-4 h-4 mr-1" />;
      case 'pending':
        return <Clock className="w-4 h-4 mr-1" />;
      case 'expired':
        return <XCircle className="w-4 h-4 mr-1" />;
      default:
        return <Clock className="w-4 h-4 mr-1" />;
    }
  };

  return (
    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusStyles()}`}>
      {getStatusIcon()}
      {status || 'Pending'}
    </span>
  );
};

export default StatusBadge;