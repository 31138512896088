// src/backend/referralServices/referralSubmissionService.js
import { supabase } from '../supabase';
import { documentReferralService } from './DocumentReferralService';
import { s3Service } from '../uploadService';
import { emailApiService } from '../emailApiService';

// Add formatting function
const formatPhoneForDB = (phone) => {
  // First strip all formatting (spaces, parentheses, dashes)
  const digitsOnly = phone.replace(/\D/g, '');
  
  // For US numbers (XXX) XXX - XXXX -> +1XXXXXXXXXX
  if (digitsOnly.length === 10) {
    return `+1${digitsOnly}`;
  }
  
  // For international numbers or numbers already with country code
  if (digitsOnly.length >= 8 && digitsOnly.length <= 15) {
    // If it doesn't start with +, add it
    return digitsOnly.startsWith('+') ? digitsOnly : `+${digitsOnly}`;
  }
  
  throw new Error('Invalid phone number format');
};

export const referralSubmissionService = {
  async handleReferralSubmit(formData, files) {
    let caseId = null;
    let nonUserId = null;
    let referralId = null;
    let uploadedFiles = [];
    let existingAttorney = null;

    try {
      console.log('Starting new referral submission with data:', {
        ...formData,
        filesCount: files?.length || 0
      });

      // Get authenticated user
      const { data: { user: authUser }, error: authError } = await supabase.auth.getUser();
      if (authError) throw authError;
      if (!authUser) throw new Error('No authenticated user');

      // Format phone number before proceeding
      const formattedPhone = formatPhoneForDB(formData.phone);

      // 1. Create case
      const { data: caseData, error: caseError } = await supabase
        .from('cases')
        .insert([{
          id: crypto.randomUUID(),
          title: `${formData.name} - ${formData.selectedSubject}`,
          description: formData.caseDetails,
          practice_area: formData.selectedSubject,
          status: 'pending',
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        }])
        .select()
        .single();

      if (caseError) throw caseError;
      caseId = caseData.id;

      // 2. Check if attorney already exists
      const { data: existingUser, error: searchError } = await supabase
        .from('users')
        .select(`
          id,
          email,
          phone,
          lawyer_profiles!inner (
            id,
            firm_name,
            practice_areas
          )
        `)
        .eq('email', formData.email.toLowerCase())
        .maybeSingle();

      if (searchError && searchError.code !== 'PGRST116') throw searchError;

      if (existingUser) {
        existingAttorney = { type: 'user', data: existingUser };
      } else {
        // Check non_users table
        const { data: existingNonUser, error: nonUserSearchError } = await supabase
          .from('non_users')
          .select('*')
          .eq('email', formData.email.toLowerCase())
          .maybeSingle();

        if (nonUserSearchError && nonUserSearchError.code !== 'PGRST116') throw nonUserSearchError;

        if (existingNonUser) {
          existingAttorney = { type: 'non_user', data: existingNonUser };
        }
      }

      // 3. Create or use existing non_user record
      let referredUserId = null;
      let referredNonUserId = null;

      if (existingAttorney) {
        if (existingAttorney.type === 'user') {
          referredUserId = existingAttorney.data.id;
        } else {
          referredNonUserId = existingAttorney.data.id;
        }
      } else {
        // Create new non_user record
        const { data: newNonUser, error: createNonUserError } = await supabase
          .from('non_users')
          .insert([{
            id: crypto.randomUUID(),
            first_name: formData.name.split(' ')[0],
            last_name: formData.name.split(' ').slice(1).join(' '),
            email: formData.email.toLowerCase(),
            phone: formattedPhone, // Use formatted phone number
            company_name: formData.firm,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
          }])
          .select()
          .single();

        if (createNonUserError) {
          // Clean up case if non_user creation fails
          if (caseId) {
            await supabase.from('cases').delete().eq('id', caseId);
          }
          throw createNonUserError;
        }
        referredNonUserId = newNonUser.id;
        nonUserId = newNonUser.id;
      }

      // 4. Create referral record
      const { data: referral, error: referralError } = await supabase
        .from('referrals')
        .insert([{
          id: crypto.randomUUID(),
          case_id: caseId,
          referring_user_id: authUser.id,
          referred_user_id: referredUserId,
          referred_non_user_id: referredNonUserId,
          status: 'pending',
          email_sent: false,
          reminder_count: 0,
          referral_type: 'standard',
          acceptance_deadline: new Date(formData.acceptanceDeadline).toISOString(),
          compensation_terms: `${formData.compensationPercentage}% referral fee`,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        }])
        .select()
        .single();

      if (referralError) {
        // Clean up case and non_user if referral creation fails
        if (caseId) {
          await supabase.from('cases').delete().eq('id', caseId);
        }
        if (nonUserId) {
          await supabase.from('non_users').delete().eq('id', nonUserId);
        }
        throw referralError;
      }
      referralId = referral.id;

      // 5. Handle file uploads using S3
      if (files?.length > 0) {
        console.log('Processing file uploads:', files.length, 'files');
        
        for (const file of files) {
          try {
            console.log('Uploading file:', file.name);
            
            // Upload to S3
            const s3Result = await s3Service.uploadFile(file, authUser.email);
            if (!s3Result.key) throw new Error(`Failed to upload file ${file.name}`);

            console.log('File uploaded to S3:', s3Result.key);

            // Create document record
            const { success, error: docError, data: docData } = await documentReferralService
              .createDocumentRecord(file, referralId, s3Result.key);

            if (!success) throw docError;

            console.log('Document record created:', docData.id);
            
            uploadedFiles.push({
              key: s3Result.key,
              docId: docData.id
            });
          } catch (uploadError) {
            console.error('Error uploading file:', file.name, uploadError);
            throw new Error(`Failed to upload file ${file.name}: ${uploadError.message}`);
          }
        }
      }

      // 6. Send email notification
      try {
        console.log('Sending referral email notification');
        await emailApiService.sendReferralEmail({
          ...formData,
          referralId: referral.id
        });
        console.log('Email notification sent successfully');

        // Update email_sent flag
        await supabase
          .from('referrals')
          .update({ email_sent: true })
          .eq('id', referralId);
      } catch (emailError) {
        console.error('Error sending email notification:', emailError);
        // Don't fail the whole submission if email fails
      }

      // 7. Update metrics for referring user
      const { data: currentUser } = await supabase
        .from('users')
        .select('outgoing_referrals')
        .eq('id', authUser.id)
        .single();

      await supabase
        .from('users')
        .update({
          outgoing_referrals: (currentUser?.outgoing_referrals || 0) + 1,
          updated_at: new Date().toISOString()
        })
        .eq('id', authUser.id);

      // 8. Update metrics for referred user if applicable
      if (referredUserId) {
        const { data: referredUser } = await supabase
          .from('users')
          .select('incoming_referrals')
          .eq('id', referredUserId)
          .single();

        await supabase
          .from('users')
          .update({
            incoming_referrals: (referredUser?.incoming_referrals || 0) + 1,
            updated_at: new Date().toISOString()
          })
          .eq('id', referredUserId);
      }

      console.log('Referral submission completed successfully:', {
        referralId,
        caseId,
        uploadedFiles: uploadedFiles.length
      });

      return { 
        success: true, 
        data: {
          ...referral,
          documents: uploadedFiles
        }
      };

    } catch (error) {
      console.error('Error in handleReferralSubmit:', error);

      // Comprehensive cleanup on error
      try {
        console.log('Starting cleanup process');

        // Clean up uploaded files from S3
        for (const file of uploadedFiles) {
          try {
            await s3Service.deleteFile(file.key);
            if (file.docId) {
              await documentReferralService.deleteReferralDocument(file.docId);
            }
          } catch (cleanupError) {
            console.error('Error cleaning up file:', file, cleanupError);
          }
        }

        // Clean up database records
        if (referralId) {
          await supabase.from('referrals').delete().eq('id', referralId);
        }
        if (caseId) {
          await supabase.from('cases').delete().eq('id', caseId);
        }
        if (nonUserId) {
          await supabase.from('non_users').delete().eq('id', nonUserId);
        }

        console.log('Cleanup completed');
      } catch (cleanupError) {
        console.error('Error during cleanup:', cleanupError);
      }

      return {
        success: false,
        error: error.message || 'An error occurred during submission'
      };
    }
  }
};