// src/backend/referralServices/DocumentReferralService.js
import { supabase } from '../supabase';

class DocumentReferralService {
  constructor() {
    this.folder = 'referral-documents';
  }

  async getReferralDocuments(referralId) {
    try {
      // First get the case_id from the referral
      const { data: referral, error: refError } = await supabase
        .from('referrals')
        .select('case_id')
        .eq('id', referralId)
        .single();

      if (refError) throw refError;

      // Then get documents for that case
      const { data, error } = await supabase
        .from('documents')
        .select('*')
        .eq('case_id', referral.case_id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      return { success: true, data };
    } catch (error) {
      console.error('Error fetching referral documents:', error);
      return { success: false, error: error.message };
    }
  }

  async createDocumentRecord(file, referralId, s3Key) {
    try {
      // First get the case_id from the referral
      const { data: referral, error: refError } = await supabase
        .from('referrals')
        .select('case_id')
        .eq('id', referralId)
        .single();

      if (refError) throw refError;

      // Then create the document record with the case_id
      const { data: documentRecord, error: docError } = await supabase
        .from('documents')
        .insert([{
          case_id: referral.case_id, // Use case_id from referral
          file_name: file.name,
          file_type: file.type,
          file_size: file.size,
          url_id: s3Key,
          created_by: (await supabase.auth.getUser()).data.user?.id,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }])
        .select()
        .single();

      if (docError) throw docError;

      return { success: true, data: documentRecord };
    } catch (error) {
      console.error('Error creating document record:', error);
      return { success: false, error: error.message };
    }
  }

  async deleteReferralDocument(documentId) {
    try {
      const { error: deleteError } = await supabase
        .from('documents')
        .delete()
        .eq('id', documentId);

      if (deleteError) throw deleteError;

      return { success: true };
    } catch (error) {
      console.error('Error deleting document:', error);
      return { success: false, error: error.message };
    }
  }

  async getDocumentData(documentId) {
    try {
      const { data: document, error: fetchError } = await supabase
        .from('documents')
        .select('url_id, file_name')
        .eq('id', documentId)
        .single();

      if (fetchError) throw fetchError;

      return { success: true, data: document };
    } catch (error) {
      console.error('Error getting document data:', error);
      return { success: false, error: error.message };
    }
  }
}

export const documentReferralService = new DocumentReferralService();