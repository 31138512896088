// src/components/documentUI/document-git/documentVersioning.js

// Helper function to create hash using Web Crypto API
async function createHash(content) {
    const msgUint8 = new TextEncoder().encode(
      typeof content === 'string' ? content : JSON.stringify(content)
    );
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  }
  
  export class DocumentVersionControl {
    constructor() {
      this.objects = new Map();
      this.refs = new Map();
      this.staging = new Map();
    }
  
    // Store a blob of content
    async storeBlob(content) {
      const hash = await createHash(content);
      this.objects.set(hash, {
        type: 'blob',
        content
      });
      return hash;
    }
  
    // Create a commit object
    async createCommit(documentId, content, parentHash = null, message = '') {
      const blobHash = await this.storeBlob(content);
      const timestamp = new Date().toISOString();
      const commit = {
        type: 'commit',
        blob: blobHash,
        parent: parentHash,
        message,
        timestamp
      };
      
      const commitHash = await createHash(commit);
      this.objects.set(commitHash, commit);
      this.refs.set(documentId, commitHash);
      
      return commitHash;
    }
  
    // Get document history
    async getHistory(documentId) {
      const history = [];
      let currentHash = this.refs.get(documentId);
      
      while (currentHash) {
        const commit = this.objects.get(currentHash);
        if (!commit) break;
        
        const blob = this.objects.get(commit.blob);
        history.push({
          hash: currentHash,
          content: blob.content,
          message: commit.message,
          timestamp: commit.timestamp
        });
        
        currentHash = commit.parent;
      }
      
      return history;
    }
  
    // Stage changes
    async stageChanges(documentId, content) {
      this.staging.set(documentId, content);
    }
  
    // Commit staged changes
    async commitChanges(documentId, message) {
      const content = this.staging.get(documentId);
      if (!content) {
        throw new Error('No changes staged for commit');
      }
      
      const parentHash = this.refs.get(documentId);
      const commitHash = await this.createCommit(documentId, content, parentHash, message);
      this.staging.delete(documentId);
      
      return commitHash;
    }
  
    // Compare versions
    async compareVersions(hashA, hashB) {
      const commitA = this.objects.get(hashA);
      const commitB = this.objects.get(hashB);
      
      if (!commitA || !commitB) {
        throw new Error('Version not found');
      }
      
      const contentA = this.objects.get(commitA.blob).content;
      const contentB = this.objects.get(commitB.blob).content;
      
      return this.findDifferences(contentA, contentB);
    }
  
    findDifferences(contentA, contentB) {
      const differences = [];
      const allKeys = new Set([
        ...Object.keys(contentA),
        ...Object.keys(contentB)
      ]);
      
      for (const key of allKeys) {
        if (contentA[key] !== contentB[key]) {
          differences.push({
            field: key,
            oldValue: contentA[key],
            newValue: contentB[key]
          });
        }
      }
      
      return differences;
    }
  }