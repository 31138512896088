// src/components/KiwiAI/ChatList.jsx
import React from 'react';
import { Search, Plus, MoreHorizontal, MessageCircle, Bookmark, Filter, Loader2 } from 'lucide-react';

const ChatList = ({ 
  chats, 
  selectedChatId, 
  onChatSelect, 
  onCreateChat,
  isInitialLoad 
}) => {
  const getFormattedTime = (timestamp) => {
    if (!timestamp) return 'Just now';
    const date = new Date(timestamp);
    const now = new Date();
    const diffInHours = (now - date) / (1000 * 60 * 60);
    
    if (diffInHours < 1) {
      const minutes = Math.floor((now - date) / (1000 * 60));
      return minutes <= 1 ? 'Just now' : `${minutes}m ago`;
    } else if (diffInHours < 24) {
      const hours = Math.floor(diffInHours);
      return `${hours}h ago`;
    } else if (diffInHours < 48) {
      return 'Yesterday';
    } else {
      return date.toLocaleDateString();
    }
  };

  return (
    <div className="w-[280px] bg-[#34261E] flex flex-col">
      <div className="p-3 pb-0">
        {/* Header */}
        <div className="flex items-center justify-between mb-3">
          <h1 className="text-[#e1e1e1] text-base font-semibold">My Chats</h1>
          <div className="flex gap-2">
            <button 
              onClick={onCreateChat}
              className="w-8 h-8 rounded-lg flex items-center justify-center text-[#666666] hover:text-[#999999] bg-[#1F2937]"
            >
              <Plus size={16} />
            </button>
            <button className="w-8 h-8 rounded-lg flex items-center justify-center text-[#666666] hover:text-[#999999] bg-[#1E1F22]">
              <MoreHorizontal size={16} />
            </button>
          </div>
        </div>

        {/* Tabs */}
        <div className="bg-[#3A302E] rounded-lg p-1.5 mb-3 grid grid-cols-2 gap-1">
          <button className="flex items-center justify-center gap-1.5 py-2 px-3 rounded-md text-xs font-medium bg-[#1E1F22] text-[#23C69E] w-full">
            <MessageCircle size={13} className="text-[#23C69E] fill-[#23C69E] flex-shrink-0" />
            <span className="text-[10px]">CHATS</span>
            <span className="bg-[#10A37F26] text-[9px] px-1.5 py-0.5 rounded-md flex-shrink-0">24</span>
          </button>
          <button className="flex items-center justify-center gap-1.5 py-2 px-3 rounded-md text-xs font-medium text-[#666666] hover:text-[#999999] w-full">
            <Bookmark size={13} className="text-[#EEEEEE] fill-[#EEEEEE] flex-shrink-0" />
            <span className="text-[10px]">SAVED</span>
            <span className="bg-[#D6D6D626] text-[9px] px-1.5 py-0.5 rounded-md flex-shrink-0">0</span>
          </button>
        </div>

        {/* Search */}
        <div className="flex gap-2 mb-3">
          <div className="relative flex-1">
            <div className="absolute left-3 top-1/2 -translate-y-1/2">
              <Search size={12} className="text-[#666666]" />
            </div>
            <input
              type="text"
              placeholder="Search chats..."
              className="w-full bg-[#3A302E] rounded-md pl-8 pr-3 py-1.5 text-xs text-[#e1e1e1] placeholder-[#666666] focus:outline-none"
            />
          </div>
          <button className="bg-[#1E1F22] p-1.5 rounded-md">
            <Filter size={12} className="text-[#666666]" />
          </button>
        </div>
      </div>

      {/* Chat List */}
      <div className="flex-1 overflow-y-auto">
        {isInitialLoad ? (
          <div className="flex items-center justify-center h-full">
            <Loader2 size={24} className="animate-spin text-[#666666]" />
          </div>
        ) : chats.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-full text-center px-4">
            <MessageCircle size={24} className="text-[#666666] mb-2" />
            <p className="text-[#666666] text-sm mb-2">No chats yet</p>
            <p className="text-[#666666] text-xs">Click the + button to start a new chat</p>
          </div>
        ) : (
          chats.map((chat) => (
            <div 
              key={chat.id} 
              onClick={() => onChatSelect(chat.id)}
              className={`px-3 py-2 cursor-pointer mx-2 ${
                selectedChatId === chat.id 
                  ? 'bg-[#1E1F22] rounded-lg' 
                  : 'hover:bg-[#3A302E] hover:rounded-lg'
              }`}
            >
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-2 flex-1">
                  <img 
                    src="/assistant_photo.png" 
                    alt="AI" 
                    className="w-5 h-5 rounded-full"
                  />
                  <span className="text-xs font-medium text-[#e1e1e1]">
                    {chat.title}
                  </span>
                </div>
                <span className="text-[10px] text-[#666666]">
                  {getFormattedTime(chat.updated_at)}
                </span>
              </div>
              <p className="text-[10px] text-[#666666] line-clamp-2 mt-1 ml-6">
                {chat.last_message}
              </p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ChatList;