import React, { useState, useEffect } from 'react';
import { Filter, Search, Plus } from 'lucide-react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { getLiens } from '../../backend/lienService';
import LienHeader from './LienHeader';
import LiensTabs from './LiensTabs';
import LienCard from './LienCard';
import LienPagination from './LienPagination';
import LienDetailPage from './LienDetailPage';
import { deleteLien } from '../../backend/lienService';

const LienManagement = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  
  // State Management
  const [selectedFilter, setSelectedFilter] = useState('All Liens');
  const [searchQuery, setSearchQuery] = useState('');
  const [liens, setLiens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const liensPerPage = 9; // 3x3 grid

  useEffect(() => {
    fetchLiens();
  }, []);

  // Reset page when filtering or searching
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery, selectedFilter]);

  const refreshLiens = async () => {
    try {
      const data = await getLiens();
      setLiens(data || []);
    } catch (error) {
      console.error('Error refreshing liens:', error);
    }
  };

  const fetchLiens = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await getLiens();
      setLiens(data || []);
    } catch (error) {
      console.error('Error fetching liens:', error);
      setError('Failed to load liens');
    } finally {
      setLoading(false);
    }
  };

  const filterLiens = (liens) => {
    return liens.filter(lien => {
      // Search filter
      if (searchQuery) {
        const searchLower = searchQuery.toLowerCase();
        return (
          lien.lienholder_name?.toLowerCase().includes(searchLower) ||
          lien.debtor_name?.toLowerCase().includes(searchLower) ||
          lien.property_address?.toLowerCase().includes(searchLower)
        );
      }

      // Status filter
      if (selectedFilter !== 'All Liens') {
        return lien.status === selectedFilter.toLowerCase();
      }

      return true;
    });
  };

  const getCurrentLiens = () => {
    const filteredLiens = filterLiens(liens);
    const indexOfLastLien = currentPage * liensPerPage;
    const indexOfFirstLien = indexOfLastLien - liensPerPage;
    return filteredLiens.slice(indexOfFirstLien, indexOfLastLien);
  };

  // Navigation handlers
  const handleNewLien = () => {
    navigate('/dashboard/liens/new');
  };

  //delete lien
  const handleDeleteLien = async (lienId) => {
    try {
      await deleteLien(lienId);
      // Refresh the liens list
      fetchLiens();
    } catch (error) {
      console.error('Error deleting lien:', error);
      alert('Failed to delete lien');
    }
  };

  // Check if we're on a lien detail page
  const isDetailView = location.pathname.includes('/liens/');
  if (isDetailView) {
    const lien = liens.find(l => l.id === parseInt(id));
    if (!lien && !loading) return null;

    return (
      <LienDetailPage 
        lien={lien} 
        lienId={id}
        onBack={() => navigate('/dashboard/liens')}
        refreshData={refreshLiens}
      />
    );
  }

  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex items-center justify-center h-64">
          <div className="text-[#3d3731] text-lg">Loading liens...</div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="flex items-center justify-center h-64">
          <div className="text-red-500 text-lg">{error}</div>
        </div>
      );
    }

    if (!liens.length) {
      return (
        <div className="flex flex-col items-center justify-center h-64 space-y-4">
          <div className="text-[#3d3731] text-lg font-medium">No liens to display</div>
          <button
            onClick={handleNewLien}
            className="px-4 py-2 bg-black text-white rounded-md hover:bg-[#3d3731] transition-colors duration-150 flex items-center"
          >
            <Plus className="w-4 h-4 mr-2" />
            Create Your First Lien
          </button>
        </div>
      );
    }

    const currentLiens = getCurrentLiens();
    if (!currentLiens.length) {
      return (
        <div className="flex items-center justify-center h-64">
          <div className="text-[#3d3731] text-lg">No liens match your search</div>
        </div>
      );
    }

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {currentLiens.map((lien) => (
          <LienCard key={lien.id} lien={lien} onDelete={handleDeleteLien} />
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col h-screen bg-[#d8d1c7]">
      <div className="flex justify-between items-center py-4 px-8 border-b border-[#a39b8f] bg-[#e9e4dc]">
        <h1 className="text-2xl font-semibold text-black">Lien Management</h1>
        <div className="flex space-x-2">
          <button className="px-4 py-2 text-[#3d3731] bg-[#d8d1c7] border border-[#a39b8f] rounded-md hover:bg-[#c5beb2] flex items-center">
            <Filter className="w-4 h-4 mr-2" />
            Filters
          </button>
          <button
            onClick={handleNewLien}
            className="px-4 py-2 bg-black text-white rounded-md hover:bg-[#3d3731] transition-colors duration-150 flex items-center"
          >
            <Plus className="w-4 h-4 mr-2" />
            New Lien
          </button>
        </div>
      </div>

      <LiensTabs selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />

      <div className="flex-1 overflow-auto">
        <div className="p-8">
          <div className="flex justify-between items-center mb-6">
            <div className="relative w-64">
              <input
                type="text"
                placeholder="Search liens..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-4 py-2 pl-10 bg-[#e9e4dc] border border-[#a39b8f] rounded-md text-[#3d3731] focus:outline-none focus:border-black"
              />
              <Search className="w-4 h-4 absolute left-3 top-3 text-[#3d3731]" />
            </div>
          </div>

          {renderContent()}
        </div>
      </div>

      {!loading && liens.length > 0 && (
        <LienPagination
          currentPage={currentPage}
          totalLiens={filterLiens(liens).length}
          liensPerPage={liensPerPage}
          onPageChange={setCurrentPage}
        />
      )}
    </div>
  );
};

export default LienManagement;