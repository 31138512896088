// IncomingReferralRow.js
import React, { useState, useRef, useEffect } from 'react';
import { Upload, Check, X, ActivitySquare, Loader } from 'lucide-react';
import { referralStatusService } from '../../backend/referralServices/referralStatusService';

export const IncomingReferralRow = ({ 
  id,
  name,
  status: initialStatus,
  contact,
  email,
  phone,
  progress,
  case_id,
  non_user_id,
  caseDetails,
  compensationTerms,
  acceptanceDeadline,
  onStatusUpdate,
  onRefresh,
  selected,
  onSelect,
  onClick
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [actionLoading, setActionLoading] = useState(false);
  const [actionError, setActionError] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(initialStatus);
  const [isTransitioning, setIsTransitioning] = useState(false);
  
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  const errorTimeoutRef = useRef(null);
  const transitionTimeoutRef = useRef(null);

  const statuses = [
    'pending',
    'received',
    'viewed',
    'renegotiating',
    'accepted',
    'rejected',
    'expired',
    'litigation',
    'billing',
    'completed'
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Cleanup timeouts on unmount
  useEffect(() => {
    return () => {
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
      if (transitionTimeoutRef.current) {
        clearTimeout(transitionTimeoutRef.current);
      }
    };
  }, []);

  const handleAction = async (action) => {
    if (actionLoading || isTransitioning) return;

    setActionLoading(true);
    setActionError(null);
    setIsTransitioning(true);
    
    const newStatus = action === 'accept' ? 'accepted' : 
                     action === 'reject' ? 'rejected' : 
                     'renegotiating';

    // Optimistically update the UI
    setCurrentStatus(newStatus);
    
    try {
      const result = await referralStatusService.updateStatus(id, newStatus);
      if (!result.success) {
        throw new Error(result.error);
      }
    } catch (error) {
      console.error('Error handling action:', error);
      setActionError(error.message);
      setCurrentStatus(initialStatus);

      // Clear error after 5 seconds
      errorTimeoutRef.current = setTimeout(() => {
        setActionError(null);
      }, 5000);
    } finally {
      setActionLoading(false);
      // Allow time for transition animation
      transitionTimeoutRef.current = setTimeout(() => {
        setIsTransitioning(false);
      }, 300);
    }
  };

  const handleStatusUpdate = async (newStatus) => {
    if (actionLoading || isTransitioning) return;

    setIsTransitioning(true);
    setCurrentStatus(newStatus);
    setIsOpen(false);
    
    try {
      const result = await referralStatusService.updateStatus(id, newStatus);
      if (!result.success) {
        throw new Error(result.error);
      }
    } catch (error) {
      console.error('Error updating status:', error);
      setActionError(error.message);
      setCurrentStatus(currentStatus);

      errorTimeoutRef.current = setTimeout(() => {
        setActionError(null);
      }, 5000);
    } finally {
      transitionTimeoutRef.current = setTimeout(() => {
        setIsTransitioning(false);
      }, 300);
    }
  };

  const getStatusStyle = (status) => {
    const styles = {
      pending: 'bg-[#d8d1c7] text-[#3d3731]',
      received: 'bg-[#c5beb2] text-[#3d3731]',
      viewed: 'bg-[#c5beb2] text-[#3d3731]',
      renegotiating: 'bg-[#a39b8f] text-[#e9e4dc]',
      accepted: 'bg-[#3d3731] text-[#e9e4dc]',
      rejected: 'bg-red-200 text-red-800',
      expired: 'bg-[#a39b8f] text-[#e9e4dc]',
      litigation: 'bg-[#3d3731] text-[#e9e4dc]',
      billing: 'bg-[#3d3731] text-[#e9e4dc]',
      completed: 'bg-black text-white'
    };
    return styles[status.toLowerCase()] || styles.pending;
  };

  const handleToggleDropdown = () => {
    if (!isOpen && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;
      const dropdownHeight = 320;
      
      const showAbove = spaceBelow < dropdownHeight && spaceAbove > spaceBelow;
      
      setDropdownPosition({
        left: rect.left + scrollLeft,
        top: showAbove 
          ? rect.top + scrollTop - dropdownHeight - 8
          : rect.bottom + scrollTop + 8
      });
    }
    setIsOpen(!isOpen);
  };

  // Calculate progress based on current status
  const currentProgress = (() => {
    const statusProgress = {
      pending: 25,
      received: 40,
      viewed: 50,
      renegotiating: 65,
      accepted: 75,
      litigation: 85,
      billing: 95,
      completed: 100,
      rejected: 0,
      expired: 0
    };
    return statusProgress[currentStatus.toLowerCase()] || progress;
  })();

  return (
    <tr 
      className="bg-[#f5f1eb] border-b border-[#d8d1c7] cursor-pointer hover:bg-[#e9e4dc] transition-colors duration-150"
      onClick={(e) => {
        if (
          e.target.closest('.status-dropdown') ||
          e.target.closest('.action-buttons') ||
          e.target.closest('input[type="checkbox"]')
        ) {
          return;
        }
        onClick?.();
      }}
    >
      <td className="px-6 py-4">
        <input 
          type="checkbox" 
          checked={selected}
          onChange={(e) => {
            e.stopPropagation();
            onSelect(id, e.target.checked);
          }}
          className="h-4 w-4 text-[#3d3731] focus:ring-[#a39b8f] border-[#c5beb2] rounded" 
        />
      </td>
      <td className="px-6 py-4">
        <div className="flex items-center">
        <div className="h-10 w-10 rounded-full bg-[#3d3731] text-[#e9e4dc] text-sm flex items-center justify-center">
  {contact?.split(' ').map(name => name[0]).join('')}
</div>
          <div className="ml-4">
            <div className="text-sm font-medium text-black">{name}</div>
            <div className="text-sm text-[#3d3731]">{compensationTerms}</div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4">
        <div className="relative status-dropdown">
          <button
            ref={buttonRef}
            onClick={(e) => {
              e.stopPropagation();
              if (!isTransitioning) {
                handleToggleDropdown();
              }
            }}
            disabled={isTransitioning}
            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full transition-all duration-300 ${getStatusStyle(currentStatus)} ${isTransitioning ? 'opacity-75' : ''}`}
          >
            {currentStatus}
          </button>
          
          {isOpen && (
            <div
              ref={dropdownRef}
              style={{
                position: 'fixed',
                left: dropdownPosition.left,
                top: dropdownPosition.top,
                width: '12rem'
              }}
              className="rounded-lg bg-white shadow-lg border border-[#d8d1c7] z-50 max-h-80 overflow-y-auto"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="py-1">
                {statuses.map((statusOption) => (
                  <button
                    key={statusOption}
                    onClick={() => handleStatusUpdate(statusOption)}
                    disabled={isTransitioning}
                    className="w-full flex items-center px-4 py-2 text-sm hover:bg-[#f5f1eb] transition-colors duration-150"
                  >
                    <span className={`flex-1 px-2 py-0.5 rounded-full text-xs font-semibold transition-all duration-300 ${getStatusStyle(statusOption)}`}>
                      {statusOption}
                    </span>
                    {statusOption === currentStatus.toLowerCase() && (
                      <Check className="w-4 h-4 ml-2 text-[#3d3731]" />
                    )}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </td>
      <td className="px-6 py-4">
        <div className="text-sm text-[#3d3731]">{contact}</div>
        <div className="text-sm text-[#a39b8f]">{email}</div>
        <div className="text-sm text-[#a39b8f]">{phone}</div>
      </td>
      <td className="px-6 py-4">
        <div className="w-full bg-[#d8d1c7] rounded-full h-2.5">
          <div 
            className="bg-[#3d3731] h-2.5 rounded-full transition-all duration-300" 
            style={{ width: `${currentProgress}%` }}
          />
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium action-buttons">
        {actionLoading ? (
          <Loader size={20} className="animate-spin text-[#3d3731]" />
        ) : (
          <div className="flex items-center justify-end space-x-2">
            <button 
              onClick={(e) => {
                e.stopPropagation();
                handleAction('accept');
              }}
              disabled={currentStatus === 'accepted' || isTransitioning}
              className={`text-[#a39b8f] hover:text-green-600 disabled:opacity-50 transition-colors duration-300
                ${isTransitioning ? 'cursor-not-allowed' : ''}`}
            >
              <Check size={20} />
            </button>
            <button 
              onClick={(e) => {
                e.stopPropagation();
                handleAction('reject');
              }}
              disabled={currentStatus === 'rejected' || isTransitioning}
              className={`text-[#a39b8f] hover:text-red-600 disabled:opacity-50 transition-colors duration-300
                ${isTransitioning ? 'cursor-not-allowed' : ''}`}
            >
              <X size={20} />
            </button>
            <button 
              onClick={(e) => {
                e.stopPropagation();
                handleAction('renegotiate');
              }}
              disabled={currentStatus === 'renegotiating' || isTransitioning}
              className={`text-[#a39b8f] hover:text-[#3d3731] disabled:opacity-50 transition-colors duration-300
                ${isTransitioning ? 'cursor-not-allowed' : ''}`}
            >
              <ActivitySquare size={20} />
            </button>
            <div className="relative">
              <input
                type="file"
                id={`file-upload-${id}`}
                className="hidden"
                accept=".pdf,.doc,.docx,.txt,.jpg,.jpeg,.png"
                disabled={isTransitioning}
              />
              <label
                htmlFor={`file-upload-${id}`}
                className={`cursor-pointer text-[#a39b8f] hover:text-[#3d3731] transition-colors duration-300
                  ${isTransitioning ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={(e) => e.stopPropagation()}
              >
                <Upload size={20} />
              </label>
            </div>
          </div>
        )}

        {actionError && (
          <div 
            className="absolute right-0 top-full mt-2 p-2 bg-red-50 border border-red-200 rounded text-sm text-red-600 whitespace-normal z-10 transition-opacity duration-300"
          >
            {actionError}
          </div>
        )}
      </td>
    </tr>
  );
};

export default IncomingReferralRow;