import { supabase } from './supabase';

export const workflowService = {
  async updateWorkflowSteps(lienId, updatedSteps) {
    try {
      const { data, error } = await supabase
        .from('liens')
        .update({ 
          workflow_steps: updatedSteps,
          updated_at: new Date().toISOString()
        })
        .eq('id', lienId)
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error updating workflow steps:', error);
      throw error;
    }
  },

  getDefaultSteps() {
    return [
      {
        id: 1,
        title: 'Initial Filing',
        description: 'Lien documentation submitted to court',
        status: 'pending',
        completion_date: null
      },
      {
        id: 2,
        title: 'Document Review',
        description: 'All documents verified and approved',
        status: 'pending',
        completion_date: null
      },
      {
        id: 3,
        title: 'Client Notification',
        description: 'Notifying all relevant parties',
        status: 'pending',
        completion_date: null
      },
      {
        id: 4,
        title: 'Payment Processing',
        description: 'Awaiting payment confirmation',
        status: 'pending',
        completion_date: null
      },
      {
        id: 5,
        title: 'Final Resolution',
        description: 'Completing lien resolution process',
        status: 'pending',
        completion_date: null
      }
    ];
  }
};