import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../backend/supabase';
import { PersonalDetails } from './registration/RegistrationPhases/PersonalDetails';
import { FirmDetails } from './registration/RegistrationPhases/FirmDetails';
import { BarVerification } from './registration/RegistrationPhases/BarVerification';
import { PhaseIndicator } from './registration/PhaseIndicator';

const RegistrationForm = () => {
  const navigate = useNavigate();
  const [currentPhase, setCurrentPhase] = useState(1);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isOAuthUser, setIsOAuthUser] = useState(false);
  const [formData, setFormData] = useState({
    // Personal Details
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    
    // Firm Details
    workEmail: '',
    secondaryEmail: '',
    phone: '',
    secondaryPhone: '',
    
    // Additional fields
    firmName: '',
    office_address: '',
    firmCity: '',
    firmState: '',
    firmZip: '',
    practiceAreas: [],
    barId: '',
    yearsOfExperience: '',
    publicReferrals: false
  });

  useEffect(() => {
    const initializeRegistration = async () => {
      // First check if we have a role selected
      const userRole = localStorage.getItem('userRole');
      if (!userRole) {
        navigate('/signup');
        return;
      }

      // Clear any existing sessions if not OAuth
      if (!localStorage.getItem('tempAuthUser')) {
        await supabase.auth.signOut();
      }

      const tempAuthUser = localStorage.getItem('tempAuthUser');
      if (tempAuthUser) {
        try {
          const { data: { session } } = await supabase.auth.getSession();
          const authUserData = JSON.parse(tempAuthUser);
          
          if (session?.user && authUserData) {
            console.log('Found OAuth user data:', authUserData);
            // Update form data with OAuth info
            setFormData(prevData => ({
              ...prevData,
              firstName: authUserData.user_metadata?.given_name || session.user.user_metadata?.given_name || '',
              lastName: authUserData.user_metadata?.family_name || session.user.user_metadata?.family_name || '',
              workEmail: authUserData.email || session.user.email || ''
            }));
            setIsOAuthUser(true);
          }
        } catch (error) {
          console.error('Error parsing auth user data:', error);
          localStorage.removeItem('tempAuthUser');
        }
      }
    };

    initializeRegistration();
  }, [navigate]);

  const validatePhase = (phase) => {
    const errors = {};

    const isValidEmail = (email) => {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const isValidPhone = (phone) => {
      return /^\d{10}$/.test(phone.replace(/\D/g, ''));
    };

    const isValidZipCode = (zip) => {
      return /^\d{5}(-\d{4})?$/.test(zip);
    };

    switch (phase) {
      case 1:
        if (!formData.firstName.trim()) errors.firstName = 'First name is required';
        if (!formData.lastName.trim()) errors.lastName = 'Last name is required';
        if (!isOAuthUser) {
          if (!formData.password) errors.password = 'Password is required';
          if (formData.password.length < 8) {
            errors.password = 'Password must be at least 8 characters';
          }
          if (formData.password !== formData.confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
          }
        }
        break;

      case 2:
        if (!formData.workEmail) {
          errors.workEmail = 'Work email is required';
        } else if (!isValidEmail(formData.workEmail)) {
          errors.workEmail = 'Please enter a valid email';
        }

        if (formData.secondaryEmail && !isValidEmail(formData.secondaryEmail)) {
          errors.secondaryEmail = 'Please enter a valid email';
        }

        if (!formData.phone) {
          errors.phone = 'Phone number is required';
        } else if (!isValidPhone(formData.phone)) {
          errors.phone = 'Please enter a valid 10-digit phone number';
        }

        if (formData.secondaryPhone && !isValidPhone(formData.secondaryPhone)) {
          errors.secondaryPhone = 'Please enter a valid 10-digit phone number';
        }
        break;

      case 3:
        if (!formData.firmName.trim()) errors.firmName = 'Firm name is required';
        if (!formData.office_address.trim()) errors.office_address = 'Firm address is required';
        if (!formData.barId.trim()) errors.barId = 'Bar ID is required';
        if (!formData.firmCity.trim()) errors.firmCity = 'City is required';
        if (!formData.firmState) errors.firmState = 'State is required';
        if (!formData.firmZip) {
          errors.firmZip = 'ZIP code is required';
        } else if (!isValidZipCode(formData.firmZip)) {
          errors.firmZip = 'Please enter a valid ZIP code';
        }
        break;

      default:
        break;
    }

    return errors;
  };

  const createUserRecord = async () => {
    try {
      console.log('Starting registration process...');
      let authUser;

      if (isOAuthUser) {
        console.log('Processing OAuth user...');
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError || !session?.user) {
          throw new Error('Session expired. Please sign in again to complete registration.');
        }

        const existingUser = await supabase
          .from('users')
          .select('*')
          .eq('email', session.user.email)
          .single();

        if (existingUser.data) {
          throw new Error('An account with this email already exists');
        }

        authUser = session.user;
      } else {
        console.log('Processing email signup...');
        
        // First check if email already exists
        const { data: existingEmail, error: emailCheckError } = await supabase
          .from('users')
          .select('email')
          .eq('email', formData.workEmail.toLowerCase())
          .single();

        if (existingEmail) {
          throw new Error('An account with this email already exists');
        }

        // Proceed with signup
        const { data, error: signUpError } = await supabase.auth.signUp({
          email: formData.workEmail,
          password: formData.password,
          options: {
            data: {
              first_name: formData.firstName,
              last_name: formData.lastName,
              user_type: 'attorney'
            }
          }
        });

        if (signUpError) throw signUpError;
        if (!data?.user) throw new Error('Registration failed - please try again');
        
        // Create the user profile immediately after auth signup
        const publicUserData = {
          id: data.user.id,
          first_name: formData.firstName,
          last_name: formData.lastName,
          middle_name: null,
          email: formData.workEmail.toLowerCase(),
          phone: formData.phone.replace(/\D/g, ''),
          street_address: formData.office_address,
          city: formData.firmCity,
          state: formData.firmState,
          zip_code: formData.firmZip,
          user_type: 'attorney',
          is_public: formData.publicReferrals === 'public',
          is_active: true,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        };

        const { error: userError } = await supabase
          .from('users')
          .insert([publicUserData]);

        if (userError) {
          console.error('Error creating user profile:', userError);
          throw userError;
        }

        // Create lawyer profile
        const lawyerProfileData = {
          user_id: data.user.id,
          firm_name: formData.firmName,
          practice_areas: formData.practiceAreas || [],
          bar_number: formData.barId,
          years_of_experience: parseInt(formData.yearsOfExperience) || 0,
          office_address: `${formData.office_address}, ${formData.firmCity}, ${formData.firmState} ${formData.firmZip}`,
          referral_count: 0,
          is_visible: true,
          verification_status: 'pending',
          bio: formData.bio || null,
          website: formData.website || null,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        };

        const { error: lawyerError } = await supabase
          .from('lawyer_profiles')
          .insert([lawyerProfileData]);

        if (lawyerError) {
          console.error('Error creating lawyer profile:', lawyerError);
          // Cleanup if lawyer profile creation fails
          await supabase.from('users').delete().match({ id: data.user.id });
          throw lawyerError;
        }

        setErrors({
          submit: 'Account created! Please check your email to verify your account before continuing.'
        });
        return;
      }

      const formattedZipCode = formData.firmZip.replace(/[^\d-]/g, '');
      if (!/^\d{5}(-\d{4})?$/.test(formattedZipCode)) {
        throw new Error('Invalid ZIP code format. Must be 5 digits or 5-4 digits.');
      }

      // Check if user already exists
      const { data: existingUser } = await supabase
        .from('users')
        .select('*')
        .eq('id', authUser.id)
        .single();

      if (existingUser) {
        console.log('User already exists, proceeding to dashboard');
        return existingUser;
      }

      // Create public user record
      const publicUserData = {
        id: authUser.id,
        first_name: formData.firstName,
        last_name: formData.lastName,
        middle_name: null,
        email: formData.workEmail.toLowerCase(),
        phone: formData.phone.replace(/\D/g, ''),
        street_address: formData.office_address,
        city: formData.firmCity,
        state: formData.firmState,
        zip_code: formattedZipCode,
        user_type: 'attorney',
        is_public: formData.publicReferrals === 'public',
        is_active: true,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      };

      const { data: userData, error: userError } = await supabase
        .from('users')
        .insert([publicUserData])
        .select()
        .single();

      if (userError) {
        console.error('Error creating public user:', userError);
        if (!isOAuthUser) {
          await supabase.auth.signOut();
        }
        throw userError;
      }

      // Create lawyer profile
      const lawyerProfileData = {
        user_id: userData.id,
        firm_name: formData.firmName,
        practice_areas: formData.practiceAreas || [],
        bar_number: formData.barId,
        years_of_experience: parseInt(formData.yearsOfExperience) || 0,
        office_address: `${formData.office_address}, ${formData.firmCity}, ${formData.firmState} ${formattedZipCode}`,
        referral_count: 0,
        is_visible: true,
        verification_status: 'pending',
        bio: formData.bio || null,
        website: formData.website || null,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      };

      const { error: lawyerError } = await supabase
        .from('lawyer_profiles')
        .insert([lawyerProfileData]);

      if (lawyerError) {
        console.error('Error creating lawyer profile:', lawyerError);
        await supabase
          .from('users')
          .delete()
          .match({ id: userData.id });
          
        if (!isOAuthUser) {
          await supabase.auth.signOut();
        }
        throw lawyerError;
      }

      if (isOAuthUser) {
        localStorage.removeItem('tempAuthUser');
      }

      console.log('Registration completed successfully');
      return userData;
    } catch (error) {
      console.error('Registration failed:', error);
      throw error;
    }
  };

  const handleBack = () => {
    if (currentPhase > 1) {
      setCurrentPhase(prev => prev - 1);
    } else {
      localStorage.removeItem('tempAuthUser');
      localStorage.removeItem('userRole');
      navigate(-1);
    }
  };

  const handleNext = () => {
    const validationErrors = validatePhase(currentPhase);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    
    setErrors({});
    setCurrentPhase(prev => prev + 1);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async () => {
    const validationErrors = validatePhase(currentPhase);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    try {
      await createUserRecord();
      navigate('/dashboard');
    } catch (error) {
      console.error('Submission error:', error);
      setErrors({ 
        submit: error.message || 'There was a problem creating your account. Please try again.' 
      });
    } finally {
      setLoading(false);
    }
  };

  const renderError = () => {
    if (errors.submit) {
      return (
        <div className="w-full max-w-md mb-6">
          <div className="bg-red-50 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline">{errors.submit}</span>
          </div>
        </div>
      );
    }
    return null;
  };

  const getPhaseTitle = () => {
    switch (currentPhase) {
      case 1:
        return 'Create Account';
      case 2:
        return 'Almost Done';
      case 3:
        return 'Final Step';
      default:
        return 'Create Account';
    }
  };

  const getPhaseSubtitle = () => {
    switch (currentPhase) {
      case 1:
        return 'Personal Details';
      case 2:
        return 'Contact Information';
      case 3:
        return 'Bar Verification';
      default:
        return '';
    }
  };

  const renderContent = () => {
    const pageContent = (
      <div className="w-full flex flex-col items-center pt-32">
        <h1 className="text-3xl font-bold text-[#333333] mb-6">
          {getPhaseTitle()}
        </h1>
        <p className="text-gray-600 mb-6">
          {getPhaseSubtitle()}
        </p>
        <div className="mb-12 w-full max-w-md">
          <PhaseIndicator currentPhase={currentPhase} />
        </div>

        {renderError()}

        <div className="w-full max-w-md">
          {currentPhase === 1 && (
            <PersonalDetails 
              formData={formData} 
              onChange={handleChange}
              errors={errors}
              isOAuthUser={isOAuthUser}
            />
          )}
          {currentPhase === 2 && (
            <FirmDetails 
              formData={formData} 
              onChange={handleChange}
              errors={errors}
            />
          )}
          {currentPhase === 3 && (
            <BarVerification 
              formData={formData} 
              onChange={handleChange}
              errors={errors}
            />
          )}
          
          <button
            onClick={currentPhase === 3 ? handleSubmit : handleNext}
            disabled={loading}
            className="w-full py-3 bg-[#34261E] text-white rounded-full hover:bg-opacity-90 transition-colors mt-8 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? 'Processing...' : currentPhase === 3 ? 'Create Account' : 'Next Step'}
          </button>

          <div className="text-center mt-8">
            <div className="text-gray-500">- or -</div>
            <p className="mt-4 text-gray-600">
              Already have an account?{' '}
              <button 
                onClick={() => navigate('/signup/login')} 
                className="text-[#34261E] font-semibold hover:underline"
              >
                Sign in
              </button>
            </p>
          </div>
        </div>
      </div>
    );

    if (currentPhase === 1) {
      return (
        <div className="min-h-screen flex">
          <div className="w-[30%] bg-[#34261E]" />
          <div className="flex-1 bg-[#fef6ef]">
            {pageContent}
          </div>
        </div>
      );
    }

    return (
      <div className="min-h-screen bg-[#fef6ef]">
        {pageContent}
      </div>
    );
  };

  return (
    <div className="relative">
      <button 
        onClick={handleBack}
        className={`absolute top-6 ${currentPhase === 1 ? 'left-[calc(30%+24px)]' : 'left-6'} text-gray-600 hover:text-gray-800 flex items-center gap-2 z-10`}
      >
        ← Back
      </button>

      {renderContent()}
    </div>
  );
};

export default RegistrationForm;