// src/backend/emailApiService.js
import { supabase } from './supabase';
import axiosInstance from '../axiosInstance';

// Config for production vs development
const EMAIL_API_URL = process.env.NODE_ENV === 'production' 
  ? 'https://api.lawdie.co'
  : 'http://localhost:4001';

// Get these from your environment variables
const API_KEY = process.env.REACT_APP_EMAIL_API_KEY;
const API_SECRET = process.env.REACT_APP_EMAIL_API_SECRET;

// Helper function to convert string to byte array
function stringToUint8Array(str) {
  const encoder = new TextEncoder();
  return encoder.encode(str);
}

// Helper function to convert byte array to hex string
function byteArrayToHexString(byteArray) {
  return Array.from(byteArray)
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');
}

export const emailApiService = {
  // Helper function to generate signature using Web Crypto API
  async generateSignature(timestamp, method, path, body) {
    const data = timestamp + method + path + (body ? JSON.stringify(body) : '');
    const encoder = new TextEncoder();
    const keyData = encoder.encode(API_SECRET);
    const messageData = encoder.encode(data);

    try {
      // Import the key
      const key = await window.crypto.subtle.importKey(
        'raw',
        keyData,
        { name: 'HMAC', hash: 'SHA-256' },
        false,
        ['sign']
      );

      // Sign the message
      const signature = await window.crypto.subtle.sign(
        'HMAC',
        key,
        messageData
      );

      // Convert to hex string
      return byteArrayToHexString(new Uint8Array(signature));
    } catch (error) {
      console.error('Error generating signature:', error);
      throw new Error('Failed to generate request signature');
    }
  },

  // Helper function to validate required fields
  validateReferralData(data) {
    const requiredFields = [
      'name',
      'email',
      'selectedSubject',
      'caseDetails',
      'compensationPercentage',
      'acceptanceDeadline'
    ];

    const missingFields = requiredFields.filter(field => !data[field]);
    if (missingFields.length > 0) {
      throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
    }

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(data.email)) {
      throw new Error('Invalid email format');
    }

    // Validate compensation percentage
    if (typeof data.compensationPercentage !== 'number' || 
        data.compensationPercentage < 0 || 
        data.compensationPercentage > 100) {
      throw new Error('Invalid compensation percentage');
    }

    // Validate deadline
    const deadline = new Date(data.acceptanceDeadline);
    if (isNaN(deadline.getTime()) || deadline < new Date()) {
      throw new Error('Invalid or past deadline date');
    }
  },

  // Send referral email with file attachments
  async sendReferralEmail(referralData) {
    try {
      // Validate data before proceeding
      this.validateReferralData(referralData);

      // Get authentication token
      const { data: { session }, error: authError } = await supabase.auth.getSession();
      if (authError) throw authError;
      if (!session) throw new Error('No active session');

      // Prepare the referral data - format it to match what the email service expects
      const emailPayload = {
        referralData: {
          name: referralData.name,
          email: referralData.email,
          phone: referralData.phone,
          firm: referralData.firm,
          selectedSubject: referralData.selectedSubject,
          caseDetails: referralData.caseDetails,
          compensationPercentage: referralData.compensationPercentage,
          acceptanceDeadline: referralData.acceptanceDeadline,
          referralId: referralData.referralId || null,
          // Include any attached file information
          attachments: referralData.files ? referralData.files.map(file => ({
            name: file.name,
            size: file.size,
            type: file.type
          })) : []
        }
      };

      // Generate timestamp and signature
      const timestamp = Date.now().toString();
      const method = 'POST';
      const path = '/api/send-referral-email';
      const signature = await this.generateSignature(timestamp, method, path, emailPayload);

      // Log request details in development
      if (process.env.NODE_ENV !== 'production') {
        console.log('Making referral email request:', {
          url: `${EMAIL_API_URL}${path}`,
          headers: {
            'Authorization': `Bearer ${session.access_token}`,
            'X-API-Key': API_KEY,
            'X-Timestamp': timestamp,
            'X-Signature': signature
          },
          body: emailPayload
        });
      }

      // Make request to email service
      const response = await axiosInstance.post(
        `${EMAIL_API_URL}${path}`,
        emailPayload,
        {
          headers: {
            'Authorization': `Bearer ${session.access_token}`,
            'X-API-Key': API_KEY,
            'X-Timestamp': timestamp,
            'X-Signature': signature
          }
        }
      );

      // Log success in development
      if (process.env.NODE_ENV !== 'production') {
        console.log('Email sent successfully:', response.data);
      }

      return {
        success: true,
        messageId: response.data.messageId,
        timestamp: new Date().toISOString()
      };
    } catch (error) {
      // Log detailed error information
      console.error('Error sending referral email:', {
        error: error.message,
        code: error.code,
        response: error.response?.data,
        status: error.response?.status,
        timestamp: new Date().toISOString()
      });

      // Throw a user-friendly error
      throw new Error(
        error.response?.data?.error || 
        error.message || 
        'Failed to send referral email'
      );
    }
  },

  // Send a generic email
  async sendGenericEmail(to, subject, content) {
    try {
      // Get authentication token
      const { data: { session }, error: authError } = await supabase.auth.getSession();
      if (authError) throw authError;
      if (!session) throw new Error('No active session');

      const emailPayload = {
        to,
        subject,
        html: content
      };

      // Generate timestamp and signature
      const timestamp = Date.now().toString();
      const method = 'POST';
      const path = '/api/send-email';
      const signature = await this.generateSignature(timestamp, method, path, emailPayload);

      // Make request to email service
      const response = await axiosInstance.post(
        `${EMAIL_API_URL}${path}`,
        emailPayload,
        {
          headers: {
            'Authorization': `Bearer ${session.access_token}`,
            'X-API-Key': API_KEY,
            'X-Timestamp': timestamp,
            'X-Signature': signature
          }
        }
      );

      return response.data;
    } catch (error) {
      console.error('Error sending generic email:', error);
      throw error;
    }
  },

  // Test the email service connection
  async testEmailConnection() {
    try {
      const response = await axiosInstance.get(`${EMAIL_API_URL}/health`);
      return {
        success: true,
        status: response.data.status,
        timestamp: response.data.timestamp
      };
    } catch (error) {
      console.error('Error testing email connection:', {
        error: error.message,
        status: error.response?.status,
        timestamp: new Date().toISOString()
      });
      throw new Error('Failed to connect to email service');
    }
  },

  // Retry mechanism for failed email sends
  async retryEmailSend(emailData, maxRetries = 3) {
    let attempts = 0;
    let lastError = null;

    while (attempts < maxRetries) {
      try {
        const result = await this.sendReferralEmail(emailData);
        return result;
      } catch (error) {
        attempts++;
        lastError = error;
        
        if (attempts < maxRetries) {
          // Wait before retrying (exponential backoff)
          await new Promise(resolve => 
            setTimeout(resolve, Math.pow(2, attempts) * 1000)
          );
        }
      }
    }

    throw new Error(`Failed to send email after ${maxRetries} attempts: ${lastError.message}`);
  }
};

// Export the service instance
export default emailApiService;