import React, { useState, useEffect } from 'react';
import { Network, Globe, FileText, FolderOpen, Bot, Brain, Users, Shield } from 'lucide-react';

const ServiceCard = ({ icon: Icon, title, description, comingSoon, isActive }) => {
  return (
    <div className={`absolute inset-0 p-8 bg-gradient-to-br from-[#1a1410] to-[#0f0b08] rounded-3xl border border-[#2a2118] transform transition-all duration-500 shadow-xl flex items-center justify-center ${
      isActive ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-8'
    }`}>
      <div className="relative w-full">
        {/* Background decorative element */}
        <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-[#FFC499]/10 to-[#964B00]/10 rounded-full -translate-y-12 translate-x-12 blur-xl" />
        
        <div className="relative flex flex-col items-center gap-8">
          {/* Icon Container */}
          <div>
            <div className="p-4 rounded-2xl bg-gradient-to-br from-[#5D341A] to-[#FFC499] text-white shadow-lg">
              <Icon className="w-8 h-8" />
            </div>
          </div>
          
          {/* Title and Description */}
          <div className="text-center">
            <div className="flex flex-col items-center gap-3 mb-4">
              <h3 className="text-2xl font-helvetica font-bold text-[#d8d1c7]">{title}</h3>
              {comingSoon && (
                <span className="text-xs px-3 py-1 rounded-full bg-gradient-to-r from-[#FFC499]/20 to-[#964B00]/20 text-[#FFC499] border border-[#FFC499]/20 font-medium">
                  Coming Soon
                </span>
              )}
            </div>
            <p className="text-[#8a8178] leading-relaxed max-w-lg mx-auto">{description}</p>
          </div>
          
          {/* Feature bullets */}
          <div className="grid grid-cols-2 gap-4 w-full max-w-md">
            {[
              { icon: Shield, text: "Secure & Compliant" },
              { icon: Users, text: "Team Collaboration" },
              { icon: Brain, text: "AI-Powered" },
              { icon: Globe, text: "Cloud-Based" }
            ].map((feature, idx) => (
              <div key={idx} className="flex items-center justify-center gap-2 text-[#a39b8f]">
                <feature.icon className="w-4 h-4 text-[#FFC499]" />
                <span className="text-sm">{feature.text}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ServicesOffered = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const services = [
    {
      icon: Globe,
      title: "Public Referral Network",
      description: "Connect with potential clients and other attorneys through our public referral marketplace. Expand your reach and find the right opportunities.",
      comingSoon: false
    },
    {
      icon: FileText,
      title: "Lien Management",
      description: "Streamline your lien processes with our comprehensive management system. Track, organize, and resolve liens efficiently all in one place.",
      comingSoon: false
    },
    {
      icon: FolderOpen,
      title: "Document Management",
      description: "Secure, organize, and access your documents with ease. Our intuitive system helps you manage case files, contracts, and important paperwork.",
      comingSoon: true
    },
    {
      icon: Bot,
      title: "AI Paralegal",
      description: "Using Kiwi-AI, lawyers can leverage cutting-edge AI technology to automate routine tasks, draft documents, and get instant answers to common legal questions.",
      comingSoon: false
    },
    {
      icon: Bot,
      title: "Client-Relation",
      description: "Build stronger client relationships with our intuitive platform, enabling personalized interactions, follow-ups, and insights to enhance client satisfaction.",
      comingSoon: true
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((current) => (current + 1) % services.length);
    }, 4000);
    return () => clearInterval(interval);
  }, [services.length]);

  return (
    <div className="flex flex-col md:flex-row w-full h-full">
      {/* Left Panel - Dark section with text */}
      <div className="w-full md:w-1/2 bg-[#161616] p-12 md:p-24 flex flex-col justify-center">
        <div className="text-gray-400 mb-4 text-lg">We Offer</div>
        <h2 className="font-helvetica font-bold text-6xl md:text-7xl text-white mb-8 leading-tight">
          Transform
          <br />
          Your Legal
          <br />
          Practice
        </h2>
        <p className="text-gray-400 text-lg mb-8 max-w-md">
          Streamline your workflow and enhance client relationships with our comprehensive suite of legal practice solutions.
        </p>
        <div className="flex gap-2">
          {services.map((_, index) => (
            <button
              key={index}
              onClick={() => setActiveIndex(index)}
              className={`w-12 h-1 rounded-full transition-all duration-300 ${
                index === activeIndex ? 'bg-white' : 'bg-gray-700'
              }`}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      </div>

      {/* Right Panel - Dark brown section with cards */}
      <div className="w-full md:w-1/2 bg-[#0f0b08] p-12 md:p-24 flex items-center">
        <div className="relative h-[500px] w-full max-w-2xl mx-auto">
          {/* Decorative background elements */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-64 h-64 bg-gradient-to-br from-[#FFC499]/10 to-[#964B00]/10 rounded-full blur-3xl" />
            <div className="w-48 h-48 bg-gradient-to-br from-[#5D341A]/10 to-[#FFC499]/10 rounded-full blur-3xl -translate-x-24" />
          </div>
          
          {/* Service cards */}
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              {...service}
              isActive={index === activeIndex}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesOffered;