// src/backend/referralServices/referralValidationService.js
import { supabase } from '../supabase';

export const referralValidationService = {
  validatePhoneFormat(phone) {
    // Remove all non-digit characters
    const digitsOnly = phone.replace(/\D/g, '');
    
    // US phone number validation and formatting
    if (digitsOnly.length === 10) {
      return `+1${digitsOnly}`;
    } else if (digitsOnly.length === 11 && digitsOnly.startsWith('1')) {
      return `+${digitsOnly}`;
    } else if (digitsOnly.length > 11) {
      // Handle international numbers
      return `+${digitsOnly}`;
    }
    
    // Default case: pad with zeros if necessary
    const paddedNumber = digitsOnly.padStart(10, '0');
    return `+1${paddedNumber}`;
  },

  calculateProgress(status) {
    const statusProgress = {
      pending: 25,
      received: 40,
      viewed: 50,
      renegotiating: 65,
      accepted: 75,
      litigation: 85,
      billing: 95,
      completed: 100,
      rejected: 0,
      expired: 0,
    };
    return statusProgress[status.toLowerCase()] || 0;
  },

  validateReferralForm(formData) {
    const errors = {};

    // Contact validation
    if (!formData.name?.trim()) {
      errors.name = 'Contact name is required';
    } else if (formData.name.length < 2) {
      errors.name = 'Name must be at least 2 characters';
    }

    if (!formData.email?.trim()) {
      errors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = 'Invalid email format';
    }

    if (!formData.phone?.trim()) {
      errors.phone = 'Phone number is required';
    } else {
      const digitsOnly = formData.phone.replace(/\D/g, '');
      if (digitsOnly.length < 10) {
        errors.phone = 'Phone number must be at least 10 digits';
      }
    }

    if (!formData.firm?.trim()) {
      errors.firm = 'Firm name is required';
    }

    // Case details validation
    if (!formData.selectedSubject) {
      errors.subject = 'Practice area is required';
    }

    if (!formData.caseDetails?.trim()) {
      errors.caseDetails = 'Case details are required';
    } else if (formData.caseDetails.length < 50) {
      errors.caseDetails = 'Case details must be at least 50 characters';
    }

    // Compensation validation
    if (!formData.compensationPercentage) {
      errors.compensation = 'Compensation percentage is required';
    } else {
      const percentage = parseInt(formData.compensationPercentage);
      if (isNaN(percentage) || percentage < 0 || percentage > 100) {
        errors.compensation = 'Compensation must be between 0 and 100%';
      }
    }

    // Deadline validation
    if (!formData.acceptanceDeadline) {
      errors.deadline = 'Acceptance deadline is required';
    } else {
      const deadline = new Date(formData.acceptanceDeadline);
      const now = new Date();
      const maxDeadline = new Date();
      maxDeadline.setDate(maxDeadline.getDate() + 30); // Max 30 days

      if (isNaN(deadline.getTime())) {
        errors.deadline = 'Invalid deadline date';
      } else if (deadline < now) {
        errors.deadline = 'Deadline cannot be in the past';
      } else if (deadline > maxDeadline) {
        errors.deadline = 'Deadline cannot be more than 30 days in the future';
      }
    }

    // File validation
    if (formData.files?.length > 0) {
      const maxFileSize = 10 * 1024 * 1024; // 10MB
      const allowedTypes = [
        'application/pdf',
        'image/jpeg',
        'image/png',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ];

      formData.files.forEach((file, index) => {
        if (file.size > maxFileSize) {
          errors[`file_${index}`] = 'File size must be less than 10MB';
        }
        if (!allowedTypes.includes(file.type)) {
          errors[`file_${index}`] = 'Invalid file type';
        }
      });
    }

    return {
      isValid: Object.keys(errors).length === 0,
      errors
    };
  },

  async validateBarCredentials(barId, state) {
    try {
      // Check for format based on state
      const formatValid = this.validateBarIdFormat(barId, state);
      if (!formatValid.isValid) {
        return formatValid;
      }

      // Check against existing verified attorneys
      const { data: existing, error: searchError } = await supabase
        .from('lawyer_profiles')
        .select('verification_status')
        .eq('bar_number', barId)
        .single();

      if (searchError && searchError.code !== 'PGRST116') throw searchError;

      if (existing?.verification_status === 'verified') {
        return {
          isValid: false,
          error: 'Bar number is already registered to a verified attorney'
        };
      }

      // Additional state-specific validations
      const stateValidation = await this.validateStateSpecificRules(barId, state);
      if (!stateValidation.isValid) {
        return stateValidation;
      }

      return { isValid: true };
    } catch (error) {
      console.error('Error validating bar credentials:', error);
      return {
        isValid: false,
        error: error.message || 'Failed to validate bar credentials'
      };
    }
  },

  validateBarIdFormat(barId, state) {
    const stateRules = {
      'CA': /^\d{6}$/,
      'NY': /^\d{8}$/,
      'TX': /^\d{8}$/,
      'FL': /^\d{7}$/,
      'GA': /^\d{6}$/,
      // Add more states as needed
    };

    const rule = stateRules[state.toUpperCase()];
    if (!rule) {
      return {
        isValid: false,
        error: 'Unsupported state for bar validation'
      };
    }

    return {
      isValid: rule.test(barId),
      error: rule.test(barId) ? null : 'Invalid bar number format for state'
    };
  },

  async validateStateSpecificRules(barId, state) {
    // This would integrate with state bar APIs
    // For now, return valid
    return { isValid: true };
  },

  validateEmailDomain(email, firm) {
    // Remove common suffixes from firm name
    const cleanFirm = firm.toLowerCase()
      .replace(/\s*(llp|llc|pc|pa|law firm|law office|associates|group)\s*$/i, '')
      .replace(/[^\w\s]/g, '')
      .trim();

    // Get domain from email
    const domain = email.split('@')[1].toLowerCase();

    // Check if domain contains cleaned firm name
    if (!domain.includes(cleanFirm.replace(/\s+/g, ''))) {
      return {
        isValid: false,
        warning: 'Email domain does not match firm name'
      };
    }

    return { isValid: true };
  },

  async validateReferralStatus(referralId, newStatus, userId) {
    try {
      const { data: referral, error } = await supabase
        .from('referrals')
        .select('status, referred_user_id, referring_user_id')
        .eq('id', referralId)
        .single();

      if (error) throw error;

      // Check user authorization
      if (userId !== referral.referred_user_id && 
          userId !== referral.referring_user_id) {
        return {
          isValid: false,
          error: 'Unauthorized to update referral status'
        };
      }

      // Validate status transition
      const validTransitions = {
        pending: ['received', 'rejected', 'expired'],
        received: ['viewed', 'rejected', 'expired'],
        viewed: ['renegotiating', 'accepted', 'rejected', 'expired'],
        renegotiating: ['accepted', 'rejected', 'expired'],
        accepted: ['litigation', 'completed', 'rejected'],
        litigation: ['billing', 'completed', 'rejected'],
        billing: ['completed', 'rejected'],
        completed: [], // No transitions allowed
        rejected: [], // No transitions allowed
        expired: ['pending'] // Can reactivate expired
      };

      if (!validTransitions[referral.status]?.includes(newStatus)) {
        return {
          isValid: false,
          error: `Invalid status transition from ${referral.status} to ${newStatus}`
        };
      }

      return { isValid: true };
    } catch (error) {
      console.error('Error validating status transition:', error);
      return {
        isValid: false,
        error: error.message || 'Failed to validate status transition'
      };
    }
  }
};