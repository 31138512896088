// src/backend/referralServices/referralUpdateService.js
import { supabase } from '../supabase';
import { referralValidationService } from './referralValidationService';

export const referralUpdateService = {
  async updateReferral(referralId, updateData) {
    try {
      console.log('Updating referral:', { referralId, updateData });

      if (!updateData.non_user_id || !updateData.case_id) {
        throw new Error('Missing required IDs for update');
      }

      // Validate compensation percentage
      if (updateData.compensationPercentage) {
        const percentage = parseInt(updateData.compensationPercentage);
        if (isNaN(percentage) || percentage < 0 || percentage > 100) {
          throw new Error('Compensation percentage must be between 0 and 100');
        }
      }

      // Validate deadline
      if (updateData.acceptanceDeadline) {
        const deadline = new Date(updateData.acceptanceDeadline);
        if (isNaN(deadline.getTime())) {
          throw new Error('Invalid deadline date');
        }
        const now = new Date();
        if (deadline < now) {
          throw new Error('Deadline cannot be in the past');
        }
      }

      // First get current referral state
      const { data: currentReferral, error: fetchError } = await supabase
        .from('referrals')
        .select('status, referred_user_id')
        .eq('id', referralId)
        .single();

      if (fetchError) throw fetchError;

      // Check if referral is in an updatable state
      const nonUpdateableStates = ['completed', 'rejected', 'expired'];
      if (nonUpdateableStates.includes(currentReferral.status)) {
        throw new Error(`Cannot update referral in ${currentReferral.status} status`);
      }

      // If there's an assigned user, certain fields can't be updated
      if (currentReferral.referred_user_id) {
        const restrictedFields = ['email', 'phone', 'firm'];
        const attemptedRestrictedUpdate = restrictedFields.some(field => 
          updateData[field] !== undefined
        );
        if (attemptedRestrictedUpdate) {
          throw new Error('Cannot update contact details for assigned referrals');
        }
      }

      // Start transaction for all updates
      const updates = [];

      // 1. Update non_user record
      updates.push(
        supabase
          .from('non_users')
          .update({
            first_name: updateData.name.split(' ')[0],
            last_name: updateData.name.split(' ').slice(1).join(' '),
            email: updateData.email?.toLowerCase(),
            phone: updateData.phone ? referralValidationService.validatePhoneFormat(updateData.phone) : undefined,
            company_name: updateData.firm,
            updated_at: new Date().toISOString(),
          })
          .eq('id', updateData.non_user_id)
      );

      // 2. Update case record
      updates.push(
        supabase
          .from('cases')
          .update({
            title: `${updateData.name} - ${updateData.selectedSubject}`,
            description: updateData.caseDetails,
            practice_area: updateData.selectedSubject,
            updated_at: new Date().toISOString(),
          })
          .eq('id', updateData.case_id)
      );

      // 3. Update referral record
      updates.push(
        supabase
          .from('referrals')
          .update({
            compensation_terms: updateData.compensationPercentage ? 
              `${updateData.compensationPercentage}% referral fee` : undefined,
            acceptance_deadline: updateData.acceptanceDeadline,
            updated_at: new Date().toISOString(),
          })
          .eq('id', referralId)
      );

      // Execute all updates
      const results = await Promise.all(updates);

      // Check for any errors
      const errors = results.filter(r => r.error);
      if (errors.length > 0) {
        throw new Error(errors.map(e => e.error.message).join(', '));
      }

      // Get updated referral
      const { data: referral, error: getError } = await supabase
        .from('referrals')
        .select(`
          *,
          cases (*),
          non_users (*)
        `)
        .eq('id', referralId)
        .single();

      if (getError) throw getError;

      return { success: true, data: referral };
    } catch (error) {
      console.error('Error updating referral:', error);
      return {
        success: false,
        error: error.message || 'Failed to update referral',
      };
    }
  }
};