// Home2.js - Consolidated component with updated theme
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './backend/supabase';
import { 
  Home, 
  Layers, 
  Files,
  CheckSquare, 
  PieChart, 
  Users, 
  Bell, 
  HelpCircle, 
  Settings, 
  ChevronDown,
  LogOut,
  Filter,
  Search,
  MoreVertical,
  Trash2,
  Edit2,
  Bot,
  AlignJustify
} from 'lucide-react';

const SidebarItem = ({ icon: Icon, text, count, active, onClick }) => (
  <button
    onClick={onClick}
    className={`w-full flex items-center px-6 py-3 ${
      active ? 'bg-[#e2e2e2] text-black' : 'text-[#3d3731] hover:bg-[#e5e7eb]'
    } transition-colors duration-150`}
  >
    <Icon className="w-5 h-5 mr-3" />
    <span className="text-sm font-medium">{text}</span>
    {count !== undefined && (
      <span className="ml-auto bg-[#a39b8f] text-black rounded-full px-2 py-1 text-xs">{count}</span>
    )}
  </button>
);

const Home2 = () => {
  const navigate = useNavigate();
  const [showHome2, setShowHome2] = useState(false);
  const [currentView, setCurrentView] = useState('overview');
  const [searchQuery, setSearchQuery] = useState('');
  const [activeItem, setActiveItem] = useState('Home');
  const [metrics] = useState({
    outgoingReferrals: 245,
    incomingReferrals: 182,
    referralEarnings: 52300
  });

  // Sample referral data
  const referrals = [
    {
      id: 1,
      name: "Smith & Associates",
      contact: "John Smith",
      email: "john@smithlaw.com",
      status: "Active",
      progress: 75
    },
    // Add more sample data as needed
  ];

  const Sidebar = () => (
    <div className="w-64 bg-[#c9d1d3] h-screen shadow-sm flex flex-col">
      <div className="p-6 flex items-center">
        <div className="w-8 h-8 bg-black rounded mr-3 flex items-center justify-center"   onClick={() => navigate('/dashboard')}>
          <span className="text-[#e9e4dc] font-bold">L</span>
        </div>
        <h2 className="text-xl font-semibold text-[#eeeeee]">Lawdie</h2>
      </div>
      
      <nav className="flex-grow mt-2">
        <SidebarItem 
          icon={Home} 
          text={showHome2 ? "Home 2" : "Home"} 
          active={activeItem === 'Home'} 
          onClick={() => setShowHome2(!showHome2)} 
        />
        <SidebarItem icon={Files} text="Documents" active={activeItem === 'Documents'} onClick={() => {}} />
        <SidebarItem icon={Layers} text="Referrals" active={activeItem === 'Projects'} onClick={() => {}} />
        <SidebarItem icon={CheckSquare} text="Tasks" count={10} active={activeItem === 'Tasks'} onClick={() => {}} />
        <SidebarItem icon={PieChart} text="Liens" active={activeItem === 'Liens'} onClick={() => {}} />
        <SidebarItem icon={Bot} text="Kiwi AI" active={activeItem === 'Kiwi AI'} onClick={() => {}} />
      </nav>
      
      <div className="mt-auto border-t border-[#a39b8f]">
        <SidebarItem icon={Bell} text="Notifications" count={10} active={activeItem === 'Notifications'} onClick={() => {}} />
        <SidebarItem icon={HelpCircle} text="Support" active={activeItem === 'Support'} onClick={() => {}} />
        <SidebarItem icon={Settings} text="Settings" active={activeItem === 'Settings'} onClick={() => {}} />
      </div>
    </div>
  );

  const MetricsSection = () => (
    <div className="grid grid-cols-3 gap-6 mb-8">
      <div className="bg-[#e5e7eb] p-6 rounded-lg shadow">
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-sm text-[#3d3731]">Outgoing Referrals</h3>
          <MoreVertical size={20} className="text-[#a39b8f]" />
        </div>
        <div className="flex items-baseline">
          <span className="text-3xl font-bold mr-2 text-[#3d3731]">{metrics.outgoingReferrals}</span>
          <span className="text-sm text-green-500">+20%</span>
        </div>
      </div>

      <div className="bg-[#e5e7eb] p-6 rounded-lg shadow">
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-sm text-[#3d3731]">Incoming Referrals</h3>
          <MoreVertical size={20} className="text-[#a39b8f]" />
        </div>
        <div className="flex items-baseline">
          <span className="text-3xl font-bold mr-2 text-[#3d3731]">{metrics.incomingReferrals}</span>
          <span className="text-sm text-green-500">+15%</span>
        </div>
      </div>

      <div className="bg-[#e5e7eb] p-6 rounded-lg shadow">
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-sm text-[#3d3731]">Referral Earnings</h3>
          <MoreVertical size={20} className="text-[#a39b8f]" />
        </div>
        <div className="flex items-baseline">
          <span className="text-3xl font-bold mr-2 text-[#3d3731]">${metrics.referralEarnings.toLocaleString()}</span>
          <span className="text-sm text-green-500">+15%</span>
        </div>
      </div>
    </div>
  );

  const ReferralTable = () => (
    <div className="bg-[#f5f5f5] shadow rounded-lg">
      <div className="px-6 py-4 border-b border-[#d8d1c7] flex justify-between items-center">
        <div className="flex space-x-4">
          {['Overview', 'Table', 'List view', 'Segment', 'Custom'].map((view) => (
            <button
              key={view}
              onClick={() => setCurrentView(view.toLowerCase().replace(' ', ''))}
              className={`px-3 py-2 text-sm font-medium ${
                currentView === view.toLowerCase().replace(' ', '')
                  ? 'border-b-2 border-black text-[#3d3731]'
                  : 'text-[#a39b8f] hover:text-[#3d3731]'
              }`}
            >
              {view}
            </button>
          ))}
        </div>
      </div>

      <div className="px-6 py-4 flex justify-between items-center">
        <div className="flex space-x-2">
          <span className="inline-flex items-center px-3 py-0.5 rounded-md text-sm font-medium bg-[#caced6] text-[#3d3731]">
            All time
          </span>
          <span className="inline-flex items-center px-3 py-0.5 rounded-md text-sm font-medium bg-[#caced6] text-[#3d3731]">
            US, AU, +4
          </span>
          <button className="inline-flex items-center px-3 py-0.5 rounded-md text-sm font-medium bg-[#caced6] text-[#3d3731] hover:bg-[#e5e7eb]">
            More filters
            <ChevronDown size={16} className="ml-1" />
          </button>
        </div>
        <div className="relative">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search"
            className="focus:ring-[#a39b8f] focus:border-[#a39b8f] block w-full pl-10 pr-3 py-2 border border-[#a39b8f] rounded-md leading-5 bg-[#e5e7eb] placeholder-[#a39b8f] focus:outline-none focus:placeholder-[#c5beb2] sm:text-sm"
          />
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search size={20} className="text-[#a39b8f]" />
          </div>
        </div>
      </div>

      <div className="px-6 py-4">
        <table className="min-w-full divide-y divide-[#d8d1c7]">
          <thead className="bg-[#caced6]">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-[#3d3731] uppercase tracking-wider">Firm</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-[#3d3731] uppercase tracking-wider">Contact</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-[#3d3731] uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-[#3d3731] uppercase tracking-wider">Progress</th>
              <th className="relative px-6 py-3"><span className="sr-only">Actions</span></th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-[#d8d1c7]">
            {referrals.map((referral) => (
              <tr key={referral.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-[#3d3731]">{referral.name}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-[#3d3731]">{referral.contact}</div>
                  <div className="text-sm text-[#a39b8f]">{referral.email}</div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className="px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-[#caced6] text-[#3d3731]">
                    {referral.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="w-full bg-[#e5e7eb] rounded-full h-2">
                    <div className="bg-[#3d3731] h-2 rounded-full" style={{ width: `${referral.progress}%` }}></div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button className="text-[#a39b8f] hover:text-[#3d3731]">
                    <Edit2 size={16} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <div className="flex h-screen bg-[#c9d1d3] overflow-hidden">
      <Sidebar />
      <div className="flex-1 overflow-auto bg-[#f5f5f5]">
        <div className="py-8 px-10">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-2xl font-semibold text-[#3d3731]">Referrals</h1>
            <div className="flex space-x-3">
              <button className="px-4 py-2 border border-[#a39b8f] rounded-md text-[#3d3731] bg-[#caced6] hover:bg-[#e5e7eb]">
                <span className="mr-2">↓</span> Import
              </button>
              <button className="px-4 py-2 border border-transparent rounded-md shadow-sm text-[#e9e4dc] bg-black hover:bg-[#3d3731]">
                + Add Referral
              </button>
            </div>
          </div>

          <MetricsSection />
          <ReferralTable />
        </div>
      </div>
    </div>
  );
};

export default Home2;