// gmailService.js
import axiosInstance from '../axiosInstance';
import { supabase } from './supabase';

export const emailService = {
    // Get Gmail authentication URL
    async getGmailAuthUrl() {
        try {
            const response = await axiosInstance.get('/api/auth/gmail/url');
            return response.data.url;
        } catch (error) {
            console.error('Error getting Gmail auth URL:', error);
            throw error;
        }
    },

    // Exchange auth code for tokens
    async exchangeGmailCode(code) {
        try {
            const { data: { session } } = await supabase.auth.getSession();
            if (!session) {
                throw new Error('No active session');
            }

            const response = await axiosInstance.post('/api/auth/gmail/exchange-code', 
                { code },
                {
                    headers: {
                        'Authorization': `Bearer ${session.access_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error exchanging Gmail code:', error);
            throw error;
        }
    },

    // Send email
    async sendEmail({ to, subject, body }) {
        try {
            const { data: { session } } = await supabase.auth.getSession();
            if (!session) {
                throw new Error('No active session');
            }

            const response = await axiosInstance.post('/api/email/send', 
                { to, subject, body },
                {
                    headers: {
                        'Authorization': `Bearer ${session.access_token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error sending email:', error);
            throw error;
        }
    }
};