import { supabase } from './supabase';

export const publicNetworkService = {
  fetchAttorneys: async (page, itemsPerPage) => {
    try {
      // Get total count first
      const { count, error: countError } = await supabase
        .from('lawyer_profiles')
        .select('*, users!inner(*)', { count: 'exact', head: true })
        .neq('firm_name', null)
        .eq('users.is_public', true);

      if (countError) throw countError;

      // Calculate pagination range
      const from = (page - 1) * itemsPerPage;
      const to = from + itemsPerPage - 1;

      // Fetch paginated data
      const { data, error } = await supabase
        .from('lawyer_profiles')
        .select(`
          firm_name,
          users!inner (
            id,
            first_name,
            last_name,
            email,
            phone,
            is_public,
            is_active
          )
        `)
        .neq('firm_name', null)
        .eq('users.is_public', true)
        .order('firm_name', { ascending: true })
        .range(from, to);

      if (error) throw error;

      // Format the data
      const formattedData = data.map(profile => ({
        id: profile.users.id,
        first_name: profile.users.first_name,
        last_name: profile.users.last_name,
        email: profile.users.email,
        phone: profile.users.phone,
        is_active: profile.users.is_active,
        firm_name: profile.firm_name,
        is_favorite: false // Default state, will be updated by component
      }));

      return {
        success: true,
        data: formattedData,
        totalCount: count
      };

    } catch (error) {
      console.error('Error fetching attorneys:', error);
      return {
        success: false,
        error: error.message || 'Failed to fetch attorneys'
      };
    }
  }
};