import React, { useState, useEffect } from 'react';
import { WebViewer } from '@pdftron/webviewer';
import { Document, Page } from 'react-pdf';
import { FileText, Save, Download } from 'lucide-react';
import { documentService } from '../../backend/documentService';
import { versionService } from './document-git/versionService';

const DocumentEditor = ({ document, onSave }) => {
  const [fileType, setFileType] = useState(null);
  const [viewer, setViewer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const viewerRef = React.useRef(null);

  useEffect(() => {
    if (!document) return;

    const initializeViewer = async () => {
      try {
        setLoading(true);
        const type = document.file_name.split('.').pop().toLowerCase();
        setFileType(type);

        if (type === 'pdf') {
          const instance = await WebViewer(
            {
              path: '/webviewer/lib',
              initialDoc: document.url,
              licenseKey: process.env.REACT_APP_PDFTRON_LICENSE_KEY,
            },
            viewerRef.current
          );

          // Enable editing features
          const { docViewer, annotManager } = instance;
          docViewer.on('documentLoaded', () => {
            // Enable all annotations
            annotManager.enableAnnotations();
          });

          setViewer(instance);
        }

        setLoading(false);
      } catch (err) {
        setError('Failed to initialize document viewer: ' + err.message);
        setLoading(false);
      }
    };

    initializeViewer();
  }, [document]);

  const handleSave = async () => {
    try {
      if (fileType === 'pdf' && viewer) {
        const { docViewer, annotManager } = viewer;
        const doc = docViewer.getDocument();
        
        // Get the modified document as blob
        const data = await doc.getFileData({
          // Include annotations in saved file
          includeAnnotations: true,
          // Flatten annotations to prevent editing in other viewers
          flatten: true
        });

        // Create new version
        const blob = new Blob([data], { type: 'application/pdf' });
        await onSave(blob);
      }
    } catch (err) {
      setError('Failed to save document: ' + err.message);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-black"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-full text-red-500">
        {error}
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      {/* Toolbar */}
      <div className="bg-white border-b border-[#d8d1c7] p-4 flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <FileText className="w-5 h-5 text-[#3d3731]" />
          <span className="font-medium text-[#3d3731]">{document?.file_name}</span>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-black text-white rounded-md hover:bg-[#3d3731] flex items-center"
          >
            <Save className="w-4 h-4 mr-2" />
            Save Changes
          </button>
        </div>
      </div>

      {/* Editor */}
      <div className="flex-1 bg-[#f5f1eb] overflow-hidden">
        {fileType === 'pdf' ? (
          <div ref={viewerRef} className="h-full"></div>
        ) : (
          <div className="flex items-center justify-center h-full">
            <p className="text-[#3d3731]">
              Editing not supported for this file type
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentEditor;