// GmailCallback.js
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { emailService } from './backend/gmailService';
import { supabase } from './backend/supabase';
import { Loader2 } from 'lucide-react';

const GmailCallback = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleCallback = async () => {
            try {
                const searchParams = new URLSearchParams(location.search);
                const code = searchParams.get('code');

                if (!code) {
                    throw new Error('No authorization code received');
                }

                await emailService.exchangeGmailCode(code);

                // Navigate back to the chat with state and replace to prevent refresh
                navigate('/kiwi-ai', { 
                    state: { 
                        gmailAuthSuccess: true,
                    },
                    replace: true  // Use replace instead of push to prevent refresh
                });
            } catch (error) {
                console.error('Error handling Gmail callback:', error);
                navigate('/kiwi-ai', { 
                    state: { 
                        gmailAuthError: 'Failed to authenticate with Gmail' 
                    },
                    replace: true
                });
            }
        };

        handleCallback();
    }, [location, navigate]);

    return (
        <div className="flex items-center justify-center min-h-screen bg-[#34261E]">
            <div className="text-white text-center">
                <h2 className="text-xl mb-4">Connecting to Gmail...</h2>
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white mx-auto">
                    <Loader2 size={32} />
                </div>
            </div>
        </div>
    );
};

export default GmailCallback;