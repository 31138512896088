import React, { useState, useEffect, useRef } from 'react';
import { Trash2, Edit2, Check, AlertCircle, Loader2, X } from 'lucide-react';
import { referralStatusService } from '../../backend/referralServices/referralStatusService';
import ReferralDetailView from '../referrals/ReferralDetailView';

// Delete Confirmation Dialog Component
const DeleteConfirmDialog = ({ isOpen, onClose, onConfirm, loading }) => {
  if (!isOpen) return null;

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black/50 backdrop-blur-[2px] flex items-center justify-center z-[1000]"
      onClick={handleBackgroundClick}
    >
      <div className="bg-[#e9e4dc] p-6 rounded-lg max-w-md w-full" onClick={e => e.stopPropagation()}>
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <AlertCircle className="w-6 h-6 text-red-500 mr-2" />
            <h3 className="text-lg font-semibold text-black">Confirm Deletion</h3>
          </div>
          <button onClick={onClose} className="text-[#3d3731] hover:text-black">
            <X className="w-5 h-5" />
          </button>
        </div>
        
        <p className="text-[#3d3731] mb-6">
          Are you sure you want to delete this referral? This action cannot be undone.
        </p>
        
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            disabled={loading}
            className="px-4 py-2 text-[#3d3731] bg-[#d8d1c7] border border-[#a39b8f] rounded-md hover:bg-[#c5beb2] transition-colors duration-300"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            disabled={loading}
            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 flex items-center transition-colors duration-300"
          >
            {loading ? (
              <>
                <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                Deleting...
              </>
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export const CustomerRow = ({ 
  id,
  logo,
  name: firmName,
  status: initialStatus,
  contact,
  email,
  phone,
  position,
  progress,
  case_id,
  non_user_id,
  caseDetails,
  compensationTerms,
  acceptanceDeadline,
  onStatusUpdate,
  onRefresh,
  selected,
  onSelect,
  onClick
}) => {
  // State management
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showDetailView, setShowDetailView] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(initialStatus);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [error, setError] = useState(null);
  
  // Refs for event handling and timeouts
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  const errorTimeoutRef = useRef(null);
  const transitionTimeoutRef = useRef(null);

  // Available status options
  const statuses = [
    'pending',
    'received',
    'viewed',
    'renegotiating',
    'accepted',
    'rejected',
    'expired',
    'litigation',
    'billing',
    'completed'
  ];

  // Cleanup effect for listeners and timeouts
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && 
          !dropdownRef.current.contains(event.target) && 
          !buttonRef.current?.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      if (errorTimeoutRef.current) clearTimeout(errorTimeoutRef.current);
      if (transitionTimeoutRef.current) clearTimeout(transitionTimeoutRef.current);
    };
  }, []);

  // Handle delete action
  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this referral?')) return;
    
    setDeleteLoading(true);
    setError(null);
    try {
      const result = await referralStatusService.deleteReferral(id);
      if (!result.success) throw new Error(result.error);
      if (onRefresh) await onRefresh();
    } catch (error) {
      console.error('Error deleting referral:', error);
      setError(error.message);
      
      errorTimeoutRef.current = setTimeout(() => {
        setError(null);
      }, 5000);
    } finally {
      setDeleteLoading(false);
    }
  };

  // Handle status updates with optimistic UI
  const handleStatusUpdate = async (newStatus) => {
    if (isTransitioning) return;

    setIsTransitioning(true);
    const previousStatus = currentStatus;
    setCurrentStatus(newStatus); // Optimistic update
    setIsOpen(false);
    
    try {
      const result = await referralStatusService.updateStatus(id, newStatus);
      if (!result.success) throw new Error(result.error);
      if (onStatusUpdate) onStatusUpdate(id, newStatus);
    } catch (error) {
      console.error('Error updating status:', error);
      setError(error.message);
      setCurrentStatus(previousStatus); // Rollback on error

      errorTimeoutRef.current = setTimeout(() => {
        setError(null);
      }, 5000);
    } finally {
      transitionTimeoutRef.current = setTimeout(() => {
        setIsTransitioning(false);
      }, 300);
    }
  };

  // Status style mapping
  const getStatusStyle = (status) => {
    const styles = {
      pending: 'bg-[#d8d1c7] text-[#3d3731]',
      received: 'bg-[#c5beb2] text-[#3d3731]',
      viewed: 'bg-[#c5beb2] text-[#3d3731]',
      renegotiating: 'bg-[#a39b8f] text-[#e9e4dc]',
      accepted: 'bg-[#3d3731] text-[#e9e4dc]',
      rejected: 'bg-red-200 text-red-800',
      expired: 'bg-[#a39b8f] text-[#e9e4dc]',
      litigation: 'bg-[#3d3731] text-[#e9e4dc]',
      billing: 'bg-[#3d3731] text-[#e9e4dc]',
      completed: 'bg-black text-white'
    };
    return styles[status.toLowerCase()] || styles.pending;
  };

  // Handle dropdown positioning
  const handleToggleDropdown = () => {
    if (!isOpen && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;
      const dropdownHeight = 320;
      
      const showAbove = spaceBelow < dropdownHeight && spaceAbove > spaceBelow;
      
      setDropdownPosition({
        left: rect.left + scrollLeft,
        top: showAbove 
          ? rect.top + scrollTop - dropdownHeight - 8
          : rect.bottom + scrollTop + 8
      });
    }
    setIsOpen(!isOpen);
  };

  // Calculate progress based on current status
  const currentProgress = (() => {
    const statusProgress = {
      pending: 25,
      received: 40,
      viewed: 50,
      renegotiating: 65,
      accepted: 75,
      litigation: 85,
      billing: 95,
      completed: 100,
      rejected: 0,
      expired: 0
    };
    return statusProgress[currentStatus.toLowerCase()] || progress;
  })();

  const handleRowClick = (e) => {
    if (
      e.target.closest('.status-dropdown') ||
      e.target.closest('.action-buttons') ||
      e.target.closest('input[type="checkbox"]')
    ) {
      return;
    }
    setShowDetailView(true);
  };

  return (
    <React.Fragment>
      {/* Delete Confirmation Dialog */}
      {showDeleteConfirm && (
        <DeleteConfirmDialog
          isOpen={showDeleteConfirm}
          onClose={() => setShowDeleteConfirm(false)}
          onConfirm={handleDelete}
          loading={deleteLoading}
        />
      )}

      {/* Detail View */}
      {showDetailView && (
        <ReferralDetailView
          referral={{
            id,
            name: firmName,
            status: currentStatus,
            contact,
            email,
            phone,
            case_id,
            non_user_id,
            caseDetails,
            compensationTerms,
            acceptanceDeadline
          }}
          isOpen={showDetailView}
          onClose={() => setShowDetailView(false)}
          onRefresh={onRefresh}
          type="outgoing"
        />
      )}

      {/* Main Row */}
      <tr 
        className="bg-[#f5f1eb] border-b border-[#d8d1c7] cursor-pointer hover:bg-[#e9e4dc] transition-colors duration-150"
        onClick={handleRowClick}
      >
        <td className="px-6 py-4">
          <input 
            type="checkbox" 
            checked={selected}
            onChange={(e) => {
              e.stopPropagation();
              onSelect(id, e.target.checked);
            }}
            className="h-4 w-4 text-[#3d3731] focus:ring-[#a39b8f] border-[#c5beb2] rounded" 
          />
        </td>
        <td className="px-6 py-4">
          <div className="flex items-center">
            <div className="w-10 h-10 rounded-full bg-[#3d3731] text-[#e9e4dc] text-sm flex items-center justify-center">
              {contact?.split(' ').map(name => name[0]).join('')}
            </div>
            <div className="ml-4">
              <div className="text-sm font-medium text-black">{firmName}</div>
            </div>
          </div>
        </td>
        <td className="px-6 py-4">
          <div className="relative status-dropdown">
            <button
              ref={buttonRef}
              onClick={(e) => {
                e.stopPropagation();
                if (!isTransitioning) {
                  handleToggleDropdown();
                }
              }}
              disabled={isTransitioning}
              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full transition-all duration-300 ease-in-out ${getStatusStyle(currentStatus)} ${isTransitioning ? 'opacity-75 transform scale-95' : ''}`}
            >
              {currentStatus}
            </button>
            
            {isOpen && (
              <div
                ref={dropdownRef}
                style={{
                  position: 'fixed',
                  left: dropdownPosition.left,
                  top: dropdownPosition.top,
                  width: '12rem'
                }}
                className="rounded-lg bg-white shadow-lg border border-[#d8d1c7] z-[1000] max-h-80 overflow-y-auto"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="py-1">
                  {statuses.map((statusOption) => (
                    <button
                      key={statusOption}
                      onClick={() => handleStatusUpdate(statusOption)}
                      disabled={isTransitioning}
                      className="w-full flex items-center px-4 py-2 text-sm hover:bg-[#f5f1eb] transition-colors duration-150"
                    >
                      <span className={`flex-1 px-2 py-0.5 rounded-full text-xs font-semibold transition-all duration-300 ${getStatusStyle(statusOption)}`}>
                        {statusOption}
                      </span>
                      {statusOption === currentStatus.toLowerCase() && (
                        <Check className="w-4 h-4 ml-2 text-[#3d3731]" />
                      )}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </td>
        <td className="px-6 py-4">
          <div className="text-sm text-[#3d3731]">{contact}</div>
          <div className="text-sm text-[#a39b8f]">{email}</div>
          <div className="text-sm text-[#a39b8f]">{phone}</div>
        </td>
        <td className="px-6 py-4">
          <div className="w-full bg-[#d8d1c7] rounded-full h-2.5">
            <div 
              className="bg-[#3d3731] h-2.5 rounded-full transition-all duration-300" 
              style={{ width: `${currentProgress}%` }}
            />
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium action-buttons">
          <div className="flex items-center justify-end space-x-2">
            <button 
              onClick={(e) => {
                e.stopPropagation();
                if (!isTransitioning) setShowDeleteConfirm(true);
              }}
              disabled={isTransitioning}
              className="text-[#a39b8f] hover:text-red-600 transition-colors duration-300 disabled:opacity-50"
            ><Trash2 size={20} />
            </button>
            <button 
              onClick={(e) => {
                e.stopPropagation();
                setShowDetailView(true);
              }}
              disabled={isTransitioning}
              className="text-[#a39b8f] hover:text-[#3d3731] transition-colors duration-300 disabled:opacity-50"
            >
              <Edit2 size={20} />
            </button>
          </div>
        </td>
      </tr>
  
      {/* Error Message */}
      {error && (
        <tr>
          <td colSpan={6}>
            <div className="m-4 p-3 bg-red-50 border border-red-200 rounded-md flex items-center text-red-700 transition-opacity duration-300">
              <AlertCircle className="w-5 h-5 mr-2" />
              {error}
            </div>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};
  
export default CustomerRow;