import { supabase } from './supabase';

export const createLien = async (lienData) => {
  try {
    const { data: authUser } = await supabase.auth.getUser();
    
    if (!authUser?.user?.id) {
      throw new Error('User not authenticated');
    }

    const lienRecord = {
      created_by: authUser.user.id,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      lienholder_name: lienData.lienholderName,
      amount: parseInt(lienData.amount),
      type: lienData.type,
      debtor_name: lienData.debtor,
      filing_date: lienData.filingDate,
      expiry_date: lienData.expiryDate,
      property_address: lienData.propertyAddress,
      description: lienData.description || null
    };

    const { data, error } = await supabase
      .from('liens')
      .insert([lienRecord])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error creating lien:', error);
    throw error;
  }
};

export const getLiens = async () => {
  try {
    const { data: authUser } = await supabase.auth.getUser();
    
    if (!authUser?.user?.id) {
      throw new Error('User not authenticated');
    }
    
    const { data, error } = await supabase
      .from('liens')
      .select('*')
      .eq('created_by', authUser.user.id)
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching liens:', error);
    throw error;
  }
};

export const getLienById = async (id) => {
  try {
    const { data, error } = await supabase
      .from('liens')
      .select('*')
      .eq('id', id)
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error fetching lien:', error);
    throw error;
  }
};

export const updateLien = async (lienId, lienData) => {
  try {
    const { data, error } = await supabase
      .from('liens')
      .update(lienData)
      .eq('id', lienId)
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error updating lien:', error);
    throw error;
  }
};

export const deleteLien = async (id) => {
  try {
    const { error } = await supabase
      .from('liens')
      .delete()
      .eq('id', id);

    if (error) throw error;
    return true;
  } catch (error) {
    console.error('Error deleting lien:', error);
    throw error;
  }
};

export const updateLienStatus = async (lienId, status) => {
  try {
    const { data, error } = await supabase
      .from('liens')
      .update({ status: status })
      .eq('id', lienId)
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error updating lien status:', error);
    throw error;
  }
};