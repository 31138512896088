// src/components/documents/DocumentDashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Upload, 
  Grid, 
  List,
  Search,
  FileText 
} from 'lucide-react';
import { documentService } from '../../backend/documentService';
import { versionService } from './document-git/versionService';

const DocumentDashboard = () => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [viewType, setViewType] = useState('grid');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const { data, error } = await documentService.getDocuments();
      if (error) throw error;
      setDocuments(data || []);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      // Upload document
      const { data: doc, error } = await documentService.uploadDocument(file);
      if (error) throw error;

      // Create initial version
      await versionService.createInitialVersion(doc.id, {
        fileName: doc.file_name,
        fileType: doc.file_type,
        fileSize: doc.file_size,
        uploadedAt: doc.created_at
      });

      // Add to list
      setDocuments(prev => [doc, ...prev]);
    } catch (err) {
      setError('Upload failed: ' + err.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="min-h-screen bg-[#f5f1eb]">
      {/* Header */}
      <div className="bg-[#e9e4dc] border-b border-[#a39b8f] p-4">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold text-black">Documents</h1>
          <div className="flex items-center space-x-4">
            <div className="relative">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search documents..."
                className="w-64 pl-10 pr-4 py-2 bg-white border border-[#d8d1c7] rounded-md"
              />
              <Search className="w-4 h-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-[#a39b8f]" />
            </div>
            
            <div className="flex items-center space-x-2">
              <button 
                onClick={() => setViewType('grid')}
                className={`p-2 rounded-md ${viewType === 'grid' ? 'bg-[#d8d1c7]' : ''}`}
              >
                <Grid className="w-4 h-4" />
              </button>
              <button
                onClick={() => setViewType('list')}
                className={`p-2 rounded-md ${viewType === 'list' ? 'bg-[#d8d1c7]' : ''}`}
              >
                <List className="w-4 h-4" />
              </button>
              
              <label className="px-4 py-2 bg-black text-white rounded-md hover:bg-[#3d3731] cursor-pointer">
                <Upload className="w-4 h-4 mr-2 inline-block" />
                Upload
                <input
                  type="file"
                  onChange={handleUpload}
                  className="hidden"
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* Document Grid/List */}
      <div className="p-6">
        {viewType === 'grid' ? (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {documents.map(doc => (
              <div
                key={doc.id}
                onClick={() => navigate(`/documents/${doc.id}`)}
                className="bg-white p-4 rounded-lg border border-[#d8d1c7] hover:shadow-md cursor-pointer"
              >
                <div className="flex items-center mb-4">
                  <FileText className="w-8 h-8 text-[#3d3731]" />
                  <div className="ml-3">
                    <h3 className="font-medium text-black">{doc.file_name}</h3>
                    <p className="text-sm text-[#a39b8f]">
                      Version {doc.version_count || 1}
                    </p>
                  </div>
                </div>
                <div className="text-sm text-[#a39b8f]">
                  <p>Size: {(doc.file_size / 1024 / 1024).toFixed(2)} MB</p>
                  <p>Updated: {new Date(doc.updated_at).toLocaleDateString()}</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="bg-white rounded-lg border border-[#d8d1c7]">
            <table className="w-full">
              <thead>
                <tr className="border-b border-[#d8d1c7]">
                  <th className="text-left p-4">Name</th>
                  <th className="text-left p-4">Version</th>
                  <th className="text-left p-4">Size</th>
                  <th className="text-left p-4">Last Updated</th>
                </tr>
              </thead>
              <tbody>
                {documents.map(doc => (
                  <tr
                    key={doc.id}
                    onClick={() => navigate(`/documents/${doc.id}`)}
                    className="border-b border-[#d8d1c7] hover:bg-[#f5f1eb] cursor-pointer"
                  >
                    <td className="p-4">
                      <div className="flex items-center">
                        <FileText className="w-5 h-5 mr-3 text-[#3d3731]" />
                        {doc.file_name}
                      </div>
                    </td>
                    <td className="p-4">Version {doc.version_count || 1}</td>
                    <td className="p-4">
                      {(doc.file_size / 1024 / 1024).toFixed(2)} MB
                    </td>
                    <td className="p-4">
                      {new Date(doc.updated_at).toLocaleDateString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentDashboard;