// referralStatusService.js
import { supabase } from '../supabase';

export const referralStatusService = {
  async updateStatus(referralId, newStatus) {
    try {
      const { data, error } = await supabase
        .from('referrals')
        .update({ 
          status: newStatus,
          updated_at: new Date().toISOString()
        })
        .eq('id', referralId)
        .select()
        .single();

      if (error) throw error;

      return { success: true, data };
    } catch (error) {
      console.error('Error updating referral status:', error);
      return {
        success: false,
        error: error.message || 'Failed to update status'
      };
    }
  }
};