// src/axiosInstance.js
import axios from "axios";

// Separate base URLs for each service
const MAIN_API_URL = process.env.NODE_ENV === 'production'
  ? "https://api.lawdie.co"  // Production API endpoint
  : "http://localhost:4000"; // Development endpoint

const EMAIL_API_URL = process.env.NODE_ENV === 'production'
  ? "https://api.lawdie.co"  // Production API endpoint
  : "http://localhost:4001"; // Development endpoint

// Helper to determine the correct base URL based on the request
const getBaseURL = (config) => {
  // Check if the request is going to the email service
  if (config?.url?.includes('/api/send-email') || 
      config?.url?.includes('/api/send-referral-email')) {
    return EMAIL_API_URL;
  }
  return MAIN_API_URL;
};

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "Accept": "text/event-stream"
  },
  withCredentials: true
});

// Add request interceptor to dynamically set base URL
axiosInstance.interceptors.request.use(
  async config => {
    // Set the appropriate base URL
    config.baseURL = getBaseURL(config);
    return config;
  },
  error => {
    console.error('Request setup error:', error);
    return Promise.reject(error);
  }
);

// Add response interceptor for error handling
axiosInstance.interceptors.response.use(
  response => {
    // Log successful responses in development
    if (process.env.NODE_ENV !== 'production') {
      console.log('Response received:', {
        url: response.config.url,
        status: response.status,
        data: response.data
      });
    }
    return response;
  },
  error => {
    if (error.response) {
      // Server responded with non 2xx
      console.error('Response error:', {
        url: error.config?.url,
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers
      });
    } else if (error.request) {
      // Request made but no response
      console.error('Request error:', {
        url: error.config?.url,
        method: error.config?.method,
        error: error.request
      });
    } else {
      // Request setup error
      console.error('Error:', {
        message: error.message,
        config: error.config
      });
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;