import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Mail, ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';
import Footer from './components/landing/Footer';
import FloatingCalendlyButton from './components/landing/FloatingButton';

const ComingSoonPage = () => {
  const navigate = useNavigate();
  const [isBlackAndWhite, setIsBlackAndWhite] = useState(false);
  const canvasRef = useRef(null);
  const darkBrownGradientRef = useRef(null);
  const lightBrownGradientRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    let mouse = { x: 0, y: 0 };

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      canvas.style.width = '100%';
      canvas.style.height = '100%';
    };

    const createGrid = () => {
      const gridSize = 30;
      const rows = Math.ceil(canvas.height / gridSize);
      const cols = Math.ceil(canvas.width / gridSize);
      const grid = [];

      for (let y = 0; y <= rows; y++) {
        for (let x = 0; x <= cols; x++) {
          grid.push({
            x: x * gridSize,
            y: y * gridSize,
            baseX: x * gridSize,
            baseY: y * gridSize,
          });
        }
      }

      return { grid, rows, cols };
    };

    let { grid, rows, cols } = createGrid();

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.beginPath();
      ctx.strokeStyle = isBlackAndWhite ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.1)';
      ctx.lineWidth = 0.5;

      for (let i = 0; i < grid.length; i++) {
        const point = grid[i];
        const distX = mouse.x - point.baseX;
        const distY = mouse.y - point.baseY;
        const distance = Math.sqrt(distX * distX + distY * distY);
        const maxDistance = 150;

        if (distance < maxDistance) {
          const force = (1 - distance / maxDistance) * 0.2;
          point.x = point.baseX - distX * force;
          point.y = point.baseY - distY * force;
        } else {
          point.x += (point.baseX - point.x) * 0.1;
          point.y += (point.baseY - point.y) * 0.1;
        }

        if (i < grid.length - 1 && (i + 1) % (cols + 1) !== 0) {
          ctx.moveTo(point.x, point.y);
          ctx.lineTo(grid[i + 1].x, grid[i + 1].y);
        }

        if (i + cols + 1 < grid.length) {
          ctx.moveTo(point.x, point.y);
          ctx.lineTo(grid[i + cols + 1].x, grid[i + cols + 1].y);
        }
      }

      ctx.stroke();
      animationFrameId = requestAnimationFrame(animate);
    };

    const handleMouseMove = (event) => {
      mouse.x = event.clientX;
      mouse.y = event.clientY;
    };

    const handleResize = () => {
      resizeCanvas();
      ({ grid, rows, cols } = createGrid());
    };

    resizeCanvas();
    window.addEventListener('resize', handleResize);
    window.addEventListener('mousemove', handleMouseMove);
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', handleMouseMove);
      cancelAnimationFrame(animationFrameId);
    };
  }, [isBlackAndWhite]);

  const toggleBlackAndWhite = () => {
    setIsBlackAndWhite(!isBlackAndWhite);
  };

  const baseStyles = "transition-all duration-300";
  const colorStyles = isBlackAndWhite 
    ? "bg-white text-black" 
    : "bg-black text-white";

  return (
    <div className={`min-h-screen flex flex-col relative overflow-hidden ${baseStyles} ${colorStyles}`}>
      <canvas 
        ref={canvasRef} 
        className="fixed inset-0 pointer-events-none"
        style={{ zIndex: 1 }}
      />

      {!isBlackAndWhite && (
        <>
          <div
            ref={darkBrownGradientRef}
            className="fixed -top-1/4 -right-1/4 w-2/3 h-2/3 bg-[#964B00] rounded-full filter blur-[100px] opacity-30 origin-center"
            style={{ zIndex: 2 }}
          />
          <div
            ref={lightBrownGradientRef}
            className="fixed -bottom-1/3 -left-1/4 w-3/4 h-3/4 bg-[#FFC499] rounded-full filter blur-[100px] opacity-30 origin-center"
            style={{ zIndex: 2 }}
          />
        </>
      )}

      <div className="relative z-10 flex flex-col min-h-screen">
        <header className="p-6 flex justify-between items-center">
          <div className="flex items-center">
            <span className="text-xl font-bold cursor-pointer" onClick={toggleBlackAndWhite}>
              Lawdie
            </span>
          </div>
          <nav>
            <ul className="flex space-x-6 items-center">
              <li>
                <Link to="/" className="hover:underline">Home</Link>
              </li>
              <li>
                <Link to="/company" className="hover:underline">Company</Link>
              </li>
              <li>
                <Link to="#" className="hover:underline">
                  Lawyer Search{' '}
                  <span className={isBlackAndWhite 
                    ? "bg-gradient-to-r from-gray-600 to-gray-400 text-transparent bg-clip-text" 
                    : "bg-gradient-to-r from-[#FFC499] to-[#FFC499] text-transparent bg-clip-text"
                  }>(BETA)</span>
                </Link>
              </li>
              <li>
                <Link 
                  to="/signup"
                  className={`border px-4 py-1 rounded-full hover:bg-opacity-80 transition duration-300 ${
                    isBlackAndWhite ? "border-black hover:bg-gray-200" : "border-white hover:bg-white hover:text-black"
                  }`}
                >
                  Login
                </Link>
              </li>
            </ul>
          </nav>
        </header>

        <main className="flex-grow flex flex-col items-center justify-center px-4 py-16">
          <div className="text-center max-w-4xl mx-auto">
            <h1 className="text-8xl font-bold mb-8 flex items-center justify-center gap-4">
              <span>Coming</span>
              <span className={`bg-gradient-to-r ${
                isBlackAndWhite 
                  ? "from-gray-700 via-gray-500 to-gray-300" 
                  : "from-[#5D341A] to-[#FFC499]"
              } text-transparent bg-clip-text`}>Soon</span>
            </h1>
            
            <p className={`text-xl mb-12 ${isBlackAndWhite ? "text-gray-600" : "text-gray-400"}`}>
              We're working hard to bring you something amazing. Be the first to know when we launch!
            </p>

            <div className="flex flex-col sm:flex-row items-center justify-center gap-4 max-w-3xl mx-auto">
              <input
                type="email"
                placeholder="Email"
                className={`w-full sm:w-[70%] px-8 py-4 rounded-full focus:outline-none ${
                  isBlackAndWhite 
                    ? "bg-gray-100 text-black border border-gray-300" 
                    : "bg-gray-800 text-white border border-gray-700"
                }`}
              />
              <button
                className={`w-full sm:w-[30%] px-8 py-4 rounded-full font-medium whitespace-nowrap ${
                  isBlackAndWhite 
                    ? "bg-black text-white hover:bg-gray-800" 
                    : "bg-[#FFC499] text-black hover:bg-[#FFB479]"
                } transition-colors duration-300`}
              >
                JOIN NEWSLETTER
              </button>
            </div>
          </div>

          <div className="mt-24 w-full max-w-6xl mx-auto px-6">
            <h2 className={`text-4xl font-bold text-center mb-4 ${
              isBlackAndWhite ? "text-gray-800" : "text-white"
            }`}>We Offer</h2>
            <p className={`text-center mb-12 ${
              isBlackAndWhite ? "text-gray-600" : "text-gray-400"
            }`}>
              The legal search process doesn't need to be complicated. Let us help!
            </p>

            <div className={`grid md:grid-cols-2 gap-8 ${
            isBlackAndWhite ? "text-gray-800" : "text-gray-300"
          }`}>
            <div className={`p-8 rounded-lg ${
              isBlackAndWhite 
                ? "bg-gray-100" 
                : "bg-[#1a1410] border border-[#2a2118]"
            }`}>
              <h3 className="text-xl font-bold mb-4">Revolutionized Legal Search</h3>
              <p className={isBlackAndWhite ? "text-gray-600" : "text-gray-400"}>
                Search for vetted, honest, and effective attorneys with options to compare, insuring to multiple with ease, and more.
              </p>
            </div>
            <div className={`p-8 rounded-lg ${
              isBlackAndWhite 
                ? "bg-gray-100" 
                : "bg-[#1a1410] border border-[#2a2118]"
            }`}>
              <h3 className="text-xl font-bold mb-4">Class Action Inquiries</h3>
              <p className={isBlackAndWhite ? "text-gray-600" : "text-gray-400"}>
                Have an issue? Your not alone. Talk about your problem and join with others experiencing the same issue to file a class action.
              </p>
            </div>
          </div>
          </div>
        </main>

        <Footer isBlackAndWhite={isBlackAndWhite} />
        <FloatingCalendlyButton isBlackAndWhite={isBlackAndWhite} />
      </div>
    </div>
  );
};

export default ComingSoonPage;