import React from 'react';
import { Lock } from 'lucide-react';

export const BarVerification = ({ formData = {}, onChange, errors = {} }) => {
  // Format zip code as user types
  const handleZipChange = (e) => {
    const zip = e.target.value.replace(/\D/g, '').slice(0, 5);
    onChange({
      target: {
        name: 'firmZip',
        value: zip
      }
    });
  };

  return (
    <div className="w-full space-y-6">
      {/* Firm Name */}
      <div className="relative">
        <label className="absolute -top-2.5 left-4 px-2 bg-[#fef6ef] text-gray-500 text-sm">
          Firm Name
        </label>
        <input
          type="text"
          name="firmName"
          value={formData.firmName || ''}
          onChange={onChange}
          placeholder="Enter firm name"
          className={`w-full px-4 py-3 bg-transparent border ${errors.firmName ? 'border-red-500' : 'border-gray-300'} rounded-full 
            focus:outline-none focus:border-gray-400
            placeholder-gray-400 text-gray-800`}
        />
        {errors.firmName && (
          <p className="text-red-500 text-xs mt-1 ml-4">{errors.firmName}</p>
        )}
      </div>

      {/* Firm Address */}
      <div className="relative">
        <label className="absolute -top-2.5 left-4 px-2 bg-[#fef6ef] text-gray-500 text-sm">
          Firm Address
        </label>
        <input
          type="text"
          name="office_address"
          value={formData.office_address || ''}
          onChange={onChange}
          placeholder="Enter street address"
          className={`w-full px-4 py-3 bg-transparent border ${errors.office_address ? 'border-red-500' : 'border-gray-300'} rounded-full 
            focus:outline-none focus:border-gray-400
            placeholder-gray-400 text-gray-800`}
        />
        {errors.office_address && (
          <p className="text-red-500 text-xs mt-1 ml-4">{errors.office_address}</p>
        )}
      </div>

      {/* Bar ID */}
      <div className="relative">
        <label className="absolute -top-2.5 left-4 px-2 bg-[#fef6ef] text-gray-500 text-sm">
          Bar ID
        </label>
        <input
          type="text"
          name="barId"
          value={formData.barId || ''}
          onChange={onChange}
          placeholder="Enter Bar ID"
          className={`w-full px-4 py-3 bg-transparent border ${errors.barId ? 'border-red-500' : 'border-gray-300'} rounded-full 
            focus:outline-none focus:border-gray-400
            placeholder-gray-400 text-gray-800`}
        />
        {errors.barId && (
          <p className="text-red-500 text-xs mt-1 ml-4">{errors.barId}</p>
        )}
      </div>

      {/* City and Zip Code Row */}
      <div className="grid grid-cols-2 gap-4">
        <div className="relative">
          <label className="absolute -top-2.5 left-4 px-2 bg-[#fef6ef] text-gray-500 text-sm">
            City
          </label>
          <input
            type="text"
            name="firmCity"
            value={formData.firmCity || ''}
            onChange={onChange}
            placeholder="Enter city"
            className={`w-full px-4 py-3 bg-transparent border ${errors.firmCity ? 'border-red-500' : 'border-gray-300'} rounded-full 
              focus:outline-none focus:border-gray-400
              placeholder-gray-400 text-gray-800`}
          />
          {errors.firmCity && (
            <p className="text-red-500 text-xs mt-1 ml-4">{errors.firmCity}</p>
          )}
        </div>

        <div className="relative">
          <label className="absolute -top-2.5 left-4 px-2 bg-[#fef6ef] text-gray-500 text-sm">
            Zip Code
          </label>
          <input
            type="text"
            name="firmZip"
            value={formData.firmZip || ''}
            onChange={handleZipChange}
            placeholder="Enter zip code"
            maxLength={5}
            className={`w-full px-4 py-3 bg-transparent border ${errors.firmZip ? 'border-red-500' : 'border-gray-300'} rounded-full 
              focus:outline-none focus:border-gray-400
              placeholder-gray-400 text-gray-800`}
          />
          {errors.firmZip && (
            <p className="text-red-500 text-xs mt-1 ml-4">{errors.firmZip}</p>
          )}
        </div>
      </div>

      {/* State and Privacy Setting Row */}
      <div className="grid grid-cols-2 gap-4">
        <div className="relative">
          <label className="absolute -top-2.5 left-4 px-2 bg-[#fef6ef] text-gray-500 text-sm">State</label>
          <select
            name="firmState"
            value={formData.firmState || ''}
            onChange={onChange}
            className={`w-full px-4 py-3 bg-transparent border ${errors.firmState ? 'border-red-500' : 'border-gray-300'} rounded-full 
              focus:outline-none focus:border-gray-400
              text-gray-600 appearance-none cursor-pointer`}
          >
            <option value="" disabled>Select from dropdown</option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
          {errors.firmState && (
            <p className="text-red-500 text-xs mt-1 ml-4">{errors.firmState}</p>
          )}
        </div>

        <div className="relative">
          <label className="absolute -top-2.5 left-4 px-2 bg-[#fef6ef] text-gray-500 text-sm">
            Privacy Setting
          </label>
          <select
            name="publicReferrals"
            value={formData.publicReferrals || ''}
            onChange={onChange}
            className={`w-full px-4 py-3 bg-transparent border ${errors.publicReferrals ? 'border-red-500' : 'border-gray-300'} rounded-full 
              focus:outline-none focus:border-gray-400
              text-gray-600 appearance-none cursor-pointer pr-12`}
          >
            <option value="" disabled>Select from dropdown</option>
            <option value="public">Public</option>
            <option value="private">Private</option>
          </select>
          <span className="absolute right-4 top-3.5 text-gray-400 pointer-events-none">
            <Lock size={20} />
          </span>
          {errors.publicReferrals && (
            <p className="text-red-500 text-xs mt-1 ml-4">{errors.publicReferrals}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BarVerification;