// src/components/dashboard/FavoritesPage.js
import React, { useState, useEffect } from 'react';
import { Edit2, Trash2, Star } from 'lucide-react';
import { favoritesService } from '../../backend/favoritesService';

const FavoritesTable = ({ data, onToggleFavorite }) => (
  <div className="flex-grow">
    <table className="w-full">
      <thead>
        <tr className="text-left text-[#3d3731] bg-[#d8d1c7]">
          <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Firm</th>
          <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Firm Contact</th>
          <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Phone Number</th>
          <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Email</th>
          <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Actions</th>
        </tr>
      </thead>
      <tbody className="bg-[#e9e4dc] divide-y divide-[#d8d1c7]">
        {data.map((row) => (
          <tr key={row.id}>
            <td className="px-6 py-4">{row.firm}</td>
            <td className="px-6 py-4">{row.contact}</td>
            <td className="px-6 py-4">{row.phone}</td>
            <td className="px-6 py-4">{row.email}</td>
            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
              <div className="flex items-center justify-end space-x-2">
                <button 
                  onClick={() => onToggleFavorite(row)}
                  className="text-yellow-500 hover:text-yellow-600 transition-colors"
                >
                  <Star size={20} fill="#FFD700"/>
                </button>
                <button className="text-gray-400 hover:text-gray-500">
                  <Edit2 size={20} />
                </button>
                <button className="text-gray-400 hover:text-gray-500">
                  <Trash2 size={20} />
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const Pagination = ({ currentPage, totalItems, itemsPerPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  return (
    <div className="flex justify-between items-center px-6 py-3 bg-[#e9e4dc] border-t border-[#a39b8f]">
      <p className="text-sm text-[#3d3731]">
        Showing <span className="font-medium">{startItem}</span> to{' '}
        <span className="font-medium">{endItem}</span> of{' '}
        <span className="font-medium">{totalItems}</span> entries
      </p>
      <div className="flex space-x-1">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            onClick={() => onPageChange(page)}
            className={`px-3 py-1 ${
              page === currentPage ? 'bg-black text-[#e9e4dc]' : 'bg-[#d8d1c7] text-[#3d3731] hover:bg-[#c5beb2]'
            } border border-[#a39b8f] rounded-md text-sm`}
          >
            {page}
          </button>
        ))}
      </div>
    </div>
  );
};

const FavoritesPage = () => {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const fetchFavorites = async () => {
    setLoading(true);
    try {
      const result = await favoritesService.fetchFavorites();
      if (result.success) {
        setFavorites(result.data);
      } else {
        setError(result.error);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFavorites();
  }, []);

  const handleToggleFavorite = async (favorite) => {
    try {
      const contact = {
        email: favorite.email,
        firm_name: favorite.firm,
        first_name: favorite.contact.split(' ')[0],
        last_name: favorite.contact.split(' ')[1],
        phone: favorite.phone,
        is_favorite: true
      };

      const result = await favoritesService.toggleFavorite(contact);
      if (result.success) {
        fetchFavorites();
      }
    } catch (err) {
      console.error('Error toggling favorite:', err);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-black"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-700 bg-red-100 border border-red-200 rounded-md m-4">
        Error loading favorites: {error}
      </div>
    );
  }

  // Calculate pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedFavorites = favorites.slice(startIndex, endIndex);

  return (
    <div className="flex flex-col h-full">
      {favorites.length === 0 ? (
        <div className="flex justify-center items-center h-full text-[#3d3731]">
          No favorites yet
        </div>
      ) : (
        <>
          <FavoritesTable 
            data={paginatedFavorites} 
            onToggleFavorite={handleToggleFavorite}
          />
          <Pagination 
            currentPage={currentPage}
            totalItems={favorites.length}
            itemsPerPage={itemsPerPage}
            onPageChange={setCurrentPage}
          />
        </>
      )}
    </div>
  );
};

export default FavoritesPage;