// chatPersistenceService.js

import { supabase } from './supabase';

const generateChatTitle = (message) => {
  // Remove any special characters and extra whitespace
  const cleanMessage = message.replace(/[^\w\s]/gi, ' ').replace(/\s+/g, ' ').trim();
  
  // Get the first sentence or first 50 characters
  const title = cleanMessage.split(/[.!?]/, 1)[0] || cleanMessage;
  return title.length > 50 ? `${title.substring(0, 47)}...` : title;
};

export const chatPersistenceService = {
  // Load initial chats list
  async loadChats() {
    try {
      const { data: authUser } = await supabase.auth.getUser();
    
      if (!authUser?.user?.id) {
        throw new Error('User not authenticated');
      }

      const { data: chats, error } = await supabase
        .from('chats')
        .select('*')
        .eq('created_by', authUser.user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      return chats;
    } catch (error) {
      console.error('Error loading chats:', error);
      throw error;
    }
  },

  // Load messages for a specific chat
  async loadChatMessages(chatId) {
    try {
      const { data: messages, error } = await supabase
        .from('chat_messages')
        .select('*')
        .eq('chat_id', chatId)
        .order('created_at', { ascending: true });

      if (error) throw error;
      return messages;
    } catch (error) {
      console.error('Error loading chat messages:', error);
      throw error;
    }
  },

  // Create a new chat
  async createChat(initialMessage) {
    try {
      const { data: authUser } = await supabase.auth.getUser();
    
      if (!authUser?.user?.id) {
        throw new Error('User not authenticated');
      }

      const chatTitle = generateChatTitle(initialMessage);

      const { data: chat, error } = await supabase
        .from('chats')
        .insert([{
          title: chatTitle,
          created_at: new Date().toISOString(),
          last_message: initialMessage,
          thread_id: null,
          created_by: authUser.user.id
        }])
        .select()
        .single();

      if (error) throw error;
      return chat;
    } catch (error) {
      console.error('Error creating chat:', error);
      throw error;
    }
  },

  // Save a new message
  async saveMessage(chatId, message, threadId = null) {
    try {
      // Save the message
      const { data: savedMessage, error: messageError } = await supabase
        .from('chat_messages')
        .insert([{
          chat_id: chatId,
          content: message.content,
          type: message.type,
          created_at: new Date().toISOString()
        }])
        .select()
        .single();

      if (messageError) throw messageError;

      // If it's the first user message, update the chat title
      if (message.type === 'user') {
        const chatTitle = generateChatTitle(message.content);
        
        const { error: chatError } = await supabase
          .from('chats')
          .update({ 
            title: chatTitle,
            last_message: message.content,
            thread_id: threadId,
            updated_at: new Date().toISOString()
          })
          .eq('id', chatId);

        if (chatError) throw chatError;
      } else {
        // For non-user messages, just update last_message and thread_id
        const { error: chatError } = await supabase
          .from('chats')
          .update({ 
            last_message: message.content,
            thread_id: threadId,
            updated_at: new Date().toISOString()
          })
          .eq('id', chatId);

        if (chatError) throw chatError;
      }

      return savedMessage;
    } catch (error) {
      console.error('Error saving message:', error);
      throw error;
    }
  },

  // Update chat title
  async updateChatTitle(chatId, title) {
    try {
      const { error } = await supabase
        .from('chats')
        .update({ title })
        .eq('id', chatId);

      if (error) throw error;
    } catch (error) {
      console.error('Error updating chat title:', error);
      throw error;
    }
  }
};