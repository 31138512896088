// src/backend/referralServices/outgoingReferrals.js
import { supabase } from '../supabase';
import { calculateProgress } from '../referralService';

export const outgoingReferralService = {
  async fetchAllOutgoingReferrals(userId) {
    try {
      const { data, error } = await supabase
        .from('referrals')
        .select(`
          id,
          status,
          case_id,
          compensation_terms,
          acceptance_deadline,
          referred_user_id,
          referred_non_user_id,
          cases (
            id,
            title,
            description,
            practice_area
          ),
          lawyer_profiles (     
            firm_name,
            user_id,
            users (
              first_name,
              last_name,
              email,
              phone
            )
          ),
          non_users (          
            first_name,
            last_name,
            company_name,
            email,
            phone
          )
        `)
        .eq('referring_user_id', userId)
        .order('created_at', { ascending: false });

      if (error) throw error;

      return {
        success: true,
        data: this.formatReferrals(data)
      };
    } catch (error) {
      console.error('Error fetching outgoing referrals:', error);
      return { success: false, error: error.message };
    }
  },

  // Separate formatting logic for easier maintenance
  formatReferrals(referrals) {
    return (referrals || []).map(referral => {
      // Determine if this is a user or non-user referral
      const isUserReferral = !!referral.referred_user_id;
      const referredPerson = isUserReferral ? 
        referral.lawyer_profiles?.users : 
        referral.non_users;

      return {
        id: referral.id,
        logo: "/api/placeholder/40/40",
        name: isUserReferral ? 
          referral.lawyer_profiles?.firm_name : 
          referral.non_users?.company_name || 'Unknown Firm',
        status: referral.status,
        contact: referredPerson ? 
          `${referredPerson.first_name || ''} ${referredPerson.last_name || ''}`.trim() : 
          'Unknown Contact',
        email: referredPerson?.email,
        phone: referredPerson?.phone,
        position: "Attorney",
        progress: calculateProgress(referral.status),
        case_id: referral.case_id,
        caseDetails: {
          title: referral.cases?.title,
          description: referral.cases?.description,
          practiceArea: referral.cases?.practice_area
        },
        compensationTerms: referral.compensation_terms,
        acceptanceDeadline: referral.acceptance_deadline
      };
    });
  },

  // Helper method for getting referral name
  getReferralName(referral) {
    if (referral.non_users?.company_name) {
      return referral.non_users.company_name;
    }
    if (referral.lawyer_profiles?.firm_name) {
      return referral.lawyer_profiles.firm_name;
    }
    return 'Unknown Firm';
  },

  // Helper method for getting contact name
  getContactName(referral) {
    const person = referral.referred_user_id ? 
      referral.lawyer_profiles?.users : 
      referral.non_users;

    if (!person) return 'Unknown Contact';
    return `${person.first_name || ''} ${person.last_name || ''}`.trim();
  },

  // Helper method for getting contact email
  getContactEmail(referral) {
    return referral.non_users?.email || 
           referral.lawyer_profiles?.users?.email || 
           null;
  },

  // Helper method for getting contact phone
  getContactPhone(referral) {
    return referral.non_users?.phone || 
           referral.lawyer_profiles?.users?.phone || 
           null;
  }
};