import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { 
  FileText, 
  DollarSign, 
  Trash2, 
  Edit2, 
  MessageSquare,
  CheckCircle,
  XCircle,
  AlertCircle,
  Loader,
  X,
  Upload,
  Download 
} from 'lucide-react';
import { Button } from '../ui/button';
import { updateReferralStatus, deleteReferral } from '../../backend/referralService';
import ReferralDocumentList from './ReferralDocumentList';
import EditReferralModal from './EditReferralModal';
import { documentReferralService } from '../../backend/referralServices/DocumentReferralService';

// Portal Component for Modal
const ModalPortal = ({ children, isOpen }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  if (!mounted || !isOpen) return null;

  return createPortal(
    children,
    document.body
  );
};

const ReferralDetailView = ({ 
  referral, 
  type = 'outgoing', 
  onClose, 
  onRefresh,
  isOpen = true
}) => {
  const [activeTab, setActiveTab] = useState('details');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [settlementAmount, setSettlementAmount] = useState('');
  const [showSettlementInput, setShowSettlementInput] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const [documentError, setDocumentError] = useState(null);

  // Ref to track if changes were made that require a refresh
  const needsRefresh = useRef(false);

  useEffect(() => {
    if (activeTab === 'documents') {
      fetchDocuments();
    }
  }, [activeTab, referral.id]);

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'unset';
      };
    }
  }, [isOpen]);

  const handleClose = () => {
    // Only refresh if changes were made
    if (needsRefresh.current && onRefresh) {
      onRefresh();
      needsRefresh.current = false;
    }
    onClose();
  };

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const fetchDocuments = async () => {
    setDocumentsLoading(true);
    setDocumentError(null);
    try {
      const { success, data, error } = await documentReferralService.getReferralDocuments(referral.id);
      if (!success) throw new Error(error);
      setDocuments(data || []);
    } catch (err) {
      console.error('Error fetching documents:', err);
      setDocumentError('Failed to load documents');
    } finally {
      setDocumentsLoading(false);
    }
  };

  const formatMoney = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount || 0);
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const handleStatusUpdate = async (newStatus) => {
    setLoading(true);
    setError(null);
    try {
      const result = await updateReferralStatus(referral.id, newStatus);
      if (!result.success) throw new Error(result.error);
      needsRefresh.current = true; // Mark that changes were made
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this referral?')) return;
    
    setLoading(true);
    setError(null);
    try {
      const result = await deleteReferral(referral.id);
      if (!result.success) throw new Error(result.error);
      needsRefresh.current = true; // Mark that changes were made
      handleClose();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSettlementSubmit = async () => {
    if (!settlementAmount) return;
    setLoading(true);
    setError(null);
    try {
      // Add settlement amount update logic here
      needsRefresh.current = true; // Mark that changes were made
      setShowSettlementInput(false);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const renderActionButtons = () => {
    if (loading) return (
      <Button disabled className="w-full">
        <Loader className="w-4 h-4 mr-2 animate-spin" />
        Processing...
      </Button>
    );

    if (type === 'outgoing') {
      return (
        <div className="space-y-2">
          {referral.status === 'accepted' && (
            <Button 
              onClick={() => setShowSettlementInput(true)}
              className="w-full"
            >
              <DollarSign className="w-4 h-4 mr-2" />
              Request Settlement Amount
            </Button>
          )}
          
          {referral.status === 'renegotiating' && (
            <Button 
              onClick={() => handleStatusUpdate('accepted')}
              className="w-full bg-black hover:bg-[#3d3731]"
            >
              <MessageSquare className="w-4 h-4 mr-2" />
              Renegotiate Terms
            </Button>
          )}
          
          <Button
            onClick={() => setShowEditModal(true)}
            variant="outline"
            className="w-full"
          >
            <Edit2 className="w-4 h-4 mr-2" />
            Edit Referral
          </Button>
          
          <Button
            onClick={handleDelete}
            variant="destructive"
            className="w-full"
          >
            <Trash2 className="w-4 h-4 mr-2" />
            Delete Referral
          </Button>
        </div>
      );
    }

    // Incoming referral actions
    return (
      <div className="space-y-2">
        {referral.status === 'pending' && (
          <>
            <Button 
              onClick={() => handleStatusUpdate('accepted')}
              className="w-full bg-green-600 hover:bg-green-700"
            >
              <CheckCircle className="w-4 h-4 mr-2" />
              Accept Referral
            </Button>
            
            <Button 
              onClick={() => handleStatusUpdate('rejected')}
              variant="destructive"
              className="w-full"
            >
              <XCircle className="w-4 h-4 mr-2" />
              Decline Referral
            </Button>
            
            <Button 
              onClick={() => handleStatusUpdate('renegotiating')}
              className="w-full"
            >
              <MessageSquare className="w-4 h-4 mr-2" />
              Request Changes
            </Button>
          </>
        )}
        
        {referral.status === 'accepted' && (
          <Button 
            onClick={() => setShowSettlementInput(true)}
            className="w-full"
          >
            <DollarSign className="w-4 h-4 mr-2" />
            Enter Settlement Amount
          </Button>
        )}
      </div>
    );
  };

  return (
    <ModalPortal isOpen={isOpen}>
      <div 
        className="fixed inset-0 overflow-auto bg-black/30 backdrop-blur-[2px]" 
        onClick={handleBackdropClick}
        style={{ zIndex: 9999 }}
      >
        {/* Modal Container */}
        <div className="flex min-h-screen items-center justify-center p-4">
          <div 
            className="relative w-full max-w-2xl rounded-xl bg-white shadow-2xl"
            onClick={e => e.stopPropagation()}
          >
            {/* Header */}
            <div className="sticky top-0 z-50 bg-[#f5f1eb] border-b border-[#d8d1c7] p-6">
              <div className="flex justify-between items-center">
                <div>
                  <h2 className="text-xl font-semibold text-black mb-1">
                    {referral.caseDetails?.title || referral.name}
                  </h2>
                  <p className="text-[#3d3731]">Case #{referral.case_id}</p>
                </div>

                <div className="flex items-center space-x-4">
                  <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                    referral.status === 'completed' ? 'bg-green-100 text-green-800' :
                    referral.status === 'rejected' ? 'bg-red-100 text-red-800' :
                    'bg-[#d8d1c7] text-[#3d3731]'
                  }`}>
                    {referral.status.charAt(0).toUpperCase() + referral.status.slice(1)}
                  </span>

                  <button
                    onClick={handleClose}
                    className="group p-2 hover:bg-[#d8d1c7] rounded-full transition-colors"
                    aria-label="Close modal"
                  >
                    <X className="w-5 h-5 text-[#3d3731]" />
                  </button>
                </div>
              </div>
            </div>

            {/* Content Area */}
            <div className="max-h-[calc(100vh-16rem)] overflow-y-auto">
              {/* Tabs */}
              <div className="sticky top-0 z-40 bg-white border-b border-[#d8d1c7]">
                <div className="flex">
                  <button
                    onClick={() => setActiveTab('details')}
                    className={`flex-1 px-6 py-3 text-sm font-medium ${
                      activeTab === 'details' 
                        ? 'border-b-2 border-black text-black' 
                        : 'text-[#3d3731] hover:text-black'
                    }`}
                  >
                    Details
                  </button>
                  <button
                    onClick={() => setActiveTab('documents')}
                    className={`flex-1 px-6 py-3 text-sm font-medium ${
                      activeTab === 'documents' 
                        ? 'border-b-2 border-black text-black' 
                        : 'text-[#3d3731] hover:text-black'
                    }`}
                  >
                    Documents
                  </button>
                </div>
              </div>

              {/* Tab Content */}
              <div className="p-6">
                {activeTab === 'details' ? (
                  <div className="space-y-6">
                    <div>
                      <h3 className="text-sm font-medium text-[#3d3731] mb-2">Contact Information</h3>
                      <div className="bg-[#f5f1eb] p-4 rounded-lg">
                        <p className="text-black font-medium">{referral.contact}</p>
                        <p className="text-[#3d3731]">{referral.email}</p>
                        <p className="text-[#3d3731]">{referral.phone}</p>
                      </div>
                    </div>

                    <div>
                      <h3 className="text-sm font-medium text-[#3d3731] mb-2">Case Details</h3>
                      <div className="bg-[#f5f1eb] p-4 rounded-lg">
                        <p className="text-black font-medium mb-2">{referral.caseDetails?.title}</p>
                        <p className="text-[#3d3731] mb-2">{referral.caseDetails?.description}</p>
                        <p className="text-[#3d3731]">Practice Area: {referral.caseDetails?.practiceArea}</p>
                      </div>
                    </div>

                    <div>
                      <h3 className="text-sm font-medium text-[#3d3731] mb-2">Terms</h3>
                      <div className="bg-[#f5f1eb] p-4 rounded-lg">
                        <p className="text-[#3d3731]">Compensation: {referral.compensationTerms}</p>
                        <p className="text-[#3d3731]">Deadline: {formatDate(referral.acceptanceDeadline)}</p>
                      </div>
                    </div>

                    {error && (
                      <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-lg flex items-center">
                        <AlertCircle className="w-4 h-4 mr-2 flex-shrink-0" />
                        {error}
                      </div>
                    )}

                    {showSettlementInput && (
                      <div className="bg-[#f5f1eb] p-4 rounded-lg">
                        <label className="block text-sm font-medium text-[#3d3731] mb-2">
                          Enter Settlement Amount
                        </label>
                        <div className="flex space-x-2">
                          <input
                            type="number"
                            value={settlementAmount}
                            onChange={(e) => setSettlementAmount(e.target.value)}
                            className="flex-1 px-4 py-2 border border-[#d8d1c7] rounded-md"
                            placeholder="0.00"
                          />
                          <Button 
                            onClick={handleSettlementSubmit}
                            disabled={!settlementAmount}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    )}

                    {renderActionButtons()}
                  </div>
                ) : (
                  <ReferralDocumentList 
                    referralId={referral.id}
                    caseId={referral.case_id}
                    isOutgoing={type === 'outgoing'}
                    documents={documents}
                    loading={documentsLoading}
                    error={documentError}
                    onRefresh={fetchDocuments}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Edit Modal */}
        {showEditModal && (
          <EditReferralModal
            isOpen={showEditModal}
            onClose={() => setShowEditModal(false)}
            referral={{
              id: referral.id,
              contact: referral.contact,
              email: referral.email,
              phone: referral.phone,
              name: referral.name,
              status: referral.status,
              caseDetails: referral.caseDetails,
              compensationTerms: referral.compensationTerms,
              acceptanceDeadline: referral.acceptanceDeadline,
              case_id: referral.case_id,
              files: documents
            }}
            onUpdate={async () => {
              needsRefresh.current = true;
              setShowEditModal(false);
            }}
          />
        )}
      </div>
    </ModalPortal>
  );
};

export default ReferralDetailView;