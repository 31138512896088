import React from 'react';

export const PhaseIndicator = ({ currentPhase }) => (
  <div className="flex justify-between space-x-4">
    {[1, 2, 3].map((phase) => (
      <div
        key={phase}
        className={`flex-1 h-1 rounded-full ${
          phase <= currentPhase ? 'bg-[#34261E]' : 'bg-gray-300'
        }`}
      />
    ))}
  </div>
);