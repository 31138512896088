import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronLeft, Loader } from 'lucide-react';
import { getLienById, updateLien } from '../../backend/lienService';

const LienEditPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    lienholder_name: '',
    amount: '',
    type: '',
    debtor_name: '',
    filing_date: '',
    expiry_date: '',
    property_address: '',
    description: ''
  });

  useEffect(() => {
    const fetchLien = async () => {
      try {
        setLoading(true);
        const data = await getLienById(id);
        if (data) {
          // Format dates for input fields
          const formattedData = {
            ...data,
            filing_date: data.filing_date ? new Date(data.filing_date).toISOString().split('T')[0] : '',
            expiry_date: data.expiry_date ? new Date(data.expiry_date).toISOString().split('T')[0] : '',
            amount: data.amount ? data.amount.toString() : ''
          };
          setFormData(formattedData);
        }
      } catch (error) {
        console.error('Error fetching lien:', error);
        setError('Failed to load lien details');
      } finally {
        setLoading(false);
      }
    };

    fetchLien();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    setError(null);

    try {
      const updatedData = {
        ...formData,
        amount: parseFloat(formData.amount),
        updated_at: new Date().toISOString()
      };

      await updateLien(id, updatedData);
      navigate('/dashboard/liens');
    } catch (error) {
      console.error('Error updating lien:', error);
      setError('Failed to update lien. Please try again.');
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-[#d8d1c7] flex items-center justify-center">
        <Loader className="w-8 h-8 animate-spin text-[#3d3731]" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#d8d1c7]">
      {/* Header */}
      <div className="bg-[#e9e4dc] border-b border-[#a39b8f]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-4">
            <div className="flex items-center">
              <button
                onClick={() => navigate('/dashboard/liens')}
                className="text-[#3d3731] hover:text-black transition-colors duration-150 flex items-center mr-4"
              >
                <ChevronLeft className="w-5 h-5" />
              </button>
              <h1 className="text-2xl font-semibold text-black">Edit Lien</h1>
            </div>
          </div>
        </div>
      </div>

      {/* Form */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <form onSubmit={handleSubmit} className="bg-[#e9e4dc] rounded-lg shadow-sm border border-[#a39b8f] p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Lienholder Name */}
            <div>
              <label className="block text-sm font-medium text-[#3d3731] mb-2">
                Lienholder Name
              </label>
              <input
                type="text"
                name="lienholder_name"
                value={formData.lienholder_name}
                onChange={handleInputChange}
                className="w-full p-2 border border-[#a39b8f] rounded-md bg-white text-black focus:outline-none focus:ring-2 focus:ring-[#3d3731]"
              />
            </div>

            {/* Amount */}
            <div>
              <label className="block text-sm font-medium text-[#3d3731] mb-2">
                Amount
              </label>
              <input
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleInputChange}
                min="0"
                step="0.01"
                className="w-full p-2 border border-[#a39b8f] rounded-md bg-white text-black focus:outline-none focus:ring-2 focus:ring-[#3d3731]"
              />
            </div>

            {/* Type */}
            <div>
              <label className="block text-sm font-medium text-[#3d3731] mb-2">
                Type
              </label>
              <select
                name="type"
                value={formData.type}
                onChange={handleInputChange}
                className="w-full p-2 border border-[#a39b8f] rounded-md bg-white text-black focus:outline-none focus:ring-2 focus:ring-[#3d3731]"
              >
                <option value="">Select type...</option>
                <option value="Medical">Medical</option>
                <option value="Property">Property</option>
                <option value="Tax">Tax</option>
                <option value="Mechanic">Mechanic</option>
              </select>
            </div>

            {/* Debtor Name */}
            <div>
              <label className="block text-sm font-medium text-[#3d3731] mb-2">
                Debtor Name
              </label>
              <input
                type="text"
                name="debtor_name"
                value={formData.debtor_name}
                onChange={handleInputChange}
                className="w-full p-2 border border-[#a39b8f] rounded-md bg-white text-black focus:outline-none focus:ring-2 focus:ring-[#3d3731]"
              />
            </div>

            {/* Filing Date */}
            <div>
              <label className="block text-sm font-medium text-[#3d3731] mb-2">
                Filing Date
              </label>
              <input
                type="date"
                name="filing_date"
                value={formData.filing_date}
                onChange={handleInputChange}
                className="w-full p-2 border border-[#a39b8f] rounded-md bg-white text-black focus:outline-none focus:ring-2 focus:ring-[#3d3731]"
              />
            </div>

            {/* Expiry Date */}
            <div>
              <label className="block text-sm font-medium text-[#3d3731] mb-2">
                Expiry Date
              </label>
              <input
                type="date"
                name="expiry_date"
                value={formData.expiry_date}
                onChange={handleInputChange}
                className="w-full p-2 border border-[#a39b8f] rounded-md bg-white text-black focus:outline-none focus:ring-2 focus:ring-[#3d3731]"
              />
            </div>
          </div>

          {/* Property Address */}
          <div className="mt-6">
            <label className="block text-sm font-medium text-[#3d3731] mb-2">
              Property Address
            </label>
            <input
              type="text"
              name="property_address"
              value={formData.property_address}
              onChange={handleInputChange}
              className="w-full p-2 border border-[#a39b8f] rounded-md bg-white text-black focus:outline-none focus:ring-2 focus:ring-[#3d3731]"
            />
          </div>

          {/* Description */}
          <div className="mt-6">
            <label className="block text-sm font-medium text-[#3d3731] mb-2">
              Description
            </label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              rows={4}
              className="w-full p-2 border border-[#a39b8f] rounded-md bg-white text-black focus:outline-none focus:ring-2 focus:ring-[#3d3731] resize-none"
            />
          </div>

          {/* Error Message */}
          {error && (
            <div className="mt-4 p-3 bg-red-50 border border-red-200 rounded-md">
              <p className="text-sm text-red-600">{error}</p>
            </div>
          )}

          {/* Buttons */}
          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={() => navigate('/dashboard/liens')}
              className="px-4 py-2 text-[#3d3731] bg-[#d8d1c7] border border-[#a39b8f] rounded-md hover:bg-[#c5beb2]"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={saving}
              className="px-6 py-2 bg-black text-white rounded-md hover:bg-[#3d3731] transition-colors duration-150 flex items-center disabled:opacity-50"
            >
              {saving ? (
                <>
                  <Loader className="w-4 h-4 mr-2 animate-spin" />
                  Saving...
                </>
              ) : (
                'Save Changes'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LienEditPage;