import React, { useState } from 'react';
import { handleReferralSubmit } from '../../backend/referralService';
import { Loader } from 'lucide-react';

const EmailTest = () => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  const testReferral = {
    name: 'Meeth Naik',
    email: 'meeth.naik@outlook.com',
    phone: '1234567890',
    firm: 'Test Firm',
    selectedSubject: 'Personal Injury',
    caseDetails: 'Test case details - sent via test page',
    compensationPercentage: 25,
    acceptanceDeadline: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toISOString()
  };

  const handleTest = async () => {
    try {
      setLoading(true);
      setError(null);
      setResult(null);

      console.log('Starting email test...');
      console.log('AWS Configuration:', {
        region: process.env.REACT_APP_AWS_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ? 'Present' : 'Missing',
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ? 'Present' : 'Missing'
      });

      const result = await handleReferralSubmit(testReferral, []);
      console.log('Test complete. Result:', result);
      setResult(result);

    } catch (err) {
      console.error('Test error:', err);
      setError(err.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-[#d8d1c7] p-8">
      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-sm p-6">
        <h1 className="text-2xl font-bold mb-6">Email Test Page</h1>
        
        <div className="mb-6">
          <h2 className="font-semibold mb-2">Test Data:</h2>
          <pre className="bg-[#f5f1eb] p-4 rounded-lg overflow-auto">
            {JSON.stringify(testReferral, null, 2)}
          </pre>
        </div>

        <div className="mb-4">
          <h2 className="font-semibold mb-2">AWS Configuration:</h2>
          <pre className="bg-[#f5f1eb] p-4 rounded-lg overflow-auto">
            {JSON.stringify({
              region: process.env.REACT_APP_AWS_REGION,
              accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ? 'Present' : 'Missing',
              secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ? 'Present' : 'Missing'
            }, null, 2)}
          </pre>
        </div>

        <button
          onClick={handleTest}
          disabled={loading}
          className="px-6 py-3 bg-black text-white rounded-lg hover:bg-[#3d3731] disabled:opacity-50 flex items-center justify-center w-full"
        >
          {loading ? (
            <>
              <Loader className="w-4 h-4 mr-2 animate-spin" />
              Sending Test Email...
            </>
          ) : (
            'Send Test Email'
          )}
        </button>

        {error && (
          <div className="mt-4 p-4 bg-red-50 text-red-700 rounded-lg">
            Error: {error}
          </div>
        )}

        {result && (
          <div className="mt-4 p-4 bg-green-50 text-green-700 rounded-lg">
            Success! Email sent.
            <pre className="mt-2 text-xs overflow-auto">
              {JSON.stringify(result, null, 2)}
            </pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailTest;