// src/components/documents/DocumentDetail.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  GitBranch, 
  GitCommit, 
  Download, 
  FileText,
  Upload,
  X
} from 'lucide-react';
import { documentService } from '../../backend/documentService';
import { versionService } from './document-git/versionService';
import DocumentEditor from './DocumentEditor';

const DocumentDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [document, setDocument] = useState(null);
  const [versions, setVersions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [showCommitDialog, setShowCommitDialog] = useState(false);
  const [commitMessage, setCommitMessage] = useState('');
  
  useEffect(() => {
    fetchDocument();
  }, [id]);

  const fetchDocument = async () => {
    try {
      // Get document details
      const { data: doc, error: docError } = await documentService.getDocument(id);
      if (docError) throw docError;

      // Get version history
      const { history, error: historyError } = await versionService.getVersionHistory(id);
      if (historyError) throw historyError;

      setDocument(doc);
      setVersions(history || []);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (version = null) => {
    try {
      const { data, error } = await documentService.downloadDocument(id, version?.hash);
      if (error) throw error;

      // Create download link
      const url = URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      a.download = document.file_name;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      setError('Download failed: ' + err.message);
    }
  };

  const handleCreateVersion = async () => {
    if (!commitMessage) return;

    try {
      const { hash, error } = await versionService.createNewVersion(id, {
        ...document,
        lastModified: new Date().toISOString()
      }, commitMessage);

      if (error) throw error;

      // Refresh document and versions
      await fetchDocument();
      setCommitMessage('');
      setShowCommitDialog(false);
    } catch (err) {
      setError('Failed to create version: ' + err.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!document) return <div>Document not found</div>;

  return (
    <div className="min-h-screen bg-[#f5f1eb]">
      {/* Header */}
      <div className="bg-[#e9e4dc] border-b border-[#a39b8f] p-6">
        <button
          onClick={() => navigate('/documents')}
          className="flex items-center text-[#3d3731] hover:text-black mb-4"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Documents
        </button>
        
        <div className="flex justify-between items-start">
          <div>
            <h1 className="text-2xl font-bold text-black mb-2">{document.file_name}</h1>
            <p className="text-[#3d3731]">
              Version {document.version_count} • {(document.file_size / 1024 / 1024).toFixed(2)} MB
            </p>
          </div>
          <div className="flex space-x-3">
            <button
              onClick={() => handleDownload()}
              className="px-4 py-2 bg-[#d8d1c7] text-[#3d3731] rounded-md hover:bg-[#c5beb2]"
            >
              <Download className="w-4 h-4 mr-2 inline-block" />
              Download
            </button>
            <button
              onClick={() => setShowCommitDialog(true)}
              className="px-4 py-2 bg-black text-white rounded-md hover:bg-[#3d3731]"
            >
              <Upload className="w-4 h-4 mr-2 inline-block" />
              New Version
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex">
        {/* Document Preview */}
        <div className="flex-1 p-6">
            <div className="bg-white rounded-lg border border-[#d8d1c7] overflow-hidden h-[calc(100vh-200px)]">
                {document.file_type === 'application/pdf' || document.file_name.endsWith('.pdf') ? (
                <DocumentEditor 
                    document={document}
                    onSave={async (blob) => {
                    try {
                        // Upload modified file
                        const { data, error } = await documentService.uploadNewVersion(
                        document.id, 
                        new File([blob], document.file_name, { type: 'application/pdf' })
                        );
                        
                        if (error) throw error;

                        // Create new version
                        await versionService.createNewVersion(document.id, {
                        ...document,
                        url_id: data.url_id,
                        updated_at: new Date().toISOString()
                        }, 'Updated document content');

                        // Refresh document
                        fetchDocument();
                    } catch (err) {
                        setError('Failed to save changes: ' + err.message);
                    }
                    }}
                />
                ) : (
                <div className="flex items-center justify-center h-full">
                    <FileText className="w-24 h-24 text-[#d8d1c7]" />
                </div>
                )}
            </div>
        </div>

        {/* Version History Sidebar */}
        <div className="w-96 bg-[#e9e4dc] border-l border-[#a39b8f] min-h-screen">
          <div className="p-4">
            <h2 className="text-lg font-semibold text-black mb-4">Version History</h2>
            <div className="space-y-4">
              {versions.map((version, index) => (
                <div
                  key={version.hash}
                  className={`p-4 border-l-2 ${
                    selectedVersion?.hash === version.hash
                      ? 'border-black bg-[#f5f1eb]'
                      : 'border-[#d8d1c7] hover:bg-[#f5f1eb]'
                  } cursor-pointer`}
                  onClick={() => setSelectedVersion(version)}
                >
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center">
                      <GitCommit className="w-4 h-4 mr-2 text-[#3d3731]" />
                      <span className="font-medium text-[#3d3731]">
                        {index === 0 ? 'Current Version' : `Version ${versions.length - index}`}
                      </span>
                    </div>
                    <span className="text-sm text-[#a39b8f]">
                      {new Date(version.timestamp).toLocaleDateString()}
                    </span>
                  </div>
                  {version.message && (
                    <p className="text-sm text-[#3d3731] pl-6">{version.message}</p>
                  )}
                  {selectedVersion?.hash === version.hash && (
                    <div className="mt-2 pl-6">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDownload(version);
                        }}
                        className="text-sm text-[#3d3731] hover:text-black"
                      >
                        <Download className="w-4 h-4 mr-1 inline-block" />
                        Download this version
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* New Version Dialog */}
      {showCommitDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold text-black">Create New Version</h3>
              <button 
                onClick={() => setShowCommitDialog(false)}
                className="text-[#3d3731] hover:text-black"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            
            <textarea
              value={commitMessage}
              onChange={(e) => setCommitMessage(e.target.value)}
              placeholder="Describe what changed in this version..."
              className="w-full p-3 border border-[#d8d1c7] rounded-md mb-4 h-32 focus:outline-none focus:border-black"
            />
            
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowCommitDialog(false)}
                className="px-4 py-2 text-[#3d3731] hover:bg-[#f5f1eb] rounded-md"
              >
                Cancel
              </button>
              <button
                onClick={handleCreateVersion}
                disabled={!commitMessage}
                className="px-4 py-2 bg-black text-white rounded-md hover:bg-[#3d3731] disabled:bg-[#d8d1c7] disabled:cursor-not-allowed"
              >
                Create Version
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentDetailPage;