import React from 'react';
import { CheckCircle, Clock, AlertCircle } from 'lucide-react';
import { workflowService } from '../../backend/workflowService';

const WorkflowSection = ({ lien, onUpdate }) => {
  // Initialize steps from lien or use defaults
  const steps = lien.workflow_steps || workflowService.getDefaultSteps();

  const handleStepStatusUpdate = async (stepId) => {
    try {
      const updatedSteps = steps.map(step => {
        if (step.id === stepId) {
          const newStatus = step.status === 'pending' ? 'in-progress' :
                           step.status === 'in-progress' ? 'completed' : 'pending';
          
          return {
            ...step,
            status: newStatus,
            completion_date: newStatus === 'completed' ? new Date().toISOString() : null
          };
        }
        return step;
      });

      await workflowService.updateWorkflowSteps(lien.id, updatedSteps);
      if (onUpdate) onUpdate(); // Trigger parent refresh
    } catch (err) {
      console.error('Error updating step status:', err);
    }
  };

  const getStepIcon = (status) => {
    switch (status) {
      case 'completed':
        return <CheckCircle className="w-6 h-6 text-black" />;
      case 'in-progress':
        return <Clock className="w-6 h-6 text-[#3d3731]" />;
      default:
        return <AlertCircle className="w-6 h-6 text-[#a39b8f]" />;
    }
  };

  return (
    <div className="space-y-8">
      {steps.map((step, index) => (
        <div key={step.id} className="relative">
          <button 
            onClick={() => handleStepStatusUpdate(step.id)}
            className="w-full"
          >
            <div className="flex items-start group">
              <div className="flex-shrink-0 transition-colors duration-150 group-hover:opacity-80">
                {getStepIcon(step.status)}
              </div>
              <div className="ml-4 text-left">
                <h3 className="text-lg font-medium text-black">{step.title}</h3>
                <p className="mt-1 text-[#3d3731]">{step.description}</p>
                {step.completion_date && (
                  <p className="mt-1 text-sm text-[#3d3731]">
                    Completed on {new Date(step.completion_date).toLocaleDateString()}
                  </p>
                )}
              </div>
            </div>
          </button>
          {index < steps.length - 1 && (
            <div className="absolute left-3 top-8 h-16 w-px bg-[#a39b8f]" />
          )}
        </div>
      ))}
    </div>
  );
};

export default WorkflowSection;