// src/components/KiwiAI/ChatArea.jsx
import React, { useState, useRef, useEffect } from 'react';
import { Search, MoreHorizontal, Send, Mic, Loader2, MessageCircle, Plus } from 'lucide-react';
import ChatMessage from './MessageFormater'; // Using existing MessageFormater

const ChatArea = ({ 
  selectedChat, 
  onSendMessage, 
  isLoading,
  streamingMessage,
  isStreaming,
  error,
  onCreateChat
}) => {
  const [input, setInput] = useState('');
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [selectedChat?.messages, streamingMessage]);

  const handleSubmit = () => {
    if (!input.trim() || isLoading) return;
    onSendMessage(input);
    setInput('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  // Empty state when no chat is selected
  if (!selectedChat) {
    return (
      <div className="flex-1 flex flex-col items-center justify-center bg-[#3A302E] rounded-xl">
        <MessageCircle size={48} className="text-[#666666] mb-4" />
        <h2 className="text-[#e1e1e1] text-xl font-semibold mb-2">Welcome to Kiwi AI</h2>
        <p className="text-[#666666] text-sm mb-6">Start a new chat or select an existing one</p>
        <button 
          onClick={onCreateChat}
          className="flex items-center gap-2 bg-[#1a8177] text-white px-6 py-3 rounded-lg hover:bg-opacity-90"
        >
          <Plus size={20} />
          New Chat
        </button>
      </div>
    );
  }

  return (
    <div className="flex-1 flex flex-col bg-[#34261E] p-4 pt-3">
      {/* Chat Header */}
      <div className="flex items-center justify-between px-6 mb-3">
        <div className="text-[#e1e1e1] text-base font-bold">Kiwi AI</div>
        <div className="flex items-center gap-2">
          <button className="w-8 h-8 rounded-lg flex items-center justify-center text-[#666666] hover:text-[#999999] bg-[#1E1F22]">
            <Search size={14} />
          </button>
          <button className="w-8 h-8 rounded-lg flex items-center justify-center text-[#666666] hover:text-[#999999] bg-[#1E1F22]">
            <MoreHorizontal size={14} />
          </button>
        </div>
      </div>

      {/* Chat Container */}
      <div className="flex-1 bg-[#3A302E] rounded-xl flex flex-col min-h-0 p-4">
        {/* Messages Area */}
        <div className="flex-1 overflow-y-auto">
          <div className="space-y-6 max-w-4xl mx-auto">
            {selectedChat.messages ? (
              <>
                {selectedChat.messages.map((message) => (
                  <ChatMessage 
                    key={message.id} 
                    message={message} 
                    threadId={selectedChat.threadId}
                  />
                ))}
                {isStreaming && (
                  <ChatMessage 
                    key="streaming"
                    message={{
                      id: 'streaming',
                      type: 'assistant',
                      content: streamingMessage || 'Thinking...',
                      time: 'Just now',
                      avatar: '/ai_logo.png'
                    }}
                    isStreaming={true}
                  />
                )}
              </>
            ) : (
              <div className="flex items-center justify-center h-full">
                <Loader2 size={24} className="animate-spin text-[#666666]" />
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
        </div>

        {/* Error Message */}
        {error && (
          <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-3 mb-4">
            <p className="text-red-500 text-xs">{error}</p>
          </div>
        )}

        {/* Input Area */}
        <div className="flex-shrink-0 mt-4 max-w-3xl mx-auto w-full">
          <div className="flex items-center gap-4">
            <div className="flex-1 relative">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Message Kiwi AI..."
                className="w-full bg-[#252422] text-[#e1e1e1] pl-6 pr-14 py-4 rounded-lg placeholder-[#666666] focus:outline-none text-sm"
                disabled={isLoading}
              />
              <button 
                onClick={handleSubmit}
                disabled={isLoading || !input.trim()}
                className="absolute right-5 top-1/2 -translate-y-1/2 text-[#666666] hover:text-[#999999] disabled:opacity-50"
              >
                {isLoading ? (
                  <Loader2 size={18} className="animate-spin" />
                ) : (
                  <Send size={18} />
                )}
              </button>
            </div>
            <button className="bg-[#252422] p-4 rounded-lg text-[#666666] hover:text-[#999999]">
              <Mic size={18} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatArea;