// Please replace the contents of LoginForm.js with:

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthHandlers } from './authhandlers';
import { supabase } from '../../backend/supabase';
import { Mail, Lock } from 'lucide-react';

const LoginForm = () => {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState('');
  const { googleLogin, handleLinkedInLogin, loading: authLoading } = useAuthHandlers();
  
  const [formData, setFormData] = useState({
    emailPhone: '',
    password: '',
    rememberMe: false
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const role = localStorage.getItem('userRole');
    if (!role) {
      navigate('/signup');
    }
    setUserRole(role);
  }, [navigate]);

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: formData.emailPhone,
        password: formData.password,
      });

      if (error) throw error;
      navigate('/dashboard');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleCreateAccount = () => {
    navigate('/signup/register');
  };

  return (
    <div className="min-h-screen flex">
      <div className="flex-1 flex flex-col items-center justify-center px-4 py-8 bg-[#fef6ef]">
        {/* Back Button */}
        <button 
          onClick={() => navigate(-1)}
          className="absolute top-6 left-6 text-gray-600 hover:text-gray-800 flex items-center gap-2"
        >
          ← Back
        </button>

        <div className="w-full max-w-md">
          <h1 className="text-4xl font-bold text-[#333333] mb-2 text-center font-rubik">
            Welcome Back!
          </h1>
          <p className="text-gray-500 mb-8 text-center">
            {userRole === 'attorney' ? 'Attorney Portal' : 'Client Portal'}
          </p>

          {error && (
            <div className="mb-6 p-4 bg-red-50 border border-red-200 text-red-600 rounded-lg">
              {error}
            </div>
          )}

          <form onSubmit={handleEmailLogin} className="space-y-6">
            {/* Email Input */}
            <div>
              <label className="block text-gray-600 mb-2">Email</label>
              <div className="relative">
                <span className="absolute left-4 top-3.5">
                  <Mail className="w-5 h-5 text-gray-400" />
                </span>
                <input
                  type="email"
                  name="emailPhone"
                  value={formData.emailPhone}
                  onChange={handleChange}
                  placeholder="email@gmail.com"
                  className="w-full pl-12 pr-4 py-3 bg-[#fef6ef] rounded-full border border-[#757575] focus:outline-none focus:border-black-400"
                  disabled={loading || authLoading}
                />
              </div>
            </div>

            {/* Password Input */}
            <div>
              <label className="block text-gray-600 mb-2">Password</label>
              <div className="relative">
                <span className="absolute left-4 top-3.5">
                  <Lock className="w-5 h-5 text-gray-400" />
                </span>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                  className="w-full pl-12 pr-4 py-3 bg-[#fef6ef] rounded-full border border-[#757575] focus:outline-none focus:border-gray-400"
                  disabled={loading || authLoading}
                />
              </div>
            </div>

            {/* Remember Me & Forgot Password */}
            <div className="flex justify-between items-center">
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  name="rememberMe"
                  checked={formData.rememberMe}
                  onChange={handleChange}
                  className="w-4 h-4 border-gray-300 rounded"
                  disabled={loading || authLoading}
                />
                <span className="text-gray-600 text-sm">Remember me</span>
              </label>
              <button 
                type="button"
                onClick={() => navigate('/forgot-password')}
                className="text-[#616161] text-sm font-bold hover:text-[#3A302E]"
              >
                Forgot Password?
              </button>
            </div>

            {/* Added spacer div */}
            <div className="h-8"></div>

            {/* Login Button */}
            <button
              type="submit"
              disabled={loading || authLoading}
              className="w-full py-3 bg-[#3A302E] text-white rounded-full hover:bg-[#4A403E] transition-colors disabled:opacity-50"
            >
              {loading ? 'Logging in...' : 'Login'}
            </button>

            {/* Or Divider */}
            <div className="relative my-8">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-4 bg-[#fef6ef] text-gray-500">or</span>
              </div>
            </div>

            {/* Social Buttons with adjusted container */}
            <div className="flex justify-center items-center">
              <div className="inline-flex gap-8">
                <button
                  type="button"
                  onClick={googleLogin}
                  disabled={loading || authLoading}
                  className="flex items-center justify-center py-3 rounded-full transition-colors disabled:opacity-50"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32" height="32" viewBox="0 0 48 48">
                    <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path><path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path><path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path><path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
                  </svg>
                </button>

                {userRole === 'attorney' && (
                  <button
                    type="button"
                    onClick={handleLinkedInLogin}
                    disabled={loading || authLoading}
                    className="flex items-center justify-center py-3 rounded-full transition-colors disabled:opacity-50"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32" height="32" viewBox="0 0 48 48">
                      <path fill="#0078d4" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5	V37z"></path><path d="M30,37V26.901c0-1.689-0.819-2.698-2.192-2.698c-0.815,0-1.414,0.459-1.779,1.364	c-0.017,0.064-0.041,0.325-0.031,1.114L26,37h-7V18h7v1.061C27.022,18.356,28.275,18,29.738,18c4.547,0,7.261,3.093,7.261,8.274	L37,37H30z M11,37V18h3.457C12.454,18,11,16.528,11,14.499C11,12.472,12.478,11,14.514,11c2.012,0,3.445,1.431,3.486,3.479	C18,16.523,16.521,18,14.485,18H18v19H11z" opacity=".05"></path><path d="M30.5,36.5v-9.599c0-1.973-1.031-3.198-2.692-3.198c-1.295,0-1.935,0.912-2.243,1.677	c-0.082,0.199-0.071,0.989-0.067,1.326L25.5,36.5h-6v-18h6v1.638c0.795-0.823,2.075-1.638,4.238-1.638	c4.233,0,6.761,2.906,6.761,7.774L36.5,36.5H30.5z M11.5,36.5v-18h6v18H11.5z M14.457,17.5c-1.713,0-2.957-1.262-2.957-3.001	c0-1.738,1.268-2.999,3.014-2.999c1.724,0,2.951,1.229,2.986,2.989c0,1.749-1.268,3.011-3.015,3.011H14.457z" opacity=".07"></path><path fill="#fff" d="M12,19h5v17h-5V19z M14.485,17h-0.028C12.965,17,12,15.888,12,14.499C12,13.08,12.995,12,14.514,12	c1.521,0,2.458,1.08,2.486,2.499C17,15.887,16.035,17,14.485,17z M36,36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698	c-1.501,0-2.313,1.012-2.707,1.99C24.957,25.543,25,26.511,25,27v9h-5V19h5v2.616C25.721,20.5,26.85,19,29.738,19	c3.578,0,6.261,2.25,6.261,7.274L36,36L36,36z"></path>
                    </svg>
                  </button>
                )}
              </div>
            </div>
          </form>

          <div className="mt-8 text-center">
            <p className="text-gray-600">
              Don't have an account?{' '}
              <button 
                onClick={handleCreateAccount}
                className="text-[#34261E] hover:underline font-bold"
              >
                Sign up
              </button>
            </p>
          </div>
        </div>
      </div>
      <div className="w-[30%] bg-[#34261E]"></div>
    </div>
  );
};

export default LoginForm;