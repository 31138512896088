import React, { useState } from 'react';
import { Plus, Filter, Search, Edit2, Trash2, Star } from 'lucide-react';

const AddContactForm = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    firm: '',
    contact: '',
    phone: '',
    email: '',
    status: 'Active',
    practiceArea: ''
  });

  const practiceAreas = [
    'Personal Injury',
    'Family Law',
    'Criminal Defense',
    'Real Estate',
    'Corporate',
    'Intellectual Property',
    'Other'
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    setFormData({
      firm: '',
      contact: '',
      phone: '',
      email: '',
      status: 'Active',
      practiceArea: ''
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-2xl w-full m-4">
        <h2 className="text-2xl font-semibold text-black mb-4">
          Add Contact to Private Network
        </h2>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-[#3d3731] mb-1">Firm Name</label>
              <input
                type="text"
                value={formData.firm}
                onChange={(e) => setFormData({...formData, firm: e.target.value})}
                className="w-full p-2 border border-[#d8d1c7] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-[#3d3731] mb-1">Contact Name</label>
              <input
                type="text"
                value={formData.contact}
                onChange={(e) => setFormData({...formData, contact: e.target.value})}
                className="w-full p-2 border border-[#d8d1c7] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-[#3d3731] mb-1">Phone Number</label>
              <input
                type="tel"
                value={formData.phone}
                onChange={(e) => setFormData({...formData, phone: e.target.value})}
                className="w-full p-2 border border-[#d8d1c7] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-[#3d3731] mb-1">Email</label>
              <input
                type="email"
                value={formData.email}
                onChange={(e) => setFormData({...formData, email: e.target.value})}
                className="w-full p-2 border border-[#d8d1c7] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-[#3d3731] mb-1">Practice Area</label>
              <select
                value={formData.practiceArea}
                onChange={(e) => setFormData({...formData, practiceArea: e.target.value})}
                className="w-full p-2 border border-[#d8d1c7] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                required
              >
                <option value="">Select Practice Area</option>
                {practiceAreas.map(area => (
                  <option key={area} value={area}>{area}</option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-[#3d3731] mb-1">Status</label>
              <select
                value={formData.status}
                onChange={(e) => setFormData({...formData, status: e.target.value})}
                className="w-full p-2 border border-[#d8d1c7] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                required
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>
          </div>
          
          <div className="flex justify-end space-x-2 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-[#3d3731] bg-[#d8d1c7] rounded-md hover:bg-[#c5beb2]"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-black text-white rounded-md hover:bg-[#3d3731]"
            >
              Add Contact
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const PrivateNetworkTable = ({ data, onEdit, onDelete, onToggleFavorite }) => {
  const [sortField, setSortField] = useState('firm');
  const [sortDirection, setSortDirection] = useState('asc');
  const [filterPracticeArea, setFilterPracticeArea] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const practiceAreas = [...new Set(data.map(item => item.practiceArea))];

  const sortedAndFilteredData = data
    .filter(item => {
      const matchesPracticeArea = !filterPracticeArea || item.practiceArea === filterPracticeArea;
      const matchesSearch = !searchTerm || 
        Object.values(item).some(value => 
          value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
      return matchesPracticeArea && matchesSearch;
    })
    .sort((a, b) => {
      const aValue = a[sortField]?.toString().toLowerCase();
      const bValue = b[sortField]?.toString().toLowerCase();
      return sortDirection === 'asc' 
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });

  return (
    <div className="overflow-hidden">
      <table className="w-full">
        <thead>
          <tr className="text-left text-[#3d3731] bg-[#d8d1c7]">
            <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider cursor-pointer"
                onClick={() => {
                  if (sortField === 'firm') {
                    setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
                  } else {
                    setSortField('firm');
                    setSortDirection('asc');
                  }
                }}
            >
              Firm
            </th>
            <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Firm Contact</th>
            <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Phone Number</th>
            <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Email</th>
            <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Practice Area</th>
            <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Status</th>
            <th className="px-6 py-3 text-xs font-medium uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-[#e9e4dc] divide-y divide-[#d8d1c7]">
          {sortedAndFilteredData.map((row, index) => (
            <tr key={index} className="hover:bg-[#e9e4dc]">
              <td className="px-6 py-4">
                <div className="flex items-center">
                  <div className="ml-4">
                    <div className="text-sm font-medium text-black">{row.firm}</div>
                    <div className="text-sm text-[#3d3731]">{row.practiceArea}</div>
                  </div>
                </div>
              </td>
              <td className="px-6 py-4">
                <div className="text-sm text-[#3d3731]">{row.contact}</div>
              </td>
              <td className="px-6 py-4">
                <div className="text-sm text-[#3d3731]">{row.phone}</div>
              </td>
              <td className="px-6 py-4">
                <div className="text-sm text-[#3d3731]">{row.email}</div>
              </td>
              <td className="px-6 py-4">
                <div className="text-sm text-[#3d3731]">{row.practiceArea}</div>
              </td>
              <td className="px-6 py-4">
                <span className={`px-2 py-1 text-xs leading-5 font-semibold rounded-full ${
                  row.status === 'Active'
                    ? 'bg-[#c5beb2] text-black'
                    : 'bg-[#a39b8f] text-[#3d3731]'
                }`}>
                  {row.status}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <div className="flex items-center justify-end space-x-2">
                  <button 
                    onClick={() => onToggleFavorite(row)}
                    className={`transition-colors ${row.is_favorite ? 'text-yellow-500 hover:text-yellow-600' : 'text-gray-400 hover:text-yellow-500'}`}
                  >
                    <Star size={20} fill={row.is_favorite ? "#FFD700" : "none"} />
                  </button>
                  <button onClick={() => onEdit(row)} className="text-[#a39b8f] hover:text-[#3d3731]">
                    <Edit2 className="w-5 h-5" />
                  </button>
                  <button onClick={() => onDelete(row)} className="text-[#a39b8f] hover:text-red-600">
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export { AddContactForm, PrivateNetworkTable };