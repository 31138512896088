import { supabase } from './supabase';

export const savedReferralsService = {
  saveReferral: async (referral) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      // Check if already saved to prevent duplicates
      const { data: existingSave } = await supabase
        .from('saved_referrals')
        .select('id')
        .match({ user_id: user.id, referral_id: referral.id })
        .single();

      if (existingSave) {
        return { success: true, data: existingSave };
      }

      const { data, error } = await supabase
        .from('saved_referrals')
        .insert([{
          id: crypto.randomUUID(),
          user_id: user.id,
          referral_id: referral.id,
          title: referral.title,
          description: referral.description,
          profile_pic: referral.profile_pic,
          practice_area: referral.practice_area,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        }])
        .select()
        .single();

      if (error) throw error;
      return { success: true, data };

    } catch (error) {
      console.error('Error saving referral:', error);
      return { 
        success: false, 
        error: error.message || 'Failed to save referral'
      };
    }
  },

  unsaveReferral: async (referralId) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { error } = await supabase
        .from('saved_referrals')
        .delete()
        .match({ user_id: user.id, referral_id: referralId });

      if (error) throw error;
      return { success: true };

    } catch (error) {
      console.error('Error removing saved referral:', error);
      return { 
        success: false, 
        error: error.message || 'Failed to remove saved referral'
      };
    }
  },

  checkIfSaved: async (referralId) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        return { success: true, saved: false };
      }

      const { data, error } = await supabase
        .from('saved_referrals')
        .select('id')
        .match({ user_id: user.id, referral_id: referralId })
        .maybeSingle();

      if (error) throw error;
      return { success: true, saved: !!data };

    } catch (error) {
      console.error('Error checking saved status:', error);
      return { 
        success: false, 
        error: error.message || 'Failed to check saved status'
      };
    }
  },

  fetchSavedReferrals: async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { data, error } = await supabase
        .from('saved_referrals')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      return { success: true, data };

    } catch (error) {
      console.error('Error fetching saved referrals:', error);
      return { 
        success: false, 
        error: error.message || 'Failed to fetch saved referrals'
      };
    }
  }
};