import React, { useEffect } from 'react';

const FloatingCalendlyButton = ({ isBlackAndWhite }) => {
  useEffect(() => {
    // Load Calendly widget CSS
    const link = document.createElement('link');
    link.href = 'https://assets.calendly.com/assets/external/widget.css';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    // Load Calendly widget JS
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.head.removeChild(link);
      document.body.removeChild(script);
    };
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: 'https://calendly.com/sanayshajith/lawdie-intro-call'
      });
    }
  };

  const buttonStyle = isBlackAndWhite
    ? 'bg-black text-white hover:bg-gray-800'
    : 'bg-[#FFC499] text-black hover:bg-[#FFB479]';

  return (
    <button
      onClick={handleClick}
      className={`fixed bottom-8 right-8 px-6 py-3 rounded-full font-medium shadow-lg transition-all duration-300 transform hover:scale-105 z-50 ${buttonStyle}`}
    >
      Chat with us
    </button>
  );
};

export default FloatingCalendlyButton;