import React from 'react';
import { MoreVertical } from 'lucide-react';
import ReferralAvatars from '../referrals/ReferralAvatars';

const StatCard = ({ 
  title, 
  value, 
  change, 
  showAvatars, 
  avatarType,
  metricDropdown  // Changed from extraControls to be more specific
}) => (
  <div className="bg-[#e9e4dc] p-6 rounded-lg shadow">
    <div className="flex justify-between items-center mb-2">
      <h3 className="text-sm text-[#3d3731]">{title}</h3>
      {metricDropdown ? (
        <div className="relative">
          {metricDropdown}
        </div>
      ) : (
        <MoreVertical size={20} className="text-[#a39b8f]" />
      )}
    </div>
    <div className="flex items-baseline">
      <span className="text-3xl font-bold mr-2 text-[#3d3731]">{value}</span>
      {change && <span className="text-sm text-green-500">{change}</span>}
    </div>
    {showAvatars && (
      <div className="mt-4">
        <ReferralAvatars 
          maxVisible={4} 
          type={title.toLowerCase().includes('incoming') ? 'incoming' : 'outgoing'} 
        />
      </div>
    )}
  </div>
);

export default StatCard;