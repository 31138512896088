import React, { useEffect, useRef, useState } from 'react';

const ZoomTransition = ({ isBlackAndWhite }) => {
  const [containerSize, setContainerSize] = useState(90);
  const [isVisible, setIsVisible] = useState(false);
  const [showText, setShowText] = useState(false);
  const sectionRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!sectionRef.current) return;

      const rect = sectionRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      
      // Start showing when about halfway through previous section
      const shouldBeVisible = rect.top <= viewportHeight * 0.6;
      setIsVisible(shouldBeVisible);
      
      if (!shouldBeVisible) {
        setContainerSize(90);
        setShowText(false);
        return;
      }
      
      const scrollProgress = (viewportHeight - rect.top) / viewportHeight;
      const clampedProgress = Math.max(0, Math.min(1, scrollProgress));
      const eased = Math.pow(clampedProgress, 2);
      
      if (clampedProgress <= 0.85) {
        const normalizedProgress = eased / 0.85;
        const newSize = 90 - (normalizedProgress * 25);
        setContainerSize(newSize);
        setShowText(false);
      } else {
        setContainerSize(65);
        // Show text when zoom animation settles
        setShowText(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const containerStyle = isBlackAndWhite
    ? 'bg-white'
    : 'bg-[#0f0b08]';

  const glowColor = isBlackAndWhite
    ? 'rgba(255, 255, 255, 0.1)'
    : 'rgba(186, 123, 85, 0.15)';

  const shadowColor = isBlackAndWhite
    ? '0px 0px 100px rgba(0, 0, 0, 0.2)'
    : '0px 0px 100px rgba(186, 123, 85, 0.3)';

  const textColor = isBlackAndWhite
    ? 'text-gray-900'
    : 'text-[#FFC499]';

  return (
    <section 
      ref={sectionRef}
      className={`relative h-screen ${containerStyle}`}
      style={{
        opacity: isVisible ? 1 : 0,
        transform: `translateY(${isVisible ? '0' : '40px'})`,
        transition: 'opacity 0.4s ease-out, transform 0.4s ease-out'
      }}
    >
      {/* Background Effect Circles */}
      <div 
        className="absolute inset-0 overflow-hidden"
        style={{
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 0.4s ease-out'
        }}
      >
        <div
          className="absolute w-full h-full"
          style={{
            background: `radial-gradient(circle at 30% 50%, ${glowColor} 0%, transparent 60%),
                        radial-gradient(circle at 70% 50%, ${glowColor} 0%, transparent 60%)`,
            transform: `scale(${1 + (90 - containerSize) / 90})`,
            transition: 'transform 0.5s ease-out'
          }}
        />
      </div>

      <div className="sticky top-0 h-screen flex flex-col items-center justify-center">
        {/* Animated Text */}
        <div 
          className={`mb-8 transform transition-all duration-700 ${
            showText 
              ? 'opacity-100 translate-y-0' 
              : 'opacity-0 -translate-y-8'
          }`}
        >
          <h2 className={`text-5xl font-bold ${textColor}`}>
            Introducing Kiwi AI
          </h2>
        </div>

        {/* Image Container */}
        <div 
          ref={imageRef}
          style={{
            width: `${containerSize}%`,
            transition: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
            boxShadow: shadowColor,
            transform: `scale(${isVisible ? 1 : 0.95})`
          }}
          className="rounded-2xl overflow-hidden relative"
        >
          {/* Ambient Light Effect */}
          <div 
            className="absolute inset-0 opacity-50"
            style={{
              background: `radial-gradient(circle at 50% 50%, ${glowColor} 0%, transparent 70%)`,
              mixBlendMode: 'soft-light'
            }}
          />
          
          <img
            src="/landing_page_ss.jpg"
            alt="Transition"
            className="w-full h-auto object-contain relative z-10"
          />
        </div>
      </div>
    </section>
  );
};

export default ZoomTransition;