import React from 'react';

const TestimonialCard = ({ name, role, comment, isBlackAndWhite }) => {
  const cardStyle = isBlackAndWhite
    ? 'bg-white border border-gray-200'
    : 'bg-[#1a1410] border border-[#2a2118]';

  const nameColor = isBlackAndWhite ? 'text-gray-800' : 'text-[#d8d1c7]';
  const roleColor = isBlackAndWhite ? 'text-gray-600' : 'text-[#a39b8f]';
  const commentColor = isBlackAndWhite ? 'text-gray-600' : 'text-[#8a8178]';

  return (
    <div className={`p-6 rounded-lg flex-shrink-0 w-96 mx-4 ${cardStyle}`}>
      <p className={`mb-4 ${commentColor}`}>{comment}</p>
      <div className="flex items-center">
        <img src="/api/placeholder/40/40" alt={name} className="w-10 h-10 rounded-full mr-4" />
        <div>
          <h4 className={`font-medium ${nameColor}`}>{name}</h4>
          <p className={`text-sm ${roleColor}`}>{role}</p>
        </div>
      </div>
    </div>
  );
};

const Testimonials = ({ isBlackAndWhite = false }) => {
  const testimonials = [
    { name: "Amanda Russel", role: "Kalka Law Group", comment: "The platform has revolutionized how we handle referrals. It's intuitive and efficient!" },
    { name: "John Smith", role: "Smith & Associates", comment: "Lawdie has helped us expand our network and streamline our case management." },
    { name: "Sarah Johnson", role: "Johnson Law Firm", comment: "Outstanding platform for managing client relationships and growing our practice." },
    { name: "Michael Chen", role: "Chen Legal Group", comment: "The referral management system has been a game-changer for our firm." }
  ];

  const containerStyle = isBlackAndWhite
    ? 'bg-gray-100'
    : 'bg-[#0f0b08] text-[#d8d1c7]';

  const headingStyle = isBlackAndWhite
    ? 'text-gray-800'
    : 'text-[#d8d1c7]';

  return (
    <section className={`py-16 overflow-hidden ${containerStyle}`}>
      <div className="container mx-auto px-4">
        <h2 className={`text-3xl font-bold mb-12 text-center ${headingStyle}`}>
          Hear what our customers say
        </h2>
        <div className="relative">
          <div className="flex overflow-hidden">
            <div className="flex animate-testimonials">
              {[...testimonials, ...testimonials].map((testimonial, index) => (
                <TestimonialCard
                  key={index}
                  {...testimonial}
                  isBlackAndWhite={isBlackAndWhite}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        @keyframes testimonialScroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }
        .animate-testimonials {
          animation: testimonialScroll 30s linear infinite;
        }
        .animate-testimonials:hover {
          animation-play-state: paused;
        }
      `}</style>
    </section>
  );
};

export default Testimonials;