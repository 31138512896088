// src/components/KiwiAI/Settings.jsx
import React from 'react';
import { Bell, Moon, Globe, Lock, Shield, Key, Headphones, HelpCircle } from 'lucide-react';

const Settings = () => {
  const settingsSections = [
    {
      title: 'Preferences',
      items: [
        { icon: Bell, label: 'Notifications', description: 'Configure notification preferences' },
        { icon: Moon, label: 'Appearance', description: 'Dark mode and theme settings' },
        { icon: Globe, label: 'Language', description: 'Change language preferences' }
      ]
    },
    {
      title: 'Privacy & Security',
      items: [
        { icon: Lock, label: 'Privacy', description: 'Manage your privacy settings' },
        { icon: Shield, label: 'Security', description: 'Configure security options' },
        { icon: Key, label: 'API Keys', description: 'Manage your API keys' }
      ]
    },
    {
      title: 'Support',
      items: [
        { icon: Headphones, label: 'Help & Support', description: 'Get help with Kiwi AI' },
        { icon: HelpCircle, label: 'About', description: 'Learn more about Kiwi AI' }
      ]
    }
  ];

  return (
    <div className="flex-1 flex flex-col bg-[#34261E] p-6">
      <div className="mb-6">
        <h1 className="text-[#e1e1e1] text-2xl font-bold mb-2">Settings</h1>
        <p className="text-[#666666] text-sm">Manage your preferences and account settings</p>
      </div>

      <div className="space-y-8">
        {settingsSections.map((section, index) => (
          <div key={index} className="bg-[#3A302E] rounded-xl p-6">
            <h2 className="text-[#23C69E] text-lg font-semibold mb-4">{section.title}</h2>
            <div className="space-y-4">
              {section.items.map((item, itemIndex) => (
                <button
                  key={itemIndex}
                  className="w-full flex items-center gap-4 p-4 rounded-lg hover:bg-[#252422] transition-colors"
                >
                  <div className="bg-[#1E1F22] p-2 rounded-lg">
                    <item.icon size={20} className="text-[#23C69E]" />
                  </div>
                  <div className="flex-1 text-left">
                    <h3 className="text-[#e1e1e1] text-sm font-medium">{item.label}</h3>
                    <p className="text-[#666666] text-xs">{item.description}</p>
                  </div>
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="mt-auto pt-6 text-center">
        <p className="text-[#666666] text-xs">Kiwi AI Version 1.0.0</p>
      </div>
    </div>
  );
};

export default Settings;