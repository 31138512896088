// src/backend/documentService.js
import { supabase } from './supabase';

class DocumentService {
  async getDocuments() {
    try {
      const { data, error } = await supabase
        .from('documents')
        .select('*')
        .order('created_at', { ascending: false });
      
      return { data, error };
    } catch (error) {
      console.error('Error fetching documents:', error);
      return { data: null, error };
    }
  }

  async getDocument(id) {
    try {
      const { data, error } = await supabase
        .from('documents')
        .select('*')
        .eq('id', id)
        .single();
      
      return { data, error };
    } catch (error) {
      console.error('Error fetching document:', error);
      return { data: null, error };
    }
  }

  async uploadDocument(file) {
    try {
      const fileName = `${Date.now()}-${file.name}`;
      const filePath = `documents/${fileName}`;

      // Upload to storage
      const { error: uploadError } = await supabase
        .storage
        .from('documents')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      // Create document record
      const { data, error: dbError } = await supabase
        .from('documents')
        .insert({
          file_name: file.name,
          file_type: file.type,
          file_size: file.size,
          url_id: filePath,
          version_count: 1
        })
        .select()
        .single();

      if (dbError) throw dbError;

      return { data, error: null };
    } catch (error) {
      console.error('Error uploading document:', error);
      return { data: null, error };
    }
  }

  async downloadDocument(id, versionHash = null) {
    try {
      const { data: document, error: docError } = await this.getDocument(id);
      if (docError) throw docError;

      // If version hash is provided, get that version's content
      let filePath = document.url_id;
      if (versionHash) {
        const { data: version, error: versionError } = await supabase
          .from('document_objects')
          .select('content')
          .eq('hash', versionHash)
          .single();
          
        if (versionError) throw versionError;
        filePath = version.content.url_id;
      }

      // Download file
      const { data, error: downloadError } = await supabase
        .storage
        .from('documents')
        .download(filePath);

      if (downloadError) throw downloadError;

      return { data, error: null };
    } catch (error) {
      console.error('Error downloading document:', error);
      return { data: null, error };
    }
  }

  // Add to your documentService.js

  async uploadNewVersion(documentId, file) {
    try {
      const fileName = `${Date.now()}-${file.name}`;
      const filePath = `documents/${fileName}`;

      // Upload to storage
      const { error: uploadError } = await supabase
        .storage
        .from('documents')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      return {
        data: {
          url_id: filePath,
          file_size: file.size,
          file_type: file.type
        },
        error: null
      };
    } catch (error) {
      console.error('Error uploading new version:', error);
      return { data: null, error };
    }
  }

  async downloadFile(filePath, threadInfo) {
    try {
      console.log('Starting download for:', filePath);

      if (filePath.startsWith('sandbox:')) {
        // Extract file name from path
        const fileName = filePath.split('/').pop();
        
        // Navigate to our download handler with thread info
        window.location.href = `/api/download-file?threadId=${encodeURIComponent(threadInfo.threadId)}&messageId=${encodeURIComponent(threadInfo.messageId)}&fileName=${encodeURIComponent(fileName)}`;
      }
      
      return true;
    } catch (error) {
      console.error('Error in downloadFile:', error);
      throw error;
    }
  }

  async getLienDocuments(lienId) {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { data, error } = await supabase
        .from('documents')
        .select('*')
        .eq('for_lien', lienId)
        .order('created_at', { ascending: false });

      if (error) throw error;
      return { data, error: null };
    } catch (error) {
      console.error('Error fetching lien documents:', error);
      return { data: null, error };
    }
  }

  async uploadLienDocument(file, lienId, s3UploadResult) {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user');

      const { data, error } = await supabase
        .from('documents')
        .insert([{
          url_id: s3UploadResult.key,
          created_by: user.id,
          file_name: file.name,
          file_type: file.type,
          file_size: s3UploadResult.size,
          is_a_lien: true,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          folder: 'lien',
          for_lien: lienId
        }])
        .select()
        .single();

      if (error) throw error;
      return { data, error: null };
    } catch (error) {
      console.error('Error creating document record:', error);
      return { data: null, error };
    }
  }

  async deleteDocument(documentId) {
    try {
      const { error } = await supabase
        .from('documents')
        .delete()
        .eq('id', documentId);

      if (error) throw error;
      return { error: null };
    } catch (error) {
      console.error('Error deleting document:', error);
      return { error };
    }
  }

}

export const documentService = new DocumentService();