import React, { useState } from 'react';
import { 
  Bell,
  Info,
  CheckCircle,
  AlertTriangle,
  XCircle,
  ChevronRight,
  Archive,
  Trash2, 
  RefreshCw
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const MOCK_NOTIFICATIONS = [
  {
    id: 1,
    type: 'success',
    title: 'Document uploaded successfully',
    message: 'Your lien document has been processed and stored securely.',
    created_at: '2024-01-15T10:30:00',
    is_read: false,
    link: '/dashboard/documents/123'
  },
  {
    id: 2,
    type: 'info',
    title: 'New referral received',
    message: 'John Doe has referred a new case to you.',
    created_at: '2024-01-14T15:45:00',
    is_read: false,
    link: '/dashboard/referrals/456'
  },
  {
    id: 3,
    type: 'warning',
    title: 'Lien expiring soon',
    message: 'Lien #789 will expire in 30 days. Please take action.',
    created_at: '2024-01-13T09:20:00',
    is_read: true,
    link: '/dashboard/liens/789'
  },
  {
    id: 4,
    type: 'error',
    title: 'Failed to process document',
    message: 'The system encountered an error while processing your document.',
    created_at: '2024-01-12T14:15:00',
    is_read: true
  }
];

const NotificationTypes = {
  info: { icon: Info, className: 'text-blue-500 bg-blue-50' },
  success: { icon: CheckCircle, className: 'text-green-500 bg-green-50' },
  warning: { icon: AlertTriangle, className: 'text-yellow-500 bg-yellow-50' },
  error: { icon: XCircle, className: 'text-red-500 bg-red-50' }
};

const Notification = ({ notification, onMarkRead, onDelete, onViewDetails }) => {
  const { icon: Icon, className } = NotificationTypes[notification.type] || NotificationTypes.info;
  
  return (
    <div className={`flex items-start p-4 ${notification.is_read ? 'bg-[#f5f1eb]' : 'bg-white'} border-b border-[#d8d1c7]`}>
      <div className={`p-2 rounded-full ${className}`}>
        <Icon className="w-5 h-5" />
      </div>
      <div className="ml-3 flex-1">
        <div className="flex items-start justify-between">
          <div>
            <h3 className="text-sm font-medium text-black">{notification.title}</h3>
            <p className="mt-1 text-sm text-[#3d3731]">{notification.message}</p>
            <div className="mt-2 text-xs text-[#a39b8f]">
              {new Date(notification.created_at).toLocaleString()}
            </div>
          </div>
          <div className="ml-4 flex">
            {!notification.is_read && (
              <button
                onClick={() => onMarkRead(notification.id)}
                className="p-1 hover:bg-[#d8d1c7] rounded-md"
              >
                <Archive className="w-4 h-4 text-[#3d3731]" />
              </button>
            )}
            <button
              onClick={() => onDelete(notification.id)}
              className="p-1 hover:bg-[#d8d1c7] rounded-md ml-1"
            >
              <Trash2 className="w-4 h-4 text-[#3d3731]" />
            </button>
          </div>
        </div>
        {notification.link && (
          <button
            onClick={() => onViewDetails(notification.link)}
            className="mt-2 inline-flex items-center text-sm text-[#3d3731] hover:text-black"
          >
            View details
            <ChevronRight className="w-4 h-4 ml-1" />
          </button>
        )}
      </div>
    </div>
  );
};

const NotificationsPage = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState(MOCK_NOTIFICATIONS);
  const [filter, setFilter] = useState('all');

  const filteredNotifications = notifications.filter(n => {
    if (filter === 'unread') return !n.is_read;
    if (filter === 'read') return n.is_read;
    return true;
  });

  const handleMarkRead = (id) => {
    setNotifications(prevNotifications =>
      prevNotifications.map(notification =>
        notification.id === id ? { ...notification, is_read: true } : notification
      )
    );
  };

  const handleDelete = (id) => {
    setNotifications(prevNotifications =>
      prevNotifications.filter(notification => notification.id !== id)
    );
  };

  const handleMarkAllRead = () => {
    setNotifications(prevNotifications =>
      prevNotifications.map(notification => ({ ...notification, is_read: true }))
    );
  };

  const handleViewDetails = (link) => {
    navigate(link);
  };

  return (
    <div className="min-h-screen bg-[#d8d1c7] flex flex-col">
      {/* Header */}
      <div className="bg-[#e9e4dc] border-b border-[#a39b8f] p-6">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <Bell className="w-6 h-6 text-black mr-2" />
            <h1 className="text-2xl font-bold text-black">Notifications</h1>
          </div>
          <div className="flex items-center space-x-4">
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="px-3 py-2 border border-[#a39b8f] rounded-md bg-white text-[#3d3731] focus:outline-none focus:ring-2 focus:ring-black"
            >
              <option value="all">All</option>
              <option value="unread">Unread</option>
              <option value="read">Read</option>
            </select>
            <button
              onClick={handleMarkAllRead}
              className="px-4 py-2 text-[#3d3731] bg-[#d8d1c7] border border-[#a39b8f] rounded-md hover:bg-[#c5beb2] transition-colors duration-150"
            >
              Mark all as read
            </button>
            <button
              onClick={() => setNotifications(MOCK_NOTIFICATIONS)}
              className="p-2 text-[#3d3731] hover:text-black"
            >
              <RefreshCw className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="flex-1 overflow-auto">
        {filteredNotifications.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-64 text-[#3d3731]">
            <Bell className="w-12 h-12 mb-4 text-[#a39b8f]" />
            <p className="text-lg">No notifications to display</p>
          </div>
        ) : (
          <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-sm border border-[#d8d1c7] my-6">
            {filteredNotifications.map((notification) => (
              <Notification
                key={notification.id}
                notification={notification}
                onMarkRead={handleMarkRead}
                onDelete={handleDelete}
                onViewDetails={handleViewDetails}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationsPage;