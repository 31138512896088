import React from 'react';
import { MessageCircle, SlidersHorizontal, Compass, Hexagon, Settings, ChevronRight } from 'lucide-react';

const Navigation = ({ selectedComponent, onSelectComponent }) => {
  return (
    <div className="w-[60px] bg-[#110703] flex flex-col items-center py-3">
      <div className="mb-6">
        <img src="/ai_logo.png" alt="Kiwi AI" className="w-8 h-8 rounded-lg" />
      </div>

      <div className="flex-1 flex flex-col items-center space-y-6 mt-8">
        <NavButton 
          icon={MessageCircle}
          isSelected={selectedComponent === 'chat'}
          onClick={() => onSelectComponent('chat')}
        />
        <NavButton 
          icon={SlidersHorizontal}
          isSelected={selectedComponent === 'sliders'}
          onClick={() => onSelectComponent('sliders')}
          color="#23C69E"
        />
        <NavButton 
          icon={Compass}
          isSelected={selectedComponent === 'compass'}
          onClick={() => onSelectComponent('compass')}
          color="#23C69E"
        />
        <NavButton 
          icon={Hexagon}
          isSelected={selectedComponent === 'hexagon'}
          onClick={() => onSelectComponent('hexagon')}
          color="#23C69E"
        />
      </div>

      <div className="flex flex-col items-center space-y-3">
        <button className="w-6 h-6 rounded-lg bg-[#f0e4d8] flex items-center justify-center">
          <img src="/api/placeholder/24/24" alt="Profile" className="w-6 h-6 rounded-lg" />
        </button>
        <button 
          onClick={() => onSelectComponent('settings')}
          className="w-8 h-8 rounded-lg flex items-center justify-center text-[#4a4a4a] hover:bg-[#3A302E]"
        >
          <Settings size={16} />
        </button>
      </div>
    </div>
  );
};

const NavButton = ({ icon: Icon, isSelected, onClick, color = "white" }) => (
  <button 
    onClick={onClick}
    className="w-8 h-8 rounded-lg flex items-center justify-center text-[#4a4a4a] bg-[#211714] hover:bg-[#3A302E] relative"
  >
    {isSelected && (
      <ChevronRight size={20} className="absolute -left-4 fill-[#1F2937]" strokeWidth={0}/>
    )}
    <Icon size={19} color={color} strokeWidth={1.5} />
  </button>
);

export default Navigation;