import React, { useState, useEffect } from 'react';
import { Star } from 'lucide-react';

const AnimatedNumber = ({ end, duration, isBlackAndWhite }) => {
  const [count, setCount] = useState(0);
  const [hasStarted, setHasStarted] = useState(false);
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasStarted) {
          setHasStarted(true);
        }
      },
      { threshold: 0.5 }
    );

    const element = document.getElementById('stats-section');
    if (element) observer.observe(element);

    return () => {
      if (element) observer.unobserve(element);
    };
  }, [hasStarted]);

  useEffect(() => {
    if (!hasStarted) return;

    const interval = Math.floor(duration / end); // Time per increment to reach target in 90 seconds

    const timer = setInterval(() => {
      setCount(prevCount => {
        if (prevCount >= end) {
          clearInterval(timer);
          return end;
        }
        return prevCount + 1;
      });
    }, interval);

    return () => clearInterval(timer);
  }, [end, duration, hasStarted]);

  return (
    <span className={`text-4xl font-bold ${
      isBlackAndWhite ? 'text-gray-900' : 'text-[#FFC499]'
    }`}>
      {count.toLocaleString()}
    </span>
  );
};

const AnimatedStars = ({ isBlackAndWhite }) => {
  const [stars, setStars] = useState(0);
  const [hasStarted, setHasStarted] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasStarted) {
          setHasStarted(true);
        }
      },
      { threshold: 0.5 }
    );

    const element = document.getElementById('stats-section');
    if (element) observer.observe(element);

    return () => {
      if (element) observer.unobserve(element);
    };
  }, [hasStarted]);

  useEffect(() => {
    if (!hasStarted) return;

    const interval = setInterval(() => {
      setStars(prev => {
        if (prev < 5) return prev + 1;
        clearInterval(interval);
        return prev;
      });
    }, 400);

    return () => clearInterval(interval);
  }, [hasStarted]);

  return (
    <div className="flex gap-1">
      {[...Array(5)].map((_, index) => (
        <Star
          key={index}
          size={32}
          className={`transform transition-all duration-300 ${
            index < stars 
              ? isBlackAndWhite 
                ? 'fill-yellow-400 text-yellow-400'
                : 'fill-[#FFC499] text-[#FFC499]'
              : 'text-gray-300'
          }`}
        />
      ))}
    </div>
  );
};

const WhyChooseUs = ({ isBlackAndWhite = false }) => {
  const containerStyle = isBlackAndWhite ? 'bg-white' : 'bg-[#0f0b08]';
  const cardStyle = isBlackAndWhite
    ? 'bg-gray-50 border-gray-200'
    : 'bg-[#1a1410] border-[#2a2118]';
  const titleColor = isBlackAndWhite ? 'text-gray-900' : 'text-[#d8d1c7]';
  const textColor = isBlackAndWhite ? 'text-gray-600' : 'text-[#8a8178]';

      const stats = [
    {
      title: "Your Case Acquisitions",
      content: <AnimatedNumber end={2500} duration={90000} isBlackAndWhite={isBlackAndWhite} />,
      suffix: "+"
    },
    {
      title: "Hours Saved",
      content: <AnimatedNumber end={1000} duration={90000} isBlackAndWhite={isBlackAndWhite} />,
      suffix: "+"
    },
    {
      title: "Client Satisfaction",
      content: <AnimatedStars isBlackAndWhite={isBlackAndWhite} />,
      suffix: ""
    }
  ];

  return (
    <section id="stats-section" className={`py-24 ${containerStyle}`}>
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className={`text-4xl font-bold mb-6 ${titleColor}`}>
            Why Choose Us
          </h2>
          <p className={`text-xl max-w-2xl mx-auto ${textColor}`}>
            Empowering legal professionals with innovative solutions that drive growth and efficiency
          </p>
        </div>
        
        <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {stats.map((stat, index) => (
            <div
              key={index}
              className={`border ${cardStyle} rounded-2xl p-8 flex flex-col items-center text-center transform transition-all duration-300 hover:scale-105`}
            >
              <h3 className={`text-xl font-semibold mb-6 ${titleColor}`}>
                {stat.title}
              </h3>
              <div className="flex items-center gap-2">
                {stat.content}
                <span className={`text-2xl font-bold ${
                  isBlackAndWhite ? 'text-gray-900' : 'text-[#FFC499]'
                }`}>
                  {stat.suffix}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;