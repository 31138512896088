import React, { useState, useEffect } from 'react';
import { supabase } from '../../backend/supabase';
import { userToUserReferralService } from '../../backend/referralServices/userToUserReferral';
import { outgoingReferralService } from '../../backend/referralServices/outgoingReferrals';

const ReferralAvatars = ({ maxVisible = 4, type = 'outgoing' }) => {
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReferrals = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return;

        let result;
        if (type === 'outgoing') {
          // Use outgoingReferralService
          const { data } = await outgoingReferralService.fetchAllOutgoingReferrals(user.id);
          result = (data || []).slice(0, maxVisible + 1).map(ref => ({
            id: ref.id,
            non_users: {
              first_name: ref.contact?.split(' ')[0] || '',
              last_name: ref.contact?.split(' ').slice(1).join(' ') || ''
            }
          }));
        } else {
          // Use userToUserReferralService for incoming
          const { data } = await userToUserReferralService.fetchIncomingReferrals(user.id);
          result = (data || []).slice(0, maxVisible + 1).map(ref => ({
            id: ref.id,
            non_users: {
              first_name: ref.contact?.split(' ')[0] || '',
              last_name: ref.contact?.split(' ').slice(1).join(' ') || ''
            }
          }));
        }

        setReferrals(result || []);
      } catch (error) {
        console.error('Error fetching referrals:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReferrals();
  }, [maxVisible, type]);

  if (loading) {
    return (
      <div className="flex -space-x-2">
        {[...Array(maxVisible)].map((_, i) => (
          <div 
            key={i} 
            className="w-6 h-6 rounded-full bg-[#d8d1c7] animate-pulse ring-2 ring-[#e9e4dc]" 
          />
        ))}
      </div>
    );
  }

  const visibleReferrals = referrals.slice(0, maxVisible);
  const remainingCount = Math.max(0, referrals.length - maxVisible);

  return (
    <div className="flex -space-x-2">
      {visibleReferrals.map((referral) => (
        <div
          key={referral.id}
          className="w-6 h-6 rounded-full bg-[#3d3731] text-[#e9e4dc] text-xs flex items-center justify-center ring-2 ring-[#e9e4dc]"
          title={`${referral.non_users?.first_name} ${referral.non_users?.last_name}`}
        >
          {`${referral.non_users?.first_name?.[0] || ''}${referral.non_users?.last_name?.[0] || ''}`}
        </div>
      ))}
      {remainingCount > 0 && (
        <span className="flex items-center justify-center w-6 h-6 rounded-full bg-[#d8d1c7] text-xs font-medium text-[#3d3731] ring-2 ring-[#e9e4dc]">
          +{remainingCount}
        </span>
      )}
    </div>
  );
};

export default ReferralAvatars;