import React, { useState, useEffect } from 'react';
import { supabase } from '../../backend/supabase';
import { 
  User,
  Building,
  Mail,
  Phone,
  MapPin,
  Briefcase,
  Globe,
  Bell,
  Shield,
  Key,
  X,
  Save,
  AlertCircle,
  CheckCircle
} from 'lucide-react';

const SettingsPage = () => {
  const [activeTab, setActiveTab] = useState('profile');
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [user, setUser] = useState(null);
  const [lawyerProfile, setLawyerProfile] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [formData, setFormData] = useState({
    // Personal Info
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phone: '',
    
    // Address
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    
    // Attorney-specific fields
    firmName: '',
    barNumber: '',
    practiceAreas: [],
    yearsOfExperience: '',
    bio: '',
    website: '',
    officeAddress: '',
    isVisible: true,
    
    // Preferences
    emailNotifications: true,
    smsNotifications: true,
    publicProfile: false
  });

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const { data: { user: authUser }, error: authError } = await supabase.auth.getUser();
      if (authError) throw authError;

      // Fetch user profile
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('*')
        .eq('id', authUser.id)
        .single();
      
      if (userError) throw userError;

      // If attorney, fetch lawyer profile
      if (userData.user_type === 'attorney') {
        const { data: lawyerData, error: lawyerError } = await supabase
          .from('lawyer_profiles')
          .select('*')
          .eq('user_id', authUser.id)
          .single();
        
        if (lawyerError) throw lawyerError;
        setLawyerProfile(lawyerData);
      }

      setUser(userData);
      setFormData({
        firstName: userData.first_name || '',
        middleName: userData.middle_name || '',
        lastName: userData.last_name || '',
        email: userData.email || '',
        phone: userData.phone || '',
        streetAddress: userData.street_address || '',
        city: userData.city || '',
        state: userData.state || '',
        zipCode: userData.zip_code || '',
        publicProfile: userData.is_public || false,
        ...(lawyerProfile && {
          firmName: lawyerProfile.firm_name || '',
          barNumber: lawyerProfile.bar_number || '',
          practiceAreas: lawyerProfile.practice_areas || [],
          yearsOfExperience: lawyerProfile.years_of_experience || '',
          bio: lawyerProfile.bio || '',
          website: lawyerProfile.website || '',
          officeAddress: lawyerProfile.office_address || '',
          isVisible: lawyerProfile.is_visible
        })
      });

    } catch (error) {
      console.error('Error fetching user data:', error);
      setErrorMessage('Failed to load user data');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      // Update user profile
      const { error: userError } = await supabase
        .from('users')
        .update({
          first_name: formData.firstName,
          middle_name: formData.middleName,
          last_name: formData.lastName,
          phone: formData.phone,
          street_address: formData.streetAddress,
          city: formData.city,
          state: formData.state,
          zip_code: formData.zipCode,
          is_public: formData.publicProfile
        })
        .eq('id', user.id);

      if (userError) throw userError;

      // Update lawyer profile if applicable
      if (user.user_type === 'attorney') {
        const { error: lawyerError } = await supabase
          .from('lawyer_profiles')
          .update({
            firm_name: formData.firmName,
            practice_areas: formData.practiceAreas,
            years_of_experience: formData.yearsOfExperience,
            bio: formData.bio,
            website: formData.website,
            office_address: formData.officeAddress,
            is_visible: formData.isVisible
          })
          .eq('user_id', user.id);

        if (lawyerError) throw lawyerError;
      }

      setSuccessMessage('Settings updated successfully');
    } catch (error) {
      console.error('Error updating settings:', error);
      setErrorMessage('Failed to update settings');
    } finally {
      setSaving(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-[#f5f1eb]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#3d3731]" />
      </div>
    );
  }

  const renderTab = () => {
    switch (activeTab) {
      case 'profile':
        return (
          <div className="space-y-6">
            <div className="bg-[#e9e4dc] p-6 rounded-lg border border-[#a39b8f]">
              <h3 className="text-lg font-semibold text-black mb-4">Personal Information</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-[#3d3731] mb-2">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-[#a39b8f] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-[#3d3731] mb-2">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-[#a39b8f] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                  />
                </div>
                <div className="col-span-2">
                  <label className="block text-sm font-medium text-[#3d3731] mb-2">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    disabled
                    className="w-full px-4 py-2 border border-[#a39b8f] rounded-md bg-[#d8d1c7]"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-[#3d3731] mb-2">Phone</label>
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-[#a39b8f] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-[#3d3731] mb-2">Public Profile</label>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      name="publicProfile"
                      checked={formData.publicProfile}
                      onChange={handleChange}
                      className="h-4 w-4 text-black border-[#a39b8f] rounded"
                    />
                    <span className="ml-2 text-sm text-[#3d3731]">Make my profile public</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-[#e9e4dc] p-6 rounded-lg border border-[#a39b8f]">
              <h3 className="text-lg font-semibold text-black mb-4">Address</h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-[#3d3731] mb-2">Street Address</label>
                  <input
                    type="text"
                    name="streetAddress"
                    value={formData.streetAddress}
                    onChange={handleChange}
                    className="w-full px-4 py-2 border border-[#a39b8f] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                  />
                </div>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  <div className="col-span-2">
                    <label className="block text-sm font-medium text-[#3d3731] mb-2">City</label>
                    <input
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border border-[#a39b8f] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-[#3d3731] mb-2">State</label>
                    <input
                      type="text"
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border border-[#a39b8f] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-[#3d3731] mb-2">ZIP Code</label>
                    <input
                      type="text"
                      name="zipCode"
                      value={formData.zipCode}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border border-[#a39b8f] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                    />
                  </div>
                </div>
              </div>
            </div>

            {user.user_type === 'attorney' && (
              <div className="bg-[#e9e4dc] p-6 rounded-lg border border-[#a39b8f]">
                <h3 className="text-lg font-semibold text-black mb-4">Professional Information</h3>
                <div className="space-y-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-[#3d3731] mb-2">Firm Name</label>
                      <input
                        type="text"
                        name="firmName"
                        value={formData.firmName}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border border-[#a39b8f] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-[#3d3731] mb-2">Bar Number</label>
                      <input
                        type="text"
                        name="barNumber"
                        value={formData.barNumber}
                        disabled
                        className="w-full px-4 py-2 border border-[#a39b8f] rounded-md bg-[#d8d1c7]"
                      />
                    </div>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-[#3d3731] mb-2">Bio</label>
                    <textarea
                      name="bio"
                      value={formData.bio}
                      onChange={handleChange}
                      rows={4}
                      className="w-full px-4 py-2 border border-[#a39b8f] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-[#3d3731] mb-2">Website</label>
                    <input
                      type="url"
                      name="website"
                      value={formData.website}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border border-[#a39b8f] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-[#3d3731] mb-2">Office Address</label>
                    <input
                      type="text"
                      name="officeAddress"
                      value={formData.officeAddress}
                      onChange={handleChange}
                      className="w-full px-4 py-2 border border-[#a39b8f] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                    />
                  </div>

                  <div>
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="isVisible"
                        checked={formData.isVisible}
                        onChange={handleChange}
                        className="h-4 w-4 text-black border-[#a39b8f] rounded"
                      />
                      <span className="ml-2 text-sm text-[#3d3731]">
                        Show my profile in public attorney directory
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>
        );

      case 'notifications':
        return (
          <div className="space-y-6">
            <div className="bg-[#e9e4dc] p-6 rounded-lg border border-[#a39b8f]">
              <h3 className="text-lg font-semibold text-black mb-4">Email Notifications</h3>
              <div className="space-y-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="emailNewReferrals"
                    checked={formData.emailNewReferrals}
                    onChange={handleChange}
                    className="h-4 w-4 text-black border-[#a39b8f] rounded"
                  />
                  <span className="ml-2 text-sm text-[#3d3731]">
                    New referral notifications
                  </span>
                </label>

                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="emailDocuments"
                    checked={formData.emailDocuments}
                    onChange={handleChange}
                    className="h-4 w-4 text-black border-[#a39b8f] rounded"
                  />
                  <span className="ml-2 text-sm text-[#3d3731]">
                    Document updates
                  </span>
                </label>

                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="emailMarketing"
                    checked={formData.emailMarketing}
                    onChange={handleChange}
                    className="h-4 w-4 text-black border-[#a39b8f] rounded"
                  />
                  <span className="ml-2 text-sm text-[#3d3731]">
                    Marketing and promotional emails
                  </span>
                </label>
              </div>
            </div>

            <div className="bg-[#e9e4dc] p-6 rounded-lg border border-[#a39b8f]">
              <h3 className="text-lg font-semibold text-black mb-4">SMS Notifications</h3>
              <div className="space-y-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="smsNewReferrals"
                    checked={formData.smsNewReferrals}
                    onChange={handleChange}
                    className="h-4 w-4 text-black border-[#a39b8f] rounded"
                  />
                  <span className="ml-2 text-sm text-[#3d3731]">
                    New referral notifications
                  </span>
                </label>

                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="smsDocuments"
                    checked={formData.smsDocuments}
                    onChange={handleChange}
                    className="h-4 w-4 text-black border-[#a39b8f] rounded"
                  />
                  <span className="ml-2 text-sm text-[#3d3731]">
                    Document updates
                  </span>
                </label>
              </div>
            </div>
          </div>
        );

      case 'security':
        return (
          <div className="space-y-6">
            <div className="bg-[#e9e4dc] p-6 rounded-lg border border-[#a39b8f]">
              <h3 className="text-lg font-semibold text-black mb-4">Password</h3>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-[#3d3731] mb-2">Current Password</label>
                  <input
                    type="password"
                    name="currentPassword"
                    className="w-full px-4 py-2 border border-[#a39b8f] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-[#3d3731] mb-2">New Password</label>
                  <input
                    type="password"
                    name="newPassword"
                    className="w-full px-4 py-2 border border-[#a39b8f] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-[#3d3731] mb-2">Confirm New Password</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    className="w-full px-4 py-2 border border-[#a39b8f] rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                  />
                </div>
                <button className="px-4 py-2 bg-black text-white rounded-md hover:bg-[#3d3731] transition-colors duration-150">
                  Update Password
                </button>
              </div>
            </div>

            <div className="bg-[#e9e4dc] p-6 rounded-lg border border-[#a39b8f]">
              <h3 className="text-lg font-semibold text-black mb-4">Two-Factor Authentication</h3>
              <p className="text-sm text-[#3d3731] mb-4">
                Add an extra layer of security to your account by enabling two-factor authentication.
              </p>
              <button className="px-4 py-2 bg-black text-white rounded-md hover:bg-[#3d3731] transition-colors duration-150">
                Enable 2FA
              </button>
            </div>

            <div className="bg-[#e9e4dc] p-6 rounded-lg border border-[#a39b8f]">
              <h3 className="text-lg font-semibold text-red-600 mb-4">Danger Zone</h3>
              <p className="text-sm text-[#3d3731] mb-4">
                Once you delete your account, there is no going back. Please be certain.
              </p>
              <button className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors duration-150">
                Delete Account
              </button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="h-full flex flex-col bg-[#f5f1eb]">
      {/* Header - Fixed */}
      <div className="bg-[#e9e4dc] border-b border-[#a39b8f] sticky top-0 z-10">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col space-y-4 py-4">
            <h1 className="text-2xl font-semibold text-black">Settings</h1>

            {/* Success/Error Messages */}
            {successMessage && (
              <div className="p-4 bg-green-50 border border-green-200 rounded-md flex items-center text-green-800">
                <CheckCircle className="w-5 h-5 mr-2" />
                {successMessage}
              </div>
            )}

            {errorMessage && (
              <div className="p-4 bg-red-50 border border-red-200 rounded-md flex items-center text-red-800">
                <AlertCircle className="w-5 h-5 mr-2" />
                {errorMessage}
              </div>
            )}

            {/* Navigation Tabs */}
            <div className="flex space-x-6 border-b border-[#a39b8f]">
              <button
                onClick={() => setActiveTab('profile')}
                className={`flex items-center px-3 py-2 -mb-px text-sm font-medium ${
                  activeTab === 'profile'
                    ? 'border-b-2 border-black text-black'
                    : 'text-[#3d3731] hover:text-black'
                }`}
              >
                <User className="w-4 h-4 mr-2" />
                Profile
              </button>

              <button
                onClick={() => setActiveTab('notifications')}
                className={`flex items-center px-3 py-2 -mb-px text-sm font-medium ${
                  activeTab === 'notifications'
                    ? 'border-b-2 border-black text-black'
                    : 'text-[#3d3731] hover:text-black'
                }`}
              >
                <Bell className="w-4 h-4 mr-2" />
                Notifications
              </button>

              <button
                onClick={() => setActiveTab('security')}
                className={`flex items-center px-3 py-2 -mb-px text-sm font-medium ${
                  activeTab === 'security'
                    ? 'border-b-2 border-black text-black'
                    : 'text-[#3d3731] hover:text-black'
                }`}
              >
                <Shield className="w-4 h-4 mr-2" />
                Security
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content - Scrollable */}
      <div className="flex-1 overflow-auto">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <form onSubmit={handleSubmit} className="space-y-6 pb-24">
            <div className="space-y-6">
              {renderTab()}
              
            </div>
            {activeTab !== 'security' && (
              <div className="sticky bottom-0 bg-[#f5f1eb] py-4 border-t border-[#a39b8f] mt-6">
                <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      disabled={saving}
                      className="px-4 py-2 bg-black text-white rounded-md hover:bg-[#3d3731] transition-colors duration-150 flex items-center"
                    >
                      {saving ? (
                        <>
                          <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent mr-2" />
                          Saving...
                        </>
                      ) : (
                        <>
                          <Save className="w-4 h-4 mr-2" />
                          Save Changes
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;