import React from 'react';
import { X } from 'lucide-react';

const PracticeAreaFilter = ({ areas, selectedAreas, onSelectArea, onRemoveArea }) => {
  const toggleArea = (area) => {
    if (selectedAreas.includes(area)) {
      onRemoveArea(area);
    } else {
      onSelectArea(area);
    }
  };

  return (
    <div className="flex flex-wrap gap-2">
      {areas.map((area) => (
        <button
          key={area}
          onClick={() => toggleArea(area)}
          className={`group relative px-3 py-1 rounded-full text-sm transition-all duration-150
            ${selectedAreas.includes(area)
              ? 'bg-black text-white hover:bg-gray-800' 
              : 'bg-[#D8D1C7] text-black hover:bg-[#c5beb2]'
            }`}
        >
          {area}
          {selectedAreas.includes(area) && (
            <span 
              onClick={(e) => {
                e.stopPropagation();
                onRemoveArea(area);
              }}
              className="absolute -bottom-1 -left-1 w-4 h-4 bg-[#e9e4dc] rounded-full flex items-center justify-center border border-gray-300 hover:bg-[#d8d1c7]"
            >
              <X className="w-3 h-3" />
            </span>
          )}
        </button>
      ))}
    </div>
  );
};

export default PracticeAreaFilter;