// src/backend/versionService.js
import { supabase } from '../../../backend/supabase';
import { DocumentVersionControl } from './documentVersioning';

class VersionService {
  constructor() {
    this.versionControl = new DocumentVersionControl();
  }

  async createInitialVersion(documentId, content) {
    try {
      // Store initial version
      const hash = await this.versionControl.createCommit(
        documentId,
        content,
        null,
        'Initial version'
      );

      // Update document with version info
      await supabase
        .from('documents')
        .update({
          current_version: hash,
          version_count: 1
        })
        .eq('id', documentId);

      return { hash, error: null };
    } catch (error) {
      console.error('Error creating initial version:', error);
      return { hash: null, error };
    }
  }

  async getVersionHistory(documentId) {
    try {
      const history = await this.versionControl.getHistory(documentId);
      return { history, error: null };
    } catch (error) {
      console.error('Error getting version history:', error);
      return { history: null, error };
    }
  }

  async createNewVersion(documentId, content, message) {
    try {
      await this.versionControl.stageChanges(documentId, content);
      const hash = await this.versionControl.commitChanges(documentId, message);

      // Update document version info
      await supabase
        .from('documents')
        .update({
          current_version: hash,
          version_count: supabase.raw('version_count + 1')
        })
        .eq('id', documentId);

      return { hash, error: null };
    } catch (error) {
      console.error('Error creating new version:', error);
      return { hash: null, error };
    }
  }

  async compareVersions(hashA, hashB) {
    try {
      const differences = await this.versionControl.compareVersions(hashA, hashB);
      return { differences, error: null };
    } catch (error) {
      console.error('Error comparing versions:', error);
      return { differences: null, error };
    }
  }
}

export const versionService = new VersionService();