import React from 'react';
import { Mail, Phone } from 'lucide-react';

export const FirmDetails = ({ formData = {}, onChange, errors = {} }) => {
  // Format phone number as user types
  const formatPhoneNumber = (value) => {
    const phone = value.replace(/\D/g, '');
    if (phone.length <= 10) {
      return phone.replace(/(\d{3})(\d{3})?(\d{4})?/, function(_, p1, p2, p3) {
        let parts = [p1];
        if (p2) parts.push(p2);
        if (p3) parts.push(p3);
        return parts.join('-');
      });
    }
    return phone.slice(0, 10).replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  };

  const handlePhoneChange = (e) => {
    const formattedNumber = formatPhoneNumber(e.target.value);
    onChange({
      target: {
        name: e.target.name,
        value: formattedNumber
      }
    });
  };

  return (
    <div className="w-full space-y-6">
      {/* Work Email */}
      <div className="relative">
        <label className="absolute -top-2.5 left-4 px-2 bg-[#fef6ef] text-gray-500 text-sm">
          Work Email
        </label>
        <span className="absolute left-4 top-3.5 text-gray-400">
          <Mail size={20} />
        </span>
        <input
          type="email"
          name="workEmail"
          value={formData.workEmail || ''}
          onChange={onChange}
          placeholder="email@gmail.com"
          className={`w-full pl-12 pr-4 py-3 bg-transparent border ${errors.workEmail ? 'border-red-500' : 'border-gray-300'} rounded-full 
            focus:outline-none focus:border-gray-400
            placeholder-gray-400 text-gray-800`}
        />
        {errors.workEmail && (
          <p className="text-red-500 text-xs mt-1 ml-4">{errors.workEmail}</p>
        )}
      </div>

      {/* Secondary Email */}
      <div className="relative">
        <label className="absolute -top-2.5 left-4 px-2 bg-[#fef6ef] text-gray-500 text-sm">
          Secondary Email
        </label>
        <span className="absolute left-4 top-3.5 text-gray-400">
          <Mail size={20} />
        </span>
        <input
          type="email"
          name="secondaryEmail"
          value={formData.secondaryEmail || ''}
          onChange={onChange}
          placeholder="email@gmail.com"
          className={`w-full pl-12 pr-4 py-3 bg-transparent border ${errors.secondaryEmail ? 'border-red-500' : 'border-gray-300'} rounded-full 
            focus:outline-none focus:border-gray-400
            placeholder-gray-400 text-gray-800`}
        />
        {errors.secondaryEmail && (
          <p className="text-red-500 text-xs mt-1 ml-4">{errors.secondaryEmail}</p>
        )}
      </div>

      {/* Phone No */}
      <div className="relative">
        <label className="absolute -top-2.5 left-4 px-2 bg-[#fef6ef] text-gray-500 text-sm">
          Phone No
        </label>
        <span className="absolute left-4 top-3.5 text-gray-400">
          <Phone size={20} />
        </span>
        <input
          type="tel"
          name="phone"
          value={formData.phone || ''}
          onChange={handlePhoneChange}
          placeholder="404-111-1111"
          className={`w-full pl-12 pr-4 py-3 bg-transparent border ${errors.phone ? 'border-red-500' : 'border-gray-300'} rounded-full 
            focus:outline-none focus:border-gray-400
            placeholder-gray-400 text-gray-800`}
        />
        {errors.phone && (
          <p className="text-red-500 text-xs mt-1 ml-4">{errors.phone}</p>
        )}
      </div>

      {/* Secondary Phone No */}
      <div className="relative">
        <label className="absolute -top-2.5 left-4 px-2 bg-[#fef6ef] text-gray-500 text-sm">
          Secondary Phone No
        </label>
        <span className="absolute left-4 top-3.5 text-gray-400">
          <Phone size={20} />
        </span>
        <input
          type="tel"
          name="secondaryPhone"
          value={formData.secondaryPhone || ''}
          onChange={handlePhoneChange}
          placeholder="404-111-1111"
          className={`w-full pl-12 pr-4 py-3 bg-transparent border ${errors.secondaryPhone ? 'border-red-500' : 'border-gray-300'} rounded-full 
            focus:outline-none focus:border-gray-400
            placeholder-gray-400 text-gray-800`}
        />
        {errors.secondaryPhone && (
          <p className="text-red-500 text-xs mt-1 ml-4">{errors.secondaryPhone}</p>
        )}
      </div>
    </div>
  );
};

export default FirmDetails;