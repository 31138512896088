import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import TrustedFirms from './components/landing/TrustedFirms';
import ServicesOffered from './components/landing/ServicesOffered';
import WhyChooseUs from './components/landing/WhyChooseUs';
import Testimonials from './components/landing/Testimonials';
import ZoomTransition from './components/landing/ZoomTransition';
import Footer from './components/landing/Footer';
import FloatingCalendlyButton from './components/landing/FloatingButton';
import SectionDivider from './components/landing/SectionDivider';

const LandingPage = () => {
  const navigate = useNavigate();
  const darkBrownGradientRef = useRef(null);
  const lightBrownGradientRef = useRef(null);
  const canvasRef = useRef(null);
  const [isBlackAndWhite, setIsBlackAndWhite] = useState(false);

  const handleSignUp = () => {
    navigate('/signup');
  };

  const navigateToLawyerSearch = () => {
    navigate('/coming-soon');
  };

  const navigateToCompanyPage = () => {
    navigate('/company');
  };

  const navigateToHomePage = () => {
    navigate('/');
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    let mouse = { x: 0, y: 0 };

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      canvas.style.width = '100%';
      canvas.style.height = '100%';
    };

    const createGrid = () => {
      const gridSize = 30;
      const rows = Math.ceil(canvas.height / gridSize);
      const cols = Math.ceil(canvas.width / gridSize);
      const grid = [];

      for (let y = 0; y <= rows; y++) {
        for (let x = 0; x <= cols; x++) {
          grid.push({
            x: x * gridSize,
            y: y * gridSize,
            baseX: x * gridSize,
            baseY: y * gridSize,
          });
        }
      }

      return { grid, rows, cols };
    };

    let { grid, rows, cols } = createGrid();

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.beginPath();
      ctx.strokeStyle = isBlackAndWhite ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.1)';
      ctx.lineWidth = 0.5;

      for (let i = 0; i < grid.length; i++) {
        const point = grid[i];
        const distX = mouse.x - point.baseX;
        const distY = mouse.y - point.baseY;
        const distance = Math.sqrt(distX * distX + distY * distY);
        const maxDistance = 150;

        if (distance < maxDistance) {
          const force = (1 - distance / maxDistance) * 0.2;
          point.x = point.baseX - distX * force;
          point.y = point.baseY - distY * force;
        } else {
          point.x += (point.baseX - point.x) * 0.1;
          point.y += (point.baseY - point.y) * 0.1;
        }

        // Draw horizontal lines
        if (i < grid.length - 1 && (i + 1) % (cols + 1) !== 0) {
          ctx.moveTo(point.x, point.y);
          ctx.lineTo(grid[i + 1].x, grid[i + 1].y);
        }

        // Draw vertical lines
        if (i + cols + 1 < grid.length) {
          ctx.moveTo(point.x, point.y);
          ctx.lineTo(grid[i + cols + 1].x, grid[i + cols + 1].y);
        }
      }

      ctx.stroke();
      animationFrameId = requestAnimationFrame(animate);
    };

    const handleMouseMove = (event) => {
      mouse.x = event.clientX;
      mouse.y = event.clientY;
    };

    const handleResize = () => {
      resizeCanvas();
      ({ grid, rows, cols } = createGrid());
    };

    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (darkBrownGradientRef.current) {
        darkBrownGradientRef.current.style.transform = `rotate(${scrollY * 0.1}deg)`;
      }
      if (lightBrownGradientRef.current) {
        lightBrownGradientRef.current.style.transform = `rotate(${-scrollY * 0.1}deg)`;
      }
    };

    resizeCanvas();
    window.addEventListener('resize', handleResize);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('scroll', handleScroll);
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('scroll', handleScroll);
      cancelAnimationFrame(animationFrameId);
    };
  }, [isBlackAndWhite]);

  const toggleBlackAndWhite = () => {
    setIsBlackAndWhite(!isBlackAndWhite);
  };

  const baseStyles = "transition-all duration-300";
  const colorStyles = isBlackAndWhite 
    ? "bg-white bg-opacity-90 text-black" 
    : "bg-black text-white";
  const gapColorStyles = isBlackAndWhite 
    ? "bg-gray-100 bg-opacity-80" 
    : "bg-[#2E1A0E] bg-opacity-80";

  return (
    <div className={`min-h-screen flex flex-col relative overflow-hidden font-sans ${baseStyles} ${colorStyles} bg-black`}>
      <canvas 
        ref={canvasRef} 
        className="fixed inset-0 pointer-events-none" 
        style={{ zIndex: 1 }}
      />

      {!isBlackAndWhite && (
        <>
          <div
            ref={darkBrownGradientRef}
            className="fixed -top-1/4 -right-1/4 w-2/3 h-2/3 bg-[#964B00] rounded-full filter blur-[100px] opacity-30 origin-center"
            style={{ zIndex: 2 }}
          ></div>
          <div
            ref={lightBrownGradientRef}
            className="fixed -bottom-1/3 -left-1/4 w-3/4 h-3/4 bg-[#FFC499] rounded-full filter blur-[100px] opacity-30 origin-center"
            style={{ zIndex: 2 }}
          ></div>
        </>
      )}

      <div className="relative z-10 flex flex-col min-h-screen bg-transparent">
        <header className="p-6 flex justify-between items-center">
          <div className="flex items-center">
            <svg className="w-8 h-8 mr-2" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
              {/* Add your Lawdie logo SVG here */}
            </svg>
            <span className="text-xl font-bold cursor-pointer" onClick={toggleBlackAndWhite}>
              Lawdie
            </span>
          </div>
          <nav>
            <ul className="flex space-x-6 items-center">
            <li>
                <button className="hover:underline" onClick={navigateToHomePage}>Home</button>
              </li>
              <li>
                <button className="hover:underline" onClick={navigateToCompanyPage}>Company</button>
              </li>
              <li>
                <button className="hover:underline" onClick={navigateToLawyerSearch}>
                  Lawyer Search <span className={`${isBlackAndWhite ? "bg-gradient-to-r from-gray-600 to-gray-400 text-transparent bg-clip-text" : "bg-gradient-to-r from-[#FFC499] to-[#FFC499] text-transparent bg-clip-text"}`}>(BETA)</span>
                </button>
              </li>
              <li>
                <button 
                  onClick={handleSignUp}
                  className={`border px-4 py-1 rounded-full hover:bg-opacity-80 transition duration-300 ${
                    isBlackAndWhite ? "border-black hover:bg-gray-200" : "border-white hover:bg-white hover:text-black"
                  }`}
                >
                  Login
                </button>
              </li>
            </ul>
          </nav>
        </header>

        <main className="flex-grow flex flex-col">
          <div className="flex flex-col justify-center items-center px-4 py-16 text-center">
            <h1 className="text-7xl font-bold mb-8">
              <div>Attract</div>
              <div className={`bg-gradient-to-r ${isBlackAndWhite ? "from-gray-700 via-gray-500 to-gray-300" : "from-[#5D341A] to-[#FFC499]"} text-transparent bg-clip-text`}>Referrals</div>
              <div>like never</div>
              <div>before</div>
            </h1>
            <div className="flex w-full max-w-md mb-8 space-x-4">
              <input
                type="email"
                placeholder="Email"
                className={`w-2/3 px-6 py-4 rounded-full focus:outline-none ${baseStyles} ${
                  isBlackAndWhite ? "bg-gray-200 text-black" : "bg-gray-800 text-white"
                }`}
              />
              <button
                className={`w-1/3 px-6 py-4 rounded-full focus:outline-none ${baseStyles} ${
                  isBlackAndWhite ? "bg-gray-800 text-white" : "bg-[#2E1A0E] text-white hover:bg-[#FFC499] hover:text-[#2E1A0E]"
                }`}
              >
                Attract
              </button>
            </div>
          </div>

          <div className={isBlackAndWhite ? "bg-white" : "bg-white bg-opacity-20"}>
            <TrustedFirms isBlackAndWhite={isBlackAndWhite} />  
          </div>

          <SectionDivider isBlackAndWhite={isBlackAndWhite} />

          <ZoomTransition isBlackAndWhite={isBlackAndWhite} />

          <div className={isBlackAndWhite ? "bg-white" : "bg-white bg-opacity-20"}>
            <ServicesOffered isBlackAndWhite={isBlackAndWhite} />
          </div>

          <div className={isBlackAndWhite ? 'bg-white' : 'bg-[#0F0B07]'}>
            <WhyChooseUs isBlackAndWhite={isBlackAndWhite} />
          </div>

          <SectionDivider isBlackAndWhite={isBlackAndWhite} />

          <Testimonials isBlackAndWhite={isBlackAndWhite} />
        </main>

        <div 
          className={`w-full h-[2px] ${
            isBlackAndWhite 
              ? "bg-black" 
              : "bg-[#3d3326]"
          }`}
          style={{
            backgroundImage: `linear-gradient(to right, ${isBlackAndWhite ? 'black' : '#3d3326'} 50%, rgba(255, 255, 255, 0) 0%)`,
            backgroundPosition: 'top',
            backgroundSize: '20px 2px',
            backgroundRepeat: 'repeat-x'
          }}
        ></div>

        <Footer isBlackAndWhite={isBlackAndWhite} />
        <FloatingCalendlyButton isBlackAndWhite={isBlackAndWhite} />
      </div>
    </div>
  );
};

export default LandingPage;