import React from 'react';

const LienPagination = ({ 
  currentPage, 
  totalLiens, 
  liensPerPage, 
  onPageChange 
}) => {
  // Calculate total pages
  const totalPages = Math.ceil(totalLiens / liensPerPage);
  
  // Generate page numbers to display
  const getPageNumbers = () => {
    let pages = [];
    if (totalPages <= 7) {
      // If 7 or fewer pages, show all
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Complex pagination for many pages
      if (currentPage <= 3) {
        // Near start
        for (let i = 1; i <= 5; i++) pages.push(i);
        pages.push('...');
        pages.push(totalPages);
      } else if (currentPage >= totalPages - 2) {
        // Near end
        pages.push(1);
        pages.push('...');
        for (let i = totalPages - 4; i <= totalPages; i++) pages.push(i);
      } else {
        // Middle
        pages.push(1);
        pages.push('...');
        for (let i = currentPage - 1; i <= currentPage + 1; i++) pages.push(i);
        pages.push('...');
        pages.push(totalPages);
      }
    }
    return pages;
  };

  const handlePageClick = (page) => {
    if (page === '...' || page === currentPage) return;
    onPageChange(page);
  };

  const startIndex = (currentPage - 1) * liensPerPage + 1;
  const endIndex = Math.min(startIndex + liensPerPage - 1, totalLiens);

  return (
    <div className="flex items-center justify-between px-8 py-4 bg-[#e9e4dc] border-t border-[#a39b8f]">
      <div className="flex items-center text-sm text-[#3d3731]">
        {totalLiens > 0 ? (
          <span>
            Showing {startIndex} to {endIndex} of {totalLiens} liens
          </span>
        ) : (
          <span>No liens to display</span>
        )}
      </div>
      
      {totalPages > 1 && (
        <div className="flex items-center space-x-1">
          <button
            onClick={() => currentPage > 1 && handlePageClick(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-3 py-1 rounded-md text-sm ${
              currentPage === 1
                ? 'bg-[#d8d1c7] text-[#a39b8f] cursor-not-allowed'
                : 'bg-[#d8d1c7] text-[#3d3731] hover:bg-[#c5beb2] border border-[#a39b8f]'
            }`}
          >
            Previous
          </button>
          
          {getPageNumbers().map((page, index) => (
            <button
              key={index}
              onClick={() => handlePageClick(page)}
              disabled={page === '...'}
              className={`px-3 py-1 rounded-md text-sm ${
                page === currentPage
                  ? 'bg-black text-white'
                  : page === '...'
                  ? 'bg-transparent text-[#3d3731] cursor-default'
                  : 'bg-[#d8d1c7] text-[#3d3731] hover:bg-[#c5beb2] border border-[#a39b8f]'
              }`}
            >
              {page}
            </button>
          ))}
          
          <button
            onClick={() => currentPage < totalPages && handlePageClick(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`px-3 py-1 rounded-md text-sm ${
              currentPage === totalPages
                ? 'bg-[#d8d1c7] text-[#a39b8f] cursor-not-allowed'
                : 'bg-[#d8d1c7] text-[#3d3731] hover:bg-[#c5beb2] border border-[#a39b8f]'
            }`}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default LienPagination;