// src/components/chatbot/Kiwi-AI.js
import React, { useState, useEffect } from 'react';
import ChatBot from './ChatBot';
import Settings from './Settings';
import Navigation from './Navigation';
import axiosInstance from '../../axiosInstance';

const KiwiAI = () => {
  const [selectedComponent, setSelectedComponent] = useState('chat');
  const [connectionStatus, setConnectionStatus] = useState({
    isConnected: false,
    error: null
  });

  // Test API connection on mount
  useEffect(() => {
    const testConnection = async () => {
      try {
        const response = await axiosInstance.get('/health');
        console.log('API Connection successful:', response.data);
        setConnectionStatus({
          isConnected: true,
          error: null
        });
      } catch (error) {
        console.error('API Connection error:', error);
        setConnectionStatus({
          isConnected: false,
          error: error.message
        });
      }
    };
    
    testConnection();
  }, []);

  const renderMainComponent = () => {
    switch (selectedComponent) {
      case 'chat':
        return <ChatBot 
          isConnected={connectionStatus.isConnected} 
          connectionError={connectionStatus.error} 
        />;
      case 'settings':
        return <Settings />;
      default:
        return <ChatBot 
          isConnected={connectionStatus.isConnected} 
          connectionError={connectionStatus.error} 
        />;
    }
  };

  // Show connection error if API is not available
  if (connectionStatus.error && !connectionStatus.isConnected) {
    return (
      <div className="flex h-screen bg-[#34261E] overflow-hidden">
        <Navigation 
          selectedComponent={selectedComponent}
          onSelectComponent={setSelectedComponent}
        />
        <div className="flex-1 flex flex-col items-center justify-center text-white">
          <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-6 max-w-md w-full mx-4">
            <h2 className="text-xl font-semibold mb-2">Connection Error</h2>
            <p className="text-red-400">
              Unable to connect to the AI service. Please try again later.
            </p>
            <p className="text-sm text-red-400/70 mt-2">
              Error: {connectionStatus.error}
            </p>
            <button
              onClick={() => window.location.reload()}
              className="mt-4 px-4 py-2 bg-red-500/20 hover:bg-red-500/30 rounded-md transition-colors duration-150"
            >
              Retry Connection
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-[#34261E] overflow-hidden">
      <Navigation 
        selectedComponent={selectedComponent}
        onSelectComponent={setSelectedComponent}
      />
      {renderMainComponent()}
    </div>
  );
};

export default KiwiAI;