import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ChevronRight, Facebook, Twitter, Linkedin, Plus } from 'lucide-react';
import { publicReferralService } from '../../backend/publicReferralsService';
import BookmarkButton from './BookmarkButton';

const ExploreDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const result = await publicReferralService.fetchPublicReferrals();
        if (!result.success) throw new Error(result.error);
        const foundPost = result.data.find(p => p.id === id);
        if (!foundPost) throw new Error('Post not found');
        setPost(foundPost);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen bg-[#e9e4dc] flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-black"></div>
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="min-h-screen bg-[#e9e4dc] p-8">
        <div className="max-w-4xl mx-auto">
          <div className="p-4 bg-red-100 text-red-700 rounded-md">
            {error || 'Post not found'}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#e9e4dc]">
       {/* Header with border */}
       <div className="border-b border-white">
        <div className="max-w-7xl mx-auto px-4 py-6">
          <div className="flex justify-between items-center">
            <h1 className="text-3xl font-bold">Lawdie</h1>
            <div className="flex items-center space-x-2">
              <button className="p-2 bg-[#33022e] text-white rounded-md">
                <Plus className="w-5 h-5" />
              </button>
              <button className="px-4 py-2 bg-white hover:bg-gray-50 text-black font-semibold rounded-md">
                View Saved Cases
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 py-6">
        <div className="flex gap-8">
          <div className="flex-1">
            {/* Path Bar */}
            <div className="bg-white rounded-md py-2 px-3 mb-6 flex items-center space-x-2 text-xs font-['Raleway']">
              <span>Georgia</span>
              <ChevronRight className="w-3 h-3" />
              <span>Personal Injury</span>
              <ChevronRight className="w-3 h-3" />
              <span>Current/Former Client</span>
              <ChevronRight className="w-3 h-3" />
              <span>STATUS: {post.status.toUpperCase()}</span>
            </div>

            <div>
              {/* Title and Content */}
              <div className="flex items-center space-x-3 mb-6">
                <h2 className="text-2xl font-bold font-['Raleway']">{post.title}</h2>
                <BookmarkButton post={post} />
              </div>
              <div className="mb-8">
                <p className="mb-4 text-gray-700 whitespace-pre-line font-['Raleway']">
                  {post.description}
                </p>
                {post.incident_date && (
                  <p className="mb-4 text-gray-700 whitespace-pre-line">
                    Incident took place on {new Date(post.incident_date).toLocaleDateString()}.
                  </p>
                )}
                {post.location && (
                  <p className="text-gray-700 whitespace-pre-line">
                    {post.location}
                  </p>
                )}
              </div>

              {/* Share Section and Line */}
              <div className="max-w-3xl">
                <div className="bg-[#3a302e] text-white p-4 rounded-lg mb-8 flex items-center justify-between font-['Raleway']">
                  <span>Like what you see? Share with a friend.</span>
                  <div className="flex space-x-4">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0 0 50 50">
                        <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M37,19h-2c-2.14,0-3,0.5-3,2 v3h5l-1,5h-4v15h-5V29h-4v-5h4v-3c0-4,2-7,6-7c2.9,0,4,1,4,1V19z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0 0 50 50">
                      <path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0 0 50 50">
                      <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"></path>
                    </svg>
                  </div>
                </div>

                <div className="border-t border-gray-600 mb-8"></div>
              </div>

              {/* Related Practice Areas */}
              <h3 className="text-2xl font-semibold mb-4 font-['Raleway']">Related Practice Areas</h3>
              <div className="grid grid-cols-3 gap-4 mb-8">
                {[1, 2, 3].map((item) => (
                  <div key={item} className="bg-white p-4 rounded-lg">
                    <div className="w-2 h-2 bg-black rounded-full mb-2" />
                    <h4 className="font-medium">Personal Injury</h4>
                    <div className="text-sm text-gray-600 flex justify-between items-center">
                      <span>Oct 19</span>
                      <span>Active</span>
                    </div>
                  </div>
                ))}
              </div>

              {/* Footer */}
              <div className="text-center mb-4">
                <a href="#" className="text-gray-600 hover:text-gray-800">
                  Lawdie Inc. All Rights Reserved - 169 Madison Ave STE 11697 New York, NY 10016
                </a>
              </div>
              <div className="text-sm text-gray-600 flex justify-center items-center py-4">
                <span>Made with love in the heart of Atlanta</span>
                <span className="text-red-500 ml-2">❤</span>
              </div>
            </div>
          </div>

          {/* Right Sidebar */}
          <div className="w-80">
            {/* Profile Card */}
            <div className="bg-[#34261e] text-white p-6 rounded-lg mb-6">
              <img 
                src={post.profile_pic || "/api/placeholder/80/80"}
                alt={post.username}
                className="w-20 h-20 rounded-lg mb-4"
              />
              <h3 className="text-xl font-semibold mb-2 font-['Raleway']">{post.username}</h3>
              <p className="text-sm mb-4 font-['Raleway']">Founder of SAAS First - the Best AI and Data-Driven Customer Engagement Tool</p>
              <p className="text-sm font-['Raleway']">With 11 years in SaaS, I've built MillionVerifier and SAAS First. Passionate about SaaS, data, and AI. Let's connect if you share the same drive for success!</p>
              <a 
                href="#" 
                className="inline-flex items-center mt-4 text-blue-400 hover:text-blue-300"
              >
                <Linkedin className="w-5 h-5 mr-2" />
                LinkedIn Profile
              </a>
            </div>

            {/* Interested Box */}
            <div className="bg-[#3a302e] text-white p-6 rounded-lg font-['Raleway']">
              <h4 className="font-semibold mb-4">Interested?</h4>
              <button className="w-full bg-gray-200 text-black py-2 rounded-md hover:bg-gray-300 transition-colors">
                Respond
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreDetailPage;