import React, { useState, useRef, useEffect } from 'react';
import { MoreVertical } from 'lucide-react';

const MetricDropdown = ({ referrals, onTimeframeChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTimeframe, setSelectedTimeframe] = useState('ALL');
  const dropdownRef = useRef(null);

  const timeframes = [
    { id: '1W', label: '1 Week', days: 7 },
    { id: '1M', label: '1 Month', days: 30 },
    { id: '6M', label: '6 Months', days: 180 },
    { id: '1Y', label: '1 Year', days: 365 },
    { id: 'ALL', label: 'All Time', days: null }
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const calculateMetrics = (timeframeId) => {
    const now = new Date();
    const timeframe = timeframes.find(t => t.id === timeframeId);
    
    if (!timeframe) return { count: 0, successRate: 0 };

    const filteredReferrals = timeframe.days === null ? referrals :
      referrals.filter(ref => {
        const referralDate = new Date(ref.created_at);
        const cutoffDate = new Date(now.getTime() - (timeframe.days * 24 * 60 * 60 * 1000));
        return referralDate >= cutoffDate;
      });

    const completedCount = filteredReferrals.filter(ref => ref.status === 'completed').length;
    const successRate = filteredReferrals.length > 0 ?
      Math.round((completedCount / filteredReferrals.length) * 100) : 0;

    return {
      count: filteredReferrals.length,
      successRate
    };
  };

  const handleSelect = (timeframe) => {
    setSelectedTimeframe(timeframe.id);
    const metrics = calculateMetrics(timeframe.id);
    onTimeframeChange(timeframe.id, metrics);
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-[#3d3731] hover:text-black"
      >
        <MoreVertical className="w-5 h-5" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-1 w-48 bg-[#e9e4dc] rounded-lg shadow-sm border border-[#d8d1c7] z-50">
          <div className="py-1">
            {timeframes.map((timeframe) => {
              const metrics = calculateMetrics(timeframe.id);
              const isSelected = selectedTimeframe === timeframe.id;
              
              return (
                <button
                  key={timeframe.id}
                  onClick={() => handleSelect(timeframe)}
                  className={`w-full text-left px-3 py-2 text-sm ${
                    isSelected 
                      ? 'bg-[#d8d1c7] text-[#3d3731]' 
                      : 'text-[#3d3731] hover:bg-[#d8d1c7]'
                  } transition-colors duration-150`}
                >
                  <div className="flex justify-between items-center">
                    <span>{timeframe.label}</span>
                    <span className="text-[#a39b8f]">{metrics.count}</span>
                  </div>
                </button>
              );
            })}
          </div>
          
          <div className="border-t border-[#d8d1c7] px-3 py-2">
            <div className="flex justify-between items-center text-sm">
              <span className="text-[#a39b8f]">Success rate</span>
              <span className="text-[#3d3731]">
                {calculateMetrics(selectedTimeframe).successRate}%
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MetricDropdown;