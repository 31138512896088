// src/components/chatbot/ChatBot.js
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ChatList from './ChatList';
import ChatArea from './ChatArea';
import { chatPersistenceService } from '../../backend/chatPersistenceService';
import { emailService } from '../../backend/gmailService';
import axiosInstance from '../../axiosInstance';

const ChatBot = () => {
  const [chats, setChats] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [streamingMessage, setStreamingMessage] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [emailState, setEmailState] = useState({
    isEmailFlow: false,
    step: null,
    emailDetails: { to: '', subject: '', body: '' }
  });
  const location = useLocation();

  // Load initial chats
  useEffect(() => {
    const loadInitialChats = async () => {
      try {
        const loadedChats = await chatPersistenceService.loadChats();
        setChats(loadedChats.map(chat => ({
          ...chat,
          messages: null
        })));
        setIsInitialLoad(false);
      } catch (error) {
        console.error('Error loading chats:', error);
        setError('Failed to load chats');
        setIsInitialLoad(false);
      }
    };
    loadInitialChats();
  }, []);

  // Handle Gmail auth flow
  useEffect(() => {
    if (location.state?.gmailAuthSuccess) {
      handleGmailAuthSuccess();
    } else if (location.state?.gmailAuthError) {
      handleGmailAuthError();
    }
  }, [location.state]);

  // Handle Gmail authentication
  const handleGmailAuth = async () => {
    try {
      localStorage.setItem('currentChatId', selectedChatId);
      const authUrl = await emailService.getGmailAuthUrl();
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error getting Gmail auth URL:', error);
      addSystemMessage('Failed to connect to Gmail. Please try again.');
    }
  };

  const handleGmailAuthSuccess = () => {
    const successMessage = {
      id: Date.now(),
      type: 'assistant',
      content: 'Gmail connected successfully! Please provide the recipient\'s email address.',
      created_at: new Date().toISOString(),
      avatar: '/ai_logo.png'
    };
    
    addAssistantMessage(successMessage);
    setEmailState(prev => ({
      ...prev,
      isEmailFlow: true,
      step: 'details'
    }));
  };

  const handleGmailAuthError = () => {
    addSystemMessage('Failed to connect to Gmail. Please try again.');
  };

  // Message handling functions
  const addAssistantMessage = async (message) => {
    if (!selectedChatId) return;
    
    await chatPersistenceService.saveMessage(selectedChatId, message);
    setChats(prevChats => 
      prevChats.map(chat => 
        chat.id === selectedChatId 
          ? {
              ...chat,
              messages: [...(chat.messages || []), message],
              last_message: message.content,
              updated_at: message.created_at
            }
          : chat
      )
    );
  };

  const addSystemMessage = async (content) => {
    const message = {
      id: Date.now(),
      type: 'system',
      content,
      created_at: new Date().toISOString(),
      avatar: '/ai_logo.png'
    };
    await addAssistantMessage(message);
  };

  // Email flow handling
  const handleEmailFlow = async (userInput) => {
    if (emailState.step === 'provider-selection') {
      const lowercaseInput = userInput.toLowerCase();
      if (lowercaseInput.includes('gmail')) {
        handleGmailAuth();
      } else {
        addAssistantMessage({
          id: Date.now(),
          type: 'assistant',
          content: 'Currently, I only support Gmail. Would you like to proceed with Gmail?',
          created_at: new Date().toISOString(),
          avatar: '/ai_logo.png'
        });
      }
    } else if (emailState.step === 'details') {
      if (!emailState.emailDetails.to) {
        setEmailState(prev => ({
          ...prev,
          emailDetails: { ...prev.emailDetails, to: userInput }
        }));
        addAssistantMessage({
          id: Date.now(),
          type: 'assistant',
          content: 'Great! Now please provide the subject of your email.',
          created_at: new Date().toISOString(),
          avatar: '/ai_logo.png'
        });
      } else if (!emailState.emailDetails.subject) {
        setEmailState(prev => ({
          ...prev,
          emailDetails: { ...prev.emailDetails, subject: userInput }
        }));
        addAssistantMessage({
          id: Date.now(),
          type: 'assistant',
          content: 'Perfect! Now please type the body of your email.',
          created_at: new Date().toISOString(),
          avatar: '/ai_logo.png'
        });
      } else if (!emailState.emailDetails.body) {
        setEmailState(prev => ({
          ...prev,
          emailDetails: { ...prev.emailDetails, body: userInput }
        }));
        await handleSendEmail();
      }
    }
  };

  const handleSendEmail = async () => {
    try {
      await emailService.sendEmail(emailState.emailDetails);
      addAssistantMessage({
        id: Date.now(),
        type: 'assistant',
        content: 'Email sent successfully!',
        created_at: new Date().toISOString(),
        avatar: '/ai_logo.png'
      });
      setEmailState({
        isEmailFlow: false,
        step: null,
        emailDetails: { to: '', subject: '', body: '' }
      });
    } catch (error) {
      console.error('Error sending email:', error);
      addSystemMessage('Failed to send email. Please try again.');
    }
  };

  const initiateEmailFlow = () => {
    setEmailState({
      isEmailFlow: true,
      step: 'provider-selection',
      emailDetails: { to: '', subject: '', body: '' }
    });
    addAssistantMessage({
      id: Date.now(),
      type: 'assistant',
      content: 'I can help you send an email. Would you like to use Gmail?',
      created_at: new Date().toISOString(),
      avatar: '/ai_logo.png'
    });
  };

  // Chat management functions
  const createNewChat = async () => {
    try {
      const newChat = await chatPersistenceService.createChat("New Chat");
      
      const initialMessage = {
        id: Date.now(),
        type: 'assistant',
        content: 'Hello! I\'m Kiwi, your AI Paralegal assistant. How can I help you today?',
        created_at: new Date().toISOString(),
        avatar: '/ai_logo.png'
      };
      
      await chatPersistenceService.saveMessage(newChat.id, initialMessage);
      
      setChats(prevChats => [{
        ...newChat,
        messages: [initialMessage]
      }, ...prevChats]);
      setSelectedChatId(newChat.id);
      setError(null);
    } catch (error) {
      console.error('Error creating new chat:', error);
      setError('Failed to create new chat');
    }
  };

  const handleChatSelection = async (chatId) => {
    if (chatId === selectedChatId) return;
    
    const selectedChat = chats.find(chat => chat.id === chatId);
    
    if (!selectedChat.messages) {
      try {
        const messages = await chatPersistenceService.loadChatMessages(chatId);
        setChats(prevChats => 
          prevChats.map(chat => 
            chat.id === chatId 
              ? { ...chat, messages }
              : chat
          )
        );
      } catch (error) {
        console.error('Error loading chat messages:', error);
        setError('Failed to load chat messages');
        return;
      }
    }
    
    setSelectedChatId(chatId);
    setError(null);
  };

  // Main message sending function
  const handleSendMessage = async (userInput) => {
    if (!selectedChatId || isLoading) return;
    
    const userMessage = {
      id: Date.now(),
      type: 'user',
      content: userInput,
      created_at: new Date().toISOString(),
      avatar: '/user_input_photo.png'
    };

    // Add user message to UI immediately
    setChats(prevChats => 
      prevChats.map(chat => 
        chat.id === selectedChatId 
          ? {
              ...chat,
              messages: [...(chat.messages || []), userMessage],
              last_message: userInput,
              updated_at: new Date().toISOString()
            }
          : chat
      )
    );

    try {
      // Save user message
      await chatPersistenceService.saveMessage(selectedChatId, userMessage);

      // Handle email flow if active
      if (emailState.isEmailFlow) {
        await handleEmailFlow(userInput);
        return;
      }

      // Check if user wants to send an email
      if (userInput.toLowerCase().includes('send') && userInput.toLowerCase().includes('email')) {
        initiateEmailFlow();
        return;
      }

      // Start AI stream
      setIsStreaming(true);
      setStreamingMessage('');
      setIsLoading(true);
      setError(null);

      const streamUrl = new URL(`${axiosInstance.defaults.baseURL}/stream-ai`);
      streamUrl.searchParams.append('prompt', userInput);
      streamUrl.searchParams.append('threadId', chats.find(chat => chat.id === selectedChatId)?.threadId || '');

      // Create EventSource with credentials and force CORS mode
      const eventSource = new EventSource(streamUrl, { 
        withCredentials: true 
      });
      let fullMessage = '';

      eventSource.onmessage = async (event) => {
        try {
          if (event.data === '[DONE]') {
            eventSource.close();
            
            if (fullMessage) {
              const assistantMessage = {
                id: Date.now() + 1,
                type: 'assistant',
                content: fullMessage,
                created_at: new Date().toISOString(),
                avatar: '/ai_logo.png'
              };

              await chatPersistenceService.saveMessage(selectedChatId, assistantMessage);
              
              setChats(prevChats => 
                prevChats.map(chat => 
                  chat.id === selectedChatId 
                    ? {
                        ...chat,
                        messages: [...(chat.messages || []), assistantMessage],
                        last_message: assistantMessage.content,
                        updated_at: assistantMessage.created_at
                      }
                    : chat
                )
              );
            }
            setIsStreaming(false);
            setIsLoading(false);
            return;
          }

          const data = JSON.parse(event.data);
          
          if (data.error) {
            throw new Error(data.error);
          }

          const content = data.choices?.[0]?.delta?.content;
          if (content) {
            fullMessage += content;
            setStreamingMessage(fullMessage);
          }
        } catch (e) {
          console.error('Error processing stream data:', e);
          eventSource.close();
          setError(e.message || 'Error processing response');
          setIsStreaming(false);
          setIsLoading(false);
        }
      };

      eventSource.onerror = (error) => {
        console.error('EventSource error:', error);
        eventSource.close();
        setError('Connection error. Please try again.');
        setIsStreaming(false);
        setIsLoading(false);
      };

      // Cleanup function
      return () => {
        eventSource.close();
      };

    } catch (error) {
      console.error('Error in message handling:', error);
      setError(error.message || 'Failed to process your request');
      setIsStreaming(false);
      setIsLoading(false);
    }
  };

  const selectedChat = chats.find(chat => chat.id === selectedChatId);

  return (
    <div className="flex flex-1">
      <ChatList 
        chats={chats}
        selectedChatId={selectedChatId}
        onChatSelect={handleChatSelection}
        onCreateChat={createNewChat}
        isInitialLoad={isInitialLoad}
      />
      <ChatArea 
        selectedChat={selectedChat}
        onSendMessage={handleSendMessage}
        isLoading={isLoading}
        streamingMessage={streamingMessage}
        isStreaming={isStreaming}
        error={error}
        onCreateChat={createNewChat}
      />
    </div>
  );
};

export default ChatBot;