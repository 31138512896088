import React, { useState, useEffect } from 'react';
import { Search, Plus, ArrowLeft, Bookmark, MoreVertical } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { publicReferralService } from '../../backend/publicReferralsService';
import ExploreFormModal from './ExploreFormModal';
import SearchComponent from './SearchComponent';
import PracticeAreaFilter from './PracticeAreaFilter';
import PostActionsMenu from './PostsActionMenu';

const ExplorePage = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingPost, setEditingPost] = useState(null);
  const [posts, setPosts] = useState([]);
  const [originalPosts, setOriginalPosts] = useState([]);
  const [latestPosts, setLatestPosts] = useState([]);
  const [activeTab, setActiveTab] = useState('Recommended');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPracticeAreas, setSelectedPracticeAreas] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const practiceAreas = [
    'Medical Malpractice',
    'Immigration',
    'Personal Injury',
    'Trademark',
    'Divorce',
    'Estate',
    'Corporate',
    'Other'
  ];

  // Fetch data when tab changes
  useEffect(() => {
    fetchData();
  }, [activeTab]);

  // Apply filters whenever filters or original posts change
  useEffect(() => {
    if (originalPosts.length > 0) {
      filterPosts(originalPosts);
    }
  }, [selectedPracticeAreas, searchTerm, originalPosts]);

  const filterPosts = (postsToFilter) => {
    let filtered = [...postsToFilter];

    // Apply practice area filters
    if (selectedPracticeAreas.length > 0) {
      filtered = filtered.filter(post => 
        selectedPracticeAreas.includes(post.practice_area)
      );
    }

    // Apply search term filter
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(post =>
        post.title?.toLowerCase().includes(term) ||
        post.description?.toLowerCase().includes(term) ||
        post.practice_area?.toLowerCase().includes(term) ||
        post.username?.toLowerCase().includes(term)
      );
    }

    setPosts(filtered);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);
      
      let postsResult;
      if (activeTab === 'Recommended') {
        postsResult = await publicReferralService.fetchPublicReferrals();
      } else if (activeTab === 'Your Listings') {
        postsResult = await publicReferralService.fetchUserListings();
      } else if (activeTab === 'Following') {
        postsResult = await publicReferralService.fetchPublicReferrals();
      }

      const latestResult = await publicReferralService.fetchLatestPosts();

      if (!postsResult.success) throw new Error(postsResult.error);
      if (!latestResult.success) throw new Error(latestResult.error);

      setOriginalPosts(postsResult.data);
      // Initial filtering of the new data
      filterPosts(postsResult.data);
      setLatestPosts(latestResult.data);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    // Don't clear filters when changing tabs
  };

  const handlePostClick = (postId) => {
    navigate(`/explore/${postId}`);
  };

  const handleEdit = (post) => {
    setEditingPost(post);
    setIsModalOpen(true);
  };

  const handlePostDeleted = (postId) => {
    setPosts(prevPosts => prevPosts.filter(post => post.id !== postId));
    setOriginalPosts(prevPosts => prevPosts.filter(post => post.id !== postId));
    setLatestPosts(prevPosts => prevPosts.filter(post => post.id !== postId));
  };

  const handlePostCreated = async () => {
    setIsModalOpen(false);
    setEditingPost(null);
    await fetchData();
  };

  const handleAddPracticeArea = (area) => {
    setSelectedPracticeAreas(prev => [...prev, area]);
  };

  const handleRemovePracticeArea = (area) => {
    setSelectedPracticeAreas(prev => prev.filter(a => a !== area));
  };

  const getTabText = (tabName) => {
    const filterCount = selectedPracticeAreas.length + (searchTerm ? 1 : 0);
    if (filterCount === 0) return tabName;
    return `${tabName} (${filterCount} filters)`;
  };

  const getActiveFilters = () => {
    const filters = [...selectedPracticeAreas];
    if (searchTerm) filters.push('Search');
    return filters.join(', ');
  };

  return (
    <div className="min-h-screen bg-[#e9e4dc]">
      {/* Header */}
      <div className="relative border-b border-[#d8d1c7] py-4">
        <button
          onClick={() => navigate('/dashboard')}
          className="absolute left-6 top-1/2 -translate-y-1/2 flex items-center text-[#3d3731] hover:text-black transition-colors duration-150"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          <span>Back to Referral Hub</span>
        </button>
        <h1 className="text-2xl font-semibold text-center">Explore Referrals</h1>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex">
          {/* Main Content Area */}
          <div className="flex-1 pr-8">
            {/* Upload Header */}
            <div className="flex items-center justify-between mb-6">
              <button 
                onClick={() => setIsModalOpen(true)} 
                className="flex items-center cursor-pointer"
              >
                <Plus className="w-5 h-5 mr-2" />
                <span className="text-gray-600">Upload your own public referral</span>
              </button>
            </div>

            {/* Navigation with Filter Indicators */}
            <div className="mb-8">
              <div className="flex space-x-8 mb-2">
                {['Following', 'Recommended', 'Your Listings'].map((tab) => (
                  <button 
                    key={tab}
                    onClick={() => handleTabChange(tab)}
                    className={`text-sm font-medium transition-colors ${
                      activeTab === tab ? 'text-black' : 'text-gray-400 hover:text-gray-600'
                    }`}
                  >
                    {getTabText(tab)}
                  </button>
                ))}
              </div>
              {(selectedPracticeAreas.length > 0 || searchTerm) && (
                <div className="text-sm text-gray-500">
                  Filtered by: {getActiveFilters()}
                </div>
              )}
            </div>

            {/* Blog Posts */}
            <div className="space-y-6">
              {loading ? (
                <div className="flex justify-center py-8">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-black"></div>
                </div>
              ) : error ? (
                <div className="p-4 text-red-700 bg-red-100 border border-red-200 rounded-md">
                  {error}
                </div>
              ) : posts.length === 0 ? (
                <div className="text-center py-8 text-gray-500">
                  No posts found matching your filters
                </div>
              ) : (
                posts.map((post) => (
                  <div key={post.id} className="mb-8 max-w-3xl">
                    <div className="relative bg-[#e9e4dc] rounded-lg p-4 transition-all duration-200 ease-in-out transform hover:scale-[1.02] hover:bg-[#d8d1c7] hover:shadow-lg">
                      <div className="cursor-pointer" onClick={() => navigate(`/explore/${post.id}`)}>
                        <div className="flex items-center space-x-2">
                          <img 
                            src={post.profile_pic || "/api/placeholder/40/40"}
                            alt={post.username}
                            className="w-8 h-8 rounded-full"
                          />
                          <span className="font-medium">{post.username}</span>
                          <span className="text-gray-400">· {new Date(post.created_at).toLocaleDateString()}</span>
                        </div>
                        
                        <div className="max-w-2xl mt-3">
                          <h2 className="text-lg font-semibold">{post.title}</h2>
                          
                          <p className="text-gray-600 line-clamp-3 mb-3">
                            {post.description}
                          </p>
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="flex space-x-2">
                          <span className="px-3 py-1 bg-[#D8D1C7] rounded-full text-sm">
                            {post.practice_area}
                          </span>
                        </div>
                        <div className="absolute right-4 top-4">
                          <PostActionsMenu 
                            post={post}
                            onEdit={handleEdit}
                            onPostDeleted={handlePostDeleted}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          {/* Right Sidebar */}
          <div className="w-80">
            {/* View Saved Cases Button */}
            <button className="w-full px-6 py-2 bg-black text-white rounded-full mb-4 hover:bg-[#3d3731] transition-colors duration-150">
              View Saved Cases
            </button>

            {/* Search Bar */}
            <div className="mb-8">
              <SearchComponent 
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                className="w-full"
              />
            </div>

            {/* Latest Posts */}
            <div className="mb-8">
              <h3 className="flex items-center mb-4">
                <span className="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                Latest
              </h3>
              
              <div className="space-y-2">
                {latestPosts.map((post) => (
                  <div 
                    key={post.id} 
                    onClick={() => handlePostClick(post.id)} 
                    className="flex space-x-3 cursor-pointer hover:bg-[#d8d1c7] p-2 rounded-lg transition-duration-150"
                  >
                    <img 
                      src={post.profile_pic || "/api/placeholder/40/40"}
                      alt={`${post.user?.first_name} ${post.user?.last_name}`}
                      className="w-8 h-8 rounded-full"
                    />
                    <div>
                      <span className="block font-medium">{post.username}</span>
                      <p className="text-sm text-gray-600">{post.title}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Practice Areas */}
            <div>
              <h3 className="mb-4">Practice Areas</h3>
              <PracticeAreaFilter
                areas={practiceAreas}
                selectedAreas={selectedPracticeAreas}
                onSelectArea={handleAddPracticeArea}
                onRemoveArea={handleRemovePracticeArea}
              />
            </div>
          </div>
        </div>
      </div>

      <ExploreFormModal 
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEditingPost(null);
        }}
        onPostCreated={handlePostCreated}
        editingPost={editingPost}
      />
    </div>
  );
};

export default ExplorePage;